import { createColumnHelper } from '@tanstack/react-table';
import { Badge, Checkbox, Text } from '@chakra-ui/react';
import { User, UserStatusEnum } from '../../../../types/user';

const columnHelper = createColumnHelper<User>();

const setBadgeVariant = (status: string): string => {
  return (
    {
      [UserStatusEnum.ACTIVE]: 'green',
      [UserStatusEnum.PENDING]: 'orange',
      [UserStatusEnum.INACTIVE]: 'red',
    }[status] || status
  );
};

const setUserPermissionText = (role: string): string => {
  return (
    {
      ADMINISTRADOR: 'Administrador',
      FINANCEIRO: 'Financeiro',
      GERAL: 'Geral',
    }[role] || ' '
  );
};

export const usersTableColumn = [
  columnHelper.display({
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        size="lg"
        isChecked={table.getIsAllRowsSelected()}
        isIndeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        size="lg"
        isChecked={row.getIsSelected()}
        isIndeterminate={row.getIsSomeSelected()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
  }),
  columnHelper.accessor('fullName', {
    cell: (info) => (
      <Text cursor="pointer" onClick={info.row.getToggleSelectedHandler()}>
        {info.getValue()}
      </Text>
    ),
    header: 'Nome',
    enableColumnFilter: true,
    sortingFn: (a, b) => {
      return a.original.fullName.localeCompare(b.original.fullName);
    },
  }),
  columnHelper.accessor('username', {
    cell: (info) => {
      return (
        <Text cursor="pointer" onClick={info.row.getToggleSelectedHandler()}>
          {info.getValue()}
        </Text>
      );
    },
    header: 'E-mail',
    enableColumnFilter: true,
  }),
  columnHelper.accessor('customerUsers', {
    cell: (info) => {
      const localStorageUser = localStorage.getItem('user');
      const parsedUser = localStorageUser ? JSON.parse(localStorageUser) : {};
      if (parsedUser?.selectedCompany?.id) {
        const currentCustomerUser = info
          .getValue()
          .find(
            (customerUser) =>
              customerUser.customerId ===
              parsedUser?.selectedCompany?.customerId
          );

        return setUserPermissionText(
          currentCustomerUser?.policies &&
            currentCustomerUser?.policies.length > 0
            ? currentCustomerUser?.policies[0].name
            : ''
        );
      }
    },
    enableColumnFilter: true,
    sortingFn: (a, b) => {
      return a.original.customerUsers[0].policies[0].name.localeCompare(
        b.original.customerUsers[0].policies[0].name
      );
    },
    header: 'Permissão',
    meta: { maxWidth: '100px' },
  }),
  columnHelper.accessor('userStatus', {
    cell: (info) => {
      const userStatus =
        info.getValue() as unknown as keyof typeof UserStatusEnum;
      const variant = UserStatusEnum[userStatus];
      return (
        <Badge
          variant={setBadgeVariant(variant)}
          fontSize="sm"
          textTransform="none"
        >
          {UserStatusEnum[userStatus]}
        </Badge>
      );
    },
    header: 'Status',
    meta: { maxWidth: '160px' },
  }),
];
