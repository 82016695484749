import { createIcon } from '@chakra-ui/react';

export const WalletIcon = createIcon({
  displayName: 'WalletIcon',
  viewBox: '0 0 21 19',
  path: (
    <svg>
      <path d="M14.86 9.607h2.045v4.091h-2.046V9.607Z" fill="currentColor" />
      <path
        d="M18.948 4.493V2.447A2.047 2.047 0 0 0 16.904.402H3.612A3.072 3.072 0 0 0 .545 3.47v12.274c0 2.251 1.834 3.068 3.067 3.068h15.336a2.047 2.047 0 0 0 2.045-2.045V6.539a2.047 2.047 0 0 0-2.045-2.046ZM3.612 2.447h13.292v2.046H3.612a1.023 1.023 0 0 1 0-2.046Zm15.336 14.32H3.624c-.472-.012-1.034-.2-1.034-1.023V6.349c.32.116.661.19 1.022.19h15.336v10.228Z"
        fill="currentColor"
      />
    </svg>
  ),
});
