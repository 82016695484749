import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import EditProfileForms from '../EditProfileForms';

type EditProfileModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const EditProfileModal = ({ isOpen, onClose }: EditProfileModalProps) => {
  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontWeight={700}>Editar meus dados</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <EditProfileForms onClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditProfileModal;
