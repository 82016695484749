import { IRowHookInput } from 'dromo-uploader-react';
import {
  isExampleCpf,
  isValidCpf,
} from '../../../utils/validations/isValidCpf';
import { sanitizeString } from './sanitizeString';
import { sanitizeEmail } from '../../../utils/formatters/sanitizeEmail';
import { removeSpaces } from '../../../utils/formatters/sanitizeString';

export function getDromoRowHooks(
  existingCPFs: string[] | undefined
): ((record: IRowHookInput) => IRowHookInput)[] {
  return [
    (record) => {
      const newRecord = record;
      const {
        zipCode,
        address,
        complement,
        district,
        city,
        state,
        number,
        fullName,
        cellphone,
        identificationNumber,
        email,
        document,
        companyAddressId,
        deliveryType,
      } = newRecord.row;

      if (fullName) {
        const newValue = fullName.value;
        const sanitizedValue = sanitizeString(newValue);
        fullName.value = sanitizedValue;
      }

      if (cellphone) {
        const newValue = cellphone.value.replace(/\D/g, '');
        cellphone.value = newValue;
      }

      if (identificationNumber) {
        const newValue = removeSpaces(identificationNumber.value);
        identificationNumber.value = newValue;
      }

      if (email) {
        const newValue = sanitizeEmail(email.value);
        email.value = newValue;
      }

      if (zipCode) {
        const newValue = zipCode.value.replace(/\D/g, '');

        zipCode.value =
          newValue.length < 8 && newValue.length > 1
            ? newValue.padStart(8, '0')
            : newValue;
      }

      if (document) {
        const cpf = document.value;

        const value = cpf.replace(/\D/g, '');
        const editedCpf = value.length < 11 ? value.padStart(11, '0') : value;
        const newValue = isValidCpf(editedCpf) ? editedCpf : value;
        document.value = newValue;

        if (isExampleCpf(newValue)) {
          if (fullName) {
            fullName.info = [
              {
                message:
                  'Clique com o botão direito do mouse no número da linha à esquerda e selecione “Remover linha” para deletar o colaborador de exemplo',
                level: 'error',
              },
            ];
          }
          document.info = [
            {
              message:
                'Clique com o botão direito do mouse no número da linha à esquerda e selecione “Remover linha” para deletar o colaborador de exemplo',
              level: 'error',
            },
          ];
        } else {
          fullName.info = fullName?.info?.filter(
            (info) =>
              info.message !==
              'Clique com o botão direito do mouse no número da linha à esquerda e selecione “Remover linha” para deletar o colaborador de exemplo'
          );

          if (!isValidCpf(newValue)) {
            document.info = [
              {
                message: 'Ops... parece que o número não está certo',
                level: 'error',
              },
            ];
          } else if (existingCPFs?.includes(newValue)) {
            document.info = [
              {
                message: 'Você já cadastrou um colaborador com este CPF',
                level: 'error',
              },
            ];
          } else {
            document.info = [];
          }
        }
      }

      if (number) {
        const isAnyAddressFieldFilled = [
          zipCode,
          address,
          complement,
          district,
          city,
          state,
        ].some((field) => field?.value);

        if (!number.value && isAnyAddressFieldFilled) {
          number.info = [
            {
              message:
                'Você precisa preencher o número do endereço do colaborador, se houver',
              level: 'info',
            },
          ];
        } else {
          const newNumberInfo = number?.info?.filter(
            (info) =>
              info.message !==
              'Você precisa preencher o número do endereço do colaborador, se houver'
          );
          number.info = newNumberInfo;
        }
      }

      if (complement) {
        const isAnyAddressFieldFilled = [
          zipCode,
          address,
          number,
          district,
          city,
          state,
        ].some((field) => field?.value);

        if (!complement.value && isAnyAddressFieldFilled) {
          complement.info = [
            {
              message: 'Você precisa preencher o complemento, se houver',
              level: 'info',
            },
          ];
        } else {
          const newComplementInfo = complement?.info?.filter(
            (info) =>
              info.message !== 'Você precisa preencher o complemento, se houver'
          );
          complement.info = newComplementInfo;
        }
      }

      if (companyAddressId && deliveryType) {
        const companyAddress = companyAddressId.value;
        const delivery = deliveryType.value;

        if (companyAddress && delivery === 'Colaborador') {
          companyAddressId.info = [
            {
              message:
                'Para o cartão ser entregue neste endereço da empresa, a coluna “Local de entrega do cartão” não pode ser "Colaborador"',
              level: 'error',
            },
          ];
        } else {
          const newCompanyAddressIdInfo = companyAddressId?.info?.filter(
            (info) =>
              info.message !==
              'Para o cartão ser entregue neste endereço da empresa, a coluna “Local de entrega do cartão” não pode ser "Colaborador"'
          );
          companyAddressId.info = newCompanyAddressIdInfo;
        }
      }

      return newRecord;
    },
  ];
}
