import { createIcon } from '@chakra-ui/react';

export const CardIcon = createIcon({
  displayName: 'CardIcon',
  viewBox: '0 0 24 28',
  defaultProps: {
    boxSize: '24px',
  },
  path: (
    <path
      fill="currentColor"
      d="M19 3H5C3.67441 3.00159 2.40356 3.52888 1.46622 4.46622C0.528882 5.40356 0.00158786 6.67441 0 8L0 16C0.00158786 17.3256 0.528882 18.5964 1.46622 19.5338C2.40356 20.4711 3.67441 20.9984 5 21H19C20.3256 20.9984 21.5964 20.4711 22.5338 19.5338C23.4711 18.5964 23.9984 17.3256 24 16V8C23.9984 6.67441 23.4711 5.40356 22.5338 4.46622C21.5964 3.52888 20.3256 3.00159 19 3ZM5 5H19C19.7956 5 20.5587 5.31607 21.1213 5.87868C21.6839 6.44129 22 7.20435 22 8H2C2 7.20435 2.31607 6.44129 2.87868 5.87868C3.44129 5.31607 4.20435 5 5 5ZM19 19H5C4.20435 19 3.44129 18.6839 2.87868 18.1213C2.31607 17.5587 2 16.7956 2 16V10H22V16C22 16.7956 21.6839 17.5587 21.1213 18.1213C20.5587 18.6839 19.7956 19 19 19Z"
    />
  ),
});
