import { Text, TextProps } from '@chakra-ui/react';

const InfoText = (props: TextProps) => {
  return (
    <Text
      fontSize="sm"
      backgroundColor="gray.100"
      borderRadius="lg"
      padding="8px 12px"
      lineHeight={1.3}
      {...props}
    />
  );
};

export default InfoText;
