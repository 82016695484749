import {
  Button,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  forwardRef,
} from '@chakra-ui/react';
import { useState } from 'react';
import { EyeOpenIcon } from '../../assets/customIcons/EyeOpenIcon';
import { EyeCloseIcon } from '../../assets/customIcons/EyeCloseIcon';

const PasswordInput = forwardRef((props: InputProps, ref) => {
  const [shouldShowPassword, setShouldShowPassword] = useState(false);
  return (
    <InputGroup>
      <Input
        data-testid="password-input"
        ref={ref}
        paddingRight={'90px'}
        type={shouldShowPassword ? 'text' : 'password'}
        autoComplete="current-password"
        placeholder="Digite a sua senha"
        {...props}
      />
      <InputRightElement mr={4} width="fit-content">
        <Button
          data-testid="show-password-button"
          minWidth="105px"
          fontWeight={400}
          size="sm"
          fontSize="sm"
          colorScheme="gray"
          color="gray.800"
          background="gray.50"
          _hover={{
            background: 'gray.100',
          }}
          leftIcon={
            shouldShowPassword ? (
              <EyeCloseIcon boxSize={4} />
            ) : (
              <EyeOpenIcon boxSize={4} />
            )
          }
          onClick={() => setShouldShowPassword((prev) => !prev)}
        >
          {shouldShowPassword ? 'Ocultar' : 'Mostrar'}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
});

export default PasswordInput;
