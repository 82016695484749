import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { Company } from '../types/company';

type UserCompaniesStore = {
  userCompanies: Company[];
  selectedCompany: Company | undefined;
};

type Actions = {
  setSelectedCompany: (data?: Company) => void;
  setUserCompanies: (data: Company[]) => void;
  logoutAction: () => void;
  reset: () => void;
};

const initialState: UserCompaniesStore = {
  userCompanies: [],
  selectedCompany: undefined,
};

export const useUserCompaniesStore = create<UserCompaniesStore & Actions>()(
  persist(
    (set) => ({
      ...initialState,
      setSelectedCompany: (data) => set(() => ({ selectedCompany: data })),
      setUserCompanies: (data) => set(() => ({ userCompanies: data })),
      reset: () => {
        set(initialState);
      },
      logoutAction: () => {
        set({ userCompanies: [] });
      },
    }),
    {
      name: 'userCompanies',
      storage: createJSONStorage(() => localStorage),
    }
  )
);
