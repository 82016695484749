import { z } from 'zod';

export const UFs = [
  { value: 'AC', label: 'Acre' },
  { value: 'AL', label: 'Alagoas' },
  { value: 'AP', label: 'Amapá' },
  { value: 'AM', label: 'Amazonas' },
  { value: 'BA', label: 'Bahia' },
  { value: 'CE', label: 'Ceará' },
  { value: 'DF', label: 'Distrito Federal' },
  { value: 'ES', label: 'Espírito Santo' },
  { value: 'GO', label: 'Goiás' },
  { value: 'MA', label: 'Maranhão' },
  { value: 'MT', label: 'Mato Grosso' },
  { value: 'MS', label: 'Mato Grosso do Sul' },
  { value: 'MG', label: 'Minas Gerais' },
  { value: 'PA', label: 'Pará' },
  { value: 'PB', label: 'Paraíba' },
  { value: 'PR', label: 'Paraná' },
  { value: 'PE', label: 'Pernambuco' },
  { value: 'PI', label: 'Piauí' },
  { value: 'RJ', label: 'Rio de Janeiro' },
  { value: 'RN', label: 'Rio Grande do Norte' },
  { value: 'RS', label: 'Rio Grande do Sul' },
  { value: 'RO', label: 'Rondônia' },
  { value: 'RR', label: 'Roraima' },
  { value: 'SC', label: 'Santa Catarina' },
  { value: 'SP', label: 'São Paulo' },
  { value: 'SE', label: 'Sergipe' },
  { value: 'TO', label: 'Tocantins' },
] as const;

export const SEND_COMMS_OPTIONS = [
  'onAdmissionDate',
  'scheduled',
  'onRegistrationComplete',
  'decideLater',
] as const;

export const DOCUMENT_TYPE_OPTIONS = [
  'CPF',
  'CNPJ',
  'PASSPORT',
  'RG',
  'CIP',
  'CNH',
  'CTPS',
] as const;

export const SEND_CARD_OPTIONS = ['RESIDENTIAL', 'COMPANY'] as const;

export const CONVENTION_OPTIONS = ['jointly', 'separately'] as const;

export const CONVENTION_PERIODICITY_OPTIONS = ['daily', 'monthly'] as const;

export const SUPPORT_EMAIL_ADDRESS = 'ajuda@raiobeneficios.com' as const;
export const SUPPORT_WHATSAPP_NUMBER = '51 99244-8278' as const;
export const SUPPORT_WHATSAPP_URL = 'https://wa.me/5551992448278' as const;

export const ROWS_PER_PAGE_OPTIONS = [
  {
    value: 10,
    label: '10',
  },
  {
    value: 25,
    label: '25',
  },
  {
    value: 50,
    label: '50',
  },
];

export const PORTAL_TERMS_OF_USE_URL =
  'https://www.raiobeneficios.com/termos-de-uso';

export const PRIVACY_POLICY_URL =
  'https://www.raiobeneficios.com/politica-de-privacidade';

export const VA_VR_OPTIONS = z.enum(['TOGETHER', 'APART', 'BOTH']);
export const PAT_OPTIONS = z.enum(['YES', 'NO', 'NOTSURE']);

export const WORKING_DAYS_OPTIONS = [
  { value: 1, label: '1 dia' },
  { value: 2, label: '2 dias' },
  { value: 3, label: '3 dias' },
  { value: 4, label: '4 dias' },
  { value: 5, label: '5 dias' },
  { value: 6, label: '6 dias' },
  { value: 7, label: '7 dias' },
  { value: 8, label: '8 dias' },
  { value: 9, label: '9 dias' },
  { value: 10, label: '10 dias' },
  { value: 11, label: '11 dias' },
  { value: 12, label: '12 dias' },
  { value: 13, label: '13 dias' },
  { value: 14, label: '14 dias' },
  { value: 15, label: '15 dias' },
  { value: 16, label: '16 dias' },
  { value: 17, label: '17 dias' },
  { value: 18, label: '18 dias' },
  { value: 19, label: '19 dias' },
  { value: 20, label: '20 dias' },
  { value: 21, label: '21 dias' },
  { value: 22, label: '22 dias' },
  { value: 23, label: '23 dias' },
  { value: 24, label: '24 dias' },
  { value: 25, label: '25 dias' },
  { value: 26, label: '26 dias' },
  { value: 27, label: '27 dias' },
  { value: 28, label: '28 dias' },
  { value: 29, label: '29 dias' },
  { value: 30, label: '30 dias' },
  { value: 31, label: '31 dias' },
] as const;

export const baseDownloadUrl = `${import.meta.env.VITE_API_URL}/api/recharges/download`;
export const baseCreditOrderDownloadUrl = `${import.meta.env.VITE_API_URL}/api/credit-order/download`;

export const RECHARGE_INVOICE_TIMER = 60;

export const spreadsheetTemplate =
  'https://raio-portal.s3.sa-east-1.amazonaws.com/templates/modelo-raio-lote-de-colaboradores-v1.xlsx';

export const CARD_TRACKING_URL =
  'https://sigasuaencomenda.com.br/rastreamento/simples/raio';

export const addressMaxLength = {
  address: 40,
  number: 40,
  complement: 20,
  district: 30,
  city: 30,
};
