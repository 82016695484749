import { useEffect } from 'react';
import Spinner from '../../components/Spinner';
import { Flex } from '@chakra-ui/react';
import { useAuthContext } from '../../hooks/useAuthContext';

const LogoutPage = () => {
  const { removeAuthInfo, isAuthenticated } = useAuthContext();

  const isAuthed = isAuthenticated();

  useEffect(() => {
    if (isAuthed) {
      removeAuthInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthed]);

  return (
    <Flex
      height="100vh"
      width="100vw"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner />
    </Flex>
  );
};

export default LogoutPage;
