import {
  Box,
  Card,
  Divider,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Link,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { UserHeartIcon } from '../../../../assets/customIcons/UserHeartIcon';
import BenefitDisplay from '../../../../components/BenefitDisplay';
import { formatCurrencyInCents } from '../../../../utils/formatters/formatCurrency';
import { BenefitPackage } from '../../../../types/benefit';
import { FiTrash2, FiEdit2 } from 'react-icons/fi';

type BenefitCardProps = {
  benefitPackage: BenefitPackage;
  businessDays: number;
  onBenefitPackageEdit: (benefitPackage: BenefitPackage) => void;
  onBenefitPackageDelete: (benefitPackage: BenefitPackage) => void;
};

const BenefitCard = ({
  benefitPackage,
  businessDays,
  onBenefitPackageEdit,
  onBenefitPackageDelete,
}: BenefitCardProps) => {
  const [openDetails, setOpenDetails] = useState(false);

  function getSumOfBenefits() {
    let sum = 0;
    benefitPackage.package_categories.forEach((benefit) => {
      if (benefit.periodType === 'DAILY') {
        sum += Number(benefit.value) * businessDays;
        return;
      }
      sum += Number(benefit.value);
    });
    return sum;
  }

  function getTotalPackageValue() {
    return getSumOfBenefits() * benefitPackage.package_collaborators.length;
  }

  return (
    <Card
      width="100%"
      minWidth="270px"
      padding={6}
      border="2px solid"
      borderColor="gray.300"
      key={benefitPackage.id}
      height={openDetails ? 'auto' : 'fit-content'}
    >
      <Flex alignItems="baseline" justifyContent="space-between">
        <Text fontSize="20px" fontWeight={500} marginBottom={4}>
          {benefitPackage.name}
        </Text>
        <Box display="flex" flexDirection="row">
          <IconButton
            variant="ghost"
            boxSize={8}
            aria-label="Editar pacote de benefícios"
            title="Editar pacote de benefícios"
            icon={<FiEdit2 />}
            onClick={() => onBenefitPackageEdit(benefitPackage)}
          />

          <IconButton
            variant="ghost"
            boxSize={8}
            aria-label="Excluir pacote de benefícios"
            title="Excluir pacote de benefícios"
            icon={<FiTrash2 />}
            onClick={() => onBenefitPackageDelete(benefitPackage)}
          />
        </Box>
      </Flex>

      <Link
        textDecor="underline"
        fontWeight={500}
        marginBottom={6}
        onClick={() => setOpenDetails(!openDetails)}
        userSelect="none"
      >
        {openDetails ? 'Ocultar detalhes' : 'Ver detalhes'}
      </Link>

      {openDetails && (
        <Box>
          <Grid templateColumns="repeat(2, 1fr)">
            {benefitPackage.package_categories.map((benefit) => {
              return (
                <GridItem mb={4} key={benefit.id}>
                  <BenefitDisplay key={benefit.id} benefit={benefit}>
                    <Text ml={5}>
                      {formatCurrencyInCents(Number(benefit.value))}{' '}
                      {benefit.periodType === 'DAILY' && 'por dia'}
                    </Text>
                  </BenefitDisplay>
                </GridItem>
              );
            })}
          </Grid>
          <Divider margin="8px 0 24px" />
        </Box>
      )}

      <Text fontSize="16px" fontWeight={600} marginBottom={4}>
        Simulação de valor mensal:
      </Text>

      <Flex justifyContent="space-between" gap={2}>
        <Box>
          <Text fontSize="14px">Soma de todos os benefícios</Text>
          <Text fontSize="16px" fontWeight={600}>
            {formatCurrencyInCents(getSumOfBenefits())}
          </Text>
        </Box>

        <Box>
          <Text fontSize="14px">Colaboradores</Text>
          <Flex alignItems="center" justifyContent="flex-end">
            <UserHeartIcon boxSize="16px" />
            <Text fontSize="16px" fontWeight={600} marginLeft={2}>
              {benefitPackage.package_collaborators.length}
            </Text>
          </Flex>
        </Box>
      </Flex>

      <Text fontSize="14px" marginTop={4}>
        Valor total do pacote
      </Text>
      <Text fontSize="16px" fontWeight={600}>
        {formatCurrencyInCents(getTotalPackageValue())}
      </Text>
    </Card>
  );
};

export default BenefitCard;
