import { createIcon } from '@chakra-ui/react';

export const IncentiveIcon = createIcon({
  displayName: 'IncentiveIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    boxSize: '60px',
    fill: 'none',
  },
  path: (
    <g fill="currentColor">
      <path d="M23.147 9.471 22.36 8.4A5.884 5.884 0 0 0 17.632 6H6.382a5.892 5.892 0 0 0-4.661 2.309L.9 9.383a4.418 4.418 0 0 0 .548 5.937l7.99 7.707a3.82 3.82 0 0 0 2.55.969 3.9 3.9 0 0 0 2.6-1l7.936-7.633a4.39 4.39 0 0 0 .623-5.892Zm-2.4.107.787 1.074c.079.11.149.226.208.348H16.95a6.614 6.614 0 0 0-.372-1.4l-.64-1.6h1.693a3.878 3.878 0 0 1 3.115 1.578Zm-8.757 10.51-2.77-6.27A4.64 4.64 0 0 1 8.996 13h5.952c-.042.22-.1.436-.176.647l-2.781 6.441ZM9.1 11c.042-.18.094-.356.157-.529L10.24 8h3.544l.937 2.343c.082.214.149.434.2.657H9.1ZM3.31 9.521A3.884 3.884 0 0 1 6.384 8h1.7L7.4 9.729c-.165.41-.288.836-.367 1.271H2.245c.068-.141.151-.275.247-.4l.819-1.079Zm-.5 4.333a2.373 2.373 0 0 1-.6-.854h4.773c.066.539.196 1.068.387 1.576l2.827 6.4-7.386-7.122Zm10.96 7.16 2.855-6.614a6.57 6.57 0 0 0 .344-1.4h4.83a2.5 2.5 0 0 1-.632.9l-7.396 7.114ZM12 4a1 1 0 0 0 1-1V1a1 1 0 1 0-2 0v2a1 1 0 0 0 1 1ZM16.553 3.9a1 1 0 0 0 1.347-.453l1-2a1 1 0 1 0-1.79-.894l-1 2a1.001 1.001 0 0 0 .443 1.347ZM6.105 3.447A1.003 1.003 0 0 0 7.9 2.553l-1-2a1 1 0 0 0-1.79.894l.995 2Z" />
    </g>
  ),
});
