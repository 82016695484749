import { createColumnHelper } from '@tanstack/react-table';
import { compareAsc, format, parseISO } from 'date-fns';
import { CreditTransaction } from '../../../../types/credits';
import TransactionStatusBadge from '../TransactionStatusBadge';
import TransactionValueDisplay from '../TransactionValueDisplay';
import { isCompleteISOString } from '../../../../utils/validations/isCompleteISOString';
import { isValidStringDate } from '../../../../utils/validations/isValidStringDate';

const columnHelper = createColumnHelper<CreditTransaction>();

export const defaultColumns = [
  columnHelper.accessor('transactionId', {
    cell: (info) => {
      const id = info.getValue() as string | undefined;
      if (typeof id === 'string') {
        return id.slice(-7);
      } else {
        return id;
      }
    },
    header: 'Identificador',
  }),

  columnHelper.accessor('transactionDateTime', {
    cell: (info) => {
      const dateTime = info.getValue() as string | undefined;
      if (!dateTime) {
        return;
      }
      if (isCompleteISOString(dateTime)) {
        return format(new Date(dateTime), 'dd/MM/yy HH:mm');
      } else if (isValidStringDate(dateTime)) {
        return format(new Date(dateTime), 'dd/MM/yy');
      } else {
        return '';
      }
    },
    header: 'Data da transação',
    sortingFn: (a, b) => {
      if (!a || !b) {
        return 0;
      }
      const dateA = parseISO(a.original.transactionDateTime);
      const dateB = parseISO(b.original.transactionDateTime);
      return compareAsc(dateA, dateB);
    },
  }),
  columnHelper.accessor('type', {
    cell: (info) => info.getValue(),
    header: 'Tipo',
  }),
  columnHelper.accessor('status', {
    cell: (info) => {
      const transactionStatus = info.getValue();
      return <TransactionStatusBadge status={transactionStatus} />;
    },
    header: 'Status',
  }),
  columnHelper.accessor('amount', {
    cell: (info) => {
      const value = info.getValue();
      const transaction = info.row.original;
      if (typeof value === 'number') {
        return (
          <TransactionValueDisplay value={value} transaction={transaction} />
        );
      } else {
        return value;
      }
    },
    header: 'Valor total',
  }),
];
