import {
  AlertDialog as ChakraAlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  CloseButton,
  Text,
} from '@chakra-ui/react';
import { ReactNode, useRef } from 'react';

type AlertDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string | ReactNode;
  children?: ReactNode;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  isConfirmButtonDisabled?: boolean;
  isConfirmButtonLoading?: boolean;
  cancelButtonAction?: () => void;
  confirmButtonAction?: () => void;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'full' | '2xl';
};

const AlertDialog = ({
  isOpen,
  onClose,
  title,
  cancelButtonLabel = 'Cancelar',
  confirmButtonLabel = 'Confirmar',
  isConfirmButtonDisabled,
  isConfirmButtonLoading,
  cancelButtonAction,
  confirmButtonAction,
  size,
  children,
}: AlertDialogProps) => {
  const leastDestructiveRef = useRef<HTMLButtonElement>(null);
  return (
    <ChakraAlertDialog
      size={size}
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={leastDestructiveRef}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader
            fontSize="lg"
            fontWeight="bold"
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Text as="span" maxWidth="fit-content">
              {title}
            </Text>
            <CloseButton onClick={onClose} />
          </AlertDialogHeader>
          <AlertDialogBody>{children}</AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={leastDestructiveRef}
              variant="outline"
              onClick={cancelButtonAction}
            >
              {cancelButtonLabel}
            </Button>
            <Button
              onClick={confirmButtonAction}
              ml={3}
              isDisabled={isConfirmButtonDisabled}
              isLoading={isConfirmButtonLoading}
            >
              {confirmButtonLabel}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </ChakraAlertDialog>
  );
};

export default AlertDialog;
