import { Icon, IconProps, forwardRef } from '@chakra-ui/react';

export const CultureIcon = forwardRef<IconProps, 'svg'>((props, ref) => {
  return (
    <Icon ref={ref} viewBox="0 0 16 16" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="transparent"
      >
        <g clipPath="url(#clip0_4672_6245)">
          <path
            d="M5.80974 2.39013L5.3898 2.49653C5.42783 2.67823 5.43258 2.86642 5.40378 3.05016C5.37498 3.2339 5.31319 3.40952 5.22202 3.56682C5.13084 3.72411 5.0121 3.85994 4.87269 3.9664C4.73327 4.07286 4.57598 4.14784 4.40994 4.18697C4.07324 4.26473 3.7222 4.19436 3.43226 3.991C3.14232 3.78763 2.93662 3.4675 2.85939 3.09941L2.01951 3.28855C1.90433 3.31615 1.79544 3.36908 1.69942 3.44417C1.6034 3.51926 1.52224 3.61494 1.46085 3.72543C1.39946 3.83592 1.35911 3.95893 1.34223 4.08702C1.32536 4.21511 1.33231 4.34563 1.36268 4.47069C1.36268 4.47069 1.75031 6.44485 2.20256 8.66725M13.8855 2.37831C13.8212 2.57736 13.7207 2.76 13.5902 2.91516C13.4596 3.07031 13.3018 3.19476 13.1261 3.28097C12.9505 3.36717 12.7607 3.41333 12.5684 3.41665C12.376 3.41997 12.185 3.38038 12.007 3.30029C11.829 3.22019 11.6677 3.10125 11.5327 2.95068C11.3978 2.8001 11.2922 2.62103 11.2222 2.4243C11.1521 2.22757 11.1192 2.01728 11.1254 1.80618C11.1316 1.59508 11.1768 1.38755 11.2582 1.19618L10.386 0.746972C10.1531 0.640418 9.89134 0.638996 9.6575 0.743014C9.42365 0.847032 9.23661 1.0481 9.13695 1.30257L5.51901 10.6532L5.04523 11.8354C4.94817 12.091 4.94688 12.3784 5.04162 12.6352C5.13637 12.8919 5.31952 13.0972 5.55131 13.2066L9.923 15.2517C10.1559 15.3583 10.4177 15.3597 10.6515 15.2557C10.8854 15.1517 11.0724 14.9506 11.1721 14.6961L15.2638 4.12787C15.354 3.88212 15.3564 3.60804 15.2706 3.3604C15.1848 3.11276 15.017 2.9098 14.8008 2.79206L13.8855 2.37831Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_4672_6245">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
});
