import { useAuthContext } from '../../hooks/useAuthContext';
import { Button } from '@chakra-ui/react';
import { FiLogOut } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

type LogoutButtonProps = {
  text?: string;
  color?: string;
};

const LogoutButton = ({ text }: LogoutButtonProps) => {
  const { removeAuthInfo } = useAuthContext();
  const navigate = useNavigate();
  return (
    <Button
      padding={'16px 16px 16px 0'}
      variant="ghost"
      gap={2}
      leftIcon={<FiLogOut size="24px" />}
      onClick={() => {
        // TODO: request to invalidate token
        // TODO: fix render text based on props
        removeAuthInfo();
        navigate('/login');
      }}
    >
      {text ? text : 'Sair do portal'}
    </Button>
  );
};

export default LogoutButton;
