import { createIcon } from '@chakra-ui/react';

export const AlertIcon = createIcon({
  displayName: 'AlertIcon',
  viewBox: '0 0 36 36',
  path: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none">
      <path
        d="M18 34.667c9.204 0 16.666-7.462 16.666-16.666 0-9.205-7.462-16.667-16.666-16.667-9.205 0-16.667 7.462-16.667 16.667 0 9.204 7.462 16.666 16.667 16.666ZM18 11.334v6.667"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 24.668h.017"
        stroke="#353535"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
});
