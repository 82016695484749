import {
  Spinner as ChakraSpinner,
  Center,
  CenterProps,
  SpinnerProps,
} from '@chakra-ui/react';

type TSpinnerProps = SpinnerProps & {
  centerProps?: CenterProps;
};

const Spinner = ({ centerProps, ...props }: TSpinnerProps) => {
  return (
    <Center marginTop={8} {...centerProps}>
      <ChakraSpinner size="xl" {...props} />
    </Center>
  );
};

export default Spinner;
