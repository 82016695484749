import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import SetCompanyInfos from './SetCompanyInfos';
import { useState } from 'react';
import BenefitSettings from './BenefitSettings';
import { useGetCompanyInfo } from '../../api/company';
import Spinner from '../../components/Spinner';
import { useGetCustomerConfigs } from '../../api/configs';
import { useGetSelectedCompanyAddresses } from '../../api/address';
import RechargeConfigs from './RechargeConfigs';
import { CustomerConfig } from '../../types/config';

const SettingsPage = () => {
  const [tabsIndex, setTabsIndex] = useState(0);

  const { data: companyAddresses = [] } = useGetSelectedCompanyAddresses();
  const company = useGetCompanyInfo();

  const { data: customerConfigArray = [], isLoading } = useGetCustomerConfigs();
  const customerConfigs = customerConfigArray[0] as CustomerConfig | undefined;

  return (
    <>
      <Box marginBottom="300px" paddingBottom="8">
        <Tabs
          onChange={(e) => {
            setTabsIndex(e);
          }}
        >
          <TabList marginBottom={6} width="fit-content">
            <Tab>Dados da empresa</Tab>
            <Tab>Benefícios e categorias</Tab>
            <Tab>Recargas</Tab>
          </TabList>

          <TabPanels background="white" borderRadius="8px">
            <TabPanel padding="32px">
              {company.data ? (
                <SetCompanyInfos
                  companyInfo={company.data}
                  companyAddresses={companyAddresses}
                  tabsIndex={tabsIndex}
                />
              ) : (
                <Spinner size="md" />
              )}
            </TabPanel>

            <TabPanel padding="32px">
              {!isLoading ? (
                <BenefitSettings
                  customerConfig={customerConfigs}
                  tabsIndex={tabsIndex}
                />
              ) : (
                <Spinner size="md" />
              )}
            </TabPanel>
            <TabPanel padding="32px">
              {!isLoading ? (
                <RechargeConfigs
                  customerConfig={customerConfigs}
                  tabsIndex={tabsIndex}
                />
              ) : (
                <Spinner size="md" />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
};

export default SettingsPage;
