import React from 'react';
import { CreditTransaction } from '../../../../types/credits';
import { formatCurrencyInCents } from '../../../../utils/formatters/formatCurrency';
import { Text, TextProps } from '@chakra-ui/react';

type TransactionValueDisplayProps = {
  transaction: CreditTransaction;
  value: number;
};

function getTransactionStyling(transaction: CreditTransaction): TextProps {
  const { status: transactionStatus, type } = transaction;

  const isCreditOrRefund = [
    'Compra de créditos',
    'Compra de crédito',
    'Estorno',
    'Estorno de saldo',
    'Entrada via Pix',
    'Entrada via Boleto',
  ].includes(type);

  const isRecharge = [
    'Recarga',
    'Recarga com Créditos Raiô',
    'Recarga com pagamento via Pix',
    'Recarga com pagamento via Boleto',
  ].includes(type);

  const baseStyle = {
    _before: {
      content: isCreditOrRefund ? '"+"' : isRecharge ? '"-"' : '""',
      paddingRight: 2,
    },
    color: 'inherit',
  };

  if (transactionStatus === 'SETTLED') {
    return {
      ...baseStyle,
      color: isCreditOrRefund
        ? 'feedback.success'
        : isRecharge
          ? 'feedback.alert'
          : 'inherit',
    };
  }

  return baseStyle;
}

const TransactionValueDisplay = ({
  transaction,
  value,
}: TransactionValueDisplayProps) => {
  const styling = getTransactionStyling(transaction);
  return (
    <Text as="span" {...styling}>
      {formatCurrencyInCents(value)}
    </Text>
  );
};

export default TransactionValueDisplay;
