import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  FormControl,
  FormLabel,
  Text,
  Flex,
  UnorderedList,
  ListItem,
  Icon,
  useToast,
  Heading,
  CardFooter,
} from '@chakra-ui/react';
import Title from '../../components/Title';
import PasswordInput from '../../components/PasswordInput';
import AccessLayout from '../../layouts/AccessLayout';
import { FiCheck } from 'react-icons/fi';
import { Navigate, useParams } from 'react-router-dom';
import usePasswordValidation from '../../hooks/usePasswordValidation';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useMutation } from '@tanstack/react-query';
import { useFetchContext } from '../../hooks/useFetchContext';
import { useUserCompaniesStore } from '../../stores/useUserCompaniesStore';
import { AuthState } from '../../types/auth';
import { HourglassIcon } from '../../assets/customIcons/HourglassIcon';
import PortalTermsOfUseLink from '../../components/PortalTermsOfUseLink';

const SetNewPassword = () => {
  const { confirmationToken } = useParams<{ confirmationToken: string }>();
  const { isAuthenticated } = useAuthContext();
  const { password, passwordRules, handlePasswordChange, allRulesChecked } =
    usePasswordValidation();

  const { setAuthInfo } = useAuthContext();
  const { setSelectedCompany } = useUserCompaniesStore();
  const { api } = useFetchContext();
  const toast = useToast();

  const setNewPassword = useMutation({
    mutationFn: () =>
      api.patch<AuthState>(`/api/users/password/${confirmationToken}`, {
        password,
      }),
    onSuccess: (response) => {
      const authInfo = response.data;
      if (authInfo.accessToken && authInfo.user) {
        setSelectedCompany(authInfo.user?.selectedCompany);

        setAuthInfo(authInfo);
        toast({
          title: 'Senha definida com sucesso!',
          status: 'success',
        });
      }
    },
    onError: () => {
      toast({
        title: 'Ocorreu um erro ao definir sua senha.',
        description: 'Por favor, tente novamente.',
        status: 'error',
      });
    },
  });

  if (isAuthenticated()) {
    return <Navigate to="/" />;
  }

  return (
    <AccessLayout variant="passwordReset">
      <Box
        paddingX={{
          base: '20px',
          md: '80px',
        }}
      >
        <Card
          backgroundColor="gray.50"
          width={{
            base: '100%',
            md: '566px',
          }}
          boxShadow="none"
          position="relative"
          minWidth="327px"
        >
          <HourglassIcon
            position="absolute"
            top="-40px"
            right="-40px"
            display={{
              base: 'none',
              md: 'block',
            }}
          />
          <CardHeader paddingBottom={8}>
            <Flex direction="column" gap={4}>
              <Heading fontWeight={600} fontSize="24px">
                Defina uma nova senha de acesso
              </Heading>
            </Flex>
          </CardHeader>
          <CardBody paddingY={0}>
            <Text fontSize="14px">Sua senha precisa ter, pelo menos:</Text>
            <UnorderedList>
              <Box marginLeft={2}>
                {passwordRules.map((rule) => (
                  <ListItem
                    key={rule.description}
                    color={rule.check ? 'feedback.success' : 'inherit'}
                    alignItems="center"
                    gap={1}
                  >
                    <Flex alignItems="center" gap={1} fontSize="14px">
                      {rule.description} {rule.check && <Icon as={FiCheck} />}
                    </Flex>
                  </ListItem>
                ))}
              </Box>
            </UnorderedList>
            <FormControl marginTop={8}>
              <FormLabel fontSize="14px">Senha</FormLabel>
              <PasswordInput
                isDisabled={setNewPassword.isPending}
                placeholder="Defina uma senha"
                onChange={handlePasswordChange}
              />
            </FormControl>
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Button
                type="submit"
                width="100%"
                marginY={8}
                isDisabled={!allRulesChecked}
                onClick={() => setNewPassword.mutate()}
                isLoading={setNewPassword.isPending}
              >
                Confirmar
              </Button>
            </form>
          </CardBody>
          <CardFooter paddingTop={0}>
            <Text fontSize={'14px'}>
              Ao continuar, você confirma que leu e aceita os{' '}
              <PortalTermsOfUseLink fontSize={'14px'} />
            </Text>
          </CardFooter>
        </Card>
      </Box>
    </AccessLayout>
  );
};

export default SetNewPassword;
