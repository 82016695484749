import { createIcon } from '@chakra-ui/react';

export const HouseIcon = createIcon({
  displayName: 'HouseIcon',
  viewBox: '0 0 24 25',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <path
      d="M23.121 9.77614L15.536 2.19014C14.5973 1.25413 13.3257 0.728516 12 0.728516C10.6744 0.728516 9.40277 1.25413 8.46401 2.19014L0.879012 9.77614C0.599438 10.0539 0.377782 10.3844 0.226895 10.7485C0.0760072 11.1126 -0.0011104 11.503 1.20795e-05 11.8971V21.7141C1.20795e-05 22.5098 0.316083 23.2729 0.878692 23.8355C1.4413 24.3981 2.20436 24.7141 3.00001 24.7141H21C21.7957 24.7141 22.5587 24.3981 23.1213 23.8355C23.6839 23.2729 24 22.5098 24 21.7141V11.8971C24.0011 11.503 23.924 11.1126 23.7731 10.7485C23.6222 10.3844 23.4006 10.0539 23.121 9.77614ZM15 22.7141H9.00001V18.7801C9.00001 17.9845 9.31608 17.2214 9.87869 16.6588C10.4413 16.0962 11.2044 15.7801 12 15.7801C12.7957 15.7801 13.5587 16.0962 14.1213 16.6588C14.6839 17.2214 15 17.9845 15 18.7801V22.7141ZM22 21.7141C22 21.9794 21.8947 22.2337 21.7071 22.4212C21.5196 22.6088 21.2652 22.7141 21 22.7141H17V18.7801C17 17.4541 16.4732 16.1823 15.5355 15.2446C14.5979 14.3069 13.3261 13.7801 12 13.7801C10.6739 13.7801 9.40216 14.3069 8.46448 15.2446C7.5268 16.1823 7.00001 17.4541 7.00001 18.7801V22.7141H3.00001C2.7348 22.7141 2.48044 22.6088 2.29291 22.4212C2.10537 22.2337 2.00001 21.9794 2.00001 21.7141V11.8971C2.00094 11.6321 2.1062 11.3781 2.29301 11.1901L9.87801 3.60714C10.4417 3.04607 11.2047 2.73108 12 2.73108C12.7953 2.73108 13.5583 3.04607 14.122 3.60714L21.707 11.1931C21.8931 11.3804 21.9983 11.6332 22 11.8971V21.7141Z"
      fill="currentColor"
    />
  ),
});
