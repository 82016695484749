import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFetchContext } from '../../hooks/useFetchContext';
import { useToast } from '@chakra-ui/react';
import {
  CardDelivery,
  NonNominalCardStock,
  RequestNewCard,
} from '../../types/card';
import { useUserCompaniesStore } from '../../stores/useUserCompaniesStore';

export const useBlockCard = () => {
  const { api } = useFetchContext();
  const toast = useToast();
  const queryClient = useQueryClient();
  const { selectedCompany } = useUserCompaniesStore();

  return useMutation({
    mutationFn: ({ employeeDocument }: { employeeDocument: string }) =>
      api.patch(`/api/collaborators/${employeeDocument}/card/block`),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['employees', { customerId: selectedCompany?.customerId }],
      });
      toast({
        title: 'Cartão bloqueado com sucesso',
        status: 'success',
      });
    },
    onError: () => {
      toast({
        title: 'Tivemos um problema para bloquear o cartão.',
        status: 'error',

        description: 'Tente de novo daqui a pouco.',
      });
    },
  });
};

export const useGetCompanyNonNominalCardStock = (
  isNoNameCardsAllowed: boolean
) => {
  const { selectedCompany } = useUserCompaniesStore();
  const customerId = selectedCompany?.customerId;
  const { api } = useFetchContext();
  return useQuery({
    queryKey: ['non-nominal-stock', isNoNameCardsAllowed, customerId],
    queryFn: () =>
      api.get<NonNominalCardStock>(
        `/api/cards/not-nominal-stock?customerId=${customerId}`
      ),
    enabled: isNoNameCardsAllowed && !!customerId,
    select: (response) => {
      const { stock } = response.data;
      return stock;
    },
    staleTime: 0,
    gcTime: 0,
    meta: {
      errorMessage:
        'Não foi possível carregar a quantidade de cartões sem nome em estoque.',
    },
  });
};

export const useGetCardDeliveryInfo = (cardId: string | null) => {
  const { api } = useFetchContext();
  return useQuery({
    enabled: !!cardId,
    queryKey: ['card-delivery-info', cardId],
    queryFn: () =>
      api.get<CardDelivery[]>(`/api/cards-delivery?cardId=${cardId}`),
    select: (response) => {
      const [cardDelivery] = response.data;
      return cardDelivery;
    },
    staleTime: 0,
    gcTime: 0,
    meta: {
      errorMessage: 'Não foi possível carregar as informações do cartão.',
    },
  });
};

export const useRequestNewCard = () => {
  const { api } = useFetchContext();
  const toast = useToast();
  const queryClient = useQueryClient();
  const { selectedCompany } = useUserCompaniesStore();

  return useMutation({
    mutationFn: ({
      collaboratorId,
      data,
    }: {
      collaboratorId: string;
      data: RequestNewCard;
    }) =>
      api.post(`/api/collaborators/${collaboratorId}/card/replacement`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['employees', { customerId: selectedCompany?.customerId }],
      });
      toast({
        title: 'Cartão solicitado com sucesso',
        status: 'success',
      });
    },
    onError: () => {
      toast({
        title: 'Tivemos um problema para solicitar o cartão.',
        status: 'error',

        description: 'Tente de novo daqui a pouco.',
      });
    },
  });
};
