import { createIcon } from '@chakra-ui/react';

export const AttendanceIcon = createIcon({
  displayName: 'AttendanceIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    boxSize: '60px',
    fill: 'none',
  },
  path: (
    <path
      fill="currentColor"
      d="M17 24a7 7 0 1 1 7-7 7.008 7.008 0 0 1-7 7Zm0-12a4.999 4.999 0 1 0 0 9.998A4.999 4.999 0 0 0 17 12Zm1.707 6.707a1 1 0 0 0 0-1.414L18 16.586V15a1 1 0 1 0-2 0v2a1 1 0 0 0 .293.707l1 1a1 1 0 0 0 1.414 0ZM2 23a8.3 8.3 0 0 1 6.221-8.024 1 1 0 1 0-.442-1.952A10.213 10.213 0 0 0 0 23a1 1 0 1 0 2 0Zm6.474-12a5.5 5.5 0 1 1 5.5-5.5 5.506 5.506 0 0 1-5.5 5.5Zm0-9a3.5 3.5 0 1 0 0 7 3.5 3.5 0 0 0 0-7Z"
    />
  ),
});
