import { createIcon } from '@chakra-ui/react';

export const FreeBalanceIcon = createIcon({
  displayName: 'FreeBalanceIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    boxSize: '60px',
    fill: 'none',
  },
  path: (
    <path
      fill="currentColor"
      d="M12 24a12 12 0 1 1 12-12 12.013 12.013 0 0 1-12 12Zm0-22a10 10 0 1 0 10 10A10.01 10.01 0 0 0 12 2ZM8 14a.999.999 0 0 0-.963 1.285A5.5 5.5 0 0 0 12.007 19a5.47 5.47 0 0 0 4.966-3.715A1.021 1.021 0 0 0 16.01 14H8Zm-2-4c0 1 .895 1 2 1s2 0 2-1a2 2 0 0 0-4 0Zm8 0c0 1 .895 1 2 1s2 0 2-1a2 2 0 1 0-4 0Z"
    />
  ),
});
