import { Box, BoxProps, Flex, Text } from '@chakra-ui/react';
import { BenefitPackage } from '../../../../types/benefit';
import BenefitDisplay from '../../../../components/BenefitDisplay';
import { formatCurrencyInCents } from '../../../../utils/formatters/formatCurrency';
import CopyButton from '../../../../components/CopyButton';

type BenefitCardProps = BoxProps & {
  benefit: BenefitPackage;
};

const EmployeeImportBenefitCard = ({
  benefit,
  ...boxProps
}: BenefitCardProps) => {
  return (
    <Box
      width="288px"
      maxWidth="288px"
      {...boxProps}
      backgroundColor="gray.100"
      padding={6}
      borderRadius="lg"
    >
      <Flex justifyContent="space-between" alignItems="start">
        <Text fontWeight={700}>{benefit.name}</Text>

        <CopyButton copyText={benefit.name} />
      </Flex>
      <Flex
        direction="column"
        flexWrap="wrap"
        gap={3}
        maxHeight="260px"
        marginTop={4}
        width="100%"
        fontSize="14px"
      >
        {benefit.package_categories.map((benefit) => (
          <BenefitDisplay
            key={benefit.id}
            benefit={benefit}
            textProps={{
              maxWidth: '120px',
            }}
          >
            <Text marginTop={1} marginLeft="18px" lineHeight={1.2}>
              {formatCurrencyInCents(benefit.value)}{' '}
              {benefit.periodType === 'DAILY' && 'por dia'}
            </Text>
          </BenefitDisplay>
        ))}
      </Flex>
    </Box>
  );
};

export default EmployeeImportBenefitCard;
