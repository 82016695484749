import { parse, isValid, format } from 'date-fns';

export function isValidStringDate(dateString?: string): boolean {
  if (!dateString) {
    return false;
  }

  const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date());

  return isValid(parsedDate) && dateString === format(parsedDate, 'yyyy-MM-dd');
}
