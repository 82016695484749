import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import Title from '../../components/Title';
import Stepper from '../../components/Stepper';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import HelpMessage from '../../components/HelpMessage';
import InfoText from '../../components/InfoText';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import AddButton from '../../components/AddButton';
import { useFetchContext } from '../../hooks/useFetchContext';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useUserCompaniesStore } from '../../stores/useUserCompaniesStore';
import { PAT_OPTIONS, VA_VR_OPTIONS } from '../../constants/general';
import { RechargeVisibilityEnum } from '../../types/recharge';
import FullScreenBgFirstAccessLayout from '../../layouts/FullScreenBackgroundFirstAccessLayout';
import { HourglassIcon } from '../../assets/customIcons/HourglassIcon';

const personResponsibleSchema = z.object({
  id: z.string(),
  fullName: z.string().optional().or(z.literal('')),
  email: z.string().optional().or(z.literal('')),
});

const schema = z.object({
  companyId: z.string().nullish(),
  customerId: z.string().nullish(),
  financialResponsible: z.boolean().nullish(),
  configs: z
    .object({
      pat: PAT_OPTIONS.nullish(),
      vavr: VA_VR_OPTIONS.nullish(),
      rechargeVisibilityConfig: z.nativeEnum(RechargeVisibilityEnum).optional(),
    })
    .nullish(),
  company: z
    .object({
      stateRegistration: z.string().nullish(),
      municipalRegistration: z.string().nullish(),
      isStateRegistrationExempt: z.boolean().nullish(),
    })
    .nullish(),
  users: z
    .array(z.object({ fullName: z.string(), email: z.string() }))
    .nullish(),
  answerLater: z.boolean().nullish(),
});

type FormValues = z.infer<typeof schema>;

type PersonResponsible = z.infer<typeof personResponsibleSchema>;

const defaultPersonResponsible = {
  id: crypto.randomUUID(),
  fullName: '',
  email: '',
} satisfies PersonResponsible;

const AdditionalInfoPage = () => {
  const { selectedCompany } = useUserCompaniesStore();

  const { handleSubmit, register, control, watch, formState, setValue } =
    useForm<FormValues>({
      resolver: zodResolver(schema),
      defaultValues: {
        companyId: selectedCompany?.id,
        customerId: selectedCompany?.customerId,
        company: {
          isStateRegistrationExempt: selectedCompany?.isStateRegistrationExempt,
          municipalRegistration: selectedCompany?.municipalRegistration,
          stateRegistration: selectedCompany?.stateRegistration,
        },
        configs: {
          rechargeVisibilityConfig: RechargeVisibilityEnum.PAID,
        },
      },
    });

  const { fields: personsResponsible, append: addPersonResponsible } =
    useFieldArray({
      control: control,
      name: 'users',
    });

  const { dirtyFields } = formState;

  const pat = watch('configs.pat');
  const financialResponsible = watch('financialResponsible');
  const hasEditedFields = Object.keys(dirtyFields).length > 0;
  const isStateRegistrationExempt = watch('company.isStateRegistrationExempt');

  const { api } = useFetchContext();
  const toast = useToast();
  const navigate = useNavigate();

  const updateCompanyInfo = useMutation({
    mutationFn: (values: FormValues) =>
      api.patch('/api/customers/setup/step-four', values),
    onSuccess: () => {
      toast({
        title: 'Dados da empresa atualizados com sucesso',
        status: 'success',
      });
      navigate('/');
    },
    onError: () => {
      toast({
        title: 'Erro ao atualizar dados da empresa',
        description: 'Tente novamente mais tarde',
        status: 'error',
      });
    },
  });

  function handleAnswerLater() {
    updateCompanyInfo.mutate({
      answerLater: true,
      companyId: selectedCompany?.id,
      customerId: selectedCompany?.customerId,
    });
  }

  function onSubmit(data: FormValues) {
    updateCompanyInfo.mutate(data);
  }

  return (
    <FullScreenBgFirstAccessLayout>
      <Box
        px={{ base: 5, sm: 10, md: 20 }}
        py={{ base: 10, md: 20 }}
        marginTop={10}
        maxHeight="1163px"
        maxWidth="1212px"
        position="relative"
      >
        <HourglassIcon
          position="absolute"
          top="53px"
          right="50px"
          width={'55.8px'}
          height={'54.51px'}
          display={{
            base: 'none',
            md: 'block',
          }}
        />

        <Card bg="#fff" boxShadow="none">
          <CardHeader>
            <Flex direction="column" gap={4}>
              <Stepper numberOfSteps={4} activeStep={4} />
              <Heading fontSize="24px">Algumas informações adicionais</Heading>
            </Flex>
          </CardHeader>

          <form onSubmit={handleSubmit(onSubmit)}>
            <CardBody paddingTop={0}>
              <Flex direction="column" gap={6}>
                <InfoText>
                  Você poderá editar estas informações mais tarde se precisar.
                </InfoText>

                <Flex direction="column" gap={8}>
                  <Text fontWeight={600}>
                    Sobre os benefícios de alimentação e refeição
                  </Text>
                  <FormControl name="pat" as="fieldset">
                    <FormLabel as="legend" marginBottom={2}>
                      Sua empresa está cadastrada atualmente no PAT (Programa de
                      Alimentação do Trabalhador) como beneficiária?{' '}
                      <HelpMessage
                        label="O cadastro no PAT não é obrigatório para o pagamento de benefícios de alimentação e refeição."
                        iconProps={{ position: 'relative', bottom: '-3px' }}
                      />
                    </FormLabel>
                    <Controller
                      control={control}
                      name="configs.pat"
                      render={({ field: { onChange, onBlur } }) => (
                        <RadioGroup onChange={onChange} onBlur={onBlur}>
                          <Stack spacing={4} direction="row">
                            <Radio value={PAT_OPTIONS.Enum.YES}>Sim</Radio>
                            <Radio value={PAT_OPTIONS.Enum.NO}>Não</Radio>
                            <Radio value={PAT_OPTIONS.Enum.NOTSURE}>
                              Não sei
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                  <FormControl name="vavr" as="fieldset">
                    <Flex>
                      <FormLabel as="legend" marginBottom={2} gap={1}>
                        Como a sua empresa pretende pagar os benefícios de
                        alimentação e refeição?{' '}
                        <HelpMessage
                          iconProps={{ position: 'relative', bottom: '-3px' }}
                          label="O vale-refeição pode ser usado em lugares como restaurantes, cafeterias e lanchonetes. Já o vale-alimentação pode ser usado em mercados e açougues, por exemplo."
                        />
                      </FormLabel>
                    </Flex>
                    {pat === 'YES' && (
                      <InfoText marginBottom={4}>
                        Se a sua empresa está cadastrada no PAT, é recomendável
                        o uso de saldos separados de acordo com a legislação
                        vigente.
                      </InfoText>
                    )}
                    <Controller
                      control={control}
                      name="configs.vavr"
                      render={({ field: { onChange, onBlur } }) => (
                        <RadioGroup onChange={onChange} onBlur={onBlur}>
                          <Stack spacing={2} direction="column">
                            <Flex gap={1}>
                              <Radio value={VA_VR_OPTIONS.Enum.TOGETHER}>
                                Os dois juntos, numa carteira com saldo único
                              </Radio>
                              <HelpMessage label="Aqui os colaboradores recebem um valor que poderão usar tanto em alimentação quanto em refeição." />
                            </Flex>

                            <Flex gap={1}>
                              <Radio value={VA_VR_OPTIONS.Enum.APART}>
                                Em carteiras separadas, com seus próprios saldos
                              </Radio>
                              <HelpMessage label="Já aqui, os colaboradores recebem um valor para usar em alimentação e um para usar só em refeição." />
                            </Flex>
                            <Flex gap={1}>
                              <Radio value={VA_VR_OPTIONS.Enum.BOTH}>
                                As duas opções, tanto juntos quanto separados
                              </Radio>
                              <HelpMessage label="Você poderá escolher se você quer que os colaboradores recebam um valor para usar em alimentação e um para usar só em refeição ou os dois benefícios juntos na mesma carteira. " />
                            </Flex>
                          </Stack>
                        </RadioGroup>
                      )}
                    />
                  </FormControl>

                  <Divider />
                  <Text fontWeight={600}>
                    Sobre o financeiro da sua empresa
                  </Text>
                  <FormControl name="financialResponsible" as="fieldset">
                    <FormLabel as="legend" marginBottom={2}>
                      Você é do time financeiro da sua empresa?
                    </FormLabel>
                    <Controller
                      control={control}
                      name="financialResponsible"
                      render={({ field: { onChange, onBlur } }) => (
                        <RadioGroup
                          marginBottom="2"
                          onChange={(nextValue: 'true' | 'false') => {
                            if (nextValue === 'true') {
                              setValue('users', null);
                            }
                            if (nextValue === 'false') {
                              setValue('users', [defaultPersonResponsible]);
                            }
                            onChange(JSON.parse(nextValue));
                          }}
                          onBlur={onBlur}
                        >
                          <Stack spacing={4} direction="row">
                            <Radio value="true">Sim</Radio>
                            <Radio value="false">Não</Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                    />
                  </FormControl>
                  {/* oculta o render desses campos ref(POR-608), no momento dependemos de uma resposta da biz que será implementada no futuro */}
                  {financialResponsible !== undefined && (
                    <Flex direction="column" gap={4}>
                      {/* {financialResponsible === true && (
                        <>
                          <Flex gap={1} alignItems="flex-end">
                            <Text>
                              Caso precise que sua inscrição municipal e/ou
                              estadual sejam incluídas nas notas fiscais
                              emitidas pela Raiô, informe elas a seguir.{' '}
                              <HelpMessage
                                label="Não se preocupe, a tributação da sua empresa não será impactada pela inclusão ou não da inscrição municipal e estadual nas notas fiscais de pedidos de recarga de benefícios."
                                iconProps={{
                                  position: 'relative',
                                  bottom: '-3px',
                                }}
                              />
                            </Text>
                          </Flex>
                          <Flex
                            direction={{ base: 'column', md: 'row' }}
                            gap={4}
                          >
                            <FormControl>
                              <FormLabel>
                                Inscrição municipal (opcional)
                              </FormLabel>
                              <Input
                                {...register('company.municipalRegistration')}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel>
                                Inscrição estadual (opcional)
                              </FormLabel>
                              <Input
                                isDisabled={!!isStateRegistrationExempt}
                                {...register('company.stateRegistration')}
                              />
                            </FormControl>
                          </Flex>
                          <Checkbox
                            onChange={(e) => {
                              setValue('company.stateRegistration', '');
                              if (e.target.checked) {
                                setValue(
                                  'company.isStateRegistrationExempt',
                                  true
                                );
                              } else {
                                setValue(
                                  'company.isStateRegistrationExempt',
                                  false
                                );
                              }
                            }}
                          >
                            Empresa isenta de inscrição estadual
                          </Checkbox>
                        </>
                      )} */}

                      {financialResponsible === false && (
                        <>
                          <Text display="flex" alignItems="center" gap={1}>
                            Por favor, informe o contato da(s) pessoa(s)
                            responsáveis por isto na sua empresa.{' '}
                            <HelpMessage label="Enviaremos aos responsáveis as informações para pagamento e as notas fiscais dos pedidos de recarga de benefícios da sua empresa." />
                          </Text>
                          {personsResponsible.map((person, index) => (
                            <Flex
                              direction={{ base: 'column', md: 'row' }}
                              gap={4}
                              key={person.id}
                            >
                              <FormControl>
                                <FormLabel>Nome completo</FormLabel>
                                <Input
                                  {...register(`users.${index}.fullName`)}
                                />
                              </FormControl>
                              <FormControl>
                                <FormLabel>Endereço de e-mail</FormLabel>
                                <Input {...register(`users.${index}.email`)} />
                              </FormControl>
                            </Flex>
                          ))}
                          <AddButton
                            onClick={() =>
                              addPersonResponsible(defaultPersonResponsible)
                            }
                          >
                            Adicionar mais uma pessoa
                          </AddButton>
                        </>
                      )}
                    </Flex>
                  )}
                </Flex>
                <Divider />
                <Text fontWeight={600}>Informações da próxima recarga</Text>
                <Flex flexDirection={'column'}>
                  <Text marginBottom={4} fontSize="md">
                    Quando devemos disponibilizar aos colaboradores da sua
                    empresa as informações da próxima recarga no Aplicativo
                    Raiô?
                    <HelpMessage
                      iconProps={{
                        position: 'relative',
                        bottom: '-3px',
                        left: '4px',
                      }}
                      label="O colaborador irá visualizar uma mensagem na página inicial com as informações da próxima recarga"
                    />
                  </Text>

                  <Controller
                    control={control}
                    name="configs.rechargeVisibilityConfig"
                    render={({ field: { onChange, value } }) => (
                      <RadioGroup onChange={onChange} value={value}>
                        <Stack>
                          <Flex gap={1}>
                            <Radio value={RechargeVisibilityEnum.CREATED}>
                              Desde o momento em que a recarga foi criada
                            </Radio>
                            <HelpMessage label="Nesta opção, é exibida a data de crédito prevista, mesmo enquanto o Boleto/Pix não foi pago" />
                          </Flex>

                          <Flex gap={1}>
                            <Radio value={RechargeVisibilityEnum.PAID}>
                              Só quando o Boleto/Pix for pago
                            </Radio>
                            <HelpMessage label="Nesta opção, é exibida a data de crédito e o valor de cada benefício, a partir da confirmação do pagamento do Boleto/Pix" />
                          </Flex>

                          <Flex gap={1}>
                            <Radio value={RechargeVisibilityEnum.NEVER}>
                              Não mostrar essa informação
                            </Radio>
                            <HelpMessage label="Nesta opção, não é exibida nenhuma informação sobre a próxima recarga" />
                          </Flex>
                        </Stack>
                      </RadioGroup>
                    )}
                  />
                </Flex>
              </Flex>
            </CardBody>

            <CardFooter
              paddingTop={0}
              justifyContent={{ base: 'center', md: 'flex-end' }}
            >
              <Flex
                direction={{ base: 'column', md: 'row' }}
                gap={4}
                marginTop={4}
              >
                <Button
                  variant="outline"
                  width="290px"
                  onClick={handleAnswerLater}
                  isLoading={updateCompanyInfo.isPending}
                  isDisabled={hasEditedFields}
                >
                  Prefiro responder depois
                </Button>

                <Button
                  type="submit"
                  width="290px"
                  marginLeft={{ sm: 'unset', md: 'auto' }}
                  isLoading={updateCompanyInfo.isPending}
                >
                  Salvar informações
                </Button>
              </Flex>
            </CardFooter>
          </form>
        </Card>
      </Box>
    </FullScreenBgFirstAccessLayout>
  );
};

export default AdditionalInfoPage;
