import { createIcon } from '@chakra-ui/react';

export const HourglassIcon = createIcon({
  displayName: 'HourglassIcon',
  viewBox: '0 0 80 82',
  defaultProps: {
    width: '80px',
    height: '82px',
  },
  path: (
    <>
      <path
        d="M0.0570051 0.906826C0.108364 2.11614 0.211082 3.18725 0.382279 4.54628C2.09995 18.3209 9.60408 29.7115 20.8289 35.591C25.2686 37.9233 29.7597 39.265 34.9926 39.8466C36.1624 39.9733 40.9388 40.077 42.0516 40.0021C43.4383 39.8985 45.4127 39.6681 46.7253 39.4435C58.1041 37.5202 67.8338 30.6674 73.4605 20.6244C76.776 14.7102 78.6135 8.12238 78.973 0.906823L0.0570051 0.906826Z"
        fill="#FF6600"
      />
      <path
        d="M79.0244 81.7998C79.0244 81.5925 79.0244 81.3909 79.0244 81.1836C78.8989 75.2868 77.9003 70.1386 75.9258 65.0652C75.3722 63.6486 74.191 61.1436 73.4434 59.8191C71.5831 56.5194 68.901 53.1046 66.2646 50.6457C60.1414 44.9619 52.7457 41.6276 44.0489 40.6372C41.4296 40.3377 36.813 40.3895 34.0567 40.7408C15.967 43.0443 2.72203 56.888 0.376633 75.9375C0.102718 78.1431 0.011413 79.4618 0 81.7998L79.0244 81.7998Z"
        fill="#FF6600"
      />
    </>
  ),
});
