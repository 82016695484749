import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFetchContext } from '../../hooks/useFetchContext';
import { UseToastOptions, useToast } from '@chakra-ui/react';
import { useUserCompaniesStore } from '../../stores/useUserCompaniesStore';
import { Address, ConsultByCepData } from '../../types/address';
import axios, { isAxiosError } from 'axios';

export type AddressProps = {
  companyAddressId?: string | undefined;
  entitytype?: string;
  entityid?: string | undefined;
  addressId?: string | undefined;
  success?: () => void;
};

type GetCompanyAddressesByTypeProps = {
  type: string | undefined;
  enabled: boolean;
};

const addressConflictToastOptions: UseToastOptions = {
  title: 'Endereço já cadastrado anteriormente.',
  description: 'Altere as informações e tente novamente.',
  status: 'error',
};

export const useGetSelectedCompanyAddresses = () => {
  const { api } = useFetchContext();
  const { selectedCompany } = useUserCompaniesStore();

  return useQuery({
    queryKey: ['company-addresses', { entityId: selectedCompany?.id }],
    queryFn: () =>
      api.get<Address[]>(`/api/addresses?entityId=${selectedCompany?.id}`),
    select: (response) => response.data,
    meta: {
      errorMessage:
        'Não foi possível carregar os endereços da empresa. Tente novamente mais tarde.',
    },
    enabled: !!selectedCompany?.id,
  });
};

export const useGetCompanyAddressesByType = ({
  type,
  enabled,
}: GetCompanyAddressesByTypeProps) => {
  const { selectedCompany } = useUserCompaniesStore();
  const { api } = useFetchContext();
  return useQuery({
    queryKey: ['company-addresses-by-type', selectedCompany?.id, type],
    queryFn: async () =>
      await api.get<Address[]>(
        `/api/addresses?entityType=${type}&entityId=${selectedCompany?.id}`
      ),
    enabled: enabled && !!selectedCompany?.id,
  });
};

export const useUpdateAddress = (
  addressProps: AddressProps,
  onSuccess?: () => void
) => {
  const { api } = useFetchContext();
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation({
    mutationFn: async ({ address }: { address: Partial<Address> }) =>
      await api.patch<Address>(
        `/api/addresses/${addressProps.addressId}`,
        address
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['company-addresses'],
      });
      queryClient.invalidateQueries({
        queryKey: ['company-addresses-by-type'],
      });
      toast({
        // Alterar texto
        title: 'Endereço editado com sucesso!',
        status: 'success',
      });
      if (onSuccess) {
        onSuccess();
      }
    },

    onError: (error) => {
      if (isAxiosError(error) && error.response?.status === 409) {
        toast(addressConflictToastOptions);
        return;
      }

      toast({
        title: 'Tivemos um problema para salvar as alterações no momento.',
        description: 'Tente novamente daqui a pouco.',
        status: 'error',
      });
    },
  });
};

export const useConsultAddresByZipCode = (zipcode: string) => {
  return useQuery({
    queryKey: ['consult-zipcode', zipcode],
    queryFn: async () =>
      await axios.get<ConsultByCepData>(
        `https://viacep.com.br/ws/${zipcode}/json/`
      ),
    select: (response) => response.data,
    enabled: !!(zipcode && zipcode.length === 8),
  });
};

export const useCreateAddress = () => {
  const { api } = useFetchContext();
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ address }: { address: Partial<Address> }) =>
      api.post<Address>('/api/addresses', address),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['company-addresses'],
      });
      queryClient.invalidateQueries({
        queryKey: ['company-addresses-by-type'],
      });
      toast({
        title: 'Endereço criado com sucesso!',
        status: 'success',
      });
    },
    onError: (error) => {
      if (isAxiosError(error) && error.response?.status === 409) {
        toast(addressConflictToastOptions);
        return;
      }

      toast({
        title: 'Tivemos um problema para salvar este endereço.',
        description: 'Tente novamente daqui a pouco.',
        status: 'error',
      });
    },
  });
};
