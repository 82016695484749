import { forwardRef } from '@chakra-ui/react';
import { CellContext } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import CurrencyInput from '../../../../components/CurrencyInput';
import { Employee, EmployeeBenefitCategory } from '../../../../types/employee';

const EditableCell = forwardRef(
  (info: CellContext<Employee, EmployeeBenefitCategory>, ref) => {
    const benefit = info.getValue();
    // We need to keep and update the state of the cell normally
    const [value, setValue] = useState(benefit);

    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = () => {
      info.table.options.meta?.updateData(
        info.row.index,
        info.column.id,
        value
      );
    };

    // If the initialValue is changed externaly, sync it up with our state
    useEffect(() => {
      setValue(benefit);
    }, [benefit]);

    if (benefit === undefined) {
      return null;
    }

    return (
      <CurrencyInput
        ref={ref}
        value={benefit.value}
        onValueChange={({ floatValue }) =>
          setValue({ ...benefit, value: floatValue || 0 })
        }
        onBlur={onBlur}
        _focusWithin={{
          zIndex: 'unset',
        }}
      />
    );
  }
);

export default EditableCell;
