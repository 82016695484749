export enum EntityTypeEnum {
  PERSON = 'PERSON',
  COMPANY = 'COMPANY',
  COLLABORATOR = 'COLLABORATOR',
}

export type AddressType = 'COMPANY' | 'RESIDENTIAL';

export enum AddressTypeEnum {
  SHIPPING = 'SHIPPING',
  RESIDENTIAL = 'RESIDENTIAL',
  COMMERCIAL = 'COMMERCIAL',
  DELIVERY = 'DELIVERY',
  CORRESPONDENCE = 'CORRESPONDENCE',
  TAX = 'TAX',
  RECEIPT = 'RECEIPT',
  OTHER = 'OTHER',
}

export type Address = {
  id: string;
  address: string;
  city: string;
  complement?: string | null | undefined;
  country?: string;
  district?: string;
  name: string;
  number?: string | null | undefined;
  state: string;
  zipCode: string;
  isMain?: boolean;
  entityType?: keyof typeof EntityTypeEnum;
  entityId?: string;
  addressType?: keyof typeof AddressTypeEnum;
};

export type ConsultByCepData = {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  erro?: boolean;
};
