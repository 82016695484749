import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { H2, Semibold } from '../../../../components/Typography';
import { RechargeVisibilityEnum } from '../../../../types/recharge';
import { FiInfo } from 'react-icons/fi';
import { ReactNode, useEffect, useState } from 'react';
import HelpMessage from '../../../../components/HelpMessage';
import {
  getRechargeVisibilityTooltip,
  getRechargeVisibilityLabel,
} from '../../utils';
import { ConfigIcon } from '../../../../assets/customIcons';
import { useForm } from 'react-hook-form';

type RechargeVisibilityConfigProps = {
  rechargeVisibility: keyof typeof RechargeVisibilityEnum | undefined;
  setRechargeVisibility: (value: RechargeVisibilityEnum) => void;
  paymentMethod: string;
};

const rechargeVisibilityOptions = Object.values(RechargeVisibilityEnum);

const RechargeVisibilityConfig = ({
  rechargeVisibility,
  setRechargeVisibility,
  paymentMethod,
}: RechargeVisibilityConfigProps) => {
  const isAccountBalancePayment = paymentMethod === 'account_balance';
  const rechargeVisibilityConfigModal = useDisclosure();

  const [selectedOption, setSelectedOption] = useState<RechargeVisibilityEnum>(
    () => {
      if (rechargeVisibility) {
        return rechargeVisibility as RechargeVisibilityEnum;
      } else {
        return RechargeVisibilityEnum.PAID;
      }
    }
  );

  useEffect(() => {
    if (rechargeVisibility) {
      setSelectedOption(rechargeVisibility as RechargeVisibilityEnum);
    }
  }, [rechargeVisibility]);

  function handleSave() {
    setRechargeVisibility(selectedOption);
    rechargeVisibilityConfigModal.onClose();
  }

  function handleClose() {
    setSelectedOption(rechargeVisibility as RechargeVisibilityEnum);
    rechargeVisibilityConfigModal.onClose();
  }
  return (
    <Box padding={8} bg="white" borderRadius="8px">
      <H2 marginBottom={8}>Configurar exibição no aplicativo</H2>
      <Flex
        padding={3}
        alignItems="center"
        gap={2}
        backgroundColor="gray.100"
        borderRadius="4px"
        marginBottom={4}
      >
        <Icon as={FiInfo} />
        {getRechargeVisibilityLabel(
          rechargeVisibility as RechargeVisibilityEnum
        )}
      </Flex>
      <Button
        variant="link"
        fontWeight={600}
        padding={0}
        onClick={rechargeVisibilityConfigModal.onOpen}
      >
        Editar a configuração para esta recarga
      </Button>

      {rechargeVisibilityConfigModal.isOpen && (
        <Modal
          isCentered
          isOpen={rechargeVisibilityConfigModal.isOpen}
          onClose={handleClose}
          size="lg"
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader fontWeight={700}>
              Informações da próxima recarga
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl as="fieldset">
                <FormLabel as="legend" marginBottom={4}>
                  Quando devemos disponibilizar aos colaboradores da sua empresa
                  as informações da próxima recarga no Aplicativo Raiô?{' '}
                  <HelpMessage
                    label="O colaborador irá visualizar uma mensagem na página inicial com as informações da próxima recarga"
                    iconProps={{ position: 'relative', bottom: '-2px' }}
                  />
                </FormLabel>
                <RadioGroup defaultValue={selectedOption}>
                  <Stack spacing={2}>
                    {rechargeVisibilityOptions.map((option) => {
                      if (
                        option === RechargeVisibilityEnum.PAID &&
                        isAccountBalancePayment
                      ) {
                        return null;
                      }
                      return (
                        <Radio
                          key={option}
                          value={option}
                          onChange={() => setSelectedOption(option)}
                        >
                          <Flex alignItems="center" gap={1}>
                            {getRechargeVisibilityLabel(option)}{' '}
                            {getRechargeVisibilityTooltip(option)}
                          </Flex>
                        </Radio>
                      );
                    })}
                  </Stack>
                </RadioGroup>
              </FormControl>
              <Box
                marginTop={6}
                background="light.orange"
                display="flex"
                alignItems="center"
                padding={'20px'}
                borderRadius="lg"
                fontSize="16px"
                flexBasis="fit-content"
                gap={6}
              >
                <ConfigIcon
                  width={'24px'}
                  height={'24px'}
                  color={'primary.primary'}
                />
                <Text>
                  Acesse o menu <Semibold>Configurações</Semibold> para{' '}
                  <Semibold>alterar a opção padrão</Semibold> para recargas
                  futuras
                </Text>
              </Box>
            </ModalBody>
            <ModalFooter marginTop={4}>
              <Button variant="outline" mr={3} onClick={handleClose}>
                Cancelar
              </Button>
              <Button onClick={handleSave}>Salvar</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default RechargeVisibilityConfig;
