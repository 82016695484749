import {
  Box,
  Button,
  Card,
  Center,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerContentProps,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';

import { useLocation, useNavigate } from 'react-router-dom';
import { useUserCompaniesStore } from '../../../stores/useUserCompaniesStore';
import { H2 } from '../../../components/Typography';
import HelpMessage from '../../../components/HelpMessage';
import {
  SEND_COMMS_OPTIONS_ENUM,
  SendCommsFormValues,
  sendCommsFormSchema,
} from '../../../types/schemas/employeeRegistrationSchema';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from '../../../components/DatePicker';
import { ErrorMessage } from '@hookform/error-message';
import EmailMock from '../../EmployeeRegistrationPage/components/EmailMock';
import SmsPreview from '../../EmployeeRegistrationPage/components/SmsPreview';
import { FiAlertCircle } from 'react-icons/fi';
import EmployeeImportStepper from '../components/EmployeeImportStepper';
import useScrollTopOnFirstRender from '../../../hooks/useScrollTopOnFirstRender';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAuthContext } from '../../../hooks/useAuthContext';

const actionDrawerHeight: DrawerContentProps['height'] = '120px';

const SendCommunicationPage = () => {
  const navigate = useNavigate();
  const { selectedCompany } = useUserCompaniesStore();
  const location = useLocation();

  const {
    control,
    formState: { errors },
    trigger,
    clearErrors,
    watch,
    setValue,
  } = useForm<SendCommsFormValues>({
    resolver: zodResolver(sendCommsFormSchema),
    defaultValues: location.state,
  });

  const scheduledComm = watch('sendCommunication') === 'scheduled';
  const { sendCommunicationDate, sendCommunication } = watch();

  const companyTradeName = selectedCompany?.tradeName || '';

  const { authState } = useAuthContext();

  const { user } = authState;

  const formValues: SendCommsFormValues = {
    sendCommunication,
    sendCommunicationDate,
  };

  useScrollTopOnFirstRender();

  return (
    <>
      <Box height="100%" paddingBottom={actionDrawerHeight}>
        <EmployeeImportStepper />
        <Card padding="32px" width="100%">
          <Stack spacing={8}>
            <Stack spacing={2}>
              <H2>Enviar comunicação de boas-vindas</H2>
              <Text>
                Quando deseja enviar aos colaboradores a mensagem de boas-vindas
                com o link para baixar o aplicativo?{' '}
                <HelpMessage
                  label="Você pode consultar no menu Colaboradores quais foram cadastrados e ainda não fizeram o primeiro acesso ao Aplicativo Raiô"
                  iconProps={{ position: 'relative', top: '2px' }}
                />
              </Text>
            </Stack>

            <Controller
              control={control}
              name="sendCommunication"
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  onChange={(nextValue) => {
                    field.onChange(nextValue);

                    if (nextValue !== SEND_COMMS_OPTIONS_ENUM.Enum.scheduled) {
                      setValue('sendCommunicationDate', undefined);
                    }

                    trigger(['sendCommunication']);
                  }}
                >
                  <Stack direction="row" spacing={4} align={'flex-start'}>
                    <Radio value={SEND_COMMS_OPTIONS_ENUM.Enum.onAdmissionDate}>
                      Na data de admissão
                    </Radio>

                    <Box>
                      <Radio value={SEND_COMMS_OPTIONS_ENUM.Enum.scheduled}>
                        Agendar data
                      </Radio>
                      {scheduledComm && (
                        <FormControl
                          marginTop={2}
                          maxW="215px"
                          isInvalid={!!errors.sendCommunicationDate}
                        >
                          <FormLabel fontSize="sm">Data de envio</FormLabel>
                          <Controller
                            control={control}
                            name="sendCommunicationDate"
                            render={({ field }) => (
                              <DatePicker
                                onChange={field.onChange}
                                onBlur={() => trigger('sendCommunicationDate')}
                                value={sendCommunicationDate}
                                disabledDays={[
                                  new Date(),
                                  { before: new Date() },
                                ]}
                              />
                            )}
                          />
                          <FormErrorMessage>
                            <ErrorMessage
                              name="sendCommunicationDate"
                              errors={errors}
                            />
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Box>
                    <Radio
                      value={
                        SEND_COMMS_OPTIONS_ENUM.Enum.onRegistrationComplete
                      }
                    >
                      Enviar ao finalizar a importação
                    </Radio>
                    <Radio value={SEND_COMMS_OPTIONS_ENUM.Enum.decideLater}>
                      Não quero enviar ou vou decidir depois
                    </Radio>
                  </Stack>

                  <Link
                    color="feedback.alert"
                    textDecoration="underline"
                    onClick={() => {
                      clearErrors('sendCommunication');
                    }}
                  >
                    <ErrorMessage name="sendCommunication" errors={errors} />
                  </Link>
                </RadioGroup>
              )}
            />
            {sendCommunication === 'onAdmissionDate' && (
              <Flex
                backgroundColor="light.orange"
                padding={4}
                borderRadius="lg"
                alignItems="center"
                gap={4}
              >
                <Icon
                  as={FiAlertCircle}
                  boxSize="20px"
                  color="primary.primary"
                />
                <Text>
                  A data de admissão precisará estar preenchida na planilha
                  importada ou deverá ser informada na etapa de revisão da
                  importação importada
                </Text>
              </Flex>
            )}
            <Divider />
            <Stack>
              <Text fontWeight={600}>
                Veja a mensagem que os colaboradores vão receber
              </Text>
              <Text>
                Se ambos os contatos (e-mail e celular) forem cadastrados, o
                link será enviado por e-mail. Se o colaborador não receber, você
                pode pedir o reenvio aqui no Portal
              </Text>
            </Stack>

            <Tabs>
              <TabList>
                <Tab>Por e-mail</Tab>
                <Tab>Por SMS (para quem só tem celular cadastrado)</Tab>
              </TabList>

              <TabPanels background="gray.100">
                <TabPanel>
                  <Center>
                    <EmailMock
                      employeeName={user?.fullName}
                      companyName={companyTradeName}
                    />
                  </Center>
                </TabPanel>

                <TabPanel>
                  <Center minHeight="500px">
                    <SmsPreview />
                  </Center>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Stack>
        </Card>
      </Box>
      <Drawer
        placement="bottom"
        isOpen={true}
        onClose={() => {}}
        autoFocus={false}
        blockScrollOnMount={false}
        returnFocusOnClose={false}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        trapFocus={false}
        lockFocusAcrossFrames={false}
        variant="clickThrough"
      >
        <DrawerContent height={actionDrawerHeight} zIndex={1} paddingX={20}>
          <DrawerBody
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            gap={4}
          >
            <Button
              variant="outline"
              width="fit-content"
              onClick={() =>
                navigate('/colaboradores/importar/preparar-planilha', {
                  state: formValues,
                })
              }
            >
              Voltar
            </Button>
            <Button
              onClick={() =>
                trigger().then((isValid) => {
                  if (isValid) {
                    navigate('/colaboradores/importar/configurar-importacao', {
                      state: formValues,
                    });
                  }
                })
              }
            >
              Continuar
            </Button>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default SendCommunicationPage;
