import { parse, format, isMatch } from 'date-fns';
import { tz } from '@date-fns/tz';

export function convertToISOString(dateString: string): string {
  if (!isMatch(dateString, 'yyyy-MM-dd')) {
    throw new Error('A data deve estar no formato yyyy-MM-dd');
  }

  const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date());

  return format(parsedDate, "yyyy-MM-dd'T'HH:mm:ssXXX", {
    in: tz('America/Sao_Paulo'),
  });
}
