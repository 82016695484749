import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

type CancelRechargeModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onCloseModal: () => void;
  isEdit: boolean;
};

const DrawerAlert = ({
  isOpen,
  onClose,
  onCloseModal,
  isEdit,
}: CancelRechargeModalProps) => {
  return (
    <>
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size="md"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontWeight={700} fontSize="18px">
            Fechar {isEdit ? 'edição' : 'criação'}
          </ModalHeader>
          <ModalCloseButton top={30} />
          <ModalBody marginY={4}>
            <Text fontWeight="400" fontSize="16px">
              Você deseja realmente fechar a {isEdit ? 'edição' : 'criação'} do
              pacote de benefícios?
            </Text>
            <Text
              fontWeight="400"
              fontSize="16px"
              color="feedback.alert"
              marginTop={2}
            >
              Todas as modificações serão perdidas
            </Text>
          </ModalBody>

          <ModalFooter
            display="flex"
            w="100%"
            alignItems="center"
            gap={4}
            justifyContent="space-between"
          >
            <Button variant="outline" onClick={onClose}>
              Voltar
            </Button>
            <Button mr={3} onClick={onCloseModal}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DrawerAlert;
