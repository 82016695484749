import { createIcon } from '@chakra-ui/react';

export const SunIcon = createIcon({
  displayName: 'SunIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    boxSize: '24px',
  },
  path: (
    <path
      d="M23 11h-4.08a6.926 6.926 0 0 0-.43-1.607l3.528-2.044a.999.999 0 1 0-1-1.731l-3.53 2.047a7.064 7.064 0 0 0-1.15-1.15l2.047-3.531a1 1 0 0 0-1.731-1l-2.047 3.525A6.9 6.9 0 0 0 13 5.08V1a1 1 0 0 0-2 0v4.08a6.9 6.9 0 0 0-1.607.429L7.349 1.982a1 1 0 0 0-1.731 1l2.046 3.533a7.062 7.062 0 0 0-1.15 1.15l-3.53-2.047a1 1 0 1 0-1 1.731L5.51 9.393A6.924 6.924 0 0 0 5.079 11H1a1 1 0 0 0 0 2h4.08c.079.551.223 1.09.43 1.607l-3.527 2.044a1 1 0 1 0 1 1.731l3.53-2.047a7.06 7.06 0 0 0 1.149 1.15l-2.043 3.531a1 1 0 0 0 1.73 1l2.045-3.527A6.95 6.95 0 0 0 11 18.92V23a1 1 0 1 0 2 0v-4.08a6.949 6.949 0 0 0 1.607-.429l2.044 3.527a1 1 0 0 0 1.73-1l-2.045-3.531a7.062 7.062 0 0 0 1.149-1.15l3.53 2.047a1 1 0 1 0 1-1.731l-3.527-2.044A6.925 6.925 0 0 0 18.92 13H23a1 1 0 0 0 0-2Zm-11 6c-6.608-.21-6.606-9.791 0-10 6.608.21 6.606 9.791 0 10Z"
      fill="currentColor"
    />
  ),
});
