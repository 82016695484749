import { useState, ChangeEvent } from 'react';

type PasswordRule = {
  description: string;
  check: boolean;
  regex: RegExp;
};

const usePasswordValidation = () => {
  const [password, setPassword] = useState('');
  const [passwordRules, setPasswordRules] = useState<PasswordRule[]>([
    { description: '8 caracteres', check: false, regex: /.{8,}/ },
    {
      description: 'Pelo menos uma letra maiúscula (A-Z)',
      check: false,
      regex: /[A-Z]/,
    },
    {
      description: 'Pelo menos uma letra minúscula (a-z)',
      check: false,
      regex: /[a-z]/,
    },
    { description: 'Um número (0-9)', check: false, regex: /\d/ },
    {
      description: 'Um caractere especial (!@#$%&*)',
      check: false,
      regex: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/,
    },
  ]);

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setPassword(value);

    setPasswordRules((rules) =>
      rules.map((rule) => ({ ...rule, check: rule.regex.test(value) }))
    );
  };

  const allRulesChecked = passwordRules.every((rule) => rule.check);

  return { password, passwordRules, handlePasswordChange, allRulesChecked };
};

export default usePasswordValidation;
