import { createIcon } from '@chakra-ui/react';

export const UploadIcon = createIcon({
  displayName: 'UploadIcon',
  viewBox: '0 0 20 20',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <path
        d="M2.5 7.5L2.5 4.16667C2.5 3.72464 2.6756 3.30072 2.98816 2.98815C3.30072 2.67559 3.72464 2.5 4.16667 2.5L15.8333 2.5C16.2754 2.5 16.6993 2.6756 17.0118 2.98816C17.3244 3.30072 17.5 3.72464 17.5 4.16667L17.5 7.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1665 11.667L9.99984 7.50032L5.83317 11.667"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 7.5L10 17.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});
