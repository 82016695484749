import { useQuery } from '@tanstack/react-query';
import { useFetchContext } from '../../hooks/useFetchContext';
import { Holidays } from '../../types/utils';

export const useGetHolidays = (year: number, amount: number) => {
  const { api } = useFetchContext();
  return useQuery({
    queryKey: ['holidays', year, amount],
    queryFn: async () =>
      await api.get<Holidays[]>(
        `/api/utils/holidays?year=${year}&next=true&amount=${amount}`
      ),
    select: (response) => response.data,
  });
};
