import React, { MouseEvent, MouseEventHandler, useState } from 'react';
import { Button, ButtonProps, Icon } from '@chakra-ui/react';
import { FiCheck } from 'react-icons/fi';

const AddCurrentBalanceButton = (props: Omit<ButtonProps, 'children'>) => {
  const [buttonClicked, setButtonClicked] = useState(false);

  function handleClick(e: MouseEvent<HTMLButtonElement>) {
    if (buttonClicked) {
      return;
    }

    setButtonClicked(true);
    props.onClick?.(e);

    setTimeout(() => {
      setButtonClicked(false);
    }, 1000);
  }

  return (
    <Button
      variant="link"
      padding="0"
      color={buttonClicked ? 'feedback.success' : 'primary.primary'}
      {...props}
      onClick={handleClick}
      _hover={{
        color: 'feedback.success',
      }}
    >
      {buttonClicked && <Icon as={FiCheck} marginRight={1} />}
      {buttonClicked ? 'Valor atualizado' : 'Resgatar saldo atual'}
    </Button>
  );
};

export default AddCurrentBalanceButton;
