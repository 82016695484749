import {
  Box,
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from '@chakra-ui/react';
import RechargeHelpProcessingColabs from '../HelpCards/RechargeHelpProcessingColabs';
import RechargeHelpInfo from '../HelpCards/RechargeHelpInfo';
import { Link, useNavigate } from 'react-router-dom';
import RegistrationProcessingEmptyState from '../RegistrationProcessingQueue/EmptyState';
import RegistrationProcessingQueue from '../RegistrationProcessingQueue/RegistrationProcessingQueue';
import EmployeeEmptyState from '../EmptyState';
import EmployeesList from '../EmployeesList';
import { LuPlusCircle, LuUpload } from 'react-icons/lu';
import { useState } from 'react';
import { Employee } from '../../../../types/employee';

type Employees = {
  data: Employee[];
};
type EmployeePageProps = {
  processingEmployeesCount: number;
  employees: Employees | undefined;
};

const EmployeeTabsPage = ({
  processingEmployeesCount,
  employees,
}: EmployeePageProps) => {
  const navigate = useNavigate();

  const [activeIndex, setActiveIndex] = useState(
    processingEmployeesCount > 0 ? 0 : 1
  );

  const handleChange = (index: number) => {
    setActiveIndex(index);
  };
  return (
    <Box height="100%">
      <Tabs isLazy onChange={handleChange} index={activeIndex}>
        <Flex
          justifyContent="space-between"
          alignItems="flex-start"
          gap={4}
          wrap={['wrap-reverse', 'wrap-reverse', 'wrap-reverse', 'nowrap']}
        >
          <VStack
            spacing={4}
            align="start"
            justify="start"
            minWidth={['unset', 'unset', 'unset', '650px']}
            borderColor="gray.300"
          >
            <Box maxWidth="fit-content">
              {activeIndex === 0 && <RechargeHelpProcessingColabs />}
              {activeIndex === 1 && <RechargeHelpInfo />}
            </Box>
            <TabList
              minWidth="580px"
              display={
                processingEmployeesCount === 0 && activeIndex === 1
                  ? 'none'
                  : 'flex'
              }
              width={'fit-content'}
            >
              {processingEmployeesCount > 0 && (
                <Tab>
                  Colaboradores em processamento ({processingEmployeesCount})
                </Tab>
              )}
              {processingEmployeesCount === 0 && activeIndex === 0 && (
                <Tab>Colaboradores em processamento</Tab>
              )}
              <Tab>Colaboradores cadastrados</Tab>
            </TabList>
          </VStack>

          <Flex
            flexWrap="wrap-reverse"
            gap={4}
            justifyContent={[
              'flex-start',
              'flex-start',
              'flex-start',
              'flex-end',
            ]}
            width="100%"
          >
            <Button
              as={Link}
              leftIcon={<LuUpload size="20px" />}
              variant="outline"
              width="320px"
              to="/colaboradores/importar/preparar-planilha"
            >
              Cadastrar vários colaboradores
            </Button>
            <Button
              as={Link}
              leftIcon={<LuPlusCircle size="20px" />}
              width="320px"
              to="/colaboradores/cadastrar-colaborador"
            >
              Cadastrar um novo colaborador
            </Button>
          </Flex>
        </Flex>

        <TabPanels>
          <TabPanel padding={0}>
            {processingEmployeesCount === 0 && activeIndex === 0 ? (
              <RegistrationProcessingEmptyState marginY={10} />
            ) : (
              <RegistrationProcessingQueue />
            )}
          </TabPanel>
          <TabPanel padding={0}>
            <Flex
              direction="column"
              gap={0}
              lineHeight={1}
              height="calc(100% - 100px)"
            >
              {employees && employees.data.length === 0 ? (
                <EmployeeEmptyState marginY={10} />
              ) : (
                <EmployeesList employees={employees?.data || []} />
              )}
            </Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default EmployeeTabsPage;
