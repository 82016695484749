import {
  AvatarProps,
  Avatar as ChakraAvatar,
  Flex,
  Image,
} from '@chakra-ui/react';
import accent from '../../assets/accent.svg';

const Avatar = (props: AvatarProps) => {
  return (
    <Flex direction="column" alignItems="center" gap="2px">
      <Image src={accent} width="10px" height="6px" />
      <ChakraAvatar
        {...props}
        backgroundColor="gray.50"
        color="gray.800"
        border="2px solid"
        borderColor="gray.800"
        size="sm"
        fontWeight={600}
      />
    </Flex>
  );
};

export default Avatar;
