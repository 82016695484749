import { z } from 'zod';
import { employeeNameRegex } from '../../../utils/validations/regex';

const NAME_REQUIRED_MESSAGE = 'Você precisa preencher o nome do colaborador';

export const employeeFullNameSchema = z.preprocess(
  (val) => {
    if (typeof val === 'string') {
      return val.trim();
    }
  },
  z
    .string({
      required_error: NAME_REQUIRED_MESSAGE,
    })
    .min(1, NAME_REQUIRED_MESSAGE)
    .regex(
      employeeNameRegex,
      'O nome do colaborador só pode conter letras e precisa de pelo menos um sobrenome'
    )
);
