import {
  Card,
  Flex,
  Box,
  useSteps,
  Button,
  Text,
  useDisclosure,
  Icon,
  Drawer,
  DrawerContent,
  DrawerContentProps,
  DrawerBody,
  Link,
} from '@chakra-ui/react';
import EmployeeInfoForm from '../EmployeeInfoForm';
import { useCallback, useEffect, useRef } from 'react';
import BenefitsForm from '../BenefitsForm';
import SendCommunicationForm from '../SendCommunicationForm';
import SendCardForm from '../SendCardForm';
import { H2 } from '../../../../components/Typography';
import { z } from 'zod';
import { registerEmployeeFormSchema } from '../../../../types/schemas/employeeRegistrationSchema';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import HelpMessage from '../../../../components/HelpMessage';
import { useGetEmployeeInfo, usePostEmployee } from '../../../../api/employees';
import SuccessCollaboratorRegistrationModal from '../SuccessCollaboratorRegistrationModal';
import { useGetCompanyBenefitPackages } from '../../../../api/benefit';
import { useUserCompaniesStore } from '../../../../stores/useUserCompaniesStore';
import { addHours, formatISO } from 'date-fns';
import { useGetCustomerConfigs } from '../../../../api/configs';
import CardTypeForm from '../CardTypeForm';
import { CustomerConfig } from '../../../../types/config';
import { CardTypeEnum } from '../../../../types/card';
import { FiCreditCard } from 'react-icons/fi';
import NoNameCardSteps from '../NoNameCardSteps';
import ContactModalButton from '../../../../components/ContactModalButton';
import { useConsultAddresByZipCode } from '../../../../api/address';
import NonNominalCardStock from '../NonNominalCardStock';
import NonNominalCardsInfo from '../../../../components/NonNominalCardsInfo';
import { useGetCompanyNonNominalCardStock } from '../../../../api/card';
import { scrollPage } from '../../../../utils/scrollPage';
import EmployeeRegistrationStepper from '../Stepper/EmployeeRegistrationStepper';
import { cpfValidationSchema } from '../../../../types/schemas/cpfValidationSchema';
import BenefitModal from '../../../BenefitsPage/components/BenefitModal';
import { SunRiseIcon } from '../../../../assets/customIcons';
import EmployeeBasicInfoCard from '../../../../components/EmployeeBasicInfoCard';
import CustomTooltip from '../../../../components/CustomTooltip';

const actionDrawerHeight: DrawerContentProps['height'] = '120px';

type FormValues = z.infer<typeof registerEmployeeFormSchema>;

const EmployeeMultiStepForm = () => {
  const { data: customerConfigArray = [] } = useGetCustomerConfigs();

  const customerConfig = customerConfigArray[0] as CustomerConfig | undefined;
  const isNoNameCardsAllowed = !!customerConfig?.isNoNameCardsAllowed;

  const onboardingScreenTexts = [
    { title: 'Dados do colaborador', subtitle: '' },
    { title: 'Escolher benefícios', subtitle: '' },
    {
      title: 'Enviar comunicação de boas-vindas',
      subtitle: (
        <Text>
          Quando deseja enviar ao colaborador a mensagem de boas-vindas com o
          link para baixar o aplicativo?{' '}
          <HelpMessage
            label="Você pode consultar no menu Colaboradores quais foram cadastrados e ainda não fizeram o primeiro acesso ao Aplicativo Raiô"
            iconProps={{ position: 'relative', top: '2px' }}
          />
        </Text>
      ),
    },
    { title: 'Selecionar local de entrega', subtitle: '' },
  ];

  const {
    activeStep,
    goToNext,
    goToPrevious,
    activeStepPercent,
    setActiveStep,
  } = useSteps({
    index: 0,
    count: onboardingScreenTexts.length,
  });

  const { selectedCompany } = useUserCompaniesStore();

  const { data: benefit } = useGetCompanyBenefitPackages();

  const { data: nonNominalCardStock = 0 } =
    useGetCompanyNonNominalCardStock(isNoNameCardsAllowed);

  const methods = useForm<FormValues>({
    resolver: zodResolver(registerEmployeeFormSchema),
    reValidateMode: 'onBlur',
  });

  const zipCode = methods.watch('collaboratorData.address.zipCode');
  const document = methods.watch('collaboratorData.document');
  const hasPackageSelected = !!methods.watch('packageId');

  const selectedAddressToDelivery = !!methods.watch('deliveryType');

  const isValidCPF = cpfValidationSchema.safeParse(document).success;

  const { data: employeeInfo } = useGetEmployeeInfo(isValidCPF ? document : '');

  const {
    data: address,
    isFetching,
    isFetched,
    isError,
  } = useConsultAddresByZipCode(zipCode || '');
  const isErrorConsult = address?.erro || isError || false;

  const onConsultAddressByZipCode = useCallback(() => {
    if (address) {
      methods.setValue('collaboratorData.address.address', address.logradouro);
      methods.setValue('collaboratorData.address.district', address.bairro);
      methods.setValue('collaboratorData.address.city', address.localidade);
      methods.setValue('collaboratorData.address.state', address.uf);
    }
  }, [address, methods]);

  useEffect(() => {
    onConsultAddressByZipCode();
  }, [onConsultAddressByZipCode]);

  async function validateAndChangeStep(handleNextStep: () => void) {
    await methods.trigger();
    methods.unregister;
    if (methods.formState.isValid) {
      handleNextStep();
    }
  }

  const confirmModalProps = useDisclosure();
  const formRef = useRef<HTMLFormElement>(null);

  const postFormNewEmployee = usePostEmployee({
    onOpen: confirmModalProps.onOpen,
  });

  function handleFormReset() {
    setActiveStep(0);
    methods.reset();
    methods.setValue('isNoNameCardsAllowed', isNoNameCardsAllowed);
  }

  const cardType = methods.watch('cardType');
  const collaboratorName = methods.watch('collaboratorData.fullName');
  const collaboratorDocument = methods.watch('collaboratorData.document');
  const isAddressVisible = methods.watch('isAddressVisible');

  if (cardType === CardTypeEnum.NOT_NOMINAL && isAddressVisible === true) {
    methods.setValue('isAddressVisible', false);
  }

  const cardTypeNotSelected = isNoNameCardsAllowed && cardType === undefined;

  function handleResetAddress() {
    methods.setValue('collaboratorData.address.address', '');
    methods.setValue('collaboratorData.address.district', '');
    methods.setValue('collaboratorData.address.city', '');
    methods.setValue('collaboratorData.address.state', '');
    methods.setValue('collaboratorData.address.complement', '');
    methods.setValue('collaboratorData.address.number', '');
  }

  const shouldShowNonNominalCardColumn =
    isNoNameCardsAllowed && activeStep === 3;

  useEffect(() => {
    methods.setValue('activeFormStep', activeStep);
  }, [activeStep, methods]);

  useEffect(() => {
    methods.setValue('isNoNameCardsAllowed', isNoNameCardsAllowed);
  }, [isNoNameCardsAllowed, methods]);

  return (
    <>
      <Flex justifyContent={'space-between'} flexWrap={'wrap'}>
        <EmployeeRegistrationStepper
          isNoNameCardsAllowed={isNoNameCardsAllowed}
          activeStep={activeStep}
        />
        {activeStep !== 0 && (
          <EmployeeBasicInfoCard
            fullName={collaboratorName}
            cpf={collaboratorDocument}
          />
        )}
      </Flex>
      <Box marginBottom="300px">
        <FormProvider {...methods}>
          <form
            ref={formRef}
            onSubmit={methods.handleSubmit((data: FormValues) => {
              postFormNewEmployee.mutate({
                employee: {
                  ...data,
                  collaboratorData: {
                    ...data.collaboratorData,
                    admissionDate: data.collaboratorData.admissionDate
                      ? formatISO(
                          addHours(data.collaboratorData.admissionDate, 8)
                        )
                      : undefined,
                  },
                  sendCommunicationDate: data.sendCommunicationDate
                    ? formatISO(addHours(data.sendCommunicationDate, 8))
                    : undefined,
                  customerId: selectedCompany?.customerId,
                  companyId: selectedCompany?.id,
                },
              });
            })}
          >
            <Flex gap={5}>
              <Flex
                gap={5}
                width="100%"
                flexWrap={{ base: 'nowrap', xs: 'wrap' }}
              >
                {shouldShowNonNominalCardColumn && (
                  <Flex direction="column" maxWidth="360px" gap={6}>
                    <Card
                      padding={8}
                      borderRadius="lg"
                      flexGrow={1}
                      height="600px"
                      maxHeight="640px"
                    >
                      <Text fontWeight={500} fontSize="20px" marginBottom={8}>
                        Escolher tipo de cartão
                      </Text>
                      <CardTypeForm />
                    </Card>
                    <Box>
                      <NonNominalCardStock stock={nonNominalCardStock} />
                    </Box>

                    {shouldShowNonNominalCardColumn && (
                      <Box>
                        <NonNominalCardsInfo />
                      </Box>
                    )}
                  </Flex>
                )}
                <Card
                  padding={8}
                  borderRadius="lg"
                  flexGrow={1}
                  maxWidth="100%"
                >
                  <Flex
                    justifyContent="space-between"
                    alignItems="flex-start"
                    marginBottom={8}
                  >
                    {activeStep === 3 && cardTypeNotSelected ? (
                      <Box />
                    ) : activeStep === 3 &&
                      cardType === CardTypeEnum.NOT_NOMINAL ? (
                      <Flex
                        direction="column"
                        gap={2}
                        justifyContent="flex-start"
                      >
                        <H2>Entrega do cartão</H2>
                        Confira o passo a passo antes de concluir a solicitação
                      </Flex>
                    ) : (
                      <Flex
                        direction="column"
                        gap={2}
                        justifyContent="flex-start"
                      >
                        <>
                          <H2>{onboardingScreenTexts[activeStep]?.title}</H2>
                          {onboardingScreenTexts[activeStep]?.subtitle}
                        </>
                      </Flex>
                    )}
                  </Flex>

                  {activeStep === 0 && (
                    <EmployeeInfoForm
                      isFetchedByZipCode={isFetched}
                      isFetchingByZipCode={isFetching}
                      isErrorConsult={isErrorConsult}
                      handleResetAddress={handleResetAddress}
                      employeeInfo={employeeInfo?.data}
                    />
                  )}

                  {activeStep === 1 && (
                    <>
                      <BenefitsForm packages={benefit?.data || []} />
                      <Flex marginTop={4} marginLeft={4}>
                        <BenefitModal>
                          <Link
                            textDecor="underline"
                            fontWeight={600}
                            display="flex"
                            minHeight="fit-content"
                            gap={2}
                          >
                            <SunRiseIcon />
                            Saiba onde usar cada um dos benefícios e categorias
                            de uso livre
                          </Link>
                        </BenefitModal>
                      </Flex>
                    </>
                  )}

                  {/* {activeStep === 2 && <CategorizeEmployeeForm />}  TODO: reverts step count when implements*/}

                  {activeStep === 2 && (
                    <SendCommunicationForm setActiveStep={setActiveStep} />
                  )}

                  {activeStep === 3 &&
                    (cardType === CardTypeEnum.NOMINAL ||
                      !isNoNameCardsAllowed) && <SendCardForm />}

                  {activeStep === 3 &&
                    cardType === CardTypeEnum.NOT_NOMINAL && (
                      <Box>
                        <NoNameCardSteps />
                        <Text>
                          Se você precisar, você pode{' '}
                          <ContactModalButton variant="link">
                            falar com o nosso atendimento
                          </ContactModalButton>{' '}
                          para solicitar mais cartões sem nome.
                        </Text>
                      </Box>
                    )}

                  {activeStep === 3 &&
                    isNoNameCardsAllowed &&
                    cardType === undefined && (
                      <Flex
                        direction="column"
                        height="100%"
                        alignItems="center"
                        justifyContent="center"
                        gap={6}
                        padding={6}
                      >
                        <Icon
                          as={FiCreditCard}
                          boxSize="50px"
                          color="primary.primary"
                        />
                        <Text fontSize="28px" textAlign="center">
                          Escolha um tipo de cartão para continuar
                        </Text>
                      </Flex>
                    )}
                </Card>
              </Flex>
            </Flex>
          </form>
        </FormProvider>
        {confirmModalProps.isOpen && (
          <SuccessCollaboratorRegistrationModal
            onClose={confirmModalProps.onClose}
            isOpen={confirmModalProps.isOpen}
            handleFormReset={handleFormReset}
            isNoNameCardsAllowed={isNoNameCardsAllowed}
            nonNominalCardStock={nonNominalCardStock}
            cardType={cardType}
          />
        )}
        <Drawer
          placement="bottom"
          isOpen={
            isValidCPF && !!(employeeInfo && employeeInfo.data.length === 0)
          }
          onClose={() => {}}
          autoFocus={false}
          blockScrollOnMount={false}
          returnFocusOnClose={false}
          closeOnEsc={false}
          closeOnOverlayClick={false}
          trapFocus={false}
          lockFocusAcrossFrames={false}
          variant="clickThrough"
        >
          <DrawerContent height={actionDrawerHeight} zIndex={1} paddingX={20}>
            <DrawerBody
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              gap={4}
            >
              <Flex gap={4}>
                {activeStepPercent > 0 && (
                  <Button
                    variant="outline"
                    width="145px"
                    onClick={() => {
                      goToPrevious();
                      scrollPage(0);
                    }}
                  >
                    Voltar
                  </Button>
                )}
                {activeStepPercent < 1 && (
                  <Button
                    width="220px"
                    isDisabled={postFormNewEmployee.isPending}
                    onClick={() => {
                      validateAndChangeStep(goToNext);
                      scrollPage(0);
                    }}
                  >
                    {activeStep === 1
                      ? hasPackageSelected
                        ? 'Continuar'
                        : 'Pular'
                      : 'Continuar'}
                  </Button>
                )}

                {activeStepPercent === 1 && (
                  <CustomTooltip
                    label={
                      cardTypeNotSelected
                        ? 'Você precisa escolher um tipo de cartão'
                        : !selectedAddressToDelivery && cardType === 'NOMINAL'
                          ? 'Você precisa selecionar um endereço de entrega ou cadastrar um novo endereço'
                          : ''
                    }
                  >
                    <Button
                      onClick={async () => {
                        await methods.trigger();
                        if (formRef.current) {
                          if (methods.formState.isValid) {
                            formRef.current.requestSubmit();
                          }
                        }
                      }}
                      isLoading={postFormNewEmployee.isPending}
                      isDisabled={
                        (!selectedAddressToDelivery &&
                          cardType === 'NOMINAL') ||
                        cardTypeNotSelected
                      }
                    >
                      {cardType === 'NOT_NOMINAL'
                        ? 'Cadastrar colaborador'
                        : 'Cadastrar e entregar cartão'}
                    </Button>
                  </CustomTooltip>
                )}
              </Flex>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Box>
    </>
  );
};

export default EmployeeMultiStepForm;
