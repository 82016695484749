import { createIcon } from '@chakra-ui/react';

export const BulbIcon = createIcon({
  displayName: 'BulbIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      d="M11.997 1.524a6 6 0 0 0-9.946 3.69 5.959 5.959 0 0 0 1.862 5.175 4.174 4.174 0 0 1 1.42 3.044v.107A2.463 2.463 0 0 0 7.794 16h.414a2.463 2.463 0 0 0 2.46-2.46v-.366a3.549 3.549 0 0 1 1.288-2.667 5.996 5.996 0 0 0 .042-8.984v.001Zm-3.79 13.143h-.413a1.128 1.128 0 0 1-1.127-1.127l-.005-.207h2.672v.207a1.128 1.128 0 0 1-1.127 1.127Zm2.867-5.16A5.111 5.111 0 0 0 9.498 12h-.83V7.21A2 2 0 0 0 10 5.334a.667.667 0 1 0-1.334 0 .667.667 0 0 1-1.333 0 .667.667 0 1 0-1.333 0 2 2 0 0 0 1.333 1.878V12h-.881A5.756 5.756 0 0 0 4.82 9.412a4.667 4.667 0 1 1 7.425-1.48 4.662 4.662 0 0 1-1.17 1.573h-.002Z"
    />
  ),
});
