import {
  Box,
  Card,
  Flex,
  Icon,
  InputGroup,
  InputLeftElement,
  Text,
} from '@chakra-ui/react';

import { formatCurrencyInCents } from '../../../../utils/formatters/formatCurrency';
import { FiCalendar, FiCheckCircle, FiFileText } from 'react-icons/fi';

import { RechargeResultResponse } from '../../../../types/recharge';
import { WalletIcon } from '../../../../assets/customIcons/WalletIcon';
import { capitalizeFirstLetter } from '../../../../utils/formatters/capitalizeFirstLetter';
import { subBusinessDays, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useGetAccountBalance } from '../../../../api/recharge';

type RechargeInfoAccountBalanceProps = {
  rechargeInfo: LocationResultResponse;
  statusIsProcessed: boolean;
};

type LocationResultResponse = RechargeResultResponse & {
  isEdit?: boolean;
};

const RechargeInfoAccountBallance = ({
  rechargeInfo,
  statusIsProcessed,
}: RechargeInfoAccountBalanceProps) => {
  const isEdit = rechargeInfo?.isEdit || false;

  return (
    <>
      <Flex
        padding={8}
        flexDirection={'column'}
        alignItems="left"
        width="100%"
        gap={6}
        flexWrap={'wrap'}
        background="white"
      >
        <Flex alignItems="center">
          <Icon as={FiCheckCircle} boxSize="32px" color="feedback.success" />
          <Text fontSize="20px" fontWeight={500} ml={2}>
            Recarga {isEdit ? 'editada' : 'paga'} com sucesso com Créditos Raiô!
          </Text>
        </Flex>
        <Text fontSize="32px" fontWeight={600}>
          {formatCurrencyInCents(rechargeInfo.amount)}
        </Text>

        <Flex flexDir={'column'}>
          <Text fontSize={'16px'} fontWeight={'600'} marginBottom={2}>
            Saldo atual
          </Text>
          <Box>
            <Card
              height={'50px'}
              display={'flex'}
              padding={'16px'}
              alignContent={'flex-start'}
              justifyContent={'center'}
              background={'gray.100'}
            >
              <Box
                alignItems="center"
                display={'flex'}
                flexDir={'row'}
                justifyContent={'left'}
              >
                <Icon
                  as={WalletIcon}
                  boxSize="20px"
                  strokeWidth={2}
                  color="primary.primary"
                />
                <Text
                  fontSize={'18px'}
                  fontWeight="600"
                  lineHeight="130%"
                  ml="12px"
                  color={'gray.black'}
                >
                  Créditos Raiô:{' '}
                  {formatCurrencyInCents(
                    rechargeInfo?.accountBalance?.accountBalanceAvailable || 0
                  )}{' '}
                </Text>
              </Box>
            </Card>
          </Box>
        </Flex>
        <Flex flexDir={'column'}>
          <Text fontSize={'16px'} fontWeight={'600'} marginBottom={4}>
            Data de crédito {!statusIsProcessed && 'prevista'}
          </Text>
          <Box>
            <Card
              height={'50px'}
              display={'flex'}
              padding={'16px'}
              alignContent={'flex-start'}
              justifyContent={'center'}
              background={'gray.100'}
            >
              <Box
                display={'flex'}
                flexDir={'row'}
                justifyContent={'left'}
                alignItems={'center'}
              >
                <Icon
                  as={FiCalendar}
                  boxSize="20px"
                  color="orange.500"
                  mr={2}
                  strokeWidth={2}
                />

                <Text
                  fontSize={'18px'}
                  fontWeight={'400'}
                  lineHeight={'130%'}
                  color={'gray.black'}
                >
                  {capitalizeFirstLetter(
                    format(
                      new Date(
                        rechargeInfo.accountBalance?.scheduleDate || new Date()
                      ),
                      'eeee',
                      {
                        locale: ptBR,
                      }
                    )
                  ) + ', '}
                </Text>
                <Text
                  fontSize={'18px'}
                  fontWeight={'600'}
                  ml={1}
                  color="gray.black"
                >
                  {format(
                    new Date(
                      rechargeInfo.accountBalance?.scheduleDate || new Date()
                    ),
                    'dd/MM/yyyy'
                  )}
                </Text>
              </Box>
            </Card>
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default RechargeInfoAccountBallance;
