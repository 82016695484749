import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Icon,
  Stack,
  Text,
  Wrap,
} from '@chakra-ui/react';
import { Address } from '../../../../types/address';
import { BenefitPackage } from '../../../../types/benefit';
import { FieldKeys, fillingTips } from '../../utils/fillingTips';
import TableFieldExample from '../TableFieldExample';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { RiChat3Line } from 'react-icons/ri';
import { H2 } from '../../../../components/Typography';
import EmployeeImportAddressCard from '../EmployeeImportAddressCard';
import EmployeeImportBenefitCard from '../EmployeeImportBenefitCard';

type FieldInstructionsProps = {
  selectedField: FieldKeys | undefined;
  companyAddressList: Address[];
  benefitPackages: BenefitPackage[];
};

const FieldInstructions = ({
  selectedField,
  companyAddressList,
  benefitPackages,
}: FieldInstructionsProps) => {
  if (!selectedField) {
    return null;
  }

  return (
    <Stack spacing={4}>
      <Box marginY={4}>
        <TableFieldExample
          fieldTitle={fillingTips[selectedField].fieldTitle}
          fieldExample={fillingTips[selectedField].example}
          required={fillingTips[selectedField].required}
        />
      </Box>
      <Stack spacing={4}>
        <Text fontSize="20px" fontWeight="500">
          Dicas de preenchimento
        </Text>
        <Stack
          padding={6}
          spacing={6}
          width="100%"
          backgroundColor="light.orange"
          borderRadius="8px"
          color="black"
        >
          {fillingTips[selectedField].tips.map((tip, index) => (
            <Flex key={index} alignItems="center" gap={6}>
              <Icon as={tip.icon} boxSize="24px" color="primary.primary" />
              <Box>{tip.content}</Box>
            </Flex>
          ))}
        </Stack>
      </Stack>
      <>
        <Accordion key={selectedField} allowMultiple>
          {fillingTips[selectedField].faq.map((faq, index) => (
            <AccordionItem key={index} border="none">
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton
                      fontSize="20px"
                      fontWeight={500}
                      paddingX={0}
                      paddingY={6}
                      _hover={{}}
                    >
                      <Box as="span" flex="1" textAlign="left">
                        {faq.question}
                      </Box>

                      <Icon
                        as={isExpanded ? FiMinus : FiPlus}
                        boxSize="24px"
                        color="primary.primary"
                      />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel
                    paddingY={6}
                    backgroundColor="gray.100"
                    borderTop="1px solid"
                    borderColor="gray.300"
                  >
                    <Stack spacing={6}>
                      {faq.answers.map((answer, index) => (
                        <Flex key={index} alignItems="center" gap={6}>
                          <Icon
                            as={RiChat3Line}
                            boxSize="24px"
                            color="primary.primary"
                          />
                          <Box lineHeight="130%">{answer}</Box>
                        </Flex>
                      ))}
                    </Stack>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          ))}
        </Accordion>
      </>
      {selectedField === 'deliveryAddressName' && (
        <Stack spacing={6}>
          <H2>Nomes dos endereços da empresa</H2>
          <Wrap spacing={4}>
            {companyAddressList.map((address) => (
              <EmployeeImportAddressCard key={address.id} address={address} />
            ))}
          </Wrap>
        </Stack>
      )}
      {selectedField === 'benefitPackage' && (
        <Stack spacing={6}>
          <H2>Nomes dos pacotes de benefícios</H2>
          <Wrap spacing={4}>
            {benefitPackages.map((benefit) => (
              <EmployeeImportBenefitCard key={benefit.id} benefit={benefit} />
            ))}
          </Wrap>
        </Stack>
      )}
    </Stack>
  );
};

export default FieldInstructions;
