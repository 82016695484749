import { CellContext } from '@tanstack/react-table';
import {
  DocumentTypeEnum,
  RechargeFiles,
  RechargeListInfo,
  RechargePaymentMethod,
  RechargeStatusEnum,
} from '../../../../types/recharge';
import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Icon,
  Text,
  Box,
  Img,
  InputGroup,
  InputRightElement,
  Input,
  Link,
} from '@chakra-ui/react';
import CustomDownloadLink from '../CustomDownloadLink';
import { baseDownloadUrl } from '../../../../constants/general';
import { PiBarcode } from 'react-icons/pi';
import { PixIcon } from '../../../../assets/customIcons/PixIcon';
import { FiFileText } from 'react-icons/fi';
import { DownloadButton } from '../../../NewRechargePage/components/RechargeDocs';
import { formatCNPJ } from '../../../../utils/formatters/formatCNPJ';
import { Semibold } from '../../../../components/Typography';
import { formatCurrency } from '../../../../utils/formatters/formatCurrency';
import { RechargeReportIcon } from '../../../../assets/customIcons/RechargeReportIcon';
import InvoiceDownloadButton from '../InvoiceDownloadButton';
import { globalCustomerConfigStore } from '../../../../stores/globalCustomerConfigStore';
import CopyButton from '../../../../components/CopyButton';

type DocumentsCellProps = {
  info: CellContext<RechargeListInfo, RechargeFiles>;
};

const DocumentsCell = ({ info }: DocumentsCellProps) => {
  const rechargeFiles = info.getValue() as
    | RechargeListInfo['rechargeFiles']
    | undefined;

  const recharge = info.row.original;

  const { isOpen, onOpen, onClose } = useDisclosure();

  if (!rechargeFiles) {
    return null;
  }

  const paymentDocumentHref = `${baseDownloadUrl}?rechargeId=${rechargeFiles.rechargeId}&documentType=${DocumentTypeEnum.PAYMENT}`;

  const rechargeReportHref = `${baseDownloadUrl}?rechargeId=${rechargeFiles.rechargeId}&documentType=${DocumentTypeEnum.REPORT}`;

  const { globalCustomerConfig } = globalCustomerConfigStore();

  const paymentBeneficiary = globalCustomerConfig?.paymentBeneficiary;

  return (
    <>
      <Flex gap={2} minHeight="32px">
        {recharge.status === RechargeStatusEnum.PENDING &&
          rechargeFiles.paymentFilePath && (
            <>
              {rechargeFiles.paymentType === RechargePaymentMethod.BOLETO && (
                <CustomDownloadLink
                  href={paymentDocumentHref}
                  toolTipText={'Boleto'}
                >
                  <PiBarcode size="20px" color="fillCurrent" />
                </CustomDownloadLink>
              )}
              {rechargeFiles.paymentType === RechargePaymentMethod.PIX && (
                <CustomDownloadLink
                  toolTipText="Pix Copia e Cola"
                  onClick={onOpen}
                >
                  <PixIcon />
                </CustomDownloadLink>
              )}
            </>
          )}

        {rechargeFiles.reportFilePath && (
          <CustomDownloadLink
            toolTipText="Relatório da recarga"
            href={rechargeReportHref}
          >
            <RechargeReportIcon boxSize="16px" />
          </CustomDownloadLink>
        )}

        <InvoiceDownloadButton recharge={recharge} />
      </Flex>

      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader fontWeight={700} fontSize="18px">
              Informações do Pix Cobrança
            </ModalHeader>
            <ModalCloseButton top="7" />
            <ModalBody marginTop={4}>
              <Flex direction="column" gap={6}>
                <Text>
                  Escaneie a imagem do <Semibold>código QR</Semibold> abaixo com
                  o aplicativo do seu banco para realizar o pagamento da
                  recarga:
                </Text>

                <Flex alignItems="flex-end" gap={6}>
                  <Img
                    src={`${baseDownloadUrl}?rechargeId=${rechargeFiles.rechargeId}&documentType=${DocumentTypeEnum.PAYMENT}`}
                    margin="-20px"
                    backgroundSize="cover"
                    boxSize="250px"
                  />
                  <DownloadButton
                    color="gray.800"
                    maxWidth="fit-content"
                    as={Link}
                    href={paymentDocumentHref}
                    height="48px"
                  >
                    Código QR
                  </DownloadButton>
                </Flex>

                <Text fontSize="16px" fontWeight={400}>
                  Se preferir, pague utilizando o código{' '}
                  <Text as="span" fontWeight={600}>
                    Pix Copia e Cola
                  </Text>
                  :
                </Text>

                <InputGroup>
                  <InputRightElement
                    w="94px"
                    h="54px"
                    top="0.7px"
                    bg="white"
                    right="10px"
                    cursor="pointer"
                  >
                    <CopyButton copyText={rechargeFiles?.paymentCode || ''} />
                  </InputRightElement>
                  <Input
                    defaultValue={rechargeFiles?.paymentCode || ''}
                    paddingRight="105px"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                  />
                </InputGroup>

                <Text fontSize="28px" fontWeight={600}>
                  {formatCurrency(recharge.amount)}
                </Text>

                <Box>
                  <Text fontWeight={600} marginBottom={2}>
                    Dados do beneficiário
                  </Text>
                  <Flex
                    backgroundColor="gray.100"
                    borderRadius="lg"
                    padding={4}
                    gap={1}
                    alignItems="center"
                  >
                    <Icon
                      as={FiFileText}
                      color="primary.primary"
                      marginRight={1}
                    />
                    <Text fontWeight={600}>{paymentBeneficiary?.name}</Text>
                    <Text>
                      ({formatCNPJ(paymentBeneficiary?.registrationNumber)})
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default DocumentsCell;
