import { Input } from '@chakra-ui/react';
import { memo, useRef } from 'react';
import { useTruncateString } from '../../hooks/useTruncateString';
import CustomTooltip from '../CustomTooltip';

type CompanyNameDisplayProps = {
  companyName: string | undefined;
};

const CompanyNameDisplay = memo(({ companyName }: CompanyNameDisplayProps) => {
  const ref = useRef<HTMLInputElement>(null);
  const inputWidth = ref.current?.clientWidth || 150;

  const maxLength = Math.floor(inputWidth / 9 - 3);
  const { isTruncated, truncatedString: truncatedCompanyName } =
    useTruncateString(maxLength, companyName);

  return (
    <CustomTooltip
      data-testid="company-name-tooltip"
      isDisabled={!isTruncated}
      label={companyName}
    >
      <Input
        ref={ref}
        key={truncatedCompanyName}
        type="text"
        defaultValue={truncatedCompanyName}
        isDisabled
      />
    </CustomTooltip>
  );
});

export default CompanyNameDisplay;
