import { Icon, MenuItem, MenuList, useDisclosure } from '@chakra-ui/react';
import { FiEdit, FiXCircle } from 'react-icons/fi';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  RechargeListInfo,
  RechargeStatusEnum,
} from '../../../../types/recharge';
import CustomTooltip from '../../../../components/CustomTooltip';

import CancelRechargeOptionsModal from '../CancelRechargeModal';

type RechargeActionMenuProps = {
  recharge: RechargeListInfo;
};

const RechargeActionMenu = memo(({ recharge }: RechargeActionMenuProps) => {
  const navigate = useNavigate();
  const rechargeModificationsQuantity = recharge.modified;
  const cancelRecharge = useDisclosure();

  return (
    <MenuList>
      <CustomTooltip
        label={
          rechargeModificationsQuantity === 3
            ? 'Só é permitido editar a recarga 3 vezes'
            : ''
        }
      >
        <MenuItem
          isDisabled={rechargeModificationsQuantity === 3}
          icon={<Icon as={FiEdit} boxSize="16px" />}
          onClick={() =>
            navigate(`/recargas/editar-recarga`, {
              state: { recharge },
            })
          }
        >
          Editar ou configurar recarga
        </MenuItem>
      </CustomTooltip>

      <MenuItem
        isDisabled={
          recharge.status !== RechargeStatusEnum.PENDING ||
          recharge.paymentType === 'ACCOUNT_BALANCE'
        }
        icon={<Icon as={FiXCircle} boxSize="16px" />}
        onClick={() => cancelRecharge.onOpen()}
      >
        Cancelar recarga
      </MenuItem>
      {/* <MenuItem
        icon={<Icon as={FiXCircle} boxSize="16px" />}
      >
        Cancelar recarga
      </MenuItem>
      <MenuItem
        icon={<Icon as={FiCopy} boxSize="16px" />}
      >
        Duplicar recarga
      </MenuItem> */}
      {cancelRecharge.isOpen && (
        <CancelRechargeOptionsModal
          isOpen={cancelRecharge.isOpen}
          onClose={cancelRecharge.onClose}
          rechargeInfos={recharge}
        />
      )}
    </MenuList>
  );
});

export default RechargeActionMenu;
