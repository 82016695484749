import { Address } from '../address';

export enum CardDeliveryStatusEnum {
  PENDING = 'PENDING',
  IN_TRANSIT = 'IN_TRANSIT',
  DELIVERED = 'DELIVERED',
  CANCELED = 'CANCELED',
  RETURNED = 'RETURNED',
}

export enum CardTypeEnum {
  NOMINAL = 'NOMINAL',
  NOT_NOMINAL = 'NOT_NOMINAL',
  VIRTUAL = 'VIRTUAL',
}

export enum NewCardReasonEnum {
  LOST = 'LOST',
  STOLEN = 'STOLEN',
  DELIVERY_LOST = 'DELIVERY_LOST',
  DAMAGED = 'DAMAGED',
  OTHER = 'OTHER',
}

export type NonNominalCardStock = {
  stock: number;
};

export enum CardStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  BLOCKED = 'BLOCKED',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
  DELETED = 'DELETED',
  NOT_FOUND = 'NOT_FOUND',
}

export type Card = {
  id: string;
  userId: string | null;
  status: CardStatusEnum;
  type: CardTypeEnum;
  deliveryAddressId: string;
  externalCode: string;
  isContactlessActive: boolean;
  isInternationalShopping: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: null | string;
};

export type CardDelivery = {
  id: string;
  status: CardDeliveryStatusEnum;
  code: string | null;
  address: Address & {
    type: 'COMPANY' | 'RESIDENTIAL';
  };
  isActive: boolean;
  card: Card;
};

export type RequestNewCard = {
  deliveryAddressId: string;
  deliveryType?: 'COMPANY' | 'RESIDENTIAL';
  reason: NewCardReasonEnum;
  description?: string;
  cardId: string;
};
