type AddressCardContentProps = StackProps & {
  address: Partial<Address>;
};

import {
  Box,
  Flex,
  Icon,
  Stack,
  StackProps,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Address } from '../../../../types/address';
import { BuildingIcon } from '../../../../assets/customIcons';
import CopyButton from '../../../../components/CopyButton';

const EmployeeImportAddressCard = ({
  address,
  ...props
}: AddressCardContentProps) => {
  return (
    <VStack
      align="flex-start"
      {...props}
      backgroundColor="gray.100"
      padding={6}
      width="355px"
      borderRadius="lg"
      spacing={6}
    >
      <Icon as={BuildingIcon} boxSize="24px" />
      <Flex justifyContent="space-between" width="100%">
        <Text fontWeight={600}>{address.name}</Text>
        <CopyButton copyText={address.name || ''} />
      </Flex>
      <Box>
        <Text>
          {address.address}
          {address.number ? `, ${address.number}` : ''}
        </Text>
        <Text>
          {address.district},{' '}
          {address.zipCode &&
            address.zipCode.slice(0, 5) + '-' + address.zipCode.slice(5)}
        </Text>
        <Text>
          {address.city}/{address.state}
        </Text>
        <Text>{address.complement}</Text>
      </Box>
    </VStack>
  );
};

export default EmployeeImportAddressCard;
