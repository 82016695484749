import { Box, Flex } from '@chakra-ui/react';
import OrderInfoBoleto from './components/OrderInfoBoleto';
import OrderDocs from './components/OrderDocs';
import OrderInfoPix from './components/OrderInfoPix';
import { useLocation } from 'react-router-dom';
import {
  CreateCreditOrderResponse,
  PaymentTypeEnum,
} from '../../types/credits';

const CreditOrderInfoPage = () => {
  const location = useLocation();
  const checkoutOrder: CreateCreditOrderResponse | undefined = location.state;
  return (
    <Box>
      <Flex
        width="100%"
        alignItems={'flex-start'}
        height="100%"
        paddingBottom={8}
        flexWrap={{ sm: 'wrap', md: 'wrap' }}
        flexDirection={'row'}
        gap={8}
      >
        <Box
          background="white"
          borderRadius="8px"
          height={{
            base: 'auto',
            xl: '100%',
          }}
          width={{
            base: '100%',
            xl: 'calc(100% - 528px)',
          }}
        >
          {checkoutOrder &&
            checkoutOrder.paymentType === PaymentTypeEnum.BILLET && (
              <OrderInfoBoleto orderInfo={checkoutOrder} />
            )}
          {checkoutOrder &&
            checkoutOrder.paymentType === PaymentTypeEnum.PIX && (
              <OrderInfoPix orderInfo={checkoutOrder} />
            )}
        </Box>

        <Flex>
          <OrderDocs orderInfo={checkoutOrder} />
        </Flex>
      </Flex>
    </Box>
  );
};

export default CreditOrderInfoPage;
