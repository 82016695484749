import { Box, Flex, Image, Text } from '@chakra-ui/react';
import raioO from '../../../../assets/o.svg';

const SmsPreview = () => {
  return (
    <Box
      id="sms-preview"
      background="gray.50"
      padding={6}
      maxWidth="430px"
      borderRadius="2xl"
      fontSize="14px"
    >
      <Flex marginBottom={4} justifyContent="space-between">
        <Flex gap={2} alignItems="center">
          <Flex
            boxSize="22px"
            bg="secondary.200"
            borderRadius="sm"
            alignItems="center"
            justifyContent="center"
          >
            <Image src={raioO} boxSize="14px" filter="brightness(30%)" />
          </Flex>
          <Text>Raiô Benefícios</Text>
        </Flex>
        <Text color="gray.600">agora</Text>
      </Flex>
      <Text>
        Sua empresa cadastrou você na Raiô! Baixe o app:{' '}
        <Text as="span" textDecoration="underline">
          raioapp.co/baixar
        </Text>
      </Text>
    </Box>
  );
};

export default SmsPreview;
