import { Text, TextProps } from '@chakra-ui/react';

export const H2 = ({ children, ...props }: TextProps) => {
  return (
    <Text fontSize="20px" fontWeight={500} lineHeight="26px" {...props}>
      {children}
    </Text>
  );
};

export const Semibold = (props: TextProps) => {
  return <Text color="inherit" {...props} as="span" fontWeight={600} />;
};
