import { create } from 'zustand';
import { CustomerConfig } from '../types/config';

type GlobalCustomerConfigStore = {
  globalCustomerConfig: CustomerConfig | undefined;
};

type Actions = {
  setCustomerConfig: (customerConfig?: CustomerConfig) => void;
  reset: () => void;
};

const initialState: GlobalCustomerConfigStore = {
  globalCustomerConfig: undefined,
};

export const globalCustomerConfigStore = create<
  GlobalCustomerConfigStore & Actions
>((set) => ({
  ...initialState,
  setCustomerConfig: (globalCustomerConfig) =>
    set(() => ({ globalCustomerConfig })),
  reset: () => {
    set(initialState);
  },
}));
