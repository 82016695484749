import { Icon, IconProps, forwardRef } from '@chakra-ui/react';

export const HelmetIcon = forwardRef<IconProps, 'svg'>((props, ref) => {
  return (
    <Icon ref={ref} viewBox="0 0 20 18" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
      >
        <path
          d="M10.0001 1C11.8612 1.00018 13.6765 1.5773 15.1959 2.6519C16.7154 3.7265 17.8644 5.24573 18.4847 7.0004C19.105 8.75507 19.166 10.6589 18.6595 12.4497C18.1529 14.2405 17.1036 15.8302 15.6561 17L4.34412 17C2.89662 15.8302 1.84734 14.2405 1.34078 12.4497C0.834218 10.6589 0.895278 8.75507 1.51555 7.0004C2.13583 5.24573 3.28481 3.7265 4.8043 2.6519C6.32379 1.5773 8.13905 1.00018 10.0001 1Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.9999 6L9.1999 6C9.04774 6.00019 8.89763 6.03511 8.761 6.10209C8.62437 6.16906 8.50483 6.26634 8.41148 6.3865C8.31813 6.50666 8.25343 6.64654 8.22231 6.79548C8.19119 6.94443 8.19447 7.09851 8.2319 7.246C8.7389 9.246 9.8279 10.664 11.4999 11.5C13.4999 12.5 15.8329 13 18.4999 13"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
});
