import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Box,
  Grid,
  GridItem,
  Text,
  Icon,
  Flex,
} from '@chakra-ui/react';
import {
  freeUseBenefits,
  restrictBenefits,
} from '../../../../utils/formatters/benefitsPresentation';

type BenefitModalProps = {
  children: React.ReactNode;
};

const BenefitModal = ({ children }: BenefitModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box onClick={onOpen}>{children}</Box>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton
            top="33px"
            right="30px"
            width="24px"
            height="24px"
          />
          <ModalBody padding={4} overflow="auto">
            <Text fontSize="18px" fontWeight={700} marginLeft="9px">
              Saiba onde usar os benefícios
            </Text>
            <Grid
              gap={6}
              marginTop={10}
              marginBottom={10}
              gridTemplate={'repeat(3, 1fr) / repeat(4, 1fr)'}
              gridTemplateRows={'repeat(1, 1fr)'}
              maxHeight="1088px"
            >
              {restrictBenefits.map((benefit) => {
                return (
                  <GridItem
                    maxWidth="254px"
                    key={benefit.name}
                    padding={6}
                    border="1px solid"
                    borderColor={benefit?.color}
                    borderRadius="8px"
                    background={benefit?.backgroundColor}
                    height={'auto'}
                    minHeight="233px"
                  >
                    <Flex flexDirection="column" height="100%">
                      <Icon
                        as={benefit.icon}
                        color={benefit?.color}
                        width={6}
                        height={6}
                        marginBottom={2}
                      />
                      <Box>
                        <Flex alignItems="center">
                          <Text
                            fontSize="18px"
                            fontWeight={600}
                            color={benefit?.color}
                            marginBottom={6}
                            whiteSpace={'nowrap'}
                          >
                            {benefit.name}
                          </Text>
                        </Flex>
                        {benefit.description}
                      </Box>
                    </Flex>
                  </GridItem>
                );
              })}
            </Grid>

            <Box marginTop={4}>
              <Text fontSize="18px" fontWeight={700} marginBottom={4}>
                Categoria de uso livre
              </Text>
              <Text fontWeight={400} marginBottom={4}>
                Não existe diferença de uso entre elas. O valor poderá ser usado
                em{' '}
                <Text as="span" fontWeight={600}>
                  qualquer lugar que aceite cartões com bandeira Visa
                </Text>{' '}
                ou para{' '}
                <Text as="span" fontWeight={600}>
                  pagar boletos.
                </Text>{' '}
                Você pode escolher no menu Configurações quais categorias você
                quer oferecer aos colaboradores
              </Text>

              <Flex flexWrap={'wrap'} gap={6} overflow="auto">
                {freeUseBenefits.map((benefit) => {
                  return (
                    <Box
                      w="254px"
                      key={benefit.name}
                      padding={6}
                      border="1px solid"
                      borderColor={benefit?.color}
                      borderRadius="8px"
                      bg={benefit?.backgroundColor}
                      maxHeight="103px"
                    >
                      <Flex
                        flexDirection="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        height="100%"
                      >
                        <Box>
                          <Icon
                            as={benefit.icon}
                            color={benefit?.color}
                            width={6}
                            height={6}
                            marginBottom={2}
                          />
                          <Flex alignItems="center">
                            <Text
                              fontSize="18px"
                              fontWeight={600}
                              color={benefit?.color}
                            >
                              {benefit.name}
                            </Text>
                          </Flex>
                        </Box>
                      </Flex>
                    </Box>
                  );
                })}
              </Flex>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default BenefitModal;
