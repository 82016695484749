import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Center,
  Text,
} from '@chakra-ui/react';
import ContactModalButton from '../ContactModalButton';

const ErrorBoundaryFallback = () => {
  return (
    <Box>
      <Center height="100vh">
        <Alert
          status="error"
          variant="subtle"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          width="
           fit-content"
          padding={8}
          borderRadius="lg"
        >
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Ops! Ocorreu um erro inesperado.
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            <Text>Entre em contato com o nosso suporte.</Text>
            <ContactModalButton />
          </AlertDescription>
        </Alert>
      </Center>
    </Box>
  );
};

export default ErrorBoundaryFallback;
