import { Checkbox, CheckboxProps, Text } from '@chakra-ui/react';
import { forwardRef } from 'react';

const AddressVisibilityCheckbox = forwardRef<HTMLInputElement>(
  (props: CheckboxProps, ref) => {
    return (
      <Checkbox
        ref={ref}
        size="lg"
        backgroundColor={'gray.100'}
        padding={4}
        borderRadius="lg"
        color="gray.700"
        _checked={{
          boxShadow: 'none',
          backgroundColor: 'light.orange',
          outline: '2px solid',
          outlineColor: 'orange.500',
          color: 'inherit',
        }}
        boxShadow="md"
        spacing={4}
        {...props}
      >
        <Text fontSize="md" lineHeight="1.3">
          Selecione esta opção para que o endereço de entrega do cartão, quando
          for na empresa, ser exibido no Aplicativo Raiô para os colaboradores
        </Text>
      </Checkbox>
    );
  }
);

export default AddressVisibilityCheckbox;
