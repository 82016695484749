import { createIcon } from '@chakra-ui/react';

export const BuildingIcon = createIcon({
  displayName: 'BuildingIcon',
  viewBox: '0 0 48 48',
  defaultProps: {
    boxSize: '24px',
    fill: 'none',
  },
  path: (
    <>
      <g clipPath="url(#a)">
        <path
          fill="currentColor"
          d="M14 28a2 2 0 0 1-2 2h-2a2 2 0 1 1 0-4h2a2 2 0 0 1 2 2Zm8-2h-2a2 2 0 0 0 0 4h2a2 2 0 0 0 0-4Zm-10 8h-2a2 2 0 1 0 0 4h2a2 2 0 0 0 0-4Zm10 0h-2a2 2 0 0 0 0 4h2a2 2 0 0 0 0-4ZM12 10h-2a2 2 0 1 0 0 4h2a2 2 0 0 0 0-4Zm10 0h-2a2 2 0 0 0 0 4h2a2 2 0 0 0 0-4Zm-10 8h-2a2 2 0 1 0 0 4h2a2 2 0 0 0 0-4Zm10 0h-2a2 2 0 0 0 0 4h2a2 2 0 0 0 0-4Zm26 2v18a10.013 10.013 0 0 1-10 10H10A10.012 10.012 0 0 1 0 38V10A10.012 10.012 0 0 1 10 0h12a10.012 10.012 0 0 1 10 10h6a10.013 10.013 0 0 1 10 10ZM10 44h18V10a6 6 0 0 0-6-6H10a6 6 0 0 0-6 6v28a6 6 0 0 0 6 6Zm34-24a6 6 0 0 0-6-6h-6v30h6a6 6 0 0 0 6-6V20Zm-6 6a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 8a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0-16a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="transparent" d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </>
  ),
});
