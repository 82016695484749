import { Tag } from '@chakra-ui/react';
import {
  RechargeListInfo,
  RechargePaymentMethod,
  RechargeStatusEnum,
} from '../../../../types/recharge';
import { capitalizeFirstLetter } from '../../../../utils/formatters/capitalizeFirstLetter';

export function getStatusTag(info: RechargeListInfo) {
  switch (info.status) {
    case RechargeStatusEnum.PAID:
      return (
        <Tag
          size="md"
          fontWeight={400}
          px={4}
          borderRadius="full"
          variant="solid"
          backgroundColor="light.green"
          color="feedback.success"
        >
          Creditada
        </Tag>
      );
    case RechargeStatusEnum.PROCESSED:
      if (info.paymentType === RechargePaymentMethod.PIX) {
        return (
          <Tag
            size="md"
            fontWeight={400}
            px={4}
            borderRadius="full"
            variant="solid"
            backgroundColor="light.blue"
            color="status.paused"
          >
            Pix pago
          </Tag>
        );
      } else if (info.paymentType === RechargePaymentMethod.BOLETO) {
        return (
          <Tag
            size="md"
            fontWeight={400}
            px={4}
            borderRadius="full"
            variant="solid"
            backgroundColor="light.blue"
            color="status.paused"
          >
            Boleto pago
          </Tag>
        );
      } else if (info.paymentType === RechargePaymentMethod.ACCOUNT_BALANCE) {
        return (
          <Tag
            size="md"
            fontWeight={400}
            px={4}
            borderRadius="full"
            variant="solid"
            backgroundColor="light.blue"
            color="status.paused"
          >
            Paga com Créditos Raiô
          </Tag>
        );
      } else {
        return (
          <Tag
            size="md"
            fontWeight={400}
            px={4}
            borderRadius="full"
            variant="solid"
            backgroundColor="light.blue"
            color="status.paused"
          >
            {capitalizeFirstLetter(info.status)}
          </Tag>
        );
      }
    case RechargeStatusEnum.PENDING:
      return (
        <Tag
          size="md"
          fontWeight={400}
          px={4}
          borderRadius="full"
          variant="solid"
          backgroundColor="light.orange"
          color="primary.primary"
        >
          Criada
        </Tag>
      );
    case RechargeStatusEnum.CANCELLED:
      return (
        <Tag
          size="md"
          fontWeight={400}
          px={4}
          borderRadius="full"
          variant="solid"
          backgroundColor="light.gray"
          color="gray.800"
        >
          Cancelada
        </Tag>
      );
    case RechargeStatusEnum.EXPIRED:
      return (
        <Tag
          size="md"
          fontWeight={400}
          px={4}
          borderRadius="full"
          variant="solid"
          backgroundColor="light.gray"
          color="gray.800"
        >
          Cancelada
        </Tag>
      );
    case RechargeStatusEnum.RUNNING:
      return (
        <Tag
          size="md"
          fontWeight={400}
          px={4}
          borderRadius="full"
          variant="solid"
          backgroundColor="light.pink"
          color="status.paused"
        >
          Processando
        </Tag>
      );
    case RechargeStatusEnum.ERROR:
      return (
        <Tag
          size="md"
          fontWeight={400}
          px={4}
          borderRadius="full"
          variant="solid"
          backgroundColor="light.red"
          color="feedback.alert"
        >
          Erro na recarga
        </Tag>
      );
    case RechargeStatusEnum.INVALID_BALANCE:
      return (
        <Tag
          size="md"
          fontWeight={400}
          px={4}
          borderRadius="full"
          variant="solid"
          backgroundColor="light.red"
          color="feedback.alert"
        >
          Erro na recarga
        </Tag>
      );

    default:
      return (
        <Tag
          size="md"
          fontWeight={400}
          px={4}
          borderRadius="full"
          variant="solid"
          backgroundColor="light.gray"
          color="gray.800"
        >
          {capitalizeFirstLetter(info.status)}
        </Tag>
      );
  }
}
