import { isoDateRegex } from '../utils/validations/regex';
import { isBefore, parseISO, startOfDay } from 'date-fns';
function useDisableBilletButton(
  scheduledDate: string | undefined,
  earliestBilletCreditDate?: string | undefined
) {
  let shouldDisableBilletButton = false;

  if (!scheduledDate || !earliestBilletCreditDate) {
    return {
      shouldDisableBilletButton: false,
    };
  }

  if (
    !isoDateRegex.test(scheduledDate) ||
    !isoDateRegex.test(earliestBilletCreditDate)
  ) {
    return {
      shouldDisableBilletButton: false,
    };
  }

  const parsedscheduledDate = startOfDay(parseISO(scheduledDate));
  const parsedeErliestBilletCreditDate = startOfDay(
    parseISO(earliestBilletCreditDate)
  );

  if (isBefore(parsedscheduledDate, parsedeErliestBilletCreditDate)) {
    shouldDisableBilletButton = true;
  }

  return {
    shouldDisableBilletButton,
  };
}

export default useDisableBilletButton;
