import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import ContactForms from '../../ContactForms';

type ProfileContactFormsProps = {
  isOpen: boolean;
  onClose: () => void;
};

const ProfileContactForms = ({ isOpen, onClose }: ProfileContactFormsProps) => {
  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontWeight={700} marginBottom={3}>
            Falar com a Raiô
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ContactForms />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ProfileContactForms;
