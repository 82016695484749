import { Box, Input, InputProps, Text, forwardRef } from '@chakra-ui/react';

const InputWithCounter = forwardRef<InputProps, 'input'>((props, ref) => {
  const { maxLength, value, disabled } = props;

  const counter = value ? value.toString().length : 0;

  const counterColor = disabled
    ? '#AAAAAA'
    : counter === maxLength
      ? 'primary.primary'
      : 'gray.700';

  return (
    <Box position="relative">
      <Input {...props} ref={ref} paddingRight="84px" />
      <Text
        fontSize="16px"
        color={disabled ? '#AAAAAA' : 'gray.700'}
        position="absolute"
        bottom="19px"
        right="16px"
        lineHeight={1}
        zIndex={1}
      >
        <Text as="span" color={counterColor}>
          {counter}
        </Text>
        /{maxLength}
      </Text>
    </Box>
  );
});

export default InputWithCounter;
