import RechargeList from './components/RechargeList';
import { useFetchContext } from '../../hooks/useFetchContext';
import { useUserCompaniesStore } from '../../stores/useUserCompaniesStore';
import { useQuery } from '@tanstack/react-query';
import Spinner from '../../components/Spinner';
import { RechargeListInfo } from '../../types/recharge';

const RechargeListPage = () => {
  const { api } = useFetchContext();
  const { selectedCompany } = useUserCompaniesStore();

  const { data: rechargeListData = [], isFetching } = useQuery({
    queryKey: ['recharge-list', selectedCompany?.id],
    queryFn: () =>
      api.get<RechargeListInfo[]>(
        `/api/recharges?companyId=${selectedCompany?.id}`
      ),
    select: (response) => response.data,
  });

  if (isFetching) return <Spinner />;

  return (
    <>
      <RechargeList rechargeListData={rechargeListData} />
    </>
  );
};

export default RechargeListPage;
