import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Flex,
  Text,
  Skeleton,
} from '@chakra-ui/react';
import { FiCalendar, FiDollarSign, FiList } from 'react-icons/fi';
import { HiOutlineCurrencyDollar } from 'react-icons/hi2';
import InfoDisplayWithIcon from '../../../../components/InfoDisplayWithIcon';
import { useGetRechargeExternal } from '../../../../api/recharge';
import { formatCurrency } from '../../../../utils/formatters/formatCurrency';
import { RechargeStatusEnum } from '../../../../types/recharge';
import { format, parseISO } from 'date-fns';
import { ElementType, ReactNode } from 'react';

type ViewRechargeModalProps = {
  isOpen: boolean;
  onClose: () => void;
  externalCode: string | undefined;
};

type StyledInfoDisplayWithIconProps = {
  children: ReactNode;
  label: string;
  icon: ElementType;
  isLoaded?: boolean;
};

const StyledInfoDisplayWithIcon = (props: StyledInfoDisplayWithIconProps) => {
  const { children, label, icon, isLoaded } = props;
  return (
    <InfoDisplayWithIcon
      icon={icon}
      label={label}
      iconProps={{ boxSize: '20px' }}
      textProps={{ fontSize: '16px', color: 'gray.black' }}
      flexProps={{ gap: 2 }}
      spacing={2}
    >
      <Skeleton isLoaded={isLoaded}>{children}</Skeleton>
    </InfoDisplayWithIcon>
  );
};

const ViewRechargeModal = ({
  isOpen,
  onClose,
  externalCode,
}: ViewRechargeModalProps) => {
  const { query } = useGetRechargeExternal(true, externalCode);

  const { data: recharge } = query;

  const rechargeNumber = (recharge?.rechargeId ?? '').slice(-7);
  const rechargeValue = formatCurrency(recharge?.amount || 0);
  const rechargeStatus =
    recharge?.status === RechargeStatusEnum.SETTLED ? 'Aprovado' : 'Pendente';
  const createdDate = recharge?.createdDate
    ? format(parseISO(recharge.createdDate), 'dd/MM/yyyy')
    : '';

  const creditDate = recharge?.scheduleDate
    ? format(parseISO(recharge.scheduleDate), 'dd/MM/yyyy')
    : '';

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight={700} fontSize="18px" padding={4}>
          Visualizar recarga
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody padding={4} marginTop={2}>
          <Flex gap={6} flexDirection="column">
            <StyledInfoDisplayWithIcon
              icon={HiOutlineCurrencyDollar}
              label="Tipo"
              isLoaded={!query.isLoading}
            >
              <Text fontWeight={600}>Recarga</Text>
            </StyledInfoDisplayWithIcon>

            <StyledInfoDisplayWithIcon
              icon={FiList}
              label="Número da recarga"
              isLoaded={!query.isLoading}
            >
              <Text fontWeight={600}>{rechargeNumber}</Text>
            </StyledInfoDisplayWithIcon>

            <StyledInfoDisplayWithIcon
              icon={FiDollarSign}
              label="Valor da recarga"
              isLoaded={!query.isLoading}
            >
              <Text fontWeight={600}>{rechargeValue}</Text>
            </StyledInfoDisplayWithIcon>

            <StyledInfoDisplayWithIcon
              icon={FiDollarSign}
              label="Status"
              isLoaded={!query.isLoading}
            >
              <Text fontWeight={600}>{rechargeStatus}</Text>
            </StyledInfoDisplayWithIcon>

            <StyledInfoDisplayWithIcon
              icon={FiCalendar}
              label="Data de criação"
              isLoaded={!query.isLoading}
            >
              <Text fontWeight={600}>{createdDate}</Text>
            </StyledInfoDisplayWithIcon>

            <StyledInfoDisplayWithIcon
              icon={FiCalendar}
              label="Data do crédito para os colaboradores"
              isLoaded={!query.isLoading}
            >
              <Text fontWeight={600}>{creditDate}</Text>
            </StyledInfoDisplayWithIcon>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ViewRechargeModal;
