import { Button, ButtonProps } from '@chakra-ui/react';
import { FiPlusCircle } from 'react-icons/fi';

const AddButton = (props: ButtonProps) => {
  return (
    <Button
      variant="link"
      leftIcon={<FiPlusCircle size="20px" />}
      display="flex"
      alignItems="center"
      lineHeight={1.5}
      {...props}
    >
      {props.children}
    </Button>
  );
};

export default AddButton;
