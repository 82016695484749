import { Flex, FlexProps, Text, TextProps } from '@chakra-ui/react';

type ItemsCountDisplayProps = {
  count: number;
  total: number;
  flexProps?: FlexProps;
  textProps?: TextProps;
};

const ItemsCountDisplay = ({
  count,
  total,
  flexProps,
  textProps,
}: ItemsCountDisplayProps) => {
  return (
    <Flex {...flexProps}>
      <Text {...textProps}>
        Mostrando {count} de {total}
      </Text>
    </Flex>
  );
};

export default ItemsCountDisplay;
