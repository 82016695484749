import { Badge } from '@chakra-ui/react';
import {
  CreditTransactionStatus,
  CreditTransactionStatusTranslation,
} from '../../../../types/credits';

type TransactionStatusBadgeProps = {
  status: CreditTransactionStatus;
};

const getBadgeVariant = (status: CreditTransactionStatus) => {
  switch (status) {
    case 'PENDING':
      return 'orange';
    case 'SETTLED':
      return 'green';
    case 'CANCELLED':
      return 'gray';
  }
};

const TransactionStatusBadge = ({ status }: TransactionStatusBadgeProps) => {
  return (
    <Badge variant={getBadgeVariant(status)} fontSize="sm" textTransform="none">
      {CreditTransactionStatusTranslation[status]}
    </Badge>
  );
};

export default TransactionStatusBadge;
