import { createIcon } from '@chakra-ui/react';

export const HomeIcon = createIcon({
  displayName: 'HomeIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    boxSize: '24px',
  },
  path: (
    <path
      fill="currentColor"
      d="m23.121 9.154-7.585-7.282A5.116 5.116 0 0 0 12 .469a5.116 5.116 0 0 0-3.536 1.403L.88 9.154c-.28.267-.501.584-.652.934A2.76 2.76 0 0 0 0 11.191v9.424c0 .764.316 1.496.879 2.036s1.326.844 2.121.844h18c.796 0 1.559-.303 2.122-.844.562-.54.878-1.272.878-2.036v-9.424a2.76 2.76 0 0 0-.227-1.103 2.854 2.854 0 0 0-.652-.934Zm-8.12 12.421H9v-3.777c0-.763.315-1.496.878-2.036A3.065 3.065 0 0 1 12 14.918c.796 0 1.559.304 2.121.844.563.54.88 1.273.88 2.036v3.777Zm7-.96a.94.94 0 0 1-.294.679c-.187.18-.442.281-.707.281h-4v-3.777a4.705 4.705 0 0 0-1.464-3.394A5.107 5.107 0 0 0 12 12.998a5.108 5.108 0 0 0-3.535 1.406A4.705 4.705 0 0 0 7 17.798v3.777H3c-.265 0-.52-.101-.707-.281A.941.941 0 0 1 2 20.615v-9.424c.001-.255.106-.499.293-.68l7.585-7.279A3.073 3.073 0 0 1 12 2.392c.796 0 1.559.302 2.122.84l7.585 7.283c.186.18.291.422.293.676v9.424Z"
    />
  ),
});
