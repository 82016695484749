import {
  useSteps,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Box,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { importRechargeSteps, rechargeSteps } from '../../utils/constants';

type RechargeStepperProps = {
  isSpreadsheetImport?: boolean;
};

const RechargeStepper = ({ isSpreadsheetImport }: RechargeStepperProps) => {
  const steps = isSpreadsheetImport ? importRechargeSteps : rechargeSteps;
  const location = useLocation();
  const { activeStep } = useSteps({
    index: steps.findIndex((step) => step.path === location.pathname),
    count: steps.length,
  });

  return (
    <Stepper
      size="md"
      index={activeStep}
      maxWidth={{ sm: '500px', md: '500px', lg: '1000px' }}
    >
      {steps.map((step, index) => (
        <Step key={index}>
          <StepIndicator fontWeight={600}>
            <StepStatus
              complete={<StepIcon />}
              incomplete={<StepNumber />}
              active={<StepNumber />}
            />
          </StepIndicator>

          <Box flexShrink={{ md: 'initial', lg: '0' }}>
            <StepTitle>{step.title}</StepTitle>
            <StepDescription>{step.description}</StepDescription>
          </Box>

          <StepSeparator />
        </Step>
      ))}
    </Stepper>
  );
};

export default RechargeStepper;
