import { createIcon } from '@chakra-ui/react';

export const UsersIcon = createIcon({
  displayName: 'UsersIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    boxSize: '24px',
  },
  path: (
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2M9 11a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM23 20.999v-2a4 4 0 0 0-3-3.87M16 3.129a4 4 0 0 1 0 7.75"
    />
  ),
});
