import {
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react';
import CustomTooltip from '../../../../components/CustomTooltip';
import { FiEye, FiMoreVertical } from 'react-icons/fi';
import { CreditTransaction } from '../../../../types/credits';
import { useGetRechargeExternal } from '../../../../api/recharge';
import ViewRechargeModal from '../ViewRechargeModal';

type ActionMenuProps = {
  transaction: CreditTransaction;
};

const ActionMenu = ({ transaction }: ActionMenuProps) => {
  const isRecharge = [
    'Recarga',
    'Recarga com Créditos Raiô',
    'Recarga com pagamento via Pix',
    'Recarga com pagamento via Boleto',
  ].includes(transaction.type);

  const isMenuDisabled = !isRecharge;

  const { prefetch } = useGetRechargeExternal(
    false,
    transaction.rechargeExternalCode
  );

  const viewRechargeModal = useDisclosure();

  return (
    <Flex justifyContent="center">
      <Menu>
        <CustomTooltip
          label="Não há ações disponíveis"
          isDisabled={!isMenuDisabled}
        >
          <MenuButton
            as={IconButton}
            aria-label="Menu de ações"
            icon={<Icon as={FiMoreVertical} boxSize="24px" />}
            variant="ghost"
            boxSize="30px"
            isDisabled={isMenuDisabled}
          >
            Ações
          </MenuButton>
        </CustomTooltip>
        <MenuList>
          <MenuItem
            icon={<Icon as={FiEye} boxSize="16px" />}
            onClick={() => {
              prefetch();
              viewRechargeModal.onOpen();
            }}
          >
            Visualizar recarga
          </MenuItem>
        </MenuList>
      </Menu>

      {viewRechargeModal.isOpen && (
        <ViewRechargeModal
          isOpen={viewRechargeModal.isOpen}
          onClose={viewRechargeModal.onClose}
          externalCode={transaction.rechargeExternalCode}
        />
      )}
    </Flex>
  );
};

export default ActionMenu;
