import { EmployeeRefundFormValues } from '../types';

export function getTotalValue(formValues: EmployeeRefundFormValues): number {
  const values = formValues.values;

  if (!values) {
    return 0;
  }
  const valuesArr = Object.values(values).map((value) => value || 0);
  return valuesArr.reduce((acc, value) => acc + value, 0);
}
