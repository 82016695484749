import { Link, LinkProps } from '@chakra-ui/react';
import { PORTAL_TERMS_OF_USE_URL } from '../../constants/general';

const PortalTermsOfUseLink = (props: LinkProps, fontSize?: string) => {
  return (
    <Link
      textDecoration="underline"
      fontWeight={500}
      href={PORTAL_TERMS_OF_USE_URL}
      target="_blank"
      fontSize={fontSize}
      color="primary.100"
      {...props}
    >
      Termos de Uso do Portal Raiô.
    </Link>
  );
};

export default PortalTermsOfUseLink;
