import React from 'react';
import { AuthUser } from '../types/auth';
import { Box, Divider, Flex, Stack, Text } from '@chakra-ui/react';
import bg_light_orange from '../assets/bg_light_orange.svg';
import CompanySelect from '../components/CompanySelect';
import LogoutButton from '../components/LogoutButton';
import { useAuthContext } from '../hooks/useAuthContext';

type FullScreenBgFirstAccessLayoutProps = {
  children: React.ReactNode;
};

type FirstAccessHeaderProps = {
  user: AuthUser | null;
};

// TODO: componentizar esse header

const FirstAccessHeader = ({ user }: FirstAccessHeaderProps) => {
  return (
    <Flex
      width="100%"
      paddingX={{
        base: 4,
        md: 10,
      }}
      paddingY={6}
      position="absolute"
      top={0}
      left={0}
      justifyContent="flex-end"
      alignItems="center"
      gap={4}
    >
      <Flex alignItems="center" gap={4}>
        <Box
          display={{
            base: 'none',
            md: 'block',
          }}
        >
          <CompanySelect />
        </Box>
        <Text lineHeight={1} height="fit-content" color="black">
          {user?.fullName}
        </Text>
        <Divider
          orientation="vertical"
          height="30px"
          borderWidth="1px"
          borderColor="black"
        />
        <LogoutButton text="Sair" />
      </Flex>
    </Flex>
  );
};

const FullScreenBgFirstAccessLayout = ({
  children,
}: FullScreenBgFirstAccessLayoutProps) => {
  const { isAuthenticated, user } = useAuthContext();
  const isAuthed = isAuthenticated();

  return (
    <Stack
      backgroundImage={bg_light_orange}
      backgroundRepeat="no-repeat"
      backgroundPosition={{ base: 'center', lg: 'right' }}
      backgroundSize={{ base: 'cover', lg: 'contain' }}
      bgColor={'gray.200'}
      width="100%"
      minHeight="100vh"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      {isAuthed && <FirstAccessHeader user={user} />}

      <Flex>{children}</Flex>
    </Stack>
  );
};

export default FullScreenBgFirstAccessLayout;
