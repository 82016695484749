import { BenefitPackage } from '../../types/benefit';
import { Box, BoxProps, Flex, Text } from '@chakra-ui/react';
import BenefitDisplay from '../BenefitDisplay';
import { formatCurrencyInCents } from '../../utils/formatters/formatCurrency';

type BenefitCardProps = BoxProps & {
  benefit: BenefitPackage;
};

const BenefitCard = ({ benefit, ...boxProps }: BenefitCardProps) => {
  return (
    <Box minWidth="120px" {...boxProps}>
      <Text fontWeight="600" maxWidth="200px">
        {benefit.name}
      </Text>
      <Flex
        direction="column"
        flexWrap="wrap"
        gap={3}
        maxHeight="260px"
        marginTop={4}
        width="100%"
        fontSize="14px"
      >
        {benefit.package_categories.map((benefit) => (
          <BenefitDisplay
            key={benefit.id}
            benefit={benefit}
            textProps={{
              maxWidth: '120px',
            }}
          >
            <Text marginTop={1} marginLeft="18px" lineHeight={1.2}>
              {formatCurrencyInCents(benefit.value)}{' '}
              {benefit.periodType === 'DAILY' && 'por dia'}
            </Text>
          </BenefitDisplay>
        ))}
      </Flex>
    </Box>
  );
};

export default BenefitCard;
