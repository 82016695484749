import { forwardRef, Input, InputProps } from '@chakra-ui/react';
import CustomTooltip from '../CustomTooltip';

type InputWithTooltipProps = Omit<InputProps, 'tooltipLabel'> & {
  tooltipLabel?: string;
  disabledTooltip?: boolean;
};

const InputWithTooltip = forwardRef<InputWithTooltipProps, 'input'>(
  (props, ref) => {
    const { tooltipLabel, disabledTooltip, ...rest } = props;
    return (
      <CustomTooltip label={tooltipLabel} isDisabled={disabledTooltip}>
        <Input {...rest} ref={ref} />
      </CustomTooltip>
    );
  }
);

export default InputWithTooltip;
