import EmployeeMultiStepForm from './components/EmployeeMultiStepForm';
import { Flex } from '@chakra-ui/react';

const EmployeeRegistrationPage = () => {
  return (
    <Flex direction="column" gap={4}>
      <EmployeeMultiStepForm />
    </Flex>
  );
};

export default EmployeeRegistrationPage;
