import { Text, TextProps } from '@chakra-ui/react';

const fontFamily = `'Sora'`;

type TitleProps = TextProps & {
  children: React.ReactNode;
};

const Title = ({ children, ...props }: TitleProps) => {
  return (
    <Text
      fontFamily={fontFamily}
      fontWeight="700"
      fontSize="24px"
      _after={{
        content: '""',
        display: 'block',
        width: '48px',
        height: '4px',
        backgroundColor: 'primary.primary',
        borderRadius: '4px',
        marginTop: '6px',
      }}
      {...props}
    >
      {children}
    </Text>
  );
};

export default Title;
