import {
  Button,
  Card,
  Checkbox,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerContent,
  DrawerContentProps,
  Text,
  Circle,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  Menu,
  MenuButton,
} from '@chakra-ui/react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  RowSelectionState,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
} from '@tanstack/react-table';
import { FiChevronDown, FiMoreVertical, FiSearch } from 'react-icons/fi';
import { MouseEvent, useCallback, useState } from 'react';
import { fuzzyFilter } from './registrationProcessingTableHelpers';
import { useDebounce } from '../../../../hooks/useDebounce';
import SortDirectionIndicator from '../../../../components/SortDirectionIndicator';
import Pagination from '../../../../components/Pagination';
import { wait } from '../../../../utils/wait';
import { registrationProcessingColumns } from './registrationProcessingTableColumns';
import RegistrationProcessingActionMenu from './RegistrationProcessingActionMenu';
import { BoxIcon } from '../../../../assets/customIcons/BoxIcon';
import { RefreshIcon } from '../../../../assets/customIcons/RefreshIcon';
import { EmployeeProcessing } from '../../../../types/employee';
import SetBenefitPackageModal from '../SetBenefitPackageModal';

const actionDrawerHeight: DrawerContentProps['height'] = '200px';

type RegistrationProcessingTableProps = {
  employees: EmployeeProcessing[];
  refetchProcessingEmployees?: () => void;
};
const RegistrationProcessingTable = ({
  employees,
  refetchProcessingEmployees,
}: RegistrationProcessingTableProps) => {
  const [columns] = useState(() => [...registrationProcessingColumns]);
  const [employeesList] = useState(() => structuredClone(employees));
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [columnVisibility, setColumnVisibility] = useState({});
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'name',
      desc: false,
    },
  ]);
  const [globalFilter, setGlobalFilter] = useState('');
  const debouncedGlobalFilter = useDebounce<string>(globalFilter, 700);

  const { onClose } = useDisclosure();
  const modalControl = useDisclosure();

  const table = useReactTable({
    data: employeesList,
    columns: columns,
    state: {
      columnVisibility: columnVisibility,
      rowSelection: rowSelection,
      sorting: sorting,
      globalFilter: debouncedGlobalFilter,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: fuzzyFilter,
  });
  const totalEmployees = employeesList.length;
  const totalEmployeesOnThePage = table.getRowModel().rows.length;
  const isAllEmployeesOnThePageSelected = table.getIsAllPageRowsSelected();
  const isAllEmployeesSelected = table.getIsAllRowsSelected();
  const isSomeEmployeesSelected = table.getIsSomeRowsSelected();

  const selectedEmployees = table
    .getSelectedRowModel()
    .flatRows.map((row) => row.original);

  const numOfSelectedEmployees = selectedEmployees.length;

  const drawerTitle =
    selectedEmployees.length === 1
      ? selectedEmployees[0].name
      : 'Ações em grupo';

  const countText =
    selectedEmployees.length === 1
      ? 'colaborador selecionado'
      : 'colaboradores selecionados';

  const getButtonAction = async (e: MouseEvent<HTMLButtonElement>) => {
    const toggleAllPageRowsSelectedHandler =
      table.getToggleAllPageRowsSelectedHandler();
    if (isAllEmployeesSelected) {
      table.resetRowSelection();
      await wait(1);
      toggleAllPageRowsSelectedHandler(e);
    } else {
      toggleAllPageRowsSelectedHandler(e);
    }
  };

  const getButtonLabel = useCallback(() => {
    if (isAllEmployeesSelected) {
      return `Selecionar apenas os ${totalEmployeesOnThePage} colaboradores da página atual`;
    }

    if (
      !isAllEmployeesSelected &&
      isSomeEmployeesSelected &&
      !isAllEmployeesOnThePageSelected
    ) {
      return `Adicionar todos os ${totalEmployeesOnThePage} colaboradores da página atual na seleção existente`;
    }

    if (!isAllEmployeesSelected && isAllEmployeesOnThePageSelected) {
      return `Remover apenas os ${totalEmployeesOnThePage} colaboradores desta página da seleção`;
    }
  }, [
    isAllEmployeesOnThePageSelected,
    isAllEmployeesSelected,
    isSomeEmployeesSelected,
    totalEmployeesOnThePage,
  ]);

  return (
    <>
      <Flex
        minHeight="56px"
        justifyContent="space-between"
        alignItems="center"
        marginTop={4}
        marginBottom={4}
      >
        <>
          {modalControl.isOpen && (
            <SetBenefitPackageModal
              isOpen={modalControl.isOpen}
              onClose={modalControl.onClose}
              employees={selectedEmployees}
              queryKeyToInvalidate={['processingEmployees']}
            />
          )}
        </>
        <Flex gap="4" alignItems={'center'} flexWrap="wrap">
          <InputGroup width="360px">
            <Input
              type="search"
              placeholder="Pesquisar por nome, CPF ou pacote"
              value={globalFilter}
              onChange={(e) => setGlobalFilter(String(e.target.value))}
              backgroundColor="gray.50"
              paddingRight={12}
            />

            <InputRightElement>
              <Icon as={FiSearch} boxSize="16px" color="primary.primary" />
            </InputRightElement>
          </InputGroup>

          <Button
            variant={'link'}
            padding={0}
            onClick={refetchProcessingEmployees}
            display="flex"
            gap={3}
            alignItems="center"
          >
            <RefreshIcon />
            Atualizar colaboradores em processamento
          </Button>
        </Flex>

        <Popover>
          <PopoverTrigger>
            <Button
              variant="link"
              rightIcon={<Icon as={FiChevronDown} />}
              padding={0}
            >
              Ver colunas
            </Button>
          </PopoverTrigger>
          <PopoverContent boxShadow="lg" padding={2}>
            <PopoverArrow />
            <PopoverCloseButton />

            <PopoverBody>
              <VStack align="justify">
                {table
                  .getAllLeafColumns()
                  .filter((column) => column.id !== 'select')
                  .map((column) => {
                    return (
                      <Checkbox
                        key={column.id}
                        maxWidth="90%"
                        isChecked={column.getIsVisible()}
                        onChange={column.getToggleVisibilityHandler()}
                        padding={1}
                      >
                        {column.columnDef.header as string}
                      </Checkbox>
                    );
                  })}
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>
      <Flex direction="column" gap={4} marginBottom={actionDrawerHeight}>
        <Card padding={6} maxWidth="100%" overflowX="auto">
          <Table>
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th
                      key={header.id}
                      cursor={header.column.getCanSort() ? 'pointer' : 'unset'}
                      onClick={header.column.getToggleSortingHandler()}
                      userSelect="none"
                    >
                      <Flex
                        alignItems="center"
                        gap={2}
                        {...header.column.columnDef.meta}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getCanSort() && (
                          <SortDirectionIndicator
                            direction={header.column.getIsSorted()}
                          />
                        )}
                      </Flex>
                    </Th>
                  ))}

                  <Th textAlign="center">Ações</Th>
                </Tr>
              ))}
            </Thead>

            <Tbody>
              {table.getRowModel().rows.map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  ))}

                  <Td>
                    <Flex justifyContent="center">
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          aria-label="Menu de ações"
                          icon={<Icon as={FiMoreVertical} boxSize="24px" />}
                          variant="ghost"
                          boxSize="30px"
                        >
                          Ações
                        </MenuButton>
                        <RegistrationProcessingActionMenu
                          key={row.original.id}
                          employee={row.original}
                        />
                      </Menu>
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Card>

        <Flex justifyContent="space-between" alignItems="center">
          <Pagination
            currentPage={table.getState().pagination.pageIndex + 1}
            totalPages={table.getPageCount()}
            onChangeRowsPerPage={(option) =>
              table.setPageSize(option?.value ?? 10)
            }
            handleClickFirstPage={() => table.setPageIndex(0)}
            handleClickNextPage={() => table.nextPage()}
            handleClickPreviousPage={() => table.previousPage()}
            handleClickLastPage={() =>
              table.setPageIndex(table.getPageCount() - 1)
            }
            elementsOnPage={totalEmployeesOnThePage}
            totalElements={totalEmployees}
          />
        </Flex>
      </Flex>
      <Drawer
        placement="bottom"
        isOpen={numOfSelectedEmployees > 0}
        onClose={onClose}
        autoFocus={false}
        blockScrollOnMount={false}
        returnFocusOnClose={false}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        trapFocus={false}
        lockFocusAcrossFrames={false}
        variant="clickThrough"
      >
        <DrawerContent height={actionDrawerHeight} zIndex={1} paddingX={20}>
          <DrawerHeader>{drawerTitle}</DrawerHeader>
          <DrawerBody>
            <Flex alignItems="center" gap={4} marginBottom={6}>
              <Text as="span" fontWeight={600}>
                {numOfSelectedEmployees} {countText} de {totalEmployees}
              </Text>

              <Circle size="4px" bg="gray.800" />
              <Button variant="link" padding={0} onClick={getButtonAction}>
                {getButtonLabel()}
              </Button>
            </Flex>

            <Flex gap={3} flexWrap="wrap">
              <>
                <Button
                  variant="outline"
                  width="fit-content"
                  leftIcon={<BoxIcon />}
                  lineHeight={1}
                  onClick={() => {
                    modalControl.onOpen();
                  }}
                >
                  Definir pacote de benefícios
                </Button>
              </>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default RegistrationProcessingTable;
