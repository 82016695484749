import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Icon,
  Input,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import { IMaskInput } from 'react-imask';
import { useUserCompaniesStore } from '../../stores/useUserCompaniesStore';
import { usePatchCompanyInfo } from '../../api/company';
import { CompanyInfo } from '../../types/company';
import { Address } from '../../types/address';
import zipCodeFormat from '../../utils/formatters/zipCodeFormat';
import { LuPlusCircle } from 'react-icons/lu';

import { useEffect, useState } from 'react';
import DiscardChangesModal from './DiscardChangesModal';
import ExitWithoutSaveModal from './ExitWithoutSaveModal';
import { FiEdit2, FiHelpCircle, FiTrash2 } from 'react-icons/fi';
import AddAndEditAddressModal from './AddAndEditAddressModal';
import DeleteAddressModal from './DeleteAddressModal';
import CustomTooltip from '../../components/CustomTooltip';

const companyAddressSchema = z.object({
  registrationNumber: z.string().optional(),
  name: z.string().min(1, { message: 'Você precisa preencher a razão social' }),
  tradeName: z.string().optional(),
  stateRegistration: z.string(),
  municipalRegistration: z.string(),
  isentionStateRegistration: z.boolean().optional(),
});

type FormValues = z.infer<typeof companyAddressSchema>;

const SetCompanyInfos = ({
  companyInfo,
  companyAddresses,
  tabsIndex,
}: {
  companyInfo: CompanyInfo;
  companyAddresses: Address[];
  tabsIndex: number;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { selectedCompany } = useUserCompaniesStore();
  const displayPopoverContent = useDisclosure();
  const deleteAddressModal = useDisclosure();
  const discardChangesModal = useDisclosure();
  const exitWithoutSavingModal = useDisclosure();
  const [addressToDelete, setAddressToDelete] = useState<Address | null>(null);
  const [addressToEdit, setAddressToEdit] = useState<Address | null>(null);

  const usePatchCompanyInfoMutation = usePatchCompanyInfo();
  const defaultValues = {
    name: companyInfo?.name,
    registrationNumber: companyInfo?.registrationNumber,
    tradeName: companyInfo?.tradeName,
    municipalRegistration: companyInfo?.municipalRegistration || '',
    stateRegistration: companyInfo?.stateRegistration || '',
  };

  const {
    control,
    register,
    formState: { errors, isDirty },
    setValue,
    handleSubmit,
    getValues,
    reset,
  } = useForm<FormValues>({
    resolver: zodResolver(companyAddressSchema),
    values: defaultValues,
  });

  async function onSubmit(data: FormValues) {
    usePatchCompanyInfoMutation.mutateAsync({
      ...data,
      stateRegistration: data.stateRegistration || null,
      municipalRegistration: data.municipalRegistration || null,
    });
    exitWithoutSavingModal.onClose();
  }
  const triggerDeleteAddress = () => {
    deleteAddressModal.onOpen();
    displayPopoverContent.onClose();
  };

  const isTaxAddress = (address: Address): boolean =>
    address.addressType === 'TAX';

  useEffect(() => {
    if (isDirty && tabsIndex !== 0 && exitWithoutSavingModal.isOpen === false) {
      exitWithoutSavingModal.onOpen();
    }

    // removed exitWithoutSavingModal because it causes the modal to open twice.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabsIndex, isDirty]);

  return (
    <form>
      <Flex
        justifyContent="space-between"
        flexDirection="row"
        alignItems="center"
        marginBottom={4}
      >
        <Text fontSize="20px" fontWeight="500">
          Dados principais
        </Text>
      </Flex>

      <Grid templateColumns="repeat(12, 1fr)" gap={4}>
        <GridItem colSpan={6}>
          <FormControl isInvalid={!!errors.registrationNumber}>
            <FormLabel fontSize="sm">CNPJ</FormLabel>
            <Controller
              control={control}
              name="registrationNumber"
              render={({ field }) => (
                <Input
                  inputRef={field.ref}
                  as={IMaskInput}
                  isDisabled={true}
                  mask="00.000.000/0000-00"
                  type="text"
                  value={selectedCompany?.registrationNumber}
                  placeholder={selectedCompany?.registrationNumber as string}
                  unmask={true}
                  onAccept={(value: string) => {
                    setValue('registrationNumber', value);
                  }}
                />
              )}
            />
            <FormErrorMessage>
              <ErrorMessage errors={errors} name="registrationNumber" />
            </FormErrorMessage>
          </FormControl>
        </GridItem>

        <GridItem colSpan={6}>
          <FormControl isInvalid={!!errors.name}>
            <FormLabel fontSize="sm">Razão social</FormLabel>
            <Input type="text" {...register('name')} />
            <FormErrorMessage>
              <ErrorMessage errors={errors} name="name" />
            </FormErrorMessage>
          </FormControl>
        </GridItem>
      </Grid>

      <FormControl marginTop={4}>
        <FormLabel fontSize="sm">Nome fantasia (opcional)</FormLabel>
        <Input type="text" {...register('tradeName')} />
        <FormErrorMessage>
          <ErrorMessage errors={errors} name="tradeName" />
        </FormErrorMessage>
      </FormControl>
      <Text fontSize="20px" fontWeight="500" marginTop="32px">
        Endereços da empresa
      </Text>

      <Flex gap={4} marginTop={4} flexWrap="wrap">
        {companyAddresses.map((address) => (
          <Flex
            key={address.id}
            padding="24px"
            background="gray.100"
            borderRadius="8px"
            width="292px"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Flex justifyContent="space-between">
              {isTaxAddress(address) ? (
                <Flex alignItems="center" gap={1}>
                  <Text
                    fontWeight={600}
                    color="primary.primary"
                    fontSize="16px"
                  >
                    {address.name}
                  </Text>
                  <CustomTooltip label="O endereço fiscal da empresa fica registrado nas cobranças (Boleto/Pix) e nas notas fiscais das recargas criadas">
                    <Flex alignItems="center" justifyContent="center">
                      <Icon as={FiHelpCircle} color="primary.primary" />
                    </Flex>
                  </CustomTooltip>
                </Flex>
              ) : (
                <Text fontWeight={600} color="gray.800" fontSize="16px">
                  {address.name}
                </Text>
              )}
              <Flex gap={4} marginTop={1}>
                <Button
                  variant="ghost"
                  cursor="pointer"
                  width="fit-content"
                  padding={0}
                  margin={0}
                  minWidth="auto"
                  onClick={() => {
                    setAddressToEdit(address);
                    onOpen();
                  }}
                  _hover={{ background: 'none' }}
                  _active={{ background: 'none' }}
                >
                  <FiEdit2 />
                </Button>
                {!isTaxAddress(address) && (
                  <Button
                    cursor="pointer"
                    variant="ghost"
                    width="fit-content"
                    minWidth="auto"
                    padding={0}
                    margin={0}
                    onClick={() => {
                      setAddressToDelete(address);
                      deleteAddressModal.onOpen();
                    }}
                    _hover={{ background: 'none' }}
                    _active={{ background: 'none' }}
                  >
                    <FiTrash2 />
                  </Button>
                )}
              </Flex>
            </Flex>

            <Flex
              marginTop="24px"
              flexDirection="column"
              gap={2}
              justifyContent="start"
            >
              <Text fontSize="14px">
                {address.address}, {address.number}
              </Text>
              <Text fontSize="14px">
                {address.district}, CEP {zipCodeFormat(address.zipCode)}
              </Text>
              <Text fontSize="14px">
                {address.city} / {address.state}
              </Text>

              <Text fontSize="14px">{address.complement}</Text>
            </Flex>
          </Flex>
        ))}
        <Button
          variant="ghost"
          cursor="pointer"
          border="1.5px solid"
          borderColor="primary.primary"
          borderRadius="8px"
          width="292px"
          padding={6}
          height="auto"
          minHeight="190px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap="16px"
          color="primary.primary"
          onClick={onOpen}
        >
          <LuPlusCircle size="40px" color="currentColor" />
          <Text fontSize="16px" fontWeight={600} textAlign="center">
            Cadastrar novo endereço
          </Text>
        </Button>
      </Flex>
      <Drawer
        isOpen={isDirty}
        placement="bottom"
        onClose={() => {}}
        variant="clickThrough"
        autoFocus={false}
        blockScrollOnMount={false}
        returnFocusOnClose={false}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        trapFocus={false}
        lockFocusAcrossFrames={false}
      >
        <DrawerContent padding="24px 32px">
          <DrawerBody
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            gap={4}
            padding={0}
          >
            <Button
              variant="outline"
              width="fit-content"
              onClick={() => {
                discardChangesModal.onOpen();
              }}
            >
              Cancelar
            </Button>
            <Button
              width="fit-content"
              onClick={() => {
                const values = getValues();
                handleSubmit(() => onSubmit(values))();
              }}
              isLoading={usePatchCompanyInfoMutation.isPending}
            >
              Salvar alterações
            </Button>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      {isOpen && (
        <AddAndEditAddressModal
          isOpen={isOpen}
          onClose={() => {
            setAddressToEdit(null);
            onClose();
          }}
          addressToEdit={addressToEdit}
        />
      )}

      {deleteAddressModal.isOpen && addressToDelete && (
        <DeleteAddressModal
          address={addressToDelete}
          isOpen={deleteAddressModal.isOpen}
          onClose={deleteAddressModal.onClose}
          triggerDeleteAddress={triggerDeleteAddress}
        />
      )}

      {discardChangesModal.isOpen && (
        <DiscardChangesModal
          isOpen={true}
          onClose={discardChangesModal.onClose}
          onDiscardChanges={() => {
            reset(defaultValues);
            discardChangesModal.onClose();
          }}
        />
      )}

      {exitWithoutSavingModal.isOpen && (
        <ExitWithoutSaveModal
          isOpen={exitWithoutSavingModal.isOpen}
          onClose={() => {
            const values = getValues();
            handleSubmit(() => onSubmit(values))();
          }}
          onDiscardChanges={() => {
            exitWithoutSavingModal.onClose();
            reset(defaultValues);
          }}
        />
      )}
    </form>
  );
};

export default SetCompanyInfos;
