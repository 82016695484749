import { ReactNode } from 'react';
import { useUserCompaniesStore } from '../stores/useUserCompaniesStore';
import { useFirstAccessNavigation } from '../hooks/useFirstAccessNavigation';

type FirstAccessGateProps = {
  children: ReactNode;
};

const FirstAccessGate = ({ children }: FirstAccessGateProps) => {
  const { selectedCompany } = useUserCompaniesStore();
  useFirstAccessNavigation(selectedCompany);

  return children;
};

export default FirstAccessGate;
