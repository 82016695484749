import { Icon, IconButton, MenuItem, MenuList } from '@chakra-ui/react';
import { memo } from 'react';
import { EmployeeProcessing } from '../../../../types/employee';
import { FiEye } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

type EmployeeActionMenuProps = {
  employee: EmployeeProcessing;
};

const RegistrationProcessingActionMenu = memo(
  ({ employee }: EmployeeActionMenuProps) => {
    const navigate = useNavigate();
    return (
      <MenuList>
        <MenuItem
          onClick={() =>
            navigate(`/colaboradores/editar-colaborador/${employee.id}`)
          }
          icon={<Icon as={FiEye} boxSize="16px" />}
          aria-label="Ver colaborador"
        >
          Ver colaborador
        </MenuItem>
      </MenuList>
    );
  }
);

export default RegistrationProcessingActionMenu;
