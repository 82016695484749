import { TranslateEmployeeStatusEnum } from '../../types/employee';
import { Badge, BadgeProps } from '@chakra-ui/react';
import CustomTooltip from '../CustomTooltip';

const setBadgeVariant = (status: string) => {
  return {
    ACTIVE: 'green',
    WAITING_ONBOARDING: 'green',
    PENDING: 'orange',
    INACTIVE: 'orange',
    PROCESSING: 'pink',
  }[status];
};

type EmployeeStatusBadgeProps = BadgeProps & {
  status: keyof typeof TranslateEmployeeStatusEnum;
};

const EmployeeStatusBadge = ({
  status,
  ...props
}: EmployeeStatusBadgeProps) => {
  return (
    <CustomTooltip
      label={
        status === 'INACTIVE'
          ? 'O colaborador excluiu a conta do aplicativo Raiô, mas o cartão segue ativo e você ainda pode fazer recargas. Para reativar a conta, entre em contato com o suporte'
          : ''
      }
    >
      <Badge
        variant={setBadgeVariant(status) ?? 'blue'}
        fontSize="sm"
        textTransform="none"
        {...props}
      >
        {TranslateEmployeeStatusEnum[status] ?? status}
      </Badge>
    </CustomTooltip>
  );
};

export default EmployeeStatusBadge;
