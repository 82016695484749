import { Flex, Text } from '@chakra-ui/react';
import { UserHeartIcon } from '../../assets/customIcons';
import { maskCpf } from '../../utils/formatters/maskCpf';

type EmployeeBasicInfoCardProps = {
  cpf: string;
  fullName: string;
};

const EmployeeBasicInfoCard = ({
  cpf,
  fullName,
}: EmployeeBasicInfoCardProps) => {
  return (
    <Flex
      padding={4}
      background="white"
      alignItems="center"
      justifyContent="center"
      maxHeight="54px"
      borderRadius="8px"
      gap={2}
    >
      <UserHeartIcon boxSize="16px" color="primary.primary" />
      <Text fontWeight={600}>{fullName}</Text>
      <Text fontSize="14px">({maskCpf(cpf)})</Text>
    </Flex>
  );
};

export default EmployeeBasicInfoCard;
