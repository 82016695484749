import { HStack, Flex, Box } from '@chakra-ui/react';
import { ReactElement } from 'react';

type SupportNavItemProps = {
  icon: ReactElement;
  label: string;
  isOpen: boolean;
};

const SupportNavItem = ({ icon, label, isOpen }: SupportNavItemProps) => {
  return (
    <HStack
      _hover={{
        backgroundColor: 'orange.50',
      }}
      width="full"
      padding={3}
      cursor="pointer"
      userSelect="none"
      rounded="md"
    >
      <Flex alignItems="center" gap={4} width="full">
        {icon}
        <Box
          lineHeight={1}
          fontWeight={600}
          whiteSpace="nowrap"
          display={isOpen ? 'block' : 'none'}
          color="primary.primary"
          fontSize={14}
        >
          {label}
        </Box>
      </Flex>
    </HStack>
  );
};

export default SupportNavItem;
