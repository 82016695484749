import { Icon, IconProps, forwardRef } from '@chakra-ui/react';

export const EducationIcon = forwardRef<IconProps, 'svg'>((props, ref) => {
  return (
    <Icon ref={ref} viewBox="0 0 16 16" {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M8 12.6658C7.08788 12.1392 6.05322 11.862 5 11.862C3.94678 11.862 2.91212 12.1392 2 12.6658L2 3.99916C2.91212 3.47255 3.94678 3.19531 5 3.19531C6.05322 3.19531 7.08788 3.47255 8 3.99916M8 12.6658C8.91212 12.1392 9.94678 11.862 11 11.862C12.0532 11.862 13.0879 12.1392 14 12.6658L14 3.99916C13.0879 3.47255 12.0532 3.19531 11 3.19531C9.94678 3.19531 8.91212 3.47255 8 3.99916M8 12.6658L8 3.99916"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
});
