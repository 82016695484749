import { Flex, Text } from '@chakra-ui/react';

type ActionDrawerInfoCardProps = {
  label: string;
  value: string | number | undefined;
};

const ActionDrawerInfoCard = ({ label, value }: ActionDrawerInfoCardProps) => {
  return (
    <Flex
      direction="column"
      width="264px"
      minHeight="96px"
      backgroundColor="gray.100"
      paddingY={4}
      paddingX={6}
      justifyContent="center"
      borderRadius="xl"
    >
      <Text lineHeight={1} marginBottom={3}>
        {label}
      </Text>
      <Text lineHeight={1} fontSize="xl" fontWeight={600}>
        {value}
      </Text>
    </Flex>
  );
};

export default ActionDrawerInfoCard;
