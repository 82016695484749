import { Navigate, Outlet } from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuthContext';

const ProtectedRoute = () => {
  const { isAuthenticated } = useAuthContext();
  const isAuthed = isAuthenticated();

  if (!isAuthed) {
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
