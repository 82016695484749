export const steps = [
  {
    title: 'Preparar planilha',
    description: 'Obrigatório',
    path: '/recargas/preparar-planilha',
  },
  {
    title: 'Revisar valores',
    description: 'Obrigatório',
    path: '',
  },
  {
    title: 'Ver resumo',
    description: 'Obrigatório',
    path: '',
  },
  {
    title: 'Finalizar pedido',
    description: 'Obrigatório',
    path: '',
  },
];
