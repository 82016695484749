import { Box, Divider, Flex, Image, Stack, Text } from '@chakra-ui/react';
import login from '../assets/images/login.webp';
import handRaio from '../assets/images/hand-raio.webp';
import { useAuthContext } from '../hooks/useAuthContext';
import LogoutButton from '../components/LogoutButton';
import CompanySelect from '../components/CompanySelect';
import { AuthUser } from '../types/auth';

type getImageProps = {
  variant?: 'login' | 'firstAccess' | 'passwordReset';
};

type AccessLayoutProps = getImageProps & {
  children: React.ReactNode;
};

type FirstAccessHeaderProps = {
  user: AuthUser | null;
};

const getImage = ({ variant }: getImageProps) => {
  if (variant === 'login') {
    return login;
  }
  if (variant === 'firstAccess') {
    return handRaio;
  }
  if (variant === 'passwordReset') {
    return handRaio;
  }
  return login;
};

const FirstAccessHeader = ({ user }: FirstAccessHeaderProps) => {
  return (
    <Flex
      width="100%"
      paddingX={10}
      paddingY={6}
      position="absolute"
      top={0}
      left={0}
      justifyContent="flex-end"
      alignItems="center"
      gap={4}
    >
      <Flex alignItems="center" gap={4}>
        <CompanySelect />
        <Text lineHeight={1} height="fit-content" marginX={3}>
          {user?.fullName}
        </Text>
        <Divider
          orientation="vertical"
          height="30px"
          borderWidth="1px"
          borderColor="gray.800"
        />
        <LogoutButton text="Sair" color="gray.black" />
      </Flex>
    </Flex>
  );
};

const AccessLayout = ({
  children,
  variant = 'firstAccess',
}: AccessLayoutProps) => {
  const { isAuthenticated, user } = useAuthContext();
  const isAuthed = isAuthenticated();
  const image = getImage({ variant });
  return (
    <Stack
      minHeight="100vh"
      justifyContent="center"
      position="relative"
      display={{ base: 'block', '1xl': 'flex' }}
    >
      {isAuthed && <FirstAccessHeader user={user} />}
      <Flex
        gap={{ base: 10, lg: 2 }}
        alignItems="center"
        direction={{ base: 'column', '1xl': 'row' }}
        background="gray.100"
        height="100vh"
      >
        <Box
          width="100%"
          position="relative"
          maxWidth={{ base: '100%', '1xl': '1170px' }}
        >
          <Image
            height={{ base: '162px', '1xl': '100vh' }}
            width="100%"
            objectFit="cover"
            src={image}
            alt="Background"
            objectPosition="center"
            borderRadius={{
              base: '0px 0px 20px 0px',
              '1xl': '0px 20px 20px 0px',
            }}
          />

          <Box
            position="absolute"
            bottom={{
              base: '20%',
              md: 8,
            }}
            left={{
              base: '50%',
              md: 'auto',
            }}
            right={{
              base: 'auto',
              md: 8,
            }}
            transform={{
              base: 'translateX(-50%)',
              md: 'none',
            }}
            color="white"
            width={{
              base: '340px',
              md: '450px',
            }}
          >
            <Text
              fontSize={{
                base: '20px',
                md: '30px',
              }}
              fontWeight={400}
              fontFamily={'Sora'}
            >
              Benefício de verdade
            </Text>
            <Text
              fontSize={{
                base: '30px',
                md: '40px',
              }}
              fontWeight={700}
              fontFamily={'Sora'}
            >
              pro Brasil de verdade.
            </Text>
          </Box>
        </Box>
        <Flex
          alignItems="center"
          height="100%"
          justifyContent="center"
          width="100%"
        >
          {children}
        </Flex>
      </Flex>
    </Stack>
  );
};

export default AccessLayout;
