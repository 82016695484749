import { Alert, AlertIcon, AlertProps } from '@chakra-ui/react';
import { FiAlertCircle } from 'react-icons/fi';

type AlertCardProps = AlertProps & {
  children: React.ReactNode;
  iconColor?: string;
};

const AlertCard = ({ children, ...props }: AlertCardProps) => {
  const { gap, padding, iconColor } = props;
  return (
    <Alert
      status="warning"
      borderRadius="lg"
      backgroundColor="light.orange"
      padding={padding || 6}
      gap={gap || 6}
      {...props}
    >
      <AlertIcon
        as={FiAlertCircle}
        color={iconColor || 'primary.primary'}
        margin={0}
      />
      {children}
    </Alert>
  );
};

export default AlertCard;
