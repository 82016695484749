import { useEffect } from 'react';
import { useIsFirstRender } from './useIsFirstRender';

const useScrollTopOnFirstRender = () => {
  const isFirstRender = useIsFirstRender();
  useEffect(() => {
    if (isFirstRender) {
      if (window.scrollY !== 0) {
        window.scrollTo(0, 0);
      }
    }
  }, [isFirstRender]);
};

export default useScrollTopOnFirstRender;
