import { Box } from '@chakra-ui/react';
import PrepareSpreadsheetPage from './PrepareSpreadsheetPage';

const EmployeeImport = () => {
  return (
    <Box height="100%">
      <PrepareSpreadsheetPage />
    </Box>
  );
};

export default EmployeeImport;
