import { createIcon } from '@chakra-ui/react';

export const RechargesIcon = createIcon({
  displayName: 'RechargesIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    boxSize: '24px',
  },
  path: (
    <path
      fill="none"
      d="M12 7.55556C10.1589 7.55556 8.66667 8.55 8.66667 9.77778C8.66667 11.0056 10.1589 12 12 12C13.8411 12 15.3333 12.9944 15.3333 14.2222C15.3333 15.45 13.8411 16.4444 12 16.4444M12 7.55556V16.4444M12 7.55556C13.2333 7.55556 14.3111 8.00222 14.8878 8.66667L12 7.55556ZM12 7.55556V6.44444M12 16.4444V17.5556M12 16.4444C10.7667 16.4444 9.68889 15.9978 9.11222 15.3333L12 16.4444ZM22 12C22 13.3132 21.7413 14.6136 21.2388 15.8268C20.7363 17.0401 19.9997 18.1425 19.0711 19.0711C18.1425 19.9997 17.0401 20.7363 15.8268 21.2388C14.6136 21.7413 13.3132 22 12 22C10.6868 22 9.38642 21.7413 8.17317 21.2388C6.95991 20.7363 5.85752 19.9997 4.92893 19.0711C4.00035 18.1425 3.26375 17.0401 2.7612 15.8268C2.25866 14.6136 2 13.3132 2 12C2 9.34784 3.05357 6.8043 4.92893 4.92893C6.8043 3.05357 9.34784 2 12 2C14.6522 2 17.1957 3.05357 19.0711 4.92893C20.9464 6.8043 22 9.34784 22 12Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
