import { createIcon } from '@chakra-ui/react';

export const BoxIcon = createIcon({
  displayName: 'BoxIcon',
  viewBox: '0 0 22 26',
  defaultProps: {
    width: '22px',
    height: '26px',
    fill: 'none',
  },
  path: (
    <path
      d="M11.9315 1.24987L20.7121 6.31818C21.2879 6.65131 21.6434 7.26542 21.6434 7.93063V18.0683C21.6435 18.3953 21.5575 18.7166 21.394 18.9999C21.2305 19.2831 20.9953 19.5183 20.7121 19.6818L11.9315 24.7501C11.6484 24.9138 11.3272 25 11.0002 25C10.6732 25 10.352 24.9138 10.0689 24.7501L1.28822 19.6808C1.00516 19.5173 0.770084 19.2823 0.606615 18.9993C0.443146 18.7162 0.357035 18.3952 0.356934 18.0683V7.93063C0.356934 7.26542 0.712418 6.65131 1.28822 6.31818L10.0689 1.24987C10.352 1.08618 10.6732 1 11.0002 1C11.3272 1 11.6484 1.08618 11.9315 1.24987ZM10.8671 2.63136L2.75166 7.31651L11.0002 12.0783L19.2487 7.31651L11.1332 2.63136C11.0928 2.60779 11.0469 2.59538 11.0002 2.59538C10.9534 2.59538 10.9075 2.60779 10.8671 2.63136ZM11.7984 22.9844L19.9139 18.2993C19.9543 18.276 19.9878 18.2425 20.0111 18.2021C20.0345 18.1618 20.0468 18.116 20.0469 18.0694V8.69907L11.7984 13.4609V22.9844ZM1.95342 8.70013V18.0694C1.95342 18.1652 2.00451 18.2524 2.08646 18.2993L10.2019 22.9844V13.4619L1.95342 8.70013Z"
      fill="currentColor"
    />
  ),
});
