import { useState, useEffect } from 'react';

type UseTrincateStringReturnType = {
  truncatedString: string;
  isTruncated: boolean;
};

export function useTruncateString(
  maxLength: number,
  inputString: string | undefined | null
): UseTrincateStringReturnType {
  const [truncatedString, setTruncatedString] = useState<string>(
    inputString ?? ''
  );
  const [isTruncated, setIsTruncated] = useState<boolean>(false);

  useEffect(() => {
    if (!inputString) {
      return;
    }
    if (inputString.length > maxLength) {
      const truncated = inputString.substring(0, maxLength - 3) + '...';
      setTruncatedString(truncated);
      setIsTruncated(true);
    } else {
      setTruncatedString(inputString);
      setIsTruncated(false);
    }
  }, [inputString, maxLength]);

  return { truncatedString, isTruncated };
}
