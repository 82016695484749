import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { FiAlertCircle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

type NoBalanceAvailableModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const NoBalanceAvailableModal = ({
  isOpen,
  onClose,
}: NoBalanceAvailableModalProps) => {
  const navigate = useNavigate();

  function handleClick() {
    onClose();
    navigate('/colaboradores');
  }

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontWeight={700}>
          <Flex alignItems="center" gap={2}>
            <Icon as={FiAlertCircle} />
            <Text lineHeight={1}>
              Este colaborador não tem saldos disponíveis
            </Text>
          </Flex>
        </ModalHeader>
        <ModalBody paddingY={4}>
          Este colaborador nunca recebeu uma recarga e não possui saldos
          disponíveis para estorno
        </ModalBody>

        <ModalFooter>
          <Button width="100%" onClick={handleClick}>
            Ir para o menu Colaboradores
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NoBalanceAvailableModal;
