import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Box,
  Text,
  Button,
  Flex,
} from '@chakra-ui/react';

type DiscardChangesModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onDiscardChanges: () => void;
};

const DiscardChangesModal = ({
  isOpen,
  onClose,
  onDiscardChanges,
}: DiscardChangesModalProps) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onDiscardChanges} size="xl">
      <ModalOverlay />
      <ModalContent>
        <Box
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          padding="32px 16px"
        >
          <Box>
            <Text
              fontSize="20px"
              fontWeight="700"
              lineHeight="130%"
              marginBottom={10}
            >
              <ModalCloseButton />
              Cancelar alterações
            </Text>
            <Text>
              Gostaria de continuar editando as alterações que você fez?
            </Text>
          </Box>

          <Flex
            marginTop="40px"
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={2}
          >
            <Button
              width="250px"
              height="56px"
              variant={'outline'}
              border="1px solid orange"
              color="orange"
              onClick={() => {
                onDiscardChanges();
              }}
            >
              Descartar alterações
            </Button>
            <Button
              width="250px"
              height="56px"
              onClick={() => {
                onClose();
              }}
            >
              Continuar editando
            </Button>
          </Flex>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default DiscardChangesModal;
