export const maskCpfForPrivacy = (cpf?: string): string => {
  if (!cpf) {
    return '';
  }

  if (cpf.length !== 11) {
    return cpf;
  }

  const maskedCpf = cpf.replace(
    /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
    '$1.***.***-$4'
  );

  return maskedCpf;
};
