import { createIcon } from '@chakra-ui/react';

export const BenefitsIcon = createIcon({
  displayName: 'BenefitsIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    boxSize: '24px',
  },
  path: (
    <>
      <path
        d="M16 0H8C6.67441 0.00158786 5.40356 0.528882 4.46622 1.46622C3.52888 2.40356 3.00159 3.67441 3 5V23C3.00014 23.1819 3.04989 23.3603 3.14388 23.516C3.23788 23.6717 3.37256 23.7989 3.53344 23.8837C3.69431 23.9686 3.87529 24.008 4.05688 23.9976C4.23847 23.9873 4.4138 23.9276 4.564 23.825L6.67 22.386L8.776 23.825C8.94239 23.9389 9.13934 23.9999 9.341 23.9999C9.54266 23.9999 9.73961 23.9389 9.906 23.825L12.006 22.386L14.106 23.825C14.2725 23.9392 14.4696 24.0003 14.6715 24.0003C14.8734 24.0003 15.0705 23.9392 15.237 23.825L17.337 22.387L19.437 23.824C19.5871 23.9263 19.7623 23.9857 19.9436 23.996C20.125 24.0062 20.3057 23.9669 20.4664 23.8821C20.627 23.7974 20.7616 23.6706 20.8556 23.5151C20.9496 23.3597 20.9996 23.1816 21 23V5C20.9984 3.67441 20.4711 2.40356 19.5338 1.46622C18.5964 0.528882 17.3256 0.00158786 16 0V0ZM19 21.1L17.9 20.348C17.7334 20.2336 17.5361 20.1724 17.334 20.1724C17.1319 20.1724 16.9346 20.2336 16.768 20.348L14.668 21.787L12.568 20.348C12.4015 20.2338 12.2044 20.1727 12.0025 20.1727C11.8006 20.1727 11.6035 20.2338 11.437 20.348L9.337 21.787L7.237 20.348C7.07071 20.2343 6.87396 20.1734 6.6725 20.1734C6.47105 20.1734 6.27429 20.2343 6.108 20.348L5 21.1V5C5 4.20435 5.31607 3.44129 5.87868 2.87868C6.44129 2.31607 7.20435 2 8 2H16C16.7956 2 17.5587 2.31607 18.1213 2.87868C18.6839 3.44129 19 4.20435 19 5V21.1Z"
        fill="currentColor"
      />
      <path
        d="M15.9998 8.00061H7.99976C7.44747 8.00061 6.99976 8.44832 6.99976 9.00061C6.99976 9.55289 7.44747 10.0006 7.99976 10.0006H15.9998C16.552 10.0006 16.9998 9.55289 16.9998 9.00061C16.9998 8.44832 16.552 8.00061 15.9998 8.00061Z"
        fill="currentColor"
      />
      <path
        d="M13.9997 12H7.99975C7.44747 12 6.99976 12.4477 6.99976 13C6.99976 13.5523 7.44747 14 7.99975 14H13.9997C14.552 14 14.9997 13.5523 14.9997 13C14.9997 12.4477 14.552 12 13.9997 12Z"
        fill="currentColor"
      />
    </>
  ),
});
