import { createIcon } from '@chakra-ui/react';

export const RedoAltIcon = createIcon({
  displayName: 'RedoAltIcon',
  viewBox: '0 0 20 20',
  path: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <g clipPath="url(#a)">
        <path
          fill="currentColor"
          d="M7.1 14.723a1 1 0 0 1-1.414 0L.737 9.773a2.5 2.5 0 0 1 0-3.536l4.95-4.949A1 1 0 0 1 7.1 2.7L2.8 7H19a5 5 0 0 1 5 5v7a5.006 5.006 0 0 1-5 5H5a1 1 0 0 1 0-2h14a3 3 0 0 0 3-3v-7a3 3 0 0 0-3-3H2.788L7.1 13.31a1 1 0 0 1 0 1.413Z"
        />
      </g>
    </svg>
  ),
});
