import { ColumnHooks } from '../../../types/general';
import { sanitizeAndCapitalizeString } from '../../../utils/formatters/sanitizeAndCapitalizeString';
import { sanitizeEmail } from '../../../utils/formatters/sanitizeEmail';
import { removeSpaces } from '../../../utils/formatters/sanitizeString';
import { isValidCpf } from '../../../utils/validations/isValidCpf';
import { sanitizeString } from './sanitizeString';

export const steps = [
  {
    title: 'Preparar planilha',
    description: 'Obrigatório',
    path: '/colaboradores/importar/preparar-planilha',
  },
  {
    title: 'Enviar comunicação',
    description: 'Opcional',
    path: '/colaboradores/importar/comunicacao',
  },
  {
    title: 'Configurar e importar',
    description: 'Obrigatório',
    path: '/colaboradores/importar/configurar-importacao',
  },
];

export const employeeImportColumnHooks: ColumnHooks = [
  {
    fieldName: 'fullName',
    callback: (cells) => {
      return cells.map((cell) => {
        const value = sanitizeString(cell.value);
        return {
          ...cell,
          value,
        };
      });
    },
  },
  {
    fieldName: 'document',
    callback: (cells) => {
      return cells.map((cell) => {
        const value = cell.value.replace(/\D/g, '');
        const editedValue = value.length < 11 ? value.padStart(11, '0') : value;
        const isValidValue = isValidCpf(editedValue);

        return {
          ...cell,
          value: isValidValue ? editedValue : value,
        };
      });
    },
  },
  {
    fieldName: 'cellphone',
    callback: (cells) => {
      return cells.map((cell) => ({
        ...cell,
        value: cell.value.replace(/\D/g, ''),
      }));
    },
  },
  {
    fieldName: 'email',
    callback: (cells) => {
      return cells.map((cell) => ({
        ...cell,
        value: sanitizeEmail(cell.value),
      }));
    },
  },
  {
    fieldName: 'identificationNumber',
    callback: (cells) => {
      return cells.map((cell) => ({
        ...cell,
        value: removeSpaces(cell.value),
      }));
    },
  },
  {
    fieldName: 'zipCode',
    callback: (cells) => {
      return cells.map((cell) => {
        const value = cell.value.replace(/\D/g, '');
        return {
          ...cell,
          value:
            value.length < 8 && value.length > 1
              ? value.padStart(8, '0')
              : value,
        };
      });
    },
  },
  {
    fieldName: 'address',
    callback: (cells) => {
      return cells.map((cell) => {
        const value = sanitizeString(cell.value);
        return {
          ...cell,
          value,
        };
      });
    },
  },
  {
    fieldName: 'number',
    callback: (cells) => {
      return cells.map((cell) => ({
        ...cell,
        value: cell.value.replace(/\D/g, ''),
      }));
    },
  },
  {
    fieldName: 'complement',
    callback: (cells) => {
      return cells.map((cell) => {
        const value = sanitizeString(cell.value);
        return {
          ...cell,
          value,
        };
      });
    },
  },
  {
    fieldName: 'district',
    callback: (cells) => {
      return cells.map((cell) => ({
        ...cell,
        value: sanitizeAndCapitalizeString(cell.value),
      }));
    },
  },
  {
    fieldName: 'city',
    callback: (cells) => {
      return cells.map((cell) => {
        const value = sanitizeString(cell.value);
        return {
          ...cell,
          value,
        };
      });
    },
  },
  {
    fieldName: 'socialName',
    callback: (cells) => {
      return cells.map((cell) => {
        const value = sanitizeString(cell.value);
        return {
          ...cell,
          value,
        };
      });
    },
  },
];
