import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  Flex,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  Text,
} from '@chakra-ui/react';
import SetValue from './components/SetValue';
import PaymentMethod from './components/PaymentMethod';
import SendToFinance from '../NewRechargePage/components/SendToFinance';
import { z } from 'zod';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGetRechargeDates } from '../../api/recharge';
import { useCreateCreditOrder } from '../../api/credits';
import { PaymentTypeEnum } from '../../types/credits';
import { useNavigate } from 'react-router-dom';

const creditOrderSchema = z.object({
  paymentType: z
    .string()
    .min(1, 'Você precisa escolher uma forma de pagamento')
    .refine((val) => ['PIX', 'BOLETO'].includes(val), {
      message: 'Você precisa escolher uma forma de pagamento',
    }),

  amount: z.number().min(1, 'Você deve colocar algum valor'),
});

export type CreditOrderFormValues = z.infer<typeof creditOrderSchema>;

const CreditOrderPage = () => {
  const useCreateCreditOrderMutation = useCreateCreditOrder();
  const navigate = useNavigate();

  const methods = useForm<CreditOrderFormValues>({
    resolver: zodResolver(creditOrderSchema),
  });

  const checkoutOrderValues = methods.getValues();
  const { paymentType } = checkoutOrderValues;

  const { data: rechargeDates, isLoading: rechargeDatesIsLoading } =
    useGetRechargeDates({
      paymentMethod: paymentType,
    });

  function onSubmit() {
    if (!rechargeDates?.dueDate || !rechargeDates?.creditDate) return;

    useCreateCreditOrderMutation.mutate(
      {
        ...checkoutOrderValues,
        paymentType:
          paymentType === 'BOLETO'
            ? PaymentTypeEnum.BILLET
            : PaymentTypeEnum.PIX,
        dueDate: rechargeDates.dueDate,
        creditDate: rechargeDates.creditDate,
      },
      {
        onSuccess: (response) => {
          navigate('/creditos-raio/pedido/informacoes-do-pedido', {
            state: response.data,
          });
        },
      }
    );
  }

  return (
    <Box>
      <Stepper
        index={0}
        orientation="horizontal"
        gap="0"
        width="470px"
        marginBottom={6}
      >
        <Step>
          <StepIndicator bg="gray.100" fontWeight={600} marginRight={1}>
            <StepStatus
              complete={<StepIcon />}
              incomplete={<StepNumber />}
              active={<StepNumber />}
            />
          </StepIndicator>
          <Box>
            <Text fontWeight={600}>Adicionar créditos</Text>
            <Text fontSize="14px">Obrigatório</Text>
          </Box>
          <Box width="20px">
            <StepSeparator />
          </Box>
        </Step>
        <Step>
          <StepIndicator
            bg="gray.100"
            border="2px solid"
            borderColor="gray.300"
            fontWeight={600}
            marginRight={1}
          >
            <StepStatus
              complete={<StepIcon />}
              incomplete={<StepNumber />}
              active={<StepNumber />}
            />
          </StepIndicator>
          <Box>
            <Text>Informações do pedido</Text>
            <Text fontSize="14px">Obrigatório</Text>
          </Box>
        </Step>
      </Stepper>

      <FormProvider {...methods}>
        <form>
          <Flex flexWrap={'wrap'} gap={8}>
            <Box flexBasis={'496px'} flexGrow={1}>
              <SetValue />
            </Box>
            <Box flexBasis={'496px'} flexGrow={1}>
              <PaymentMethod
                rechargeDates={rechargeDates}
                rechargeDatesIsLoading={rechargeDatesIsLoading}
              />
            </Box>
            <Box flexBasis={'496px'} flexGrow={1} height="100%">
              <SendToFinance />
            </Box>
          </Flex>
          <Drawer
            placement="bottom"
            isOpen={true}
            autoFocus={false}
            blockScrollOnMount={false}
            returnFocusOnClose={false}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            trapFocus={false}
            lockFocusAcrossFrames={false}
            variant="clickThrough"
            onClose={() => {}}
          >
            <DrawerContent zIndex={1} paddingX={0} height="104px">
              <DrawerBody paddingX={8}>
                <Flex
                  alignItems="center"
                  direction="row-reverse"
                  gap={4}
                  height="100%"
                  justifyContent="space-between"
                >
                  <Flex
                    alignItems="center"
                    gap={4}
                    height="100%"
                    justifyContent="space-between"
                  >
                    <Button
                      variant="outline"
                      width="206px"
                      onClick={() => navigate('/creditos-raio')}
                      isDisabled={useCreateCreditOrderMutation.isPending}
                    >
                      Cancelar
                    </Button>
                    <Button
                      width="206px"
                      isLoading={useCreateCreditOrderMutation.isPending}
                      onClick={() => {
                        methods.handleSubmit(() => onSubmit())();
                      }}
                    >
                      Confirmar
                    </Button>
                  </Flex>
                </Flex>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </form>
      </FormProvider>
    </Box>
  );
};

export default CreditOrderPage;
