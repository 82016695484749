import {
  Box,
  CheckboxProps,
  Circle,
  Flex,
  Icon,
  useCheckbox,
} from '@chakra-ui/react';

import { FiCheck } from 'react-icons/fi';

type CheckboxCardProps = CheckboxProps & {
  boxHeight?: string;
};

const CheckboxCard = (props: CheckboxCardProps) => {
  const { getInputProps, getCheckboxProps, state } = useCheckbox(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box
      as="label"
      position="relative"
      userSelect="none"
      height={props.boxHeight || 'fit-content'}
      minWidth={props.minWidth || '292px'}
    >
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="2px"
        borderColor="gray.100"
        borderRadius="md"
        boxShadow="md"
        alignItems={props.alignItems || 'flex-start'}
        display={props.display || 'block'}
        padding={props.padding || 6}
        maxWidth={props.maxW || props.maxWidth}
        minWidth={props.minWidth || '292px'}
        width={props.width || '100%'}
        height={props.height || 'auto'}
        backgroundColor={props.backgroundColor || 'gray.100'}
        color={props.color || 'gray.800'}
        _checked={
          props._checked || {
            borderColor: 'primary.primary',
            boxShadow: 'none',
            backgroundColor: 'light.orange',
            color: 'gray.black',
          }
        }
        _disabled={{
          cursor: 'not-allowed',
          ...props._disabled,
        }}
        onMouseEnter={undefined}
        onMouseLeave={undefined}
      >
        <Flex
          justifyContent={props.justifyContent || 'space-between'}
          alignItems="start"
          color="inherit"
        >
          {props.children}
          <Box position="absolute" right="24px">
            {state.isChecked ? (
              <Circle
                bg="primary.primary"
                size="24px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Icon
                  as={FiCheck}
                  boxSize="16px"
                  color="gray.50"
                  strokeWidth={'3px'}
                />
              </Circle>
            ) : props.isDisabled ? (
              <></>
            ) : (
              <Circle borderWidth="2px" size="24px" borderColor="gray.300" />
            )}
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default CheckboxCard;
