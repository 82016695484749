import { Employee, EmployeeBenefitCategory } from '../../../types/employee';

export function getUniqueCategories(
  employees: Employee[]
): EmployeeBenefitCategory[] {
  const categoryMap = new Map<string, EmployeeBenefitCategory>();

  employees.forEach((employee) => {
    employee.categories.forEach((category) => {
      if (!categoryMap.has(category.id)) {
        categoryMap.set(category.id, category);
      }
    });
  });

  return Array.from(categoryMap.values());
}
