import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useFetchContext } from '../../hooks/useFetchContext';
import { useUserCompaniesStore } from '../../stores/useUserCompaniesStore';
import { useNavigate, useLocation } from 'react-router';
import { useToast } from '@chakra-ui/react';
import { Company, CompanyInfo } from '../../types/company';
import { AuthState } from '../../types/auth';
import { isAxiosError } from 'axios';
import { clarity } from 'react-microsoft-clarity';
import { setUser, captureException } from '@sentry/react';
import { firstAccessRedirect } from '../../utils/firstAccessRedirect';
import { SHOULD_NOT_REDIRECT_URLS } from '../../routes';

type CompanyListResponse = {
  user: {
    id: string;
  };
  companies: Company[];
};

export const useGetCompanyList = () => {
  const { api } = useFetchContext();
  const { user } = useAuthContext();
  const { selectedCompany } = useUserCompaniesStore();
  const userId = user?.id;
  const query = selectedCompany ? `?selected=${selectedCompany.id}` : '';

  return useQuery({
    queryKey: ['user-companies', { userId: userId, query: query }],
    queryFn: () =>
      api.get<CompanyListResponse>(`/api/users/${userId}/companies${query}`),
    enabled: !!userId,
    select: (response) => response.data,
    gcTime: 15 * 60_000, // 15 minutes,
    staleTime: 15 * 60_000, // 15 minutes,
    meta: {
      errorMessage:
        'Não foi possível carregar a lista de empresas. Tente novamente mais tarde.',
    },
  });
};

export const useGetCompanyInfo = () => {
  const { api } = useFetchContext();
  const { selectedCompany } = useUserCompaniesStore();

  return useQuery({
    queryKey: ['company-info', { companyId: selectedCompany?.id }],
    queryFn: () =>
      api.get<CompanyInfo>(`/api/companies/${selectedCompany?.id}`),
    select: (response) => response.data,
    meta: {
      errorMessage:
        'Não foi possível carregar a lista de empresas. Tente novamente mais tarde.',
    },
  });
};

export const usePatchCompanyInfo = () => {
  const { api } = useFetchContext();
  const { selectedCompany } = useUserCompaniesStore();
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: Partial<CompanyInfo>) =>
      api.patch<CompanyInfo>(`/api/companies/${selectedCompany?.id}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['company-info'],
      });
      toast({
        title: 'Alterações salvas com sucesso!',
        status: 'success',
      });
    },
    onError: (error: unknown) => {
      captureException(error);
      toast({
        title: 'Tivemos um problema salvar as alterações no momento',
        description: 'Tente de novo daqui a pouco',
        status: 'error',
      });
    },
  });
};

export const useSwitchCompanyMutation = () => {
  const { api } = useFetchContext();
  const { setSelectedCompany } = useUserCompaniesStore();
  const { setAuthInfo } = useAuthContext();
  const navigate = useNavigate();
  const toast = useToast();
  const location = useLocation();

  return useMutation({
    mutationKey: ['switch-company'],
    mutationFn: (company: Company) => {
      return api.post<AuthState>(`/api/companies/switch`, {
        companyId: company.id,
      });
    },
    onSuccess: (response) => {
      const authInfo = response.data;
      setAuthInfo(authInfo);
      const selectedCompany = authInfo.user?.selectedCompany;
      setSelectedCompany(selectedCompany);

      if (authInfo.user) {
        const user = authInfo.user;
        if (clarity.hasStarted()) {
          clarity.setTag('customerId', selectedCompany?.id || '');
        }
        setUser({
          id: user.id,
          email: user.username,
          username: user.username,
          customerId: selectedCompany?.id,
        });
      }

      if (!selectedCompany?.registrationStep) {
        return;
      }

      // on company switch, if the company finished onboarding and the user is NOT in one of these urls, it should be redirected to the home page
      if (
        selectedCompany.registrationStep === 4 &&
        !SHOULD_NOT_REDIRECT_URLS.includes(location.pathname)
      ) {
        navigate('/');
      } else if (selectedCompany.registrationStep < 4) {
        firstAccessRedirect(selectedCompany);
      }
    },
    onError: () => {
      toast({
        title: 'Tivemos um problema ao trocar de empresa.',
        description: 'Tente de novo daqui a pouco.',
        status: 'error',
      });
    },
  });
};

export const useSwitchFirstAccessCompany = () => {
  const { api } = useFetchContext();
  const { setSelectedCompany } = useUserCompaniesStore();
  const { setAuthInfo } = useAuthContext();
  const toast = useToast();

  return useMutation({
    mutationKey: ['switch-first-access-company'],
    mutationFn: ({ token }: { token: string }) => {
      return api.post<AuthState>(`/api/companies/switch`, { token });
    },
    onSuccess: (response) => {
      const authInfo = response.data;
      setAuthInfo(authInfo);
      setSelectedCompany(authInfo.user?.selectedCompany);
    },
    onError: (error) => {
      if (isAxiosError(error)) {
        if (error.response?.status !== 404) {
          toast({
            title:
              'Tivemos um problema ao realizar o primeiro acesso da empresa.',
            description: 'Tente de novo daqui a pouco.',
            status: 'error',
          });
        }
      }
    },
  });
};
