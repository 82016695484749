import { createIcon } from '@chakra-ui/react';

export const DocumentSignedIcon = createIcon({
  displayName: 'DocumentSignedIcon',
  viewBox: '0 0 24 24',
  path: (
    <>
      <g clipPath="url(#a)">
        <path
          fill="currentColor"
          d="m19.535 3.122-1.656-1.658A4.967 4.967 0 0 0 14.343 0H8a5.006 5.006 0 0 0-5 5v14a5.006 5.006 0 0 0 5 5h8a5.006 5.006 0 0 0 5-5V6.657a4.968 4.968 0 0 0-1.465-3.535Zm-1.414 1.414c.142.141.269.297.379.464H16V2.5a3.1 3.1 0 0 1 .465.38l1.656 1.656ZM19 19a3 3 0 0 1-3 3H8a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h6v3a2 2 0 0 0 2 2h3v12ZM16 9a1 1 0 1 1 0 2H8a1 1 0 1 1 0-2h8Zm1 5a1 1 0 0 1-1 1H8a1 1 0 1 1 0-2h8a1 1 0 0 1 1 1Zm-.192 3.413a1 1 0 0 1-.217 1.394A6.464 6.464 0 0 1 13.135 20a3.252 3.252 0 0 1-2-.7c-.328-.225-.453-.3-.7-.3-.669.104-1.3.377-1.832.794a1 1 0 0 1-1.214-1.588A5.862 5.862 0 0 1 10.439 17c.666.01 1.31.24 1.832.655.238.214.544.336.864.345a4.585 4.585 0 0 0 2.277-.809 1 1 0 0 1 1.396.222Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </>
  ),
});
