import { z } from 'zod';
import { isValidCpf } from '../../utils/validations/isValidCpf';

const CPF_REQUIRED_MESSAGE = 'Você precisa preencher o CPF';
export const cpfValidationSchema = z
  .string({ required_error: CPF_REQUIRED_MESSAGE })
  .min(11, CPF_REQUIRED_MESSAGE)
  .refine((value) => {
    return isValidCpf(value);
  }, 'Parece que este CPF está incorreto');
