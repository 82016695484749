import {
  Flex,
  CheckboxGroup,
  useCheckboxGroup,
  VStack,
  Icon,
  Text,
} from '@chakra-ui/react';
import React, { ElementType } from 'react';
import CheckboxCard from '../../../../components/CheckboxCard';
import {
  addressVisibilitySchema,
  cardTypeSchema,
  sendCardFormSchema,
} from '../../../../types/schemas/employeeRegistrationSchema';
import { z } from 'zod';
import ConnectForm from '../../../../components/ConnectForm';
import { CardTypeEnum } from '../../../../types/card';
import { FiArchive, FiCreditCard } from 'react-icons/fi';
type FormValues = z.infer<
  typeof cardTypeSchema &
    typeof sendCardFormSchema &
    typeof addressVisibilitySchema
>;

type CardContentProps = {
  icon: ElementType;
  title: string;
  description: string;
};

const CardTypeContent = ({ icon, title, description }: CardContentProps) => {
  return (
    <VStack spacing={6} width="100%">
      <Icon as={icon} boxSize={10} strokeWidth={'1px'} />
      <Text fontWeight={600} textAlign="center">
        {title}
      </Text>
      <Text fontSize="14px" textAlign="center">
        {description}
      </Text>
    </VStack>
  );
};

const EmployeeImportCardTypeForm = () => {
  const {
    value: selectedOptions,
    setValue: setCheckboxValue,
    getCheckboxProps,
  } = useCheckboxGroup();

  return (
    <ConnectForm<FormValues>>
      {({ setValue, getValues }) => {
        const cardType = getValues('cardType');

        // custom function to allow only one checkbox to be checked at a time
        // it is needed because the selectors should behave like radio buttons but allowing to uncheck
        function handleCheckboxChange(e: React.ChangeEvent<HTMLInputElement>) {
          const nextValue = e.target.value;

          if (nextValue === CardTypeEnum.NOT_NOMINAL) {
            setValue('deliveryType', undefined);
            setValue('isAddressVisible', undefined);
          }

          if (selectedOptions.includes(nextValue)) {
            setCheckboxValue([]);
            setValue('cardType', undefined);
            setValue('isAddressVisible', undefined);
            return;
          }

          const checked = e.target.checked;

          if (checked) {
            setCheckboxValue([nextValue]);
            setValue('cardType', nextValue);
          }
        }

        const isCheckedNominal = cardType === CardTypeEnum.NOMINAL;
        const isCheckedNotNominal = cardType === CardTypeEnum.NOT_NOMINAL;

        return (
          <Flex gap={6} flexWrap="wrap">
            <CheckboxGroup>
              <CheckboxCard
                {...getCheckboxProps({ value: CardTypeEnum.NOMINAL })}
                isChecked={isCheckedNominal}
                onChange={handleCheckboxChange}
                minWidth="100%"
              >
                <CardTypeContent
                  icon={FiCreditCard}
                  title="Cartões com nome"
                  description="Vamos produzir cartões com o nome dos colaboradores e entregar onde você indicar na planilha"
                />
              </CheckboxCard>
              <CheckboxCard
                {...getCheckboxProps({ value: CardTypeEnum.NOT_NOMINAL })}
                isChecked={isCheckedNotNominal}
                onChange={handleCheckboxChange}
                minWidth="100%"
              >
                <CardTypeContent
                  icon={FiArchive}
                  title="Cartões sem nome"
                  description="Você vai entregar aos colaboradores os cartões sem nome do lote que você já encomendou da Raiô"
                />
              </CheckboxCard>
            </CheckboxGroup>
          </Flex>
        );
      }}
    </ConnectForm>
  );
};

export default EmployeeImportCardTypeForm;
