import { Box, Flex, HStack } from '@chakra-ui/react';
import { ReactElement, ReactNode } from 'react';
import { BsCaretRightFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';

type NavItemProps = {
  href?: string;
  label: string;
  active?: boolean;
  icon: ReactElement;
  endElement?: ReactElement;
  children?: ReactNode;
  showLabel: boolean;
};

const NavItem = (props: NavItemProps) => {
  const { active, icon, children, label, endElement, href, showLabel } = props;
  return (
    <HStack
      title={label}
      tabIndex={0}
      as={Link}
      to={href}
      width="full"
      padding={3}
      cursor="pointer"
      userSelect="none"
      rounded="md"
      transition="background-color 0.2s"
      backgroundColor={active ? 'primary.primary' : undefined}
      color={active ? 'gray.50' : 'gray.800'}
      _hover={{
        backgroundColor: active ? 'primary.primary' : 'orange.50',
      }}
      _active={{ backgroundColor: 'orange.100' }}
    >
      <Flex alignItems="center" gap={4}>
        <Box
          fontSize="lg"
          color={active ? 'gray.50' : 'gray.800'}
          lineHeight={1}
        >
          {icon}
        </Box>
        <Box
          flex="1"
          lineHeight={1}
          fontWeight={active ? 700 : 400}
          whiteSpace="nowrap"
          display={showLabel ? 'block' : 'none'}
        >
          {label}
        </Box>
      </Flex>
      {endElement && !children && <Box>{endElement}</Box>}
      {children && <Box fontSize="xs" flexShrink={0} as={BsCaretRightFill} />}
    </HStack>
  );
};

export default NavItem;
