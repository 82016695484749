import { Box } from '@chakra-ui/react';
import PrepareSpreadsheetPage from '../PrepareSpreadsheetPage';

const EmployeeImportInstructionsPage = () => {
  return (
    <Box padding={6} minHeight="100%">
      <PrepareSpreadsheetPage instructionsOnly />
    </Box>
  );
};

export default EmployeeImportInstructionsPage;
