const baseTemplateUrl =
  'https://raio-portal.s3.sa-east-1.amazonaws.com/templates/modelo-raio-recarga-';

const templateLinkMapping = [
  {
    id: 'v2-1',
    categories: [
      'Alimentação',
      'Refeição',
      'Alimentação e Refeição',
      'Cultura',
      'Combustível',
      'Mobilidade',
      'Trabalho Remoto',
      'Saúde',
      'Educação',
      'Multiuso',
      'Premiação',
    ],
  },
  {
    id: 'v2-2',
    categories: [
      'Alimentação e Refeição',
      'Cultura',
      'Combustível',
      'Mobilidade',
      'Trabalho Remoto',
      'Saúde',
      'Educação',
      'Multiuso',
      'Premiação',
    ],
  },
  {
    id: 'v2-3',
    categories: [
      'Alimentação',
      'Refeição',
      'Cultura',
      'Combustível',
      'Mobilidade',
      'Trabalho Remoto',
      'Saúde',
      'Educação',
      'Multiuso',
      'Premiação',
    ],
  },
  {
    id: 'v2-4',
    categories: ['Alimentação e Refeição'],
  },
  {
    id: 'v2-5',
    categories: ['Alimentação', 'Refeição'],
  },
  {
    id: 'v2-6',
    categories: ['Alimentação e Refeição', 'Multiuso'],
  },
  {
    id: 'v2-7',
    categories: ['Alimentação e Refeição', 'Premiação'],
  },
  {
    id: 'v2-8',
    categories: ['Multiuso'],
  },
  {
    id: 'v2-9',
    categories: ['Premiação'],
  },
  {
    id: 'v2-10',
    categories: ['Multiuso', 'Premiação'],
  },
  {
    id: 'v2-11',
    categories: [
      'Alimentação',
      'Refeição',
      'Alimentação e Refeição',
      'Cultura',
      'Combustível',
      'Mobilidade',
      'Trabalho Remoto',
      'Saúde',
      'Educação',
      'Multiuso',
      'Premiação',
      'Incentivo',
      'Saldo Livre',
      'Assiduidade',
      'Aniversário',
      'Natal',
    ],
  },
];

function findMatchingId(categories: string[]): string | undefined {
  const categoriesSet = new Set(categories);

  return templateLinkMapping.find((item) => {
    const itemCategoriesSet = new Set(item.categories);
    return (
      categoriesSet.size === itemCategoriesSet.size &&
      [...categoriesSet].every((category) => itemCategoriesSet.has(category))
    );
  })?.id;
}

export function getTemplateLink(requiredCategories: string[]) {
  const defaultLink = `${baseTemplateUrl}v2-11.xlsx`;

  const fileId = findMatchingId(requiredCategories);

  if (fileId) {
    return `${baseTemplateUrl}${fileId}.xlsx`;
  } else {
    return defaultLink;
  }
}
