import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalProps,
  Button,
  useDisclosure,
  ButtonProps,
  Text,
  Flex,
  Icon,
} from '@chakra-ui/react';
import {
  FiAlertTriangle,
  FiDollarSign,
  FiInfo,
  FiPhone,
  FiUser,
} from 'react-icons/fi';
import { WalletIcon } from '../../assets/customIcons';
import { AwardsIcon } from '../../assets/customIcons/AwardsIcon';

const creditsInfo = [
  {
    index: 1,
    icon: FiAlertTriangle,
    description: (
      <>
        Este crédito é{' '}
        <Text as="span" fontWeight={600}>
          {' '}
          ideal para emergências
        </Text>
      </>
    ),
  },
  {
    index: 2,
    icon: FiInfo,
    description: (
      <>
        A{' '}
        <Text as="span" fontWeight={600}>
          nota fiscal
        </Text>{' '}
        é emitida apenas{' '}
        <Text as="span" fontWeight={600}>
          no momento da recarga
        </Text>
      </>
    ),
  },
  {
    index: 3,
    icon: FiDollarSign,
    description: (
      <>
        <Text as="span" fontWeight={600}>
          Não é possível combinar meios de pagamento
        </Text>
        , por isso o valor da recarga deve ser sempre menor ou igual ao valor
        disponível em Créditos Raiô
      </>
    ),
  },
  // {
  //   index: 4,
  //   icon: AwardsIcon,
  //   description: (
  //     <>
  //       Você{' '}
  //       <Text as="span" fontWeight={600}>
  //         {' '}
  //         não pode utilizar os Créditos Raiô
  //       </Text>{' '}
  //       para fazer recargas nas{' '}
  //       <Text as="span" fontWeight={600}>
  //         categorias de uso livre
  //       </Text>
  //     </>
  //   ),
  // },
  {
    index: 5,
    icon: FiUser,
    description: (
      <>
        Ao{' '}
        <Text as="span" fontWeight={600}>
          estornar o saldo
        </Text>{' '}
        de um colaborador, este valor é{' '}
        <Text as="span" fontWeight={600}>
          disponibilizado como Créditos Raiô
        </Text>
      </>
    ),
  },
  {
    index: 6,
    icon: FiPhone,
    description: (
      <>
        O valor disponível em créditos só pode ser{' '}
        <Text as="span" fontWeight={600}>
          retirado do Portal
        </Text>{' '}
        entrando em{' '}
        <Text as="span" fontWeight={600}>
          contato com o suporte
        </Text>
      </>
    ),
  },
];

type creditsInfoModalButtonProps = ButtonProps & {
  modalProps?: ModalProps;
  buttonTittle?: string;
  children?: React.ReactNode;
};

const CreditsInfoModalButton = ({
  modalProps,
  buttonTittle,
  children,
  ...buttonProps
}: creditsInfoModalButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        variant={'link'}
        fontWeight={500}
        leftIcon={<WalletIcon />}
        padding={0}
        {...buttonProps}
        onClick={onOpen}
      >
        {'Saiba mais sobre Créditos Raiô'}
      </Button>
      <Modal
        isCentered
        {...modalProps}
        isOpen={isOpen}
        onClose={onClose}
        size={'4xl'}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontWeight={700}>
            Saiba mais sobre Créditos Raiô
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <>
              <Text fontSize={'16px'} fontWeight={400} marginBottom={4}>
                Deixe um crédito disponível dentro do Portal para quando
                precisar fazer uma recarga
              </Text>
              <Flex
                padding={4}
                gap={6}
                borderRadius={2}
                background={'light.orange'}
                flexDir={'column'}
                alignItems={'flex-start'}
              >
                {creditsInfo.map(({ index, icon, description }) => {
                  return (
                    <Flex key={index} alignItems={'center'} gap={4}>
                      <Icon as={icon} boxSize="24px" color="primary.primary" />
                      <Text as="span" fontWeight={400} lineHeight="130%">
                        {description}
                      </Text>
                    </Flex>
                  );
                })}
              </Flex>
            </>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreditsInfoModalButton;
