export const rechargeSteps = [
  {
    title: 'Selecionar colaboradores',
    description: 'Obrigatório',
  },
  {
    title: 'Definir valores',
    description: 'Obrigatório',
    path: '/recargas/nova-recarga',
  },
  {
    title: 'Ver resumo',
    description: 'Opcional',
    path: '/recargas/nova-recarga/resumo',
  },
  {
    title: 'Finalizar pedido',
    description: 'Obrigatório',
    path: '/recargas/nova-recarga/finalizar-pedido',
  },
];

export const importRechargeSteps = [
  {
    title: 'Preparar planilha',
    description: 'Obrigatório',
  },
  {
    title: 'Revisar valores',
    description: 'Obrigatório',
    path: '/recargas/nova-recarga',
  },
  {
    title: 'Ver resumo',
    description: 'Opcional',
    path: '/recargas/nova-recarga/resumo',
  },
  {
    title: 'Finalizar pedido',
    description: 'Obrigatório',
    path: '/recargas/nova-recarga/finalizar-pedido',
  },
];
