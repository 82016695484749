import {
  DrawerContentProps,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { Required } from '../../utils/fillingTips';
import EmployeeImportMandatoryBadge from '../../../EmployeeImport/components/EmployeeImportMandatoryBadge';

type TableFieldExampleProps = {
  fieldTitle: string;
  required: Required;
  fieldExample: string;
};

const TableFieldExample = ({
  fieldTitle,
  required,
  fieldExample,
}: TableFieldExampleProps) => {
  return (
    <TableContainer>
      <Table variant="raw">
        <Thead height="63px">
          <Tr bg="gray.100">
            <Th borderRight="1px solid">
              <Flex fontSize="2xl" gap={8} height="26px">
                <Text lineHeight={1}>{fieldTitle}</Text>
                <EmployeeImportMandatoryBadge required={required} />
              </Flex>
            </Th>
            <Th width="100%"></Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr borderBottom="1px solid" height="63px">
            <Td borderRight="1px solid">{fieldExample}</Td>
            <Td></Td>
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default TableFieldExample;
