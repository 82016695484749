import { Alert, AlertIcon, Box, Flex, Text } from '@chakra-ui/react';
import { H2, Semibold } from '../../../../components/Typography';
import { PixIcon } from '../../../../assets/customIcons/PixIcon';
import { ScanBarCode } from '../../../../assets/customIcons/ScanBarCode';
import ConnectForm from '../../../../components/ConnectForm';
import { CreditOrderFormValues } from '../..';
import { Controller } from 'react-hook-form';
import CurrencyInput from '../../../../components/CurrencyInput';

const SetValue = () => {
  return (
    <ConnectForm<CreditOrderFormValues>>
      {({ control, formState: { errors }, trigger }) => {
        return (
          <Flex
            p={8}
            bg="white"
            borderRadius="8px"
            height={'100%'}
            minHeight={'400px'}
            flexDirection="column"
            gap={8}
          >
            <H2>Definir valor</H2>
            <Text>
              Escolha um valor para deixar disponível para futuras recargas
              dentro do Portal
            </Text>
            <Box>
              <Controller
                name={`amount`}
                control={control}
                render={({ field }) => {
                  return (
                    <CurrencyInput
                      onValueChange={(e) => {
                        const floatValue = e.floatValue;
                        field.onChange(floatValue);
                        if (floatValue) {
                          trigger('amount');
                        }
                      }}
                      value={field.value}
                    />
                  );
                }}
              />
              {errors.amount && (
                <Text color="feedback.alert" fontSize="16px" marginTop={2}>
                  Você precisa digitar um valor
                </Text>
              )}
            </Box>
            <Box>
              <Alert
                status="warning"
                borderRadius="lg"
                backgroundColor="light.orange"
                padding={6}
                gap={4}
              >
                <Flex gap={6} flexDirection="column">
                  <Flex gap={4} alignItems="center">
                    <AlertIcon
                      as={PixIcon}
                      color="primary.primary"
                      margin={0}
                    />
                    <Text>
                      Caso você escolha pagar com <Semibold>Pix</Semibold>, o
                      valor estará disponível{' '}
                      <Semibold>logo após o pagamento</Semibold>
                    </Text>
                  </Flex>

                  <Flex gap={4} alignItems="center">
                    <AlertIcon
                      as={ScanBarCode}
                      color="primary.primary"
                      margin={0}
                    />
                    <Text>
                      Caso escolha <Semibold>Boleto</Semibold>, o valor estará
                      disponível no{' '}
                      <Semibold>dia útil seguinte ao pagamento</Semibold>
                    </Text>
                  </Flex>
                </Flex>
              </Alert>
            </Box>
          </Flex>
        );
      }}
    </ConnectForm>
  );
};

export default SetValue;
