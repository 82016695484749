import { Alert, AlertIcon, Box, Flex } from '@chakra-ui/react';
import AlertCard from '../../../components/AlertCard';
import {
  RechargePaymentMethod,
  RechargeStatusEnum,
} from '../../../types/recharge';
import { FiAlertCircle, FiCalendar } from 'react-icons/fi';
import { PixIcon } from '../../../assets/customIcons/PixIcon';
import { ScanBarCode } from '../../../assets/customIcons/ScanBarCode';

type RechargeAlertProps = {
  status: string;
  paymentMethod: RechargePaymentMethod;
  changeDueDate: boolean;
};

const RechargeAlert = ({
  paymentMethod,
  status,
  changeDueDate,
}: RechargeAlertProps) => {
  if (!changeDueDate) {
    return (
      <Box marginTop={8}>
        <Alert
          status="warning"
          borderRadius="lg"
          backgroundColor="light.orange"
          padding={4}
          gap={4}
          marginTop={8}
        >
          <Flex gap={6} flexDirection="column">
            <Flex gap={4} alignItems="center">
              <AlertIcon
                as={FiAlertCircle}
                color="primary.primary"
                margin={0}
              />
              Você não precisa alterar a forma de pagamento
            </Flex>

            {paymentMethod === RechargePaymentMethod.PIX ? (
              <Flex gap={4} alignItems="center">
                <AlertIcon as={PixIcon} color="primary.primary" margin={0} />O
                código Pix seguirá sendo o mesmo
              </Flex>
            ) : (
              <Flex gap={4} alignItems="center">
                <AlertIcon
                  as={ScanBarCode}
                  color="primary.primary"
                  margin={0}
                />
                O Boleto seguirá sendo o mesmo
              </Flex>
            )}

            <Flex gap={4} alignItems="center">
              <AlertIcon as={FiCalendar} color="primary.primary" margin={0} />A
              data de vencimento do{' '}
              {paymentMethod === RechargePaymentMethod.PIX ? 'Pix' : 'Boleto'}{' '}
              não muda
            </Flex>
          </Flex>
        </Alert>
      </Box>
    );
  }

  if (changeDueDate && status !== 'after') {
    if (paymentMethod === RechargePaymentMethod.BOLETO) {
      return (
        <Box marginTop={8}>
          <AlertCard gap={4} padding={4} marginTop={8}>
            Mesmo que você mantenha o pagamento via Boleto, vamos gerar uma nova
            cobrança com a data de vencimento atualizada
          </AlertCard>
        </Box>
      );
    }
    if (paymentMethod === RechargePaymentMethod.PIX) {
      return (
        <Box marginTop={8}>
          <AlertCard gap={4} padding={4}>
            Mesmo que você mantenha o pagamento via Pix, vamos gerar uma novo
            código com a data de vencimento atualizada
          </AlertCard>
        </Box>
      );
    }
  }

  return null;
};

export default RechargeAlert;
