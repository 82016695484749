import { createIcon } from '@chakra-ui/react';

export const EmittedIcon = createIcon({
  displayName: 'EmittedIcon',
  viewBox: '0 0 17 16',
  path: (
    <>
      <g clipPath="url(#clip0_11671_20513)" fill="currentColor">
        <path d="M16.471 10.883a.667.667 0 0 0-.942 0l-3.752 3.752-1.792-1.769a.669.669 0 0 0-.956.933l1.83 1.8a1.25 1.25 0 0 0 .896.4h.022a1.25 1.25 0 0 0 .89-.368l3.804-3.806a.667.667 0 0 0 0-.942Z" />
        <path d="M8.06 14.639a6.666 6.666 0 1 1 7.246-6.032.668.668 0 1 0 1.328.121 8 8 0 1 0-8.694 7.239h.061a.667.667 0 1 0 .06-1.333v.005Z" />
        <path d="M8 4.667v3.057L6.196 9.529a.667.667 0 1 0 .942.942l2-2A.667.667 0 0 0 9.334 8V4.667a.667.667 0 1 0-1.334 0Z" />
      </g>
    </>
  ),
});
