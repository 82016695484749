import { createIcon } from '@chakra-ui/react';

export const UserHeartIcon = createIcon({
  displayName: 'UserHeartIcon',
  viewBox: '0 -2 22 27',
  defaultProps: {
    boxSize: '24px',
  },
  path: (
    <path
      fill="currentColor"
      d="M9 12a6 6 0 1 0-6-6 6.006 6.006 0 0 0 6 6ZM9 2a4 4 0 1 1 0 8 4 4 0 0 1 0-8ZM9 14a9.01 9.01 0 0 0-9 9 1 1 0 0 0 2 0 7 7 0 0 1 14 0 1 1 0 0 0 2 0 9.011 9.011 0 0 0-9-9ZM22 7.875a2.107 2.107 0 0 0-2 2.2 2.107 2.107 0 0 0-2-2.2 2.107 2.107 0 0 0-2 2.2c0 1.73 2.256 3.757 3.38 4.659a.992.992 0 0 0 1.24 0c1.124-.9 3.38-2.929 3.38-4.659a2.107 2.107 0 0 0-2-2.2Z"
    />
  ),
});
