import {
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  UseDisclosureReturn,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Semibold } from '../../../../components/Typography';
import AlertDialog from '../../../../components/AlertDialog';
import { Employee } from '../../../../types/employee';

type RechargeCompetencyModalProps = {
  disclosureProps: UseDisclosureReturn;
  employees: Employee[];
};

const RechargeCompetencyModal = ({
  disclosureProps,
  employees,
}: RechargeCompetencyModalProps) => {
  const [numOfDays, setNumOfDays] = useState('1');
  const { isOpen, onClose } = disclosureProps;
  const navigate = useNavigate();
  const isSingleEmployee = employees.length === 1;

  const rechargeCompetencyInDays = numOfDays ? parseInt(numOfDays) : 1;

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      cancelButtonAction={onClose}
      title="Quantidade de dias da recarga"
      confirmButtonLabel="Continuar"
      confirmButtonAction={() => {
        navigate('/recargas/nova-recarga', {
          state: {
            selectedEmployees: employees,
            rechargeCompetencyInDays: rechargeCompetencyInDays,
          },
        });
      }}
    >
      <VStack align="start" fontSize="sm" gap={4}>
        {isSingleEmployee ? (
          <>
            <Text>
              O pacote de benefícios do colaborador selecionado tem{' '}
              <Semibold>benefícios configurados com valores por dia</Semibold>.
            </Text>
            <Text>
              Para calcularmos o <Semibold>valor sugerido da recarga </Semibold>
              deste colaborador, selecione a quantidade de dias que devemos
              considerar, por favor.
            </Text>
          </>
        ) : (
          <>
            <Text>
              Você tem colaboradores selecionados cujos pacotes de benefícios
              tem{' '}
              <Semibold>benefícios configurados com valores por dia</Semibold>.
            </Text>
            <Text>
              Para calcularmos o <Semibold>valor sugerido da recarga </Semibold>
              destes colaboradores, selecione a quantidade de dias que devemos
              considerar, por favor.
            </Text>
          </>
        )}
      </VStack>

      <FormControl marginTop={8} marginBottom={4}>
        <FormLabel fontSize="sm">Quantidade de dias</FormLabel>
        <NumberInput
          min={1}
          max={31}
          keepWithinRange={true}
          onChange={(value) => {
            setNumOfDays(value);
          }}
          value={numOfDays}
        >
          <NumberInputField background="gray.50" />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>
    </AlertDialog>
  );
};

export default RechargeCompetencyModal;
