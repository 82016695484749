import { Flex, Button, useDisclosure } from '@chakra-ui/react';
import Spinner from '../../components/Spinner';
import { useGetUsers } from '../../api/users';
import { useUserCompaniesStore } from '../../stores/useUserCompaniesStore';
import UsersTable from './components/UsersTable';
import AddAndEditUser from './components/AddAndEditUser';
import { LuPlusCircle } from 'react-icons/lu';
import { useAuthContext } from '../../hooks/useAuthContext';

const UsersPage = () => {
  const { selectedCompany } = useUserCompaniesStore();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: users, isLoading } = useGetUsers({
    customerId: selectedCompany?.customerId || '',
  });

  const { isAdmin } = useAuthContext();

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      {isAdmin && (
        <Flex justify="flex-end" marginBottom={10}>
          <Button
            leftIcon={<LuPlusCircle size="20px" />}
            marginBottom="auto"
            width="fit-content"
            onClick={onOpen}
          >
            Cadastrar usuário
          </Button>
        </Flex>
      )}
      <Flex direction="column" gap={0} lineHeight={1} minHeight="90%">
        <UsersTable users={users?.data || []} />
      </Flex>
      <AddAndEditUser isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default UsersPage;
