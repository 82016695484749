import { RefundReason } from '../types';

export const steps = [
  { title: 'Justificar estorno', description: 'Obrigatório' },
  { title: 'Definir valores', description: 'Obrigatório' },
];

export const refundOptions = [
  {
    label: 'A recarga foi feita no benefício errado',
    value: RefundReason.WRONG_BENEFIT,
  },
  {
    label: 'A recarga foi feita com valor errado',
    value: RefundReason.WRONG_VALUE,
  },
  {
    label: 'O colaborador foi desligado e há valores a descontar',
    value: RefundReason.EMPLOYE_DISMISSED,
  },

  { label: 'Outro motivo', value: RefundReason.OTHER },
];
