export const sanitizeAndCapitalizeString = (inputString: string) => {
  // Remove spaces at the beginning and end
  let resultString = inputString.trim();

  // Remove duplicate spaces in sequence
  resultString = resultString.replace(/\s+/g, ' ');

  // Capitalize first letter of each word if all characters are lowercase
  if (resultString === resultString.toLowerCase()) {
    resultString = resultString
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  return resultString;
};
