import { IRowHookInput } from 'dromo-uploader-react';
import { isValidCpf } from '../../../utils/validations/isValidCpf';

const NO_VALUES_FILLED_MESSAGE =
  'Você não preencheu nenhum valor para este colaborador. Se você concluir a importação assim, será necessário definir os valores na próxima etapa';

const HIGH_VALUE_WARNING_MESSAGE =
  'Fique atento, você configurou uma recarga de mais de R$1.000 em';

const FOOD_AND_FOODANDMEAL_WARNING_MESSAGE =
  'Fique atento, você configurou recargas para Alimentação e para Alimentação e Refeição para este colaborador';

const MEAL_AND_FOODANDMEAL_WARNING_MESSAGE =
  'Fique atento, você configurou recargas para Refeição e para Alimentação e Refeição para este colaborador';

const MOBILITY_AND_FUEL_WARNING_MESSAGE =
  'Fique atento, você configurou recargas para Mobilidade e para Combustível para este colaborador';

function convertToCents(value: string | undefined): number {
  if (!value) return 0;

  value = value.trim().replace('R$', '');

  // If the string contains a comma after a period, we assume that it is the Brazilian format.
  if (value.indexOf(',') > value.indexOf('.')) {
    // Remove the dots (thousand separators)
    value = value.replace(/\./g, '');
    // Replace the comma with a period to make the conversion easier
    value = value.replace(/,/g, '.');
  } else {
    // Remove commas (thousand separators)
    value = value.replace(/,/g, '');
  }

  const numberValue = parseFloat(value);

  if (isNaN(numberValue)) {
    return 0;
  }

  const cents = Math.round(numberValue * 100);

  // if value is negative, transform it to positive
  if (cents < 0) {
    return Math.abs(cents);
  }

  return cents;
}

function formatBRLCurrencyFromCents(valueInCents: number): string {
  return (valueInCents / 100).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
}

// WARNING: The following fields may not exist depending
// on the company config, so we have to be careful when
// accessing their rows:
// "Alimentação", "Refeição", "Alimentação e Refeição"

export function getDromoRowHooks(
  existingCPFs: string[] | undefined
): ((record: IRowHookInput) => IRowHookInput)[] {
  return [
    (record) => {
      const newRecord = record;

      if (newRecord.row.document) {
        const cpf = newRecord.row.document.value;
        if (!isValidCpf(cpf)) {
          newRecord.row.document.info = [
            {
              message: 'Ops... parece que o número não está certo',
              level: 'error',
            },
          ];
        } else if (!existingCPFs?.includes(cpf)) {
          newRecord.row.document.info = [
            {
              message: 'Não há um colaborador cadastrado e ativo com este CPF',
              level: 'error',
            },
          ];
        } else {
          newRecord.row.document.info = [];
        }
      }

      const benefitsKeys = Object.keys(newRecord.row).filter(
        (key) => key !== 'document'
      );

      benefitsKeys.forEach((key) => {
        const value = newRecord.row[key]?.value || '';
        const valueInCents = convertToCents(value);

        newRecord.row[key].value = formatBRLCurrencyFromCents(valueInCents);

        if (valueInCents > 1000 * 100) {
          newRecord.row[key].info = [
            {
              message: `${HIGH_VALUE_WARNING_MESSAGE} ${key}`,
              level: 'info',
            },
          ];
        } else {
          newRecord.row[key].info = newRecord.row[key].info?.filter(
            (info) => info.message !== `${HIGH_VALUE_WARNING_MESSAGE} ${key}`
          );
        }
      });

      if (newRecord.row['Mobilidade'] && newRecord.row['Combustível']) {
        const fuelValueInCents = convertToCents(
          newRecord.row['Combustível'].value
        );
        const mobilityValueInCents = convertToCents(
          newRecord.row['Mobilidade'].value
        );

        if (fuelValueInCents !== 0 && mobilityValueInCents !== 0) {
          newRecord.row['Mobilidade'].info = [
            {
              message: MOBILITY_AND_FUEL_WARNING_MESSAGE,
              level: 'info',
            },
          ];

          newRecord.row['Combustível'].info = [
            {
              message: MOBILITY_AND_FUEL_WARNING_MESSAGE,
              level: 'info',
            },
          ];
        } else {
          newRecord.row['Mobilidade'].info = newRecord.row[
            'Mobilidade'
          ].info?.filter(
            (info) => info.message !== MOBILITY_AND_FUEL_WARNING_MESSAGE
          );
          newRecord.row['Combustível'].info = newRecord.row[
            'Combustível'
          ].info?.filter(
            (info) => info.message !== MOBILITY_AND_FUEL_WARNING_MESSAGE
          );
        }
      }

      if (
        newRecord.row['Alimentação'] &&
        newRecord.row['Refeição'] &&
        newRecord.row['Alimentação e Refeição']
      ) {
        const foodValueInCents = convertToCents(
          newRecord.row['Alimentação']?.value
        );
        const mealValueInCents = convertToCents(
          newRecord.row['Refeição']?.value
        );
        const foodAndMealValueInCents = convertToCents(
          newRecord.row['Alimentação e Refeição'].value
        );

        if (foodValueInCents !== 0 && foodAndMealValueInCents !== 0) {
          newRecord.row['Alimentação'].info = [
            {
              message: FOOD_AND_FOODANDMEAL_WARNING_MESSAGE,
              level: 'info',
            },
          ];

          newRecord.row['Alimentação e Refeição'].info = [
            {
              message: FOOD_AND_FOODANDMEAL_WARNING_MESSAGE,
              level: 'info',
            },
          ];
        } else {
          newRecord.row['Alimentação'].info = newRecord.row[
            'Alimentação'
          ].info?.filter(
            (info) => info.message !== FOOD_AND_FOODANDMEAL_WARNING_MESSAGE
          );
          newRecord.row['Alimentação e Refeição'].info = newRecord.row[
            'Alimentação e Refeição'
          ].info?.filter(
            (info) => info.message !== FOOD_AND_FOODANDMEAL_WARNING_MESSAGE
          );
        }

        if (mealValueInCents !== 0 && foodAndMealValueInCents !== 0) {
          newRecord.row['Refeição'].info = [
            {
              message: MEAL_AND_FOODANDMEAL_WARNING_MESSAGE,
              level: 'info',
            },
          ];

          newRecord.row['Alimentação e Refeição'].info = [
            {
              message: MEAL_AND_FOODANDMEAL_WARNING_MESSAGE,
              level: 'info',
            },
          ];
        } else {
          newRecord.row['Refeição'].info = newRecord.row[
            'Refeição'
          ].info?.filter(
            (info) => info.message !== MEAL_AND_FOODANDMEAL_WARNING_MESSAGE
          );
          newRecord.row['Alimentação e Refeição'].info = newRecord.row[
            'Alimentação e Refeição'
          ].info?.filter(
            (info) => info.message !== MEAL_AND_FOODANDMEAL_WARNING_MESSAGE
          );
        }
      }

      // check if the sum of all benefit values is 0
      const totalValue = benefitsKeys.reduce((acc, key) => {
        const value = newRecord.row[key].value;
        const numberValue = parseFloat(
          value.replace(/\./g, '').replace(',', '.').replace('R$', '')
        );
        return acc + numberValue;
      }, 0);

      if (totalValue === 0) {
        benefitsKeys.forEach((key) => {
          newRecord.row[key].info = [
            {
              message: NO_VALUES_FILLED_MESSAGE,
              level: 'info',
            },
          ];
        });
      } else {
        benefitsKeys.forEach((key) => {
          newRecord.row[key].info = newRecord.row[key].info?.filter(
            (info) => info.message !== NO_VALUES_FILLED_MESSAGE
          );
        });
      }

      return newRecord;
    },
  ];
}
