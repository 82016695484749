import {
  Button,
  Card,
  Checkbox,
  Circle,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerContentProps,
  DrawerHeader,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { MouseEvent, useCallback, useState, useEffect } from 'react';
import { FiChevronDown, FiSearch, FiTrash2 } from 'react-icons/fi';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  RowSelectionState,
  SortingState,
  getSortedRowModel,
  getFilteredRowModel,
} from '@tanstack/react-table';
import { fuzzyFilter } from './usersTableHelpers';
import { usersTableColumn } from './UsersTableColumns';
import { useDebounce } from '../../../../hooks/useDebounce';
import { useSendCommsToUsers, useDeleteUsers } from '../../../../api/users';
import { MailPlusIcon } from '../../../../assets/customIcons/MailPlusIcon';
import SortDirectionIndicator from '../../../../components/SortDirectionIndicator';
import { useUserCompaniesStore } from '../../../../stores/useUserCompaniesStore';
import UserActionMenu from './UserActionMenu';
import Pagination from '../../../../components/Pagination';
import AlertDialog from '../../../../components/AlertDialog';
import { User } from '../../../../types/user';
import { useAuthContext } from '../../../../hooks/useAuthContext';

type UsersListProps = {
  users: User[];
};

const actionDrawerHeight: DrawerContentProps['height'] = '200px';

const UsersTable = ({ users }: UsersListProps) => {
  const [globalFilter, setGlobalFilter] = useState('');
  const [columns] = useState(() => [...usersTableColumn]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [columnVisibility, setColumnVisibility] = useState({});
  const [sorting, setSorting] = useState<SortingState>([
    {
      id: 'fullName',
      desc: false,
    },
  ]);
  const debouncedGlobalFilter = useDebounce<string>(globalFilter, 700);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const deleteUserConfirmationModal = useDisclosure();
  const sendCommsConfirmationAlert = useDisclosure();

  const { user, isAdmin } = useAuthContext();
  const loggedInUserId = user?.id;

  const { selectedCompany } = useUserCompaniesStore();

  const table = useReactTable({
    data: users,
    columns: columns,
    state: {
      columnVisibility: columnVisibility,
      rowSelection: rowSelection,
      sorting: sorting,
      globalFilter: debouncedGlobalFilter,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: fuzzyFilter,
    enableRowSelection: (row) => {
      const customerUser = row.original.customerUsers.find(
        (customerUser) =>
          customerUser.customerId === selectedCompany?.customerId
      );

      // disable row selection for users that are the owner of the company and for the logged in user
      return !customerUser?.owner && row.original.id !== loggedInUserId;
    },
  });

  const totalUsers = users.length;
  const totalUsersOnThePage = table.getRowModel().rows.length;
  const isAllUsersOnThePageSelected = table.getIsAllPageRowsSelected();
  const isAllUsersSelected = table.getIsAllRowsSelected();
  const isSomeUsersSelected = table.getIsSomeRowsSelected();

  const selectedUsers = table
    .getSelectedRowModel()
    .flatRows.map((row) => row.original);

  const numOfSelectedUsers = selectedUsers.length;

  const drawerTitle =
    selectedUsers.length === 1 ? selectedUsers[0].fullName : 'Ações em grupo';

  const countText =
    selectedUsers.length === 1
      ? 'Usuário selecionado'
      : 'Usuários selecionados';

  const sendCommsAlertDialogTitle =
    selectedUsers.length === 1
      ? `Enviar comunicação para ${selectedUsers[0].fullName}`
      : `Enviar comunicação para ${numOfSelectedUsers} usuários`;

  const getButtonAction = (e: MouseEvent<HTMLButtonElement>) => {
    if (isAllUsersSelected) {
      table.resetRowSelection(); // reset row selection
      table.getToggleAllPageRowsSelectedHandler()(e); // select only the Users on the page
    } else {
      table.getToggleAllPageRowsSelectedHandler()(e); // select or remove all Users on the page
    }
  };

  const getButtonLabel = useCallback(() => {
    if (isAllUsersSelected) {
      return `Selecionar apenas os ${totalUsersOnThePage} usuários da página atual`;
    }

    if (
      !isAllUsersSelected &&
      isSomeUsersSelected &&
      !isAllUsersOnThePageSelected
    ) {
      return `Adicionar todos os ${totalUsersOnThePage} usuários da página atual na seleção existente`;
    }

    if (!isAllUsersSelected && isAllUsersOnThePageSelected) {
      return `Remover apenas os ${totalUsersOnThePage} usuários desta página da seleção`;
    }
  }, [
    isAllUsersOnThePageSelected,
    isAllUsersSelected,
    isSomeUsersSelected,
    totalUsersOnThePage,
  ]);

  const visibleRows = table.getRowModel().rows;

  const sendCommsToUsers = useSendCommsToUsers();
  const deleteUsers = useDeleteUsers();

  useEffect(() => {
    if (selectedUsers.length > 0 && !isOpen) {
      onOpen();
    } else if (selectedUsers.length === 0 && isOpen) {
      onClose();
    }
  }, [selectedUsers, isOpen, onOpen, onClose]);

  return (
    <>
      <Flex
        minHeight="56px"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={4}
      >
        <InputGroup maxWidth="320px">
          <Input
            type="search"
            placeholder="Pesquisar"
            value={globalFilter}
            onChange={(e) => setGlobalFilter(String(e.target.value))}
            backgroundColor="gray.50"
            paddingRight={12}
          />
          <InputRightElement>
            <Icon as={FiSearch} boxSize="16px" color="primary.primary" />
          </InputRightElement>
        </InputGroup>
        <Popover>
          <PopoverTrigger>
            <Button variant="link" rightIcon={<Icon as={FiChevronDown} />}>
              Ver colunas
            </Button>
          </PopoverTrigger>
          <PopoverContent boxShadow="lg" padding={2}>
            <PopoverArrow />
            <PopoverCloseButton />

            <PopoverBody>
              <VStack align="justify">
                {table
                  .getAllLeafColumns()
                  .filter((column) => column.id !== 'select')
                  .map((column) => {
                    return (
                      <Checkbox
                        key={column.id}
                        maxWidth="90%"
                        isChecked={column.getIsVisible()}
                        onChange={column.getToggleVisibilityHandler()}
                        padding={1}
                      >
                        {column.columnDef.header as string}
                      </Checkbox>
                    );
                  })}
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>
      <Flex
        direction="column"
        gap={4}
        marginBottom={isOpen ? actionDrawerHeight : 'unset'}
      >
        <Card padding={6} maxWidth="100%" overflowX="auto">
          <Table>
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Th
                      key={header.id}
                      cursor={header.column.getCanSort() ? 'pointer' : 'unset'}
                      onClick={header.column.getToggleSortingHandler()}
                      userSelect="none"
                    >
                      <Flex
                        alignItems="center"
                        gap={2}
                        {...header.column.columnDef.meta}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getCanSort() && (
                          <SortDirectionIndicator
                            direction={header.column.getIsSorted()}
                          />
                        )}
                      </Flex>
                    </Th>
                  ))}

                  <Th textAlign="center">Ações</Th>
                </Tr>
              ))}
            </Thead>

            <Tbody>
              {table.getRowModel().rows.map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  ))}

                  <Td>
                    <UserActionMenu user={row.original} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Card>
        <Flex justifyContent="space-between" alignItems="center">
          <Pagination
            currentPage={table.getState().pagination.pageIndex + 1}
            totalPages={table.getPageCount()}
            onChangeRowsPerPage={(option) =>
              table.setPageSize(option?.value ?? 10)
            }
            handleClickFirstPage={() => table.setPageIndex(0)}
            handleClickNextPage={() => table.nextPage()}
            handleClickPreviousPage={() => table.previousPage()}
            handleClickLastPage={() =>
              table.setPageIndex(table.getPageCount() - 1)
            }
            elementsOnPage={visibleRows.length}
            totalElements={users.length}
          />
        </Flex>
      </Flex>

      <Drawer
        placement="bottom"
        isOpen={isOpen}
        onClose={onClose}
        autoFocus={false}
        blockScrollOnMount={false}
        returnFocusOnClose={false}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        trapFocus={false}
        lockFocusAcrossFrames={false}
        variant="clickThrough"
      >
        <DrawerContent height={actionDrawerHeight} zIndex={1} paddingX={20}>
          <DrawerHeader>{drawerTitle}</DrawerHeader>
          <DrawerBody>
            <Flex alignItems="center" gap={4} marginBottom={6}>
              <Text as="span" fontWeight={600}>
                {numOfSelectedUsers} {countText} de {totalUsers}
              </Text>

              <Circle size="4px" bg="gray.800" />
              <Button variant="link" padding={0} onClick={getButtonAction}>
                {getButtonLabel()}
              </Button>
            </Flex>
            <Flex gap={3} flexWrap="wrap">
              {selectedUsers.length === 1 ? (
                <>
                  {isAdmin && (
                    <Button
                      variant="outline"
                      width="fit-content"
                      leftIcon={<FiTrash2 />}
                      lineHeight={1}
                      onClick={deleteUserConfirmationModal.onOpen}
                    >
                      Excluir usuário
                    </Button>
                  )}

                  <Button
                    variant="outline"
                    width="fit-content"
                    leftIcon={<MailPlusIcon />}
                    lineHeight={1}
                    onClick={sendCommsConfirmationAlert.onOpen}
                  >
                    Reenviar comunicação
                  </Button>
                </>
              ) : (
                <>
                  {isAdmin && (
                    <Button
                      variant="outline"
                      width="fit-content"
                      leftIcon={<FiTrash2 />}
                      lineHeight={1}
                      onClick={deleteUserConfirmationModal.onOpen}
                    >
                      Excluir usuários
                    </Button>
                  )}

                  <Button
                    variant="outline"
                    width="fit-content"
                    leftIcon={<MailPlusIcon />}
                    lineHeight={1}
                    onClick={sendCommsConfirmationAlert.onOpen}
                  >
                    Reenviar comunicação
                  </Button>
                </>
              )}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      {deleteUserConfirmationModal.isOpen && (
        <AlertDialog
          isOpen={deleteUserConfirmationModal.isOpen}
          onClose={deleteUserConfirmationModal.onClose}
          cancelButtonAction={deleteUserConfirmationModal.onClose}
          title={
            numOfSelectedUsers > 1
              ? `Deseja realmente excluir ${numOfSelectedUsers} usuários?`
              : `Deseja realmente excluir ${selectedUsers[0].fullName}?`
          }
          confirmButtonLabel="Excluir"
          isConfirmButtonLoading={deleteUsers.isPending}
          confirmButtonAction={() => {
            deleteUsers.mutate(
              {
                userIds: selectedUsers.map((user) => user.id),
              },
              {
                onSuccess: () => {
                  deleteUserConfirmationModal.onClose();
                  table.resetRowSelection();
                },
              }
            );
          }}
        ></AlertDialog>
      )}
      {sendCommsConfirmationAlert.isOpen && (
        <AlertDialog
          isOpen={sendCommsConfirmationAlert.isOpen}
          onClose={sendCommsConfirmationAlert.onClose}
          cancelButtonAction={sendCommsConfirmationAlert.onClose}
          title={sendCommsAlertDialogTitle}
          confirmButtonLabel="Enviar"
          isConfirmButtonLoading={sendCommsToUsers.isPending}
          confirmButtonAction={() =>
            sendCommsToUsers.mutate(
              { userIds: selectedUsers.map((user) => user.id) },
              {
                onSuccess: () => {
                  sendCommsConfirmationAlert.onClose();
                },
              }
            )
          }
        >
          Enviaremos novamente{' '}
          {numOfSelectedUsers > 1 ? 'aos usuários' : 'ao usuário'} o e-mail com
          link de primeiro acesso do aplicativo.
        </AlertDialog>
      )}
    </>
  );
};

export default UsersTable;
