import { z } from 'zod';
import { RechargeVisibilityEnum } from '../recharge';

export const checkoutSchema = z
  .object({
    paymentMethod: z
      .string()
      .min(1, 'Você precisa escolher uma forma de pagamento')
      .refine((val) => ['pix', 'boleto', 'account_balance'].includes(val), {
        message: 'Você precisa escolher uma forma de pagamento',
      }),
    creditAvailability: z.enum(['paymentConfirmed', 'scheduled'], {
      invalid_type_error: 'Você precisa escolher uma data de crédito',
    }),
    scheduledDate: z.string().optional(),
    rechargeVisibilityConfig: z.nativeEnum(RechargeVisibilityEnum),
    observation: z.string().optional(),
  })
  .refine(
    (data) => data.creditAvailability !== 'scheduled' || data.scheduledDate,
    {
      message: 'Você precisa escolher uma data de crédito',
      path: ['creditAvailability'],
    }
  );
