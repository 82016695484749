import CustomTooltip from '../CustomTooltip';
import { RiQuestionLine } from 'react-icons/ri';
import { Flex, Icon, IconProps, TooltipProps } from '@chakra-ui/react';

type HelpMessageProps = {
  label: TooltipProps['label'];
  tooltipProps?: Omit<TooltipProps, 'children' | 'label'>;
  iconProps?: IconProps;
};

const HelpMessage = ({ label, tooltipProps, iconProps }: HelpMessageProps) => {
  return (
    <CustomTooltip
      as="div"
      maxWidth="240px"
      label={label}
      {...tooltipProps}
      shouldWrapChildren
    >
      <Flex
        as="span"
        alignItems="center"
        minHeight="100%"
        position="relative"
        lineHeight="inherit"
      >
        <Icon as={RiQuestionLine} {...iconProps} color="primary.primary" />
      </Flex>
    </CustomTooltip>
  );
};

export default HelpMessage;
