import { MdOutlineFastfood } from 'react-icons/md';
import { CultureIcon, EducationIcon, FuelIcon } from '../../assets/customIcons';
import { HelmetIcon } from '../../assets/customIcons/HelmetIcon';

import { AwardsIcon } from '../../assets/customIcons/AwardsIcon';
import { FiHeart, FiMonitor } from 'react-icons/fi';
import { LuUtensils } from 'react-icons/lu';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import { Text } from '@chakra-ui/react';
import { SunIcon } from '../../assets/customIcons/SunIcon';
import { IncentiveIcon } from '../../assets/customIcons/IncentiveIcon';
import { FreeBalanceIcon } from '../../assets/customIcons/FreeBalanceIcon';
import { AttendanceIcon } from '../../assets/customIcons/AttendanceIcon';
import { BirthdayIcon } from '../../assets/customIcons/BirthdayIcon';
import { ChristmasIcon } from '../../assets/customIcons/ChristmasIcon';
import { UsageTypeEnum } from '../../types/employee';

export const benefitsPresentation = {
  Alimentação: {
    name: 'Alimentação',
    description: (
      <Text fontSize="16px">
        Valor que o colaborador consegue usar em{' '}
        <Text as="span" fontWeight={600}>
          mercados
        </Text>
        , atacados, padarias, feiras, açougues ou{' '}
        <Text as="span" fontWeight={600}>
          aplicativos de entrega
        </Text>{' '}
        de mercado
      </Text>
    ),
    backgroundColor: 'benefits.background.food',
    color: 'benefits.border.food',
    icon: AiOutlineShoppingCart,
    usageType: UsageTypeEnum.RESTRICT,
  },
  Refeição: {
    name: 'Refeição',
    description: (
      <Text fontSize="16px">
        Aceito em{' '}
        <Text as="span" fontWeight={600}>
          {' '}
          restaurantes
        </Text>
        , lanchonetes, cafeterias, padarias e{' '}
        <Text as="span" fontWeight={600}>
          aplicativos de entrega
        </Text>{' '}
        de comida
      </Text>
    ),
    backgroundColor: 'benefits.background.meal',
    color: 'benefits.border.meal',
    icon: MdOutlineFastfood,
    usageType: UsageTypeEnum.RESTRICT,
  },

  'Alimentação e Refeição': {
    name: 'Alimentação e Refeição',
    description: (
      <Text fontSize="16px">
        <Text as="span" fontWeight={600}>
          Une os dois benefícios
        </Text>{' '}
        e dá liberdade para o colaborador usar em todos os locais dos benefícios{' '}
        <Text as="span" fontWeight={600}>
          Alimentação
        </Text>{' '}
        e{' '}
        <Text as="span" fontWeight={600}>
          Refeição
        </Text>
      </Text>
    ),
    backgroundColor: 'benefits.background.foodAndMeal',
    color: 'benefits.border.foodAndMeal',
    icon: LuUtensils,
    usageType: UsageTypeEnum.RESTRICT,
  },

  Cultura: {
    name: 'Cultura',
    description: (
      <Text fontSize="16px">
        Pode ser usado na compra de
        <Text as="span" fontWeight={600}>
          {' '}
          ingressos para shows e cinema
        </Text>
        , livrarias ou em plataformas de vídeo e de música (por exemplo,{' '}
        <Text as="span" fontWeight={600}>
          Netflix{' '}
        </Text>
        e{' '}
        <Text as="span" fontWeight={600}>
          Spotify
        </Text>
        )
      </Text>
    ),
    backgroundColor: 'benefits.background.culture',
    color: 'benefits.border.culture',
    icon: CultureIcon,
    usageType: UsageTypeEnum.RESTRICT,
  },
  Combustível: {
    name: 'Combustível',
    description: (
      <Text fontSize="16px">
        Para usar em{' '}
        <Text as="span" fontWeight={600}>
          postos
        </Text>{' '}
        de combustível,{' '}
        <Text as="span" fontWeight={600}>
          aplicativos de abastecimento
        </Text>{' '}
        (por exemplo, Shell Box) e também para carregar veículos elétricos
      </Text>
    ),
    backgroundColor: 'benefits.background.fuel',
    color: 'benefits.border.fuel',
    icon: FuelIcon,
    usageType: UsageTypeEnum.RESTRICT,
  },
  Mobilidade: {
    name: 'Mobilidade',
    description: (
      <Text fontSize="16px">
        Aceito em
        <Text as="span" fontWeight={600}>
          {' '}
          aplicativos de mobilidade
        </Text>{' '}
        (por exemplo, Uber e 99), pedágios, compras de
        <Text as="span" fontWeight={600}>
          {' '}
          passagens de transporte público
        </Text>
        , estacionamentos e nos locais do benefício
        <Text as="span" fontWeight={600}>
          {' '}
          Combustível
        </Text>
      </Text>
    ),
    backgroundColor: 'benefits.background.mobility',
    color: 'benefits.border.mobility',
    icon: HelmetIcon,
    usageType: UsageTypeEnum.RESTRICT,
  },
  'Trabalho Remoto': {
    name: 'Trabalho Remoto',
    description: (
      <Text fontSize="16px">
        Pode ser usado para pagar
        <Text as="span" fontWeight={600}>
          {' '}
          contas de água, gás, luz, internet e telefonia
        </Text>{' '}
        e também em lojas e sites de escritório e equipamentos eletrônicos
      </Text>
    ),
    backgroundColor: 'benefits.background.remoteWork',
    color: 'benefits.border.remoteWork',
    icon: FiMonitor,
    usageType: UsageTypeEnum.RESTRICT,
  },
  Saúde: {
    name: 'Saúde',
    description: (
      <Text fontSize="16px">
        Para usar em
        <Text as="span" fontWeight={600}>
          {' '}
          farmácias
        </Text>
        , exames,
        <Text as="span" fontWeight={600}>
          {' '}
          academias
        </Text>
        , consultórios médicos e
        <Text as="span" fontWeight={600}>
          {' '}
          aplicativos de saúde
        </Text>{' '}
        física e mental (por exemplo, TotalPass e Zenklub)
      </Text>
    ),
    backgroundColor: 'benefits.background.health',
    color: 'benefits.border.health',
    icon: FiHeart,
    usageType: UsageTypeEnum.RESTRICT,
  },
  Educação: {
    name: 'Educação',
    description: (
      <Text fontSize="16px">
        Valor que o colaborador pode usar em{' '}
        <Text as="span" fontWeight={600}>
          {' '}
          escolas
        </Text>
        , universidades,
        <Text as="span" fontWeight={600}>
          {' '}
          cursos
        </Text>
        , treinamentos, escolas de idiomas, autoescola e creches
      </Text>
    ),
    backgroundColor: 'benefits.background.education',
    color: 'benefits.border.education',
    icon: EducationIcon,
    usageType: UsageTypeEnum.RESTRICT,
  },
  Multiuso: {
    name: 'Multiuso',
    description: (
      <Text fontSize="16px">
        <Text as="span" fontWeight={600}>
          Todos os benefícios juntos
        </Text>
        , dá liberdade para o colaborador usar nos locais aceitos pelos outros
        benefícios
      </Text>
    ),
    backgroundColor: 'benefits.background.allInOne',
    color: 'benefits.border.allInOne',
    icon: SunIcon,
    usageType: UsageTypeEnum.RESTRICT,
  },
  Premiação: {
    name: 'Premiação',
    description: '',
    backgroundColor: 'benefits.background.awards',
    color: 'benefits.border.awards',
    icon: AwardsIcon,
    usageType: UsageTypeEnum.FREE_USAGE,
  },
  Incentivo: {
    name: 'Incentivo',
    description: '',
    backgroundColor: 'benefits.background.incentive',
    color: 'benefits.border.incentive',
    icon: IncentiveIcon,
    usageType: UsageTypeEnum.FREE_USAGE,
  },
  'Saldo Livre': {
    name: 'Saldo Livre',
    description: '',
    backgroundColor: 'benefits.background.freeBalance',
    color: 'benefits.border.freeBalance',
    icon: FreeBalanceIcon,
    usageType: UsageTypeEnum.FREE_USAGE,
  },
  Assiduidade: {
    name: 'Assiduidade',
    description: '',
    backgroundColor: 'benefits.background.attendance',
    color: 'benefits.border.attendance',
    icon: AttendanceIcon,
    usageType: UsageTypeEnum.FREE_USAGE,
  },
  Aniversário: {
    name: 'Aniversário',
    description: '',
    backgroundColor: 'benefits.background.birthday',
    color: 'benefits.border.birthday',
    icon: BirthdayIcon,
    usageType: UsageTypeEnum.FREE_USAGE,
  },
  Natal: {
    name: 'Natal',
    description: '',
    backgroundColor: 'benefits.background.christmas',
    color: 'benefits.border.christmas',
    icon: ChristmasIcon,
    usageType: UsageTypeEnum.FREE_USAGE,
  },
} as const;

export const allBenefits = Object.entries(benefitsPresentation).map(
  ([key, value]) => ({
    name: key,
    description: value.description,
    icon: value.icon,
    color: value.color,
    backgroundColor: value.backgroundColor,
    usageType: value.usageType,
  })
);

export const restrictBenefits = allBenefits.filter(
  (benefit) => benefit.usageType === UsageTypeEnum.RESTRICT
);

export const freeUseBenefits = allBenefits.filter(
  (benefit) => benefit.usageType === UsageTypeEnum.FREE_USAGE
);
