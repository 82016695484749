import { parseISO, isValid, format } from 'date-fns';
import { isoDateRegex } from '../validations/regex';
import { tz } from '@date-fns/tz';

export function getDayMonthFromISO(isoDate: string): string {
  if (!isoDateRegex.test(isoDate)) {
    return '';
  }

  const parsedDate = parseISO(isoDate);

  if (!isValid(parsedDate)) {
    return '';
  }

  return format(parsedDate, 'dd/MM', { in: tz('America/Sao_Paulo') });
}
