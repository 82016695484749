import { Box } from '@chakra-ui/react';

import CreditOrderList from './components/CreditOrderList';
import { useGetAccountBalance } from '../../api/recharge';
import Spinner from '../../components/Spinner';
import { useGetCompanyTransactions } from '../../api/credits';

const CreditOrderListPage = () => {
  const { data: accountBalance, isLoading: isLoadingAccountBalance } =
    useGetAccountBalance();

  const companyAccountBalance = accountBalance?.data;

  const { data: transactions, isLoading: isLoadingCompanyTransactions } =
    useGetCompanyTransactions();

  if (isLoadingAccountBalance || isLoadingCompanyTransactions) {
    return <Spinner />;
  }

  return (
    <Box>
      <CreditOrderList
        accountBalance={companyAccountBalance}
        transactions={transactions}
      />
    </Box>
  );
};

export default CreditOrderListPage;
