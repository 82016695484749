import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFetchContext } from '../../hooks/useFetchContext';
import { useToast } from '@chakra-ui/react';
import { useUserCompaniesStore } from '../../stores/useUserCompaniesStore';
import { User, UserPolicyName } from '../../types/user';

type PostUserProps = {
  fullName: string;
  username: string;
  policyName?: string;
};

type PatchUserProps = {
  fullName: string;
  username: string;
  customerId?: string;
  policyName?: string;
};

export const useGetUsers = ({ customerId }: { customerId: string }) => {
  const { api } = useFetchContext();
  const res = useQuery({
    queryKey: ['users-list', customerId],
    queryFn: async () =>
      await api.get<User[]>(`/api/users?customerId=${customerId}`),
    enabled: !!customerId,
    meta: {
      errorMessage:
        'Não foi possível carregar os usuários. Tente novamente mais tarde.',
    },
  });

  return res;
};

export const usePostUser = () => {
  const { api } = useFetchContext();
  const toast = useToast();
  const queryClient = useQueryClient();
  const { selectedCompany } = useUserCompaniesStore();

  const mutation = useMutation({
    mutationFn: ({ user }: { user: PostUserProps }) =>
      api.post('/api/users', {
        ...user,
        fullName: user.fullName.trim(),
        customerId: selectedCompany?.customerId,
      }),

    onSuccess: () => {
      toast({
        title: 'Novo usuário criado!',
        status: 'success',
        description:
          'Enviamos a ele um e-mail com instruções de primeiro acesso.',
      });
      queryClient.invalidateQueries({ queryKey: ['users-list'] });
    },

    onError: () => {
      toast({
        title: 'Tivemos um problema para salvar o usuario.',
        status: 'error',
        description: 'Tente de novo daqui a pouco.',
      });
    },
  });

  return mutation;
};

export const usePatchUser = ({
  userId,
  successMessage,
}: {
  userId: string;
  successMessage?: string;
}) => {
  const { api } = useFetchContext();
  const toast = useToast();
  const queryClient = useQueryClient();
  const { selectedCompany } = useUserCompaniesStore();

  const mutation = useMutation({
    mutationFn: ({ user }: { user: PatchUserProps }) =>
      api.patch(`/api/users/${userId}`, {
        ...user,
        fullName: user.fullName.trim(),
        customerId: selectedCompany?.customerId,
      }),

    onSuccess: () => {
      toast({
        title: successMessage || 'Usuário editado!',
        status: 'success',
        description: 'Usuário editado com sucesso.',
      });
      queryClient.invalidateQueries({ queryKey: ['users-list'] });
    },

    onError: () => {
      toast({
        title: 'Tivemos um problema para salvar o usuario.',
        status: 'error',
        description: 'Tente de novo daqui a pouco.',
      });
    },
  });

  return mutation;
};

export const useDeleteUser = ({ userId }: { userId: string }) => {
  const { api } = useFetchContext();
  const toast = useToast();
  const queryClient = useQueryClient();
  const { selectedCompany } = useUserCompaniesStore();

  const mutation = useMutation({
    mutationFn: () =>
      api.delete(
        `/api/customer-users?userId=${userId}&customerId=${selectedCompany?.customerId}`
      ),

    onSuccess: () => {
      toast({
        title: 'Usuário excluído com sucesso.',
        status: 'success',
      });
      queryClient.invalidateQueries({ queryKey: ['users-list'] });
    },

    onError: () => {
      toast({
        title: 'Tivemos um problema para excluir o usuário agora.',
        status: 'error',
        description: 'Tente de novo daqui a pouco.',
      });
    },
  });

  return mutation;
};

export const useDeleteUsers = () => {
  const { api } = useFetchContext();
  const toast = useToast();
  const queryClient = useQueryClient();
  const { selectedCompany } = useUserCompaniesStore();

  const mutation = useMutation({
    mutationFn: ({ userIds }: { userIds: string[] }) =>
      api.delete(`/api/customer-users/batch`, {
        data: {
          userIds: userIds,
          customerId: selectedCompany?.customerId,
        },
      }),

    onSuccess: () => {
      toast({
        title: 'Usuários excluídos com sucesso.',
        status: 'success',
      });
      queryClient.invalidateQueries({ queryKey: ['users-list'] });
    },

    onError: () => {
      toast({
        title: 'Tivemos um problema para excluir os usuários agora.',
        status: 'error',
        description: 'Tente de novo daqui a pouco.',
      });
    },
  });

  return mutation;
};

export const useSendCommsToUsers = () => {
  const { api } = useFetchContext();
  const toast = useToast();
  const { selectedCompany } = useUserCompaniesStore();

  return useMutation({
    mutationFn: ({ userIds }: { userIds: string[] }) => {
      return api.post(
        `/api/users/send-communication?userIds=${userIds}&companyId=${selectedCompany?.id}`
      );
    },

    onSuccess: (_response, variables) => {
      const { userIds } = variables;

      const title =
        userIds.length > 1
          ? 'Comunicação reenviada aos usuários!'
          : 'Comunicação reenviada ao usuário!';

      toast({
        title: title,
        status: 'success',
      });
    },

    onError: () => {
      toast({
        title: 'Tivemos um problema para reenviar a comunicação.',
        status: 'error',
        description: 'Tente de novo daqui a pouco.',
      });
    },
  });
};

export const useGetSelectedCompanyFinanceUsers = () => {
  const { api } = useFetchContext();
  const { selectedCompany } = useUserCompaniesStore();

  return useQuery({
    queryKey: [
      'users-list',
      'finance-users-list',
      {
        userPolicy: UserPolicyName.FINANCEIRO,
        customerId: selectedCompany?.customerId,
      },
    ],
    queryFn: () =>
      api.get<User[]>(
        `/api/users?policyName=${UserPolicyName.FINANCEIRO}&customerId=${selectedCompany?.customerId}`
      ),
    select: (response) => response.data,
    meta: {
      errorMessage:
        'Não foi possível carregar os usuários financeiros. Tente novamente mais tarde.',
    },
  });
};

type CreateFinancialUserProps = {
  fullName: string;
  username: string;
  customerId?: string;
  policyName: UserPolicyName;
};

export const useCreateFinanceUser = () => {
  const { api } = useFetchContext();
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: (data: CreateFinancialUserProps) =>
      api.post('/api/users', data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['users-list'] });
      toast({
        title: 'Usuário financeiro criado com sucesso!',
        status: 'success',
      });
    },
    onError: () => {
      toast({
        title: 'Erro ao criar usuário financeiro.',
        description: 'Tente de novo daqui a pouco.',
        status: 'error',
      });
    },
  });
};
