import { Text } from '@chakra-ui/react';
import { RedoAltIcon, WalletIcon } from '../../../assets/customIcons';
import { Semibold } from '../../../components/Typography';
import { FiInfo } from 'react-icons/fi';

export const employeeRefundInstructions = [
  {
    id: 1,
    icon: RedoAltIcon,
    text: (
      <Text>
        Você pode{' '}
        <Semibold>estornar todo ou parte do saldo de benefícios</Semibold> que
        este colaborador tem
      </Text>
    ),
  },
  {
    id: 2,
    icon: FiInfo,
    text: (
      <Text>
        Só é possível estornar o valor de uma recarga{' '}
        <Semibold>depois que ela foi creditada</Semibold>
      </Text>
    ),
  },
  {
    id: 3,
    icon: WalletIcon,
    text: (
      <Text>
        O valor estornado fica{' '}
        <Semibold>disponível em Créditos Raiô imediatamente</Semibold> e pode{' '}
        ser usado para pagar novas recargas
      </Text>
    ),
  },
];
