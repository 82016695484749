import { parseISO, isValid } from 'date-fns';

export function isCompleteISOString(dateString?: string): boolean {
  if (!dateString) {
    return false;
  }

  const parsedDate = parseISO(dateString);

  const hasTimeAndOffset =
    dateString.includes('T') &&
    (dateString.includes('+') || dateString.includes('-'));

  return isValid(parsedDate) && hasTimeAndOffset;
}
