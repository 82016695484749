import { Employee } from '../../../types/employee';

export function syncEmployeeCategoriesWithCompanyBenefits(
  employees: Employee[],
  benefitCategories: Employee['categories']
) {
  const missingCategories = employees
    .map((employee) => {
      return employee.categories.filter(
        (category) =>
          !benefitCategories.some(
            (benefitCategory) => benefitCategory.id === category.id
          )
      );
    })
    .flat();

  // available categories are the company benefit categories plus the missing categories from the employee excluding possible duplicates
  const availableCategories = [
    ...benefitCategories,
    ...missingCategories.filter(
      (missingCategory, index, self) =>
        index ===
        self.findIndex(
          (category) =>
            category.id === missingCategory.id &&
            category.name === missingCategory.name
        )
    ),
  ];

  return employees.map((employee) => {
    const categories = availableCategories.map((category) => {
      const employeeCategory = employee.categories.find(
        (cat) => cat.id === category?.id
      );

      return {
        id: category.id,
        name: category.name,
        order: category.order,
        period: employeeCategory?.period ?? 'MONTHLY',
        value: employeeCategory?.value ?? 0,
        edited: employeeCategory?.edited ?? false,
      };
    });

    return {
      ...employee,
      categories,
    };
  });
}
