import { Center, Icon, Stack, Text } from '@chakra-ui/react';
import { FiDollarSign } from 'react-icons/fi';

const RefundBalanceEmptyState = () => {
  return (
    <Center height="100%">
      <Stack spacing={6} align="center">
        <Icon as={FiDollarSign} color="primary.primary" boxSize="50px" />

        <Text fontSize="28px">
          Escolha uma justificativa ao lado antes de definir os valores
        </Text>
      </Stack>
    </Center>
  );
};

export default RefundBalanceEmptyState;
