export enum CreditTransactionStatusTranslation {
  PENDING = 'Aguardando pagamento',
  SETTLED = 'Aprovado',
  CANCELLED = 'Cancelado',
}

export type CreditTransactionStatus =
  keyof typeof CreditTransactionStatusTranslation;

export type CreditTransaction = {
  amount: number;
  type: 'Estorno' | 'Recarga' | 'Compra de créditos' | 'Compra de crédito';
  transactionDateTime: string;
  transactionId: string | number;
  status: CreditTransactionStatus;
  rechargeExternalCode?: string;
};

export type CompanyAccountBalance = {
  accountBalanceAvailable: number;
  accountBalancePendingSettlement: number;
  accountBalanceTotal: number;
};

export enum PaymentTypeEnum {
  PIX = 'PIX',
  BILLET = 'BILLET',
}

export type CreateCreditOrderPayload = {
  paymentType: PaymentTypeEnum;
  amount: number;
  dueDate: string;
  creditDate: string;
};

export type CreateCreditOrderResponse = {
  orderId: string;
  paymentType: PaymentTypeEnum;
  paymentFileBase64: string;
  paymentCode: string;
  amount: number;
  paymentBeneficiary: {
    name: string;
    registrationNumber: string;
  };
  dates: {
    due: string;
    credit: string;
  };
  paymentFilePath: string;
};
