import { IDeveloperField } from 'dromo-uploader-react';
import { EmployeeBenefitCategory } from '../../../types/employee';

function getBenefitCategoryFields(
  benefitCategories: EmployeeBenefitCategory[]
): IDeveloperField[] {
  const fields: IDeveloperField[] = benefitCategories.map((category) => {
    return {
      key: category.name,
      type: 'string',
      label: category.name,
      requireMapping: true,
      alternateMatches: [
        `Valor total em R$ para recarregar em ${category.name} (digite só números)`,
      ],
      validators: [],
      description: `Valor total em R$ para recarregar em ${category.name}`,
    };
  });

  return fields;
}

export function getDromoFields(
  benefitCategories: EmployeeBenefitCategory[]
): IDeveloperField[] {
  const benefitCategoryFields = getBenefitCategoryFields(benefitCategories);

  const defaultFields: IDeveloperField[] = [
    {
      key: 'document',
      type: 'string',
      label: 'CPF do colaborador',
      requireMapping: true,
      alternateMatches: [
        'CPF (obrigatório)',
        'CPF do colaborador (obrigatório)',
      ],
      validators: [
        {
          validate: 'required',
          errorMessage: 'Você precisa preencher o CPF',
        },
        {
          validate: 'unique_case_insensitive',
          errorMessage: 'Existem duas linhas com este CPF nesta planilha',
        },
        {
          validate: 'regex_match',
          // regex to only allow numbers and 11 digits
          regex: '^[0-9]{11}$',
          errorMessage:
            'Por favor, digite somente os 11 números do CPF do colaborador, sem caracteres especiais',
        },
      ],
    },
  ];

  return defaultFields.concat(benefitCategoryFields);
}
