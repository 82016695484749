import { Navigate, createBrowserRouter } from 'react-router-dom';
import AppShell from '../components/AppShell';
import EmployeesPage from '../pages/EmployeesPage';
import EmployeeRegistrationPage from '../pages/EmployeeRegistrationPage';
import LoginPage from '../pages/LoginPage';
import NewRechargePage from '../pages/NewRechargePage';
import PasswordSettingPage from '../pages/PasswordSettingPage';
import ProtectedRoute from './ProtectedRoute';
import RequestPasswordChange from '../pages/ResetPassword/RequestPasswordChange';
import SetNewPassword from '../pages/ResetPassword/SetNewPassword';
import CompanyRegistrationPage from '../pages/CompanyRegistrationPage';
import CardTermsPage from '../pages/CardTermsPage';
import AdditionalInfoPage from '../pages/AdditionalInfoPage';
import SettingsPage from '../pages/SettingsPage';
import BenefitsPage from '../pages/BenefitsPage';
import RechargeInfo from '../pages/NewRechargePage/RechargeInfo';
import CheckoutOrder from '../pages/NewRechargePage/CheckoutOrder';
import FirstAccessGateway from './FirstAccessGate';
import SwitchCompanyPage from '../pages/SwitchCompanyPage';
import RechargeListPage from '../pages/RechargeListPage';
import UsersPage from '../pages/UsersPage';
import RechargeReview from '../pages/NewRechargePage/components/RechargeReview';
import EmployeeDetailsPage from '../pages/EmployeeDetailsPage';
import LogoutPage from '../pages/LogoutPage';
import NewCardPage from '../pages/NewCardPage';
import { ErrorBoundary } from 'react-error-boundary';
import * as Sentry from '@sentry/react';
import ErrorBoundaryFallback from '../components/ErrorBoundaryFallback';
import EmployeeImport from '../pages/EmployeeImport';
import ImportSendCommunicationPage from '../pages/EmployeeImport/ImportSendCommunicationPage';
import ImportConfigPage from '../pages/EmployeeImport/ImportConfigPage';
import EmployeeImportInstructionsPage from '../pages/EmployeeImport/EmployeeImportInstructionsPage';
import RechargeSpreadsheetImport from '../pages/RechargeSpreadsheet';
import EditRechargePage from '../pages/EditRechargePage';
import RefundEmployeeBalancePage from '../pages/RefundEmployeeBalancePage';

import CreditOrderListPage from '../pages/CreditOrderListPage';
import CreditOrderPage from '../pages/CreditOrderPage';
import CreditOrderInfoPage from '../pages/CreditOrderInfoPage';

// on company switch, if the user is on one of these pages, don't redirect to home
export const SHOULD_NOT_REDIRECT_URLS = [
  '/pacotes-de-beneficios',
  '/colaboradores',
  '/recargas',
  '/usuarios',
  '/configuracoes',
  '/creditos-raio',
];

export const protectedRoutes = [
  {
    path: '/colaboradores',
    element: <EmployeesPage />,
    title: 'Colaboradores',
  },
  {
    path: '/colaboradores/cadastrar-colaborador',
    element: <EmployeeRegistrationPage />,
    title: 'Cadastrar colaborador',
  },
  {
    path: '/colaboradores/editar-colaborador/:employeeId',
    element: <EmployeeDetailsPage />,
    title: 'Editar colaborador',
  },
  {
    path: '/colaboradores/importar/preparar-planilha',
    element: <EmployeeImport />,
    title: 'Importar colaboradores',
  },
  {
    path: '/nova-via',
    element: <NewCardPage />,
    title: '',
  },
  {
    path: '/colaboradores/importar/comunicacao',
    element: <ImportSendCommunicationPage />,
    title: 'Importar colaboradores',
  },
  {
    path: '/colaboradores/importar/configurar-importacao',
    element: <ImportConfigPage />,
    title: 'Importar colaboradores',
  },
  {
    path: '/colaboradores/estornar-saldo/:employeeId',
    element: <RefundEmployeeBalancePage />,
    title: 'Estornar saldo',
  },
  {
    path: '/recargas',
    element: <RechargeListPage />,
    title: 'Recargas',
  },
  {
    path: '/recargas/nova-recarga',
    element: <NewRechargePage />,
    title: 'Definir valores',
  },
  {
    path: '/recargas/editar-recarga',
    element: <EditRechargePage />,
    title: 'Editar recarga',
  },
  {
    path: '/recargas/editar-recarga/informacoes-da-recarga',
    element: <RechargeInfo />,
    title: 'Informações da recarga',
    state: { isEdit: true },
  },
  {
    path: '/recargas/preparar-planilha',
    element: <RechargeSpreadsheetImport />,
    title: 'Recarga via planilha',
  },
  {
    path: '/recargas/nova-recarga/resumo',
    element: <RechargeReview />,
    title: 'Ver resumo',
  },
  {
    path: '/recargas/nova-recarga/finalizar-pedido',
    element: <CheckoutOrder />,
    title: 'Finalizar pedido',
  },
  {
    path: '/pacotes-de-beneficios',
    element: <BenefitsPage />,
    title: 'Pacotes de Benefícios',
  },
  {
    path: '/usuarios',
    element: <UsersPage />,
    title: 'Usuários do Portal',
  },
  {
    path: '/configuracoes',
    element: <SettingsPage />,
    title: 'Configurações',
  },
  {
    path: '/recargas/nova-recarga/informacoes-da-recarga',
    element: <RechargeInfo />,
    title: 'Informações da recarga',
  },
  {
    path: '/creditos-raio',
    element: <CreditOrderListPage />,
    title: 'Créditos Raiô',
  },
  {
    path: '/creditos-raio/pedido',
    element: <CreditOrderPage />,
    title: 'Recarga de créditos Raio',
  },
  {
    path: '/creditos-raio/pedido/informacoes-do-pedido',
    element: <CreditOrderInfoPage />,
    title: 'Informações do pedido',
  },
];

export const router = createBrowserRouter([
  {
    element: (
      <ErrorBoundary
        onError={(error) => {
          Sentry.captureException(error);
        }}
        fallback={<ErrorBoundaryFallback />}
      >
        <FirstAccessGateway>
          <ProtectedRoute />
        </FirstAccessGateway>
      </ErrorBoundary>
    ),
    children: [
      {
        element: <AppShell />,
        children: protectedRoutes.map((page) => ({
          path: page.path,
          element: page.element,
        })),
      },
      {
        path: '/',
        element: <Navigate to="/colaboradores" />,
      },
      {
        path: '/cadastro-e-confirmacao-empresa',
        element: <CompanyRegistrationPage />,
      },
      {
        path: '/termos-de-uso',
        element: <CardTermsPage />,
      },
      {
        path: '/informacoes-adicionais',
        element: <AdditionalInfoPage />,
      },
      {
        path: '/sair',
        element: <LogoutPage />,
      },
      {
        path: '/colaboradores/importar/instrucoes',
        element: <EmployeeImportInstructionsPage />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
  {
    path: '/definicao-de-senha/:confirmationToken',
    element: <PasswordSettingPage />,
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/solicitar-troca-de-senha',
    element: <RequestPasswordChange />,
  },
  {
    path: '/alterar-senha/:confirmationToken',
    element: <SetNewPassword />,
  },
  {
    path: '/switch/:confirmationToken',
    element: <SwitchCompanyPage />,
  },
]);
