import { Card, CardProps, Text } from '@chakra-ui/react';
import { CoinIcon } from '../../../../assets/customIcons/CoinIcon';

const RechargeEmptyState = (props: CardProps) => {
  return (
    <Card
      width="100%"
      height="calc(100vh - 273px)"
      direction="column"
      alignItems="center"
      justifyContent="center"
      gap={6}
      backgroundColor="gray.50"
      minHeight="250px"
      padding={8}
      {...props}
    >
      <CoinIcon boxSize="56px" color="primary.primary" />
      <Text fontSize="40px" fontWeight={600} color="gray.black">
        Já Raiô!
      </Text>
      <Text fontSize="32px">Você ainda não possui nenhuma recarga</Text>
    </Card>
  );
};

export default RechargeEmptyState;
