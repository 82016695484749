import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Step,
  StepDescription,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  Stepper,
  Box,
  Button,
  ButtonProps,
  ModalProps,
  useDisclosure,
  Flex,
  Progress,
} from '@chakra-ui/react';
import ContactForms from '../../../../components/ContactForms';

import { AlertIcon } from '../../../../assets/customIcons/AlertIcon';
import { useGetCardDeliveryInfo } from '../../../../api/card';
import Spinner from '../../../../components/Spinner';
import { useEffect } from 'react';
import CopyButton from '../../../../components/CopyButton';

type MisplacedModalProps = {
  cardId: string | null;
  isOpen: boolean;
  onClose: () => void;
};

const MisplacedModal = ({ cardId, isOpen, onClose }: MisplacedModalProps) => {
  const {
    data: cardDeliveryInfo,
    isFetching,
    isError,
  } = useGetCardDeliveryInfo(cardId);

  const trackingCode = cardDeliveryInfo?.code || '';

  useEffect(() => {
    if (isError) {
      onClose();
    }
  }, [isError, onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody padding="8">
          <Flex
            alignItems={'center'}
            justifyContent={'center'}
            flexDir={'column'}
          >
            <AlertIcon width={'57px'} height={'47px'} />
            <ModalHeader fontWeight={700}>Extraviado</ModalHeader>
          </Flex>
          {isFetching ? (
            <Spinner size="md" />
          ) : (
            <Box>
              <Stepper
                index={-1}
                orientation="vertical"
                gap={6}
                colorScheme="gray.300"
              >
                <Step>
                  <StepIndicator background={'bg'} marginRight={'24px'} gap={0}>
                    <StepNumber>
                      <Text as="span" fontWeight="600">
                        {' '}
                        1{' '}
                      </Text>
                    </StepNumber>
                    <StepSeparator>
                      <Progress height={'25px'} />
                    </StepSeparator>
                    <StepStatus />
                  </StepIndicator>
                  <StepDescription as="div">
                    <Text color="gray.black" fontSize={'16px'}>
                      <Text as="span" fontWeight="600" margin={0}>
                        Entre em contato
                      </Text>{' '}
                      com a gente em um dos nossos canais abaixo
                    </Text>
                  </StepDescription>
                </Step>

                <Step>
                  <StepIndicator marginRight={'24px'} gap={0}>
                    <StepNumber>
                      <Text as="span" fontWeight="600">
                        {' '}
                        2{' '}
                      </Text>
                    </StepNumber>
                    <StepSeparator>
                      <Progress height={'25px'} />
                    </StepSeparator>
                    <StepStatus />
                  </StepIndicator>
                  <StepDescription as="div">
                    <Text color="gray.black" fontSize={'16px'}>
                      <Text fontWeight="600" as="span">
                        Diga
                      </Text>{' '}
                      que o
                      <Text fontWeight="600" as="span">
                        {' '}
                        cartão foi extraviado
                      </Text>{' '}
                    </Text>
                  </StepDescription>
                </Step>
                <Step>
                  <StepIndicator marginRight={'24px'} gap={0}>
                    <StepNumber>
                      <Text as="span" fontWeight="600">
                        {' '}
                        3{' '}
                      </Text>
                    </StepNumber>
                    <StepSeparator />
                    <StepStatus />
                  </StepIndicator>

                  <StepDescription as="div">
                    <Text color="gray.black" marginBottom={2} fontSize={'16px'}>
                      Informe o
                      <Text fontWeight="600" as="span">
                        {' '}
                        código de rastreio
                      </Text>
                    </Text>
                    <Box flexShrink="0" marginBottom={6}>
                      <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        padding={4}
                        border="1px solid #CDCCCE"
                        borderRadius="8px"
                        width="287px"
                      >
                        <Text fontSize="16px">{cardDeliveryInfo?.code}</Text>
                        <CopyButton copyText={trackingCode} />
                      </Flex>
                    </Box>
                  </StepDescription>
                </Step>
              </Stepper>
              <ContactForms />
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

type MisplacedCardModaProps = ButtonProps & {
  cardId: string | null;
  modalProps?: ModalProps;
  buttonTitle?: string;
};

const MisplacedCardModal = ({
  cardId,
  title,
  ...buttonProps
}: MisplacedCardModaProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Flex alignItems="center">
        <Button
          variant={'link'}
          fontWeight={400}
          padding={0}
          {...buttonProps}
          onClick={onOpen}
          leftIcon={
            <AlertIcon width={'16px'} height={'16px'} marginRight={'2'} />
          }
        >
          {title}
        </Button>
      </Flex>

      {isOpen && (
        <MisplacedModal cardId={cardId} isOpen={isOpen} onClose={onClose} />
      )}
    </>
  );
};

export default MisplacedCardModal;
