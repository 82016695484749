import { createIcon } from '@chakra-ui/react';

export const BirthdayIcon = createIcon({
  displayName: 'BirthdayIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    boxSize: '60px',
    fill: 'none',
  },
  path: (
    <path
      fill="currentColor"
      d="M23 22h-1v-8a5.006 5.006 0 0 0-5-5h-4V6.04a2.5 2.5 0 0 0 1.5-2.29A7.294 7.294 0 0 0 12.738.327a1 1 0 0 0-1.476 0A7.293 7.293 0 0 0 9.5 3.75 2.5 2.5 0 0 0 11 6.04V9H7a5.006 5.006 0 0 0-5 5v8H1a1 1 0 1 0 0 2h22a1 1 0 0 0 0-2ZM7 11h10a3 3 0 0 1 3 3v1.98c-.936-.1-1.5-.7-1.5-.98a1 1 0 0 0-2 0c0 .343-.682 1-1.75 1-1.09 0-1.75-.694-1.75-1a1 1 0 0 0-2 0c0 .343-.682 1-1.75 1-1.09 0-1.75-.694-1.75-1a1 1 0 1 0-2 0c0 .315-.58.888-1.5.981v-1.98a3 3 0 0 1 3-3Zm-3 6.98A4.156 4.156 0 0 0 6.5 17a4.31 4.31 0 0 0 5.5.015A4.31 4.31 0 0 0 17.5 17a4.157 4.157 0 0 0 2.5.978V22H4v-4.02Z"
    />
  ),
});
