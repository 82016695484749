import { createIcon } from '@chakra-ui/react';

export const CanceledIcon = createIcon({
  displayName: 'CanceledIcon',
  viewBox: '0 0 17 16',
  path: (
    <>
      <g clipPath="url(#clip0_11671_23453)" fill="currentColor">
        <path d="M16.472 10.196a.666.666 0 0 0-.943 0l-1.862 1.862-1.862-1.862a.666.666 0 0 0-.942.943L12.725 13l-1.862 1.862a.666.666 0 1 0 .942.943l1.862-1.862 1.862 1.861a.667.667 0 0 0 .943-.942L14.61 13l1.862-1.862a.666.666 0 0 0 0-.943Z" />
        <path d="M8.667 14.667A6.666 6.666 0 1 1 15.334 8a.667.667 0 1 0 1.333 0 8 8 0 1 0-8 8 .666.666 0 1 0 0-1.333Z" />
        <path d="M8.667 4A.667.667 0 0 0 8 4.667v3.057L6.196 9.529a.667.667 0 1 0 .942.942l2-2A.667.667 0 0 0 9.334 8V4.667A.667.667 0 0 0 8.667 4Z" />
      </g>
    </>
  ),
});
