import { AxiosError, isAxiosError } from 'axios';
import * as Sentry from '@sentry/react';

export const catchMutationErrorForSentry = (
  error: Error,
  mutationIdentifier: string | undefined
) => {
  if (isAxiosError(error)) {
    const axiosError = error as AxiosError;

    if (axiosError.response?.status === 401) {
      return;
    }

    // ignore if error is 404 on the company switch endpoint
    if (
      axiosError.response?.status === 404 &&
      axiosError.response?.config.url?.includes('/companies/switch')
    ) {
      return;
    }

    if (axiosError.response?.status) {
      Sentry.captureException(axiosError, {
        fingerprint: [
          mutationIdentifier || 'mutation',
          axiosError.response.status.toString(),
          axiosError.message,
        ],
        tags: {
          status: axiosError.response.status.toString(),
          mutation: mutationIdentifier || '',
        },
      });
    } else {
      Sentry.captureException(axiosError);
    }
  }
};
