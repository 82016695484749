import { Checkbox, CheckboxProps, Flex, Text } from '@chakra-ui/react';
import { forwardRef } from 'react';

type CheckboxTextProps = CheckboxProps & {
  text: string;
};

const CheckboxText = forwardRef<HTMLInputElement, CheckboxTextProps>(
  (props, ref) => {
    const { text } = props;

    return (
      <Checkbox
        ref={ref}
        size="lg"
        backgroundColor={'gray.100'}
        padding={4}
        borderRadius="lg"
        color="gray.700"
        _checked={{
          boxShadow: 'none',
          backgroundColor: 'light.orange',
          outline: '2px solid',
          outlineColor: 'orange.500',
          color: 'inherit',
        }}
        spacing={4}
        {...props}
      >
        <Text fontSize="md" lineHeight="1.3">
          {text}
        </Text>
      </Checkbox>
    );
  }
);

export default CheckboxText;
