import { forwardRef } from 'react';
import { NumberFormatBase, NumberFormatBaseProps } from 'react-number-format';
import { Input, InputProps } from '@chakra-ui/react';
import { formatCurrencyInCents } from '../../utils/formatters/formatCurrency';
import { SourceInfo } from 'react-number-format';

export type CurrencyInputProps = Omit<
  InputProps,
  'type' | 'value' | 'defaultValue'
> & {
  value?: string | number | null | undefined;
  defaultValue?: string | number | null | undefined;
  onValueChange?: NumberFormatBaseProps['onValueChange'];
};

const CurrencyInput = forwardRef<typeof NumberFormatBase, CurrencyInputProps>(
  (props, ref) => {
    function getCaretBoundary(formattedValue: string): boolean[] {
      const length = formattedValue.length || 0;
      return Array(length).fill(false).concat(true);
    }

    return (
      <NumberFormatBase
        getInputRef={ref}
        format={formatCurrencyInCents}
        getCaretBoundary={getCaretBoundary}
        customInput={Input}
        valueIsNumericString={false}
        _focusWithin={{
          zIndex: 'unset',
        }}
        {...props}
        // avoid unexpected behavior when the user types '0' for the first time
        onValueChange={(values, sorceInfo) => {
          if (values.floatValue === 0) {
            return;
          }
          props.onValueChange?.(values, sorceInfo);
        }}
        // make the onChange handle the '0' case
        onChange={(event) => {
          if (event.target.value === 'R$ 0,00') {
            props.onValueChange?.(
              {
                floatValue: 0,
                formattedValue: 'R$ 0,00',
                value: '0',
              },
              {
                source: 'event' as SourceInfo['source'],
                event: event,
              }
            );
          }
        }}
        isAllowed={(values) => {
          const { floatValue } = values;
          if (!floatValue) {
            return true;
          }
          return floatValue <= 1000000000;
        }}
      />
    );
  }
);

export default CurrencyInput;
