import { createIcon } from '@chakra-ui/react';

export const InvoiceIcon = createIcon({
  displayName: 'InvoiceIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <g clipPath="url(#a)">
        <path
          fill="currentColor"
          d="M11.332 9.333a.667.667 0 0 1-.667.667H5.332a.667.667 0 1 1 0-1.333h5.333a.667.667 0 0 1 .667.666Zm-2.667 2H5.332a.667.667 0 0 0 0 1.334h3.333a.667.667 0 0 0 0-1.334Zm6-4.343v5.677A3.337 3.337 0 0 1 11.332 16H4.665a3.337 3.337 0 0 1-3.333-3.333V3.333A3.337 3.337 0 0 1 4.665 0h3.01a4.639 4.639 0 0 1 3.3 1.367l2.323 2.324a4.633 4.633 0 0 1 1.367 3.299Zm-4.632-4.68a3.34 3.34 0 0 0-.701-.52v2.877a.667.667 0 0 0 .667.666h2.877a3.323 3.323 0 0 0-.52-.7L10.032 2.31Zm3.299 4.68c0-.11-.021-.215-.031-.323H9.999a2 2 0 0 1-2-2V1.365c-.108-.01-.214-.032-.324-.032h-3.01a2 2 0 0 0-2 2v9.334a2 2 0 0 0 2 2h6.667a2 2 0 0 0 2-2V6.99Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="transparent" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </>
  ),
});
