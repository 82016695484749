import { createIcon } from '@chakra-ui/react';

export const SunRiseIcon = createIcon({
  displayName: 'SunRiseIcon',
  viewBox: '0 0 24 25',
  defaultProps: {
    boxSize: '24px',
  },
  path: (
    <path
      fill="currentColor"
      d="M0 12.5a1 1 0 0 1 1-1h4.079a6.82 6.82 0 0 1 .434-1.6L1.982 7.849a1 1 0 1 1 1-1.731l3.535 2.048a7.084 7.084 0 0 1 1.15-1.145l-2.05-3.537a1 1 0 0 1 1.732-1l2.05 3.529A6.9 6.9 0 0 1 11 5.58V1.5a1 1 0 0 1 2 0v4.08a6.9 6.9 0 0 1 1.6.433l2.048-3.532a1 1 0 0 1 1.73 1l-2.05 3.536c.427.336.813.72 1.153 1.144l3.53-2.047a1 1 0 0 1 1 1.731l-3.52 2.049c.211.518.36 1.06.44 1.614L23 11.5a1 1 0 1 1 0 2h-4.078a2 2 0 0 1-1.972-1.711 5.009 5.009 0 0 0-9.9 0A2 2 0 0 1 5.079 13.5H.999a1 1 0 0 1-1-1Zm23.744 4.565a1 1 0 0 0-1.412-.076 2.214 2.214 0 0 1-3.4-.817.978.978 0 0 0-.6-.614 1 1 0 0 0-1.275.609 2.286 2.286 0 0 1-4.13.005.981.981 0 0 0-1.364-.574 1 1 0 0 0-.511.569 2.287 2.287 0 0 1-4.128.005.981.981 0 0 0-1.365-.574 1 1 0 0 0-.511.569 2.2 2.2 0 0 1-3.39.822 1 1 0 0 0-1.326 1.489A4.174 4.174 0 0 0 6 18.265a4.313 4.313 0 0 0 6 0 4.312 4.312 0 0 0 6 0 4.174 4.174 0 0 0 5.668.214 1.001 1.001 0 0 0 .076-1.414Zm0 5a1 1 0 0 0-1.412-.076 2.214 2.214 0 0 1-3.4-.817.978.978 0 0 0-.6-.614 1 1 0 0 0-1.275.609 2.287 2.287 0 0 1-4.13 0 .981.981 0 0 0-1.364-.574 1 1 0 0 0-.511.569 2.286 2.286 0 0 1-4.128 0 .981.981 0 0 0-1.365-.574 1 1 0 0 0-.511.569 2.2 2.2 0 0 1-3.39.822 1 1 0 1 0-1.326 1.499A4.174 4.174 0 0 0 6 23.265a4.313 4.313 0 0 0 6 0 4.312 4.312 0 0 0 6 0 4.174 4.174 0 0 0 5.668.214 1.001 1.001 0 0 0 .076-1.414Z"
    />
  ),
});
