import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Company } from '../types/company';
import { firstAccessRedirect } from '../utils/firstAccessRedirect';

export function useFirstAccessNavigation(company: Company | undefined) {
  const navigate = useNavigate();

  useEffect(() => {
    if (company) {
      firstAccessRedirect(company);
    }
  }, [navigate, company]);
}
