import { createIcon } from '@chakra-ui/react';
export const BlockedIcon = createIcon({
  displayName: 'BlockedIcon',
  viewBox: '0 0 17 16',
  path: (
    <>
      <g clipPath="url(#clip0_11671_20909)" fill="currentColor">
        <path d="M13.333 5.616v-.95a4.666 4.666 0 1 0-9.333 0v.95a3.333 3.333 0 0 0-2 3.05v4A3.337 3.337 0 0 0 5.333 16H12a3.338 3.338 0 0 0 3.333-3.333v-4a3.333 3.333 0 0 0-2-3.051Zm-8-.95a3.333 3.333 0 0 1 6.667 0v.667H5.333v-.666Zm8.667 8a2 2 0 0 1-2 2H5.333a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2H12a2 2 0 0 1 2 2v4Z" />
        <path d="M8.667 9.333A.667.667 0 0 0 8 10v1.333a.667.667 0 1 0 1.333 0V10a.667.667 0 0 0-.666-.667Z" />
      </g>
    </>
  ),
});
