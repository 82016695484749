import { Flex, Text } from '@chakra-ui/react';

import { BulbIcon } from '../../../../assets/customIcons';
import { Semibold } from '../../../../components/Typography';

const RechargeHelpProcessingColabs = () => {
  return (
    <Flex
      background="light.orange"
      maxHeight="75px"
      alignItems="center"
      borderRadius="lg"
      fontSize="20px"
      padding={{ base: '12px 16px', md: '12px 16px', lg: '10px ' }}
      lineHeight="130%"
    >
      <BulbIcon mr={3} color="primary.primary" />
      <Text whiteSpace={'nowrap'}>
        Para criar uma recarga, <Semibold>aguarde o processamento</Semibold>
      </Text>
    </Flex>
  );
};

export default RechargeHelpProcessingColabs;
