import { createIcon } from '@chakra-ui/react';

export const RefreshIcon = createIcon({
  displayName: 'RefreshIcon',
  viewBox: '0 0 19 18',
  path: (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" fill="none">
      <path
        d="M.833 2.332v5h5"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.925 11.5A7.5 7.5 0 1 0 4.7 3.7L.833 7.333"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
});
