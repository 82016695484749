import { useEffect, useState } from 'react';
import { useGetInvoice } from '../../../../api/invoice';
import { DownloadButton } from '../RechargeDocs';
import { Badge, Box, Button, Flex, Link, Text } from '@chakra-ui/react';
import Spinner from '../../../../components/Spinner';
import {
  RECHARGE_INVOICE_TIMER,
  baseDownloadUrl,
} from '../../../../constants/general';
import { DocumentTypeEnum } from '../../../../types/recharge';

type InvoiceDownloadButtonProps = {
  isPluralRecharge: boolean;
  rechargeId: string;
};

const InvoiceDownloadButton = ({
  isPluralRecharge,
  rechargeId,
}: InvoiceDownloadButtonProps) => {
  const [counter, setCounter] = useState(RECHARGE_INVOICE_TIMER);
  const { data: invoiceInfo } = useGetInvoice(!!counter, rechargeId);

  const invoiceFilePath = invoiceInfo?.invoiceFilePath;

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (counter > 0) {
      interval = setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [counter]);

  // Reset counter and stop polling when invoice is generated
  useEffect(() => {
    if (invoiceFilePath) {
      setCounter(0);
    }
  }, [invoiceFilePath]);

  if (invoiceFilePath) {
    return (
      <DownloadButton
        height="56px"
        as={Link}
        href={`${baseDownloadUrl}?rechargeId=${rechargeId}&documentType=${DocumentTypeEnum.INVOICE}`}
      >
        {isPluralRecharge ? 'Notas Fiscais' : 'Nota Fiscal'}
      </DownloadButton>
    );
  }

  return (
    <>
      {counter > 0 ? (
        <Flex
          paddingY={2}
          paddingX={4}
          background={'gray.100'}
          borderRadius="8px"
          width="fit-content"
          alignItems="center"
          gap="16px"
          height="56px"
        >
          <Badge
            padding="4px 12px"
            textTransform="none"
            fontSize="sm"
            borderRadius="full"
            backgroundColor="light.pink"
            color="status.loading"
            fontWeight={400}
          >
            {isPluralRecharge ? 'Gerando notas fiscais' : 'Gerando nota fiscal'}
          </Badge>
          <Flex alignItems="center" gap="8px">
            <Spinner
              size="sm"
              centerProps={{ marginTop: 0 }}
              color="status.loading"
            />
            <Text color="status.loading" fontWeight={600}>
              {counter}s
            </Text>
          </Flex>
        </Flex>
      ) : (
        <Box>
          <Text>
            A nota fiscal ainda está sendo gerada,{' '}
            <Text fontWeight={600} as="span">
              consulte novamente{' '}
            </Text>{' '}
            ou{' '}
            <Text fontWeight={600} as="span">
              aguarde o e-mail
            </Text>{' '}
            que enviaremos quando terminar.
          </Text>
          <Button
            variant="link"
            padding={0}
            marginTop="16px"
            onClick={() => setCounter(45)}
          >
            Consultar nota fiscal novamente
          </Button>
        </Box>
      )}
    </>
  );
};

export default InvoiceDownloadButton;
