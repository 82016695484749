import CustomDownloadLink from '../CustomDownloadLink';
import { InvoiceIcon } from '../../../../assets/customIcons/InvoiceIcon';
import {
  DocumentTypeEnum,
  RechargeListInfo,
  RechargeStatusEnum,
} from '../../../../types/recharge';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { Semibold } from '../../../../components/Typography';
import { baseDownloadUrl } from '../../../../constants/general';

type InvoiceDownloadButtonProps = {
  recharge: RechargeListInfo;
};

const InvoiceDownloadButton = ({ recharge }: InvoiceDownloadButtonProps) => {
  const { status, rechargeFiles } = recharge;
  const invoiceInfoModal = useDisclosure();

  if (
    [
      RechargeStatusEnum.PENDING,
      RechargeStatusEnum.PROCESSED,
      RechargeStatusEnum.RUNNING,
    ].includes(status) &&
    !rechargeFiles?.invoiceFilePath
  ) {
    return (
      <>
        <CustomDownloadLink
          onClick={invoiceInfoModal.onOpen}
          position="relative"
          toolTipText="Nota Fiscal"
          backgroundColor="light.pink"
          color="status.loading"
          _hover={{
            backgroundColor: 'pink.100',
            color: 'pink.700',
          }}
        >
          <InvoiceIcon />
        </CustomDownloadLink>

        <Modal
          isCentered
          autoFocus={false}
          isOpen={invoiceInfoModal.isOpen}
          onClose={invoiceInfoModal.onClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader fontSize="18px" fontWeight={700}>
              Gerando nota fiscal
            </ModalHeader>
            <ModalCloseButton top={7} />
            <ModalBody marginTop={2}>
              A nota fiscal ainda está sendo gerada,{' '}
              <Semibold>consulte novamente mais tarde</Semibold> ou{' '}
              <Semibold>aguarde o e-mail</Semibold> que enviaremos quando
              terminar.
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  } else if (
    ![
      RechargeStatusEnum.CANCELLED,
      RechargeStatusEnum.ERROR,
      RechargeStatusEnum.INVALID_BALANCE,
      RechargeStatusEnum.EXPIRED,
    ].includes(status) &&
    rechargeFiles?.invoiceFilePath
  ) {
    return (
      <CustomDownloadLink
        href={`${baseDownloadUrl}?rechargeId=${rechargeFiles.rechargeId}&documentType=${DocumentTypeEnum.INVOICE}`}
        toolTipText="Nota Fiscal"
      >
        <InvoiceIcon />
      </CustomDownloadLink>
    );
  }

  return <></>;
};

export default InvoiceDownloadButton;
