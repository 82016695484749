import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Flex,
  Text,
  Icon,
  Button,
} from '@chakra-ui/react';
import { FiAlertCircle, FiCheckCircle } from 'react-icons/fi';

type FeedbackModalProps = {
  isOpen: boolean;
  onClose: () => void;
  description: string;
  title: string;
};

const FeedbackModal = ({
  isOpen,
  onClose,
  description,
  title,
}: FeedbackModalProps) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent padding={8}>
        <ModalHeader fontWeight={700} padding={0} marginBottom={10}>
          <Flex alignItems="center" gap={2}>
            <Icon as={FiCheckCircle} boxSize="24px" color="feedback.success" />
            <Text fontSize="18px" fontWeight={700} color="gray.800">
              {title}
            </Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton top="30px" />
        <ModalBody padding={0} marginBottom={10}>
          <Flex
            padding={4}
            borderRadius="8px"
            alignItems="center"
            backgroundColor="light.orange"
            gap={4}
          >
            <Icon as={FiAlertCircle} boxSize="22px" color="orange.500" />
            <Text lineHeight={5}>{description}</Text>
          </Flex>
        </ModalBody>
        <ModalFooter padding={0}>
          <Button width="100%" onClick={onClose}>
            Entendi!
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FeedbackModal;
