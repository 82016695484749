import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardBody,
  DrawerContentProps,
  Flex,
  FormControl,
  Icon,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Stack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Text,
  useDisclosure,
  useSteps,
} from '@chakra-ui/react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { H2 } from '../../components/Typography';
import RefundBalanceEmptyState from './components/RefundBalanceEmptyState';
import { useGetEmployeeBalance, useGetEmployeeById } from '../../api/employees';
import Spinner from '../../components/Spinner';
import EmployeeBasicInfoCard from '../../components/EmployeeBasicInfoCard';
import { Controller, useForm } from 'react-hook-form';
import BenefitRefundInput from './components/BenefitRefundInput';
import { ErrorMessage } from '@hookform/error-message';
import InputWithCounter from '../../components/InputWithCounter';
import RefundAllCheckbox from './components/RefundAllCheckbox';
import { employeeRefundInstructions } from './utils/employeeRefundInstructions';
import { EmployeeRefundFormValues, RefundReason } from './types';
import { handleRefundAll } from './utils/handleRefundAll';
import { getTotalValue } from './utils/getTotalValue';
import { refundOptions, steps } from './utils/constants';
import ActionDrawer from '../../components/ActionDrawer';
import ActionDrawerInfoCard from '../NewRechargePage/components/ActionDrawerInfoCard';
import { formatCurrencyInCents } from '../../utils/formatters/formatCurrency';
import CustomTooltip from '../../components/CustomTooltip';
import NoBalanceAvailableModal from './components/NoBalanceAvailableModal';
import RefundConfirmationModal from './components/RefundConfirmationModal';

const NO_REASON_ERROR_MESSAGE = 'Você precisa dizer o motivo da solicitação';

const actionDrawerHeight: DrawerContentProps['height'] = '144px';

const RefundEmployeeBalancePage = () => {
  const [isCheckedRefundAll, setIsCheckedRefundAll] = useState(false);

  const { employeeId } = useParams();
  const {
    data,
    isLoading: isLoadingEmployee,
    isFetched: isFetchedEmployee,
  } = useGetEmployeeById(employeeId);

  const employee = data?.data;

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const navigate = useNavigate();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
    reset: resetForm,
  } = useForm<EmployeeRefundFormValues>();

  const {
    data: employeeBalance,
    isLoading: isLoadingEmployeeBalance,
    isFetched: isFetchedEmployeeBalance,
  } = useGetEmployeeBalance(employeeId);

  const noBalanceAvailableModal = useDisclosure({
    isOpen:
      isFetchedEmployeeBalance &&
      employeeBalance &&
      employeeBalance.accounts.length === 0,
  });

  const refundConfirmationModal = useDisclosure();

  if (isLoadingEmployee || isLoadingEmployeeBalance) {
    return <Spinner />;
  }

  if (
    (isFetchedEmployee && !employee) ||
    (isFetchedEmployeeBalance && !employeeBalance)
  ) {
    return <Navigate to="/colaboradores" />;
  }

  function handleGoToStepTwo() {
    if (activeStep === 0) {
      setActiveStep(1);
    }
  }

  const { reason } = watch();
  const formValues = getValues();

  const grandTotal = getTotalValue(formValues);

  const isContinueDisabled = !reason || grandTotal === 0;

  const hasMoreThanOneAccount =
    !!employeeBalance && employeeBalance.accounts.length > 1;

  if (!employeeId) {
    return null;
  }

  return (
    <Stack spacing={4} paddingBottom={actionDrawerHeight}>
      <Flex flexGrow={1} gap={4} justifyContent="space-between" flexWrap="wrap">
        <Stepper size="md" index={activeStep} width="405px">
          {steps.map((step, index) => (
            <Step key={index}>
              <StepIndicator fontWeight={600}>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>

              <Box flexShrink="0">
                <StepTitle>{step.title}</StepTitle>
                <StepDescription>{step.description}</StepDescription>
              </Box>

              <StepSeparator />
            </Step>
          ))}
        </Stepper>
        {employee && (
          <EmployeeBasicInfoCard
            cpf={employee.document}
            fullName={employee.fullName}
          />
        )}
      </Flex>
      <Flex gap={6} flexWrap={{ sm: 'wrap', lg: 'nowrap' }}>
        <Card flexBasis={{ sm: '100%', lg: '510px' }} flexShrink={0}>
          <CardBody padding={8}>
            <Stack spacing={6}>
              <Stack spacing={4}>
                <H2>Estornar saldo</H2>
                <Text lineHeight="130%">
                  Por favor, nos diga por que você vai estornar o saldo deste
                  colaborador:
                </Text>
              </Stack>

              <RadioGroup onChange={handleGoToStepTwo}>
                <Stack spacing={4}>
                  {refundOptions.map((option, index) => (
                    <Box width="100%" key={index}>
                      <Radio
                        {...register('reason', {
                          required: NO_REASON_ERROR_MESSAGE,
                        })}
                        size="lg"
                        width="100%"
                        value={option.value}
                      >
                        <Text fontSize="md">{option.label}</Text>
                      </Radio>
                    </Box>
                  ))}
                </Stack>
              </RadioGroup>
              {reason === RefundReason.OTHER && (
                <FormControl isInvalid={!!errors['observation']}>
                  <InputWithCounter
                    marginTop={-4}
                    maxLength={140}
                    placeholder="Diga o que aconteceu"
                    {...register('observation', {
                      shouldUnregister: reason !== RefundReason.OTHER,
                      validate: (value) => {
                        if (!value) {
                          return NO_REASON_ERROR_MESSAGE;
                        }
                      },
                    })}
                    value={getValues('observation')}
                  />
                </FormControl>
              )}

              <Box marginTop={-4}>
                <ErrorMessage
                  errors={errors}
                  name={
                    reason === RefundReason.OTHER ? 'observation' : 'reason'
                  }
                  render={(error) => (
                    <Text color="red.500" fontSize="sm">
                      {error.message}
                    </Text>
                  )}
                />
              </Box>

              <Box borderRadius="lg" padding={4} backgroundColor="light.orange">
                <List spacing={6}>
                  {employeeRefundInstructions.map((instruction) => (
                    <ListItem
                      key={instruction.id}
                      lineHeight="130%"
                      display="flex"
                      alignItems="center"
                      gap={4}
                    >
                      <Icon
                        as={instruction.icon}
                        boxSize="20px"
                        color="primary.primary"
                        strokeWidth={2}
                      />
                      {instruction.text}
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Stack>
          </CardBody>
        </Card>

        <Card flexGrow={1} minWidth="320px">
          <CardBody padding={8}>
            {!reason && <RefundBalanceEmptyState />}
            {reason && (
              <Stack spacing={6}>
                <Flex justifyContent="space-between" gap={4} flexWrap="wrap">
                  <Stack spacing={4}>
                    <H2>Definir valores</H2>
                    <Text>Escolha quanto deseja estornar</Text>
                  </Stack>

                  {hasMoreThanOneAccount && (
                    <RefundAllCheckbox
                      isChecked={isCheckedRefundAll}
                      onChange={(e) => {
                        setIsCheckedRefundAll(e.target.checked);
                        handleRefundAll(
                          e.target.checked,
                          employeeBalance,
                          formValues,
                          resetForm
                        );
                      }}
                    />
                  )}
                </Flex>

                {employeeBalance?.accounts.map((account) => (
                  <Controller
                    key={account.id}
                    control={control}
                    name={`values.${account.id}`}
                    rules={{
                      validate: (value) => {
                        if (!value) {
                          return true;
                        }
                        if (value > account.balance) {
                          return 'Este valor é maior do que o saldo atual';
                        }
                      },
                    }}
                    render={({ field }) => (
                      <BenefitRefundInput
                        key={account.id}
                        label={account.category}
                        balance={account.balance}
                        inputProps={{
                          maxWidth: '550px',
                          onValueChange: (values) => {
                            field.onChange(values.floatValue);
                          },
                          value: field.value || 0,
                          onKeyDown: () => {
                            if (isCheckedRefundAll) {
                              setIsCheckedRefundAll(false);
                            }
                          },
                        }}
                        handleClickRefund={() => {
                          field.onChange(account.balance);
                        }}
                        isInvalid={!!errors.values?.[account.id]}
                        errorMessage={errors.values?.[account.id]?.message}
                      />
                    )}
                  />
                ))}
              </Stack>
            )}
          </CardBody>
        </Card>
      </Flex>

      <ActionDrawer height={actionDrawerHeight}>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          width="100%"
          paddingX={8}
        >
          {reason && (
            <ActionDrawerInfoCard
              label="Valor total"
              value={formatCurrencyInCents(grandTotal)}
            />
          )}
          <Flex width="100%" justifyContent="flex-end" gap={4}>
            <Button
              variant="outline"
              width="200px"
              onClick={() => {
                navigate('/colaboradores');
              }}
            >
              Cancelar
            </Button>
            <CustomTooltip
              label={
                isContinueDisabled
                  ? 'Você precisa escolher uma justificativa e definir os valores'
                  : ''
              }
            >
              <Button
                onClick={() => {
                  handleSubmit(() => {
                    refundConfirmationModal.onOpen();
                  })();
                }}
                isDisabled={isContinueDisabled}
              >
                Continuar
              </Button>
            </CustomTooltip>
          </Flex>
        </Flex>
      </ActionDrawer>

      <NoBalanceAvailableModal
        isOpen={noBalanceAvailableModal.isOpen}
        onClose={noBalanceAvailableModal.onClose}
      />

      <RefundConfirmationModal
        isOpen={refundConfirmationModal.isOpen}
        onClose={refundConfirmationModal.onClose}
        formValues={{
          collaboratorId: employeeId,
          ...formValues,
        }}
      />
    </Stack>
  );
};

export default RefundEmployeeBalancePage;
