export const dromoStyleOverride = {
  global: {
    successColor: '#1e7500',
    warningColor: '#db0200',
  },
  primaryButton: {
    backgroundColor: '#FF6600',
    textColor: '#ffffff',
    hoverBackgroundColor: '#99440A',
    hoverTextColor: '#ffffff',
  },
  secondaryButton: {
    backgroundColor: 'transparent',
    textColor: '#FF6600',
    border: '1px solid #FF6600',
    hoverBackgroundColor: '#FF6600',
    hoverTextColor: '#ffffff',
    hoverBorder: '1px solid #FF6600',
  },
  stepperBar: {
    currentColor: '#FF6600',
  },
};
