import {
  Box,
  Button,
  Card,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerContentProps,
  Flex,
  Stack,
  Text,
  VStack,
  useDisclosure,
  useRadioGroup,
} from '@chakra-ui/react';
import { FieldKeys, fillingTips } from '../utils/fillingTips';
import { scrollPage } from '../../../utils/scrollPage';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useGetCompanyAddressesByType } from '../../../api/address';
import Spinner from '../../../components/Spinner';
import { useGetCompanyBenefitPackages } from '../../../api/benefit';
import EmployeeImportMandatoryBadge from '../components/EmployeeImportMandatoryBadge';
import FieldInstructionsEmptyState from '../components/FieldInstructionsEmptyState';
import SpreadsheetInstructionsCard from '../components/SpreadsheetInstructionsCard';
import ColumnFieldRadioCard from '../components/ColumnFieldRadioCard';
import FieldInstructions from '../components/FieldInstructions';
import EmployeeImportStepper from '../components/EmployeeImportStepper';
import EmployeeImportInstructionsModal from '../components/EmployeeImportInstructionsModal';

const actionDrawerHeight: DrawerContentProps['height'] = '120px';

type PrepareSpreadsheetPageProps = {
  instructionsOnly?: boolean;
};

const PrepareSpreadsheetPage = ({
  instructionsOnly = false,
}: PrepareSpreadsheetPageProps) => {
  const fieldMappings = Object.keys(fillingTips).map((key) => ({
    value: key,
    label: fillingTips[key as FieldKeys].fieldTitle,
    required: fillingTips[key as FieldKeys].required,
  }));

  const { getRootProps, getRadioProps, value } = useRadioGroup({
    name: 'columns',
    onChange: () => scrollPage(0),
  });

  const selectedField = value as FieldKeys | undefined;

  const group = getRootProps();

  const navigate = useNavigate();
  const location = useLocation();

  const instructionsModalDisclosure = useDisclosure({
    defaultIsOpen: true,
  });

  const [param] = useSearchParams();

  const showInstructionsModal = param.get('modal') === '1';

  const { data: companyAddressData, isPending: isPendingCompanyAddressData } =
    useGetCompanyAddressesByType({
      type: 'COMPANY',
      enabled: true,
    });

  const companyAddressList = companyAddressData?.data || [];

  const { data, isPending: isPendingBenefitPackages } =
    useGetCompanyBenefitPackages();
  const benefitPackages = data?.data || [];

  if (isPendingCompanyAddressData || isPendingBenefitPackages) {
    return <Spinner />;
  }

  return (
    <>
      <Box height="100%" paddingBottom={actionDrawerHeight}>
        {!instructionsOnly && <EmployeeImportStepper />}
        <Flex height="100%" gap={6}>
          <Flex
            direction="column"
            gap={6}
            width="100%"
            maxWidth="495px"
            height="100%"
          >
            {!instructionsOnly && <SpreadsheetInstructionsCard />}

            <Card padding="32px" height="100%">
              <VStack align="start" spacing={4}>
                <Stack spacing={4}>
                  <Text fontSize="20px" fontWeight="500">
                    Como preencher o modelo Raiô
                  </Text>
                  <Text>
                    Clique em uma coluna abaixo para ver as instruções
                  </Text>
                </Stack>

                <Stack {...group} width="100%" spacing={4}>
                  {fieldMappings.map((field) => {
                    const radio = getRadioProps({ value: field.value });
                    return (
                      <ColumnFieldRadioCard key={field.value} {...radio}>
                        <Stack>
                          <Text>{field.label}</Text>
                          <EmployeeImportMandatoryBadge
                            required={field.required}
                          />
                        </Stack>
                      </ColumnFieldRadioCard>
                    );
                  })}
                </Stack>
              </VStack>
            </Card>
          </Flex>

          <Card padding="32px" width="100%">
            {selectedField ? (
              <FieldInstructions
                selectedField={selectedField}
                companyAddressList={companyAddressList}
                benefitPackages={benefitPackages}
              />
            ) : (
              <FieldInstructionsEmptyState />
            )}
          </Card>
        </Flex>
      </Box>
      {!instructionsOnly && (
        <Drawer
          placement="bottom"
          isOpen={true}
          onClose={() => {}}
          autoFocus={false}
          blockScrollOnMount={false}
          returnFocusOnClose={false}
          closeOnEsc={false}
          closeOnOverlayClick={false}
          trapFocus={false}
          lockFocusAcrossFrames={false}
          variant="clickThrough"
        >
          <DrawerContent height={actionDrawerHeight} zIndex={1} paddingX={20}>
            <DrawerBody
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              gap={4}
            >
              <Button
                variant="outline"
                width="fit-content"
                onClick={() => navigate('/colaboradores')}
              >
                Voltar
              </Button>
              <Button
                onClick={() =>
                  navigate('/colaboradores/importar/comunicacao', {
                    state: location.state,
                  })
                }
              >
                Estou com a planilha pronta!
              </Button>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}

      {showInstructionsModal && (
        <EmployeeImportInstructionsModal
          isOpen={instructionsModalDisclosure.isOpen}
          onClose={instructionsModalDisclosure.onClose}
        />
      )}
    </>
  );
};

export default PrepareSpreadsheetPage;
