import React, { ReactNode } from 'react';
import { BoxProps, Drawer, DrawerBody, DrawerContent } from '@chakra-ui/react';

type ActionDrawerProps = {
  isOpen?: boolean;
  children: ReactNode;
  height?: BoxProps['height'];
};

const ActionDrawer = ({
  isOpen = true,
  children,
  height = '120px',
}: ActionDrawerProps) => {
  return (
    <Drawer
      placement="bottom"
      isOpen={isOpen}
      onClose={() => {}}
      autoFocus={false}
      blockScrollOnMount={false}
      returnFocusOnClose={false}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      trapFocus={false}
      lockFocusAcrossFrames={false}
      variant="clickThrough"
    >
      <DrawerContent minHeight={height} zIndex={1}>
        <DrawerBody display="flex" alignItems="center" paddingX={20}>
          {children}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default ActionDrawer;
