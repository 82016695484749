export const formatCNPJ = (cnpj: string | undefined): string => {
  if (!cnpj) {
    return '';
  }

  const isValidCNPJ = /^\d{14}$/.test(cnpj);

  if (!isValidCNPJ) {
    return cnpj;
  }

  return cnpj.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
    '$1.$2.$3/$4-$5'
  );
};
