import { createIcon } from '@chakra-ui/react';

export const PhoneIcon = createIcon({
  displayName: 'PhoneIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    boxSize: '24px',
    fill: 'none',
  },
  path: (
    <path
      d="M21.8881 16.9201V19.9201C21.8892 20.1986 21.8322 20.4743 21.7206 20.7294C21.6091 20.9846 21.4454 21.2137 21.2402 21.402C21.035 21.5902 20.7927 21.7336 20.5289 21.8228C20.265 21.912 19.9855 21.9452 19.7081 21.9201C16.631 21.5857 13.6751 20.5342 11.0781 18.8501C8.66194 17.3148 6.61345 15.2663 5.07812 12.8501C3.38809 10.2413 2.33636 7.27109 2.00812 4.1801C1.98313 3.90356 2.01599 3.62486 2.10462 3.36172C2.19324 3.09859 2.33569 2.85679 2.52288 2.65172C2.71008 2.44665 2.93792 2.28281 3.19191 2.17062C3.44589 2.05843 3.72046 2.00036 3.99812 2.0001H6.99812C7.48342 1.99532 7.95391 2.16718 8.32188 2.48363C8.68985 2.80008 8.93019 3.23954 8.99812 3.7201C9.12474 4.68016 9.35957 5.62282 9.69812 6.5301C9.83266 6.88802 9.86178 7.27701 9.78202 7.65098C9.70227 8.02494 9.51698 8.36821 9.24812 8.6401L7.97812 9.9101C9.40167 12.4136 11.4746 14.4865 13.9781 15.9101L15.2481 14.6401C15.52 14.3712 15.8633 14.1859 16.2372 14.1062C16.6112 14.0264 17.0002 14.0556 17.3581 14.1901C18.2654 14.5286 19.2081 14.7635 20.1681 14.8901C20.6539 14.9586 21.0975 15.2033 21.4146 15.5776C21.7318 15.9519 21.9003 16.4297 21.8881 16.9201Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
