import { Box, Card, Text } from '@chakra-ui/react';

type NonNominalCardStockProps = {
  stock: number;
};

const NonNominalCardStock = ({ stock }: NonNominalCardStockProps) => {
  return (
    <Card padding={8} borderRadius="lg" maxWidth="360px" flexGrow={1}>
      <Text>Cartões sem nome ainda não ativados</Text>
      <Text fontSize="40px" fontWeight={600}>
        {stock}
      </Text>
    </Card>
  );
};

export default NonNominalCardStock;
