import { Box, useDisclosure } from '@chakra-ui/react';
import {
  useGetCountProcessingEmployees,
  useGetEmployees,
} from '../../api/employees';
import Spinner from '../../components/Spinner';

import PendingActivationCardModal from './components/PendingActivationCardModal';

import EmployeeTabsPage from './components/Tabs';

const EmployeesPage = () => {
  const { data: employees, isFetching: isFetchingEmployees } =
    useGetEmployees();
  const { data: countProcessingEmployees } = useGetCountProcessingEmployees();

  const processingEmployeesCount: number =
    countProcessingEmployees?.processing || 0;

  const controlPendingActivationCardModal = useDisclosure();

  if (isFetchingEmployees) return <Spinner />;

  return (
    <Box height="100%">
      {controlPendingActivationCardModal.isOpen && (
        <PendingActivationCardModal />
      )}

      <EmployeeTabsPage
        processingEmployeesCount={processingEmployeesCount}
        employees={employees}
      />
    </Box>
  );
};

export default EmployeesPage;
