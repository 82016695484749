import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Alert,
  AlertIcon,
  Icon,
  Flex,
  Text,
  Button,
} from '@chakra-ui/react';
import { FiAlertCircle, FiCheckCircle, FiClock } from 'react-icons/fi';
import Spinner from '../../../../components/Spinner';
import { useNavigate } from 'react-router-dom';

type EmployeeImportFeedbackModalProps = {
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  retryEmployeesBulkMutation: () => void;
};

const EmployeeImportFeedbackModal = ({
  isOpen,
  onClose,
  isLoading,
  isSuccess,
  isError,
  retryEmployeesBulkMutation,
}: EmployeeImportFeedbackModalProps) => {
  const navigate = useNavigate();

  function handleClose() {
    onClose();
    navigate('/colaboradores');
  }

  return (
    <Modal
      isCentered
      size="xl"
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={handleClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {isLoading && (
            <Flex alignItems="center" gap={2}>
              <Icon as={FiClock} color="primary.primary" fontSize="24px" />
              <Text>Importando colaboradores, aguarde</Text>
            </Flex>
          )}
          {isSuccess && (
            <Flex alignItems="center" gap={2}>
              <Icon
                as={FiCheckCircle}
                color="feedback.success"
                fontSize="24px"
              />
              <Text>Colaboradores importados com sucesso!</Text>
            </Flex>
          )}
          {isError && (
            <Flex alignItems="center" gap={2}>
              <Icon as={FiAlertCircle} color="feedback.alert" fontSize="24px" />
              <Text>Não conseguimos importar este arquivo</Text>
            </Flex>
          )}
        </ModalHeader>

        {!isLoading && <ModalCloseButton />}
        <ModalBody>
          <Flex alignItems="center" justifyContent="center" paddingY={6}>
            {isLoading && <Spinner centerProps={{ marginTop: 0 }} />}
            {isSuccess && (
              <Alert
                status="warning"
                borderRadius="lg"
                backgroundColor="light.orange"
              >
                <AlertIcon as={FiAlertCircle} color="primary.primary" />
                Assim que o processamento da importação terminar, você poderá
                fazer uma recarga
              </Alert>
            )}
            {isError && (
              <Text width="100%">Por favor, tente novamente daqui a pouco</Text>
            )}
          </Flex>
        </ModalBody>

        {!isLoading && (
          <ModalFooter>
            {isSuccess ? (
              <Button variant="outline" width="full" onClick={handleClose}>
                Ir para o menu Colaboradores
              </Button>
            ) : (
              <Button
                variant="outline"
                width="full"
                onClick={retryEmployeesBulkMutation}
              >
                Tentar novamente
              </Button>
            )}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default EmployeeImportFeedbackModal;
