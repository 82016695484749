import { Flex } from '@chakra-ui/react';
import RegistrationProcessingTable from '.';
import { useGetProcessingEmployees } from '../../../../api/employees';
import Spinner from '../../../../components/Spinner';
import { useQueryClient } from '@tanstack/react-query';
import { useUserCompaniesStore } from '../../../../stores/useUserCompaniesStore';

const RegistrationProcessingQueue = () => {
  const processingEmployees = useGetProcessingEmployees();
  const queryClient = useQueryClient();
  const { selectedCompany } = useUserCompaniesStore();

  function refetchProcessingEmployees() {
    processingEmployees.refetch().then(() => {
      queryClient.invalidateQueries({
        queryKey: [
          'countProcessingEmployees',
          { customerId: selectedCompany?.customerId },
        ],
      });
    });
  }

  return (
    <>
      {processingEmployees.isLoading || processingEmployees.isRefetching ? (
        <Flex justifyContent={'center'} alignItems={'center'}>
          <Spinner />
        </Flex>
      ) : (
        <RegistrationProcessingTable
          employees={processingEmployees.data || []}
          refetchProcessingEmployees={refetchProcessingEmployees}
        />
      )}
    </>
  );
};

export default RegistrationProcessingQueue;
