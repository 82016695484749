import { createIcon } from '@chakra-ui/react';

export const CommentInfoIcon = createIcon({
  displayName: 'CommentInfoIcon',
  viewBox: '0 0 24 24',
  defaultProps: {
    width: '24px',
    height: '24px',
  },
  path: (
    <>
      <path
        d="M13.4996 6.4998C13.4996 6.89762 13.3415 7.27915 13.0602 7.56046C12.7789 7.84176 12.3974 7.9998 11.9996 7.9998C11.6017 7.9998 11.2202 7.84176 10.9389 7.56046C10.6576 7.27915 10.4996 6.89762 10.4996 6.4998C10.4996 6.10197 10.6576 5.72044 10.9389 5.43914C11.2202 5.15783 11.6017 4.9998 11.9996 4.9998C12.3974 4.9998 12.7789 5.15783 13.0602 5.43914C13.3415 5.72044 13.4996 6.10197 13.4996 6.4998ZM23.9996 18.9998V12.3398C24.0369 9.25725 22.9067 6.27469 20.8359 3.99097C18.7651 1.70725 15.9071 0.291436 12.8356 0.0277985C11.1212 -0.0921438 9.4011 0.157538 7.7916 0.759951C6.1821 1.36236 4.72077 2.30345 3.50648 3.51955C2.29219 4.73564 1.35327 6.19836 0.75324 7.80875C0.153213 9.41914 -0.0939187 11.1396 0.028565 12.8538C0.470565 19.2078 6.08157 23.9998 13.0826 23.9998H18.9996C20.3252 23.9982 21.596 23.4709 22.5333 22.5336C23.4707 21.5962 23.998 20.3254 23.9996 18.9998ZM12.6996 2.0238C15.266 2.25084 17.6515 3.44071 19.3767 5.35435C21.1018 7.268 22.0389 9.76362 21.9996 12.3398V18.9998C21.9996 19.7955 21.6835 20.5585 21.1209 21.1211C20.5583 21.6837 19.7952 21.9998 18.9996 21.9998H13.0826C7.04857 21.9998 2.39957 18.0998 2.02457 12.7158C1.92612 11.3444 2.11154 9.96739 2.56925 8.67087C3.02696 7.37434 3.74712 6.18613 4.68474 5.18047C5.62236 4.17481 6.75729 3.37331 8.01863 2.82605C9.27997 2.27878 10.6406 1.99751 12.0156 1.9998C12.2426 1.9998 12.4716 2.0088 12.6996 2.0238ZM13.9996 17.9998V11.9998C13.9996 11.4694 13.7889 10.9607 13.4138 10.5856C13.0387 10.2105 12.53 9.9998 11.9996 9.9998H10.9996C10.7344 9.9998 10.48 10.1052 10.2925 10.2927C10.1049 10.4802 9.99957 10.7346 9.99957 10.9998C9.99957 11.265 10.1049 11.5194 10.2925 11.7069C10.48 11.8944 10.7344 11.9998 10.9996 11.9998H11.9996V17.9998C11.9996 18.265 12.1049 18.5194 12.2925 18.7069C12.48 18.8944 12.7344 18.9998 12.9996 18.9998C13.2648 18.9998 13.5191 18.8944 13.7067 18.7069C13.8942 18.5194 13.9996 18.265 13.9996 17.9998Z"
        fill="currentColor"
      />
    </>
  ),
});
