import HelpMessage from '../../../components/HelpMessage';
import { Employee } from '../../../types/employee';
import { RechargeVisibilityEnum } from '../../../types/recharge';

export function sumBenefitValues(employees: Employee[]) {
  let totalSum = 0;
  employees.forEach((employee) => {
    employee.categories.forEach((category) => {
      totalSum += category.value;
    });
  });
  return totalSum;
}

export function checkIfSomeEmployeeReceiveDailyBenefits(employees: Employee[]) {
  return employees.some((employee) =>
    employee.categories.some((category) => category.period === 'DAILY')
  );
}

export function sumBenefitValuesPerEmployee(employee: Employee): number {
  return employee.categories.reduce((totalValue, category) => {
    if (category.value !== undefined && category.value !== null) {
      return totalValue + category.value;
    }
    return totalValue;
  }, 0);
}

export function getRechargeVisibilityLabel(
  rechargeVisibility: keyof typeof RechargeVisibilityEnum
): string {
  switch (rechargeVisibility) {
    case RechargeVisibilityEnum.CREATED:
      return 'Desde o momento em que a recarga foi criada';
    case RechargeVisibilityEnum.PAID:
      return 'Só quando o Boleto/Pix for pago';
    case RechargeVisibilityEnum.NEVER:
      return 'Não mostrar essa informação';
    default:
      return '';
  }
}

export function getRechargeVisibilityTooltip(
  rechargeVisibility: keyof typeof RechargeVisibilityEnum
) {
  switch (rechargeVisibility) {
    case RechargeVisibilityEnum.CREATED:
      return (
        <HelpMessage label="Nesta opção, é exibida a data de crédito prevista, mesmo enquanto o Boleto/Pix não foi pago" />
      );
    case RechargeVisibilityEnum.PAID:
      return (
        <HelpMessage label="Nesta opção, é exibida a data de crédito e o valor de cada benefício, a partir da confirmação do pagamento do Boleto/Pix" />
      );
    case RechargeVisibilityEnum.NEVER:
      return (
        <HelpMessage label="Nesta opção, não é exibida nenhuma informação sobre a próxima recarga" />
      );
    default:
      return '';
  }
}
