export enum RefundReason {
  WRONG_BENEFIT = 'WRONG_BENEFIT',
  WRONG_VALUE = 'WRONG_VALUE',
  EMPLOYE_DISMISSED = 'EMPLOYE_DISMISSED',
  OTHER = 'OTHER',
}

export type EmployeeRefundFormValues = {
  reason: RefundReason;
  values: Record<string, number | undefined>;
  observation?: string;
};
