import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalProps,
  Button,
  useDisclosure,
  ButtonProps,
} from '@chakra-ui/react';

import ContactForms from '../ContactForms';

type ContactModalButtonProps = ButtonProps & {
  modalProps?: ModalProps;
  buttonTittle?: string;
  children?: React.ReactNode;
};

const ContactModalButton = ({
  title,
  modalProps,
  children,
  ...buttonProps
}: ContactModalButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        variant={children ? 'unstyled' : 'link'}
        color="primary.200"
        fontWeight={500}
        padding={0}
        {...buttonProps}
        onClick={onOpen}
      >
        {title || children || 'Fale com a Raiô!'}
      </Button>
      <Modal isCentered {...modalProps} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontWeight={700} marginBottom={3}>
            Falar com a Raiô
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ContactForms />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ContactModalButton;
