import { createIcon } from '@chakra-ui/react';

export const ExpansionIcon = createIcon({
  displayName: 'ExpansionIcon',
  viewBox: '0 0 20 20',
  path: (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none">
      <path
        d="M13 1H19V7"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 19H1V13"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 1L12 8"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 19L8 12"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
});
