import { createIcon } from '@chakra-ui/react';

export const AwardsIcon = createIcon({
  displayName: 'AwardsIcon',
  viewBox: '0 0 16 22',
  defaultProps: {
    fill: 'none',
  },
  path: (
    <>
      <path
        d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 13V19.234C3.00014 19.4108 3.04717 19.5845 3.13631 19.7372C3.22545 19.89 3.35349 20.0163 3.50739 20.1035C3.66129 20.1906 3.83555 20.2353 4.01238 20.2331C4.18921 20.2309 4.3623 20.1819 4.514 20.091L7.486 18.309C7.6413 18.2159 7.81895 18.1668 8 18.1668C8.18105 18.1668 8.3587 18.2159 8.514 18.309L11.486 20.091C11.6377 20.1819 11.8108 20.2309 11.9876 20.2331C12.1645 20.2353 12.3387 20.1906 12.4926 20.1035C12.6465 20.0163 12.7746 19.89 12.8637 19.7372C12.9528 19.5845 12.9999 19.4108 13 19.234V13"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});
