import { createIcon } from '@chakra-ui/react';

export const LocationIcon = createIcon({
  displayName: 'LocationIcon',
  viewBox: '0 0 25 24',
  path: (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none">
      <path
        d="M21.5 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 1 1 18 0Z"
        stroke="#353535"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 13a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
        stroke="#353535"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
});
