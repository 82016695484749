import { EmployeeBalanceResponse } from '../../../types/employee';
import { EmployeeRefundFormValues } from '../types';

type Values = EmployeeRefundFormValues['values'];

export function handleRefundAll(
  checked: boolean,
  employeeBalance: EmployeeBalanceResponse | undefined,
  formValues: EmployeeRefundFormValues,
  resetFn: (values: EmployeeRefundFormValues) => void
) {
  if (!employeeBalance) {
    return;
  }

  if (employeeBalance.accounts.length === 0) {
    return;
  }

  const accounts = employeeBalance.accounts;

  if (checked) {
    const categoriesValues: Values = accounts.reduce(
      (values: Values, account) => {
        values[account.id] = account.balance;
        return values;
      },
      {}
    );
    resetFn({
      observation: formValues.observation,
      reason: formValues.reason,
      values: categoriesValues,
    });
  } else {
    const resetedCategoriesValues: Values = accounts.reduce(
      (values: Values, account) => {
        values[account.id] = 0;
        return values;
      },
      {}
    );
    resetFn({
      observation: formValues.observation,
      reason: formValues.reason,
      values: resetedCategoriesValues,
    });
  }
}
