import {
  Box,
  Button,
  Card,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerContentProps,
  Flex,
  Icon,
  Input,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserCompaniesStore } from '../../../stores/useUserCompaniesStore';
import { employeeImportColumnHooks } from '../utils/constants';
import NonNominalCardStock from '../../EmployeeRegistrationPage/components/NonNominalCardStock';
import NonNominalCardsInfo from '../../../components/NonNominalCardsInfo';
import { H2 } from '../../../components/Typography';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useRef, useState } from 'react';
import { UploadIcon } from '../../../assets/customIcons';
import DromoUploader from 'dromo-uploader-react';
import { useGetCompanyBenefitPackages } from '../../../api/benefit';
import { useGetCompanyAddressesByType } from '../../../api/address';
import Spinner from '../../../components/Spinner';
import {
  SendCommsFormValues,
  addressVisibilitySchema,
  cardTypeSchema,
} from '../../../types/schemas/employeeRegistrationSchema';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { useGetCompanyNonNominalCardStock } from '../../../api/card';
import { useGetCustomerConfigs } from '../../../api/configs';
import { CustomerConfig } from '../../../types/config';
import CustomTooltip from '../../../components/CustomTooltip';
import { zodResolver } from '@hookform/resolvers/zod';
import AddressVisibilityCheckbox from '../components/AddressVisibilityCheckbox';
import EmployeeImportCardTypeForm from '../components/EmployeeImportCardTypeForm';
import { useFetchContext } from '../../../hooks/useFetchContext';
import { useMutation } from '@tanstack/react-query';
import EmployeeImportFeedbackModal from '../components/EmployeeImportFeedbackModal';
import { EmployeeDromoResponse } from '../types';
import { getDromoFields } from '../utils/getDromoFields';
import EmployeeImportStepper from '../components/EmployeeImportStepper';
import { addHours, formatISO } from 'date-fns';
import { useGetEmployeesDocument } from '../../../api/employees';
import { getDromoRowHooks } from '../utils/getDromoRowHooks';
import { dromoStyleOverride } from '../../../styles/dromoStyleOverride';
import { FiCreditCard } from 'react-icons/fi';

const actionDrawerHeight: DrawerContentProps['height'] = '120px';
const MAX_FILE_SIZE_IN_MB = 12;

const importConfigSchema = cardTypeSchema.and(addressVisibilitySchema);

type FormValues = z.infer<typeof importConfigSchema>;

const ImportConfigPage = () => {
  const [employees, setEmployees] = useState<EmployeeDromoResponse | null>(
    null
  );

  const { data: benefitPackagesData, isPending: isPendingBenefitPackages } =
    useGetCompanyBenefitPackages();

  const { data: companyAddressData, isPending: isPendingCompanyAddresses } =
    useGetCompanyAddressesByType({
      type: 'COMPANY',
      enabled: true,
    });

  const { data: customerConfigArray = [], isPending: isPendingCustomerConfig } =
    useGetCustomerConfigs();
  const customerConfig = customerConfigArray[0] as CustomerConfig | undefined;
  const isNoNameCardsAllowed = !!customerConfig?.isNoNameCardsAllowed;

  const { data: nonNominalCardStock = 0, isFetching: isPendingCardStock } =
    useGetCompanyNonNominalCardStock(isNoNameCardsAllowed);

  const { data: existingCPFs, isFetching: isFetchingEmployeesDocument } =
    useGetEmployeesDocument();

  const location = useLocation();

  const sendCommsConfig = location.state as SendCommsFormValues | undefined;

  const sendCommsOnAdmissionDate: boolean =
    sendCommsConfig?.sendCommunication === 'onAdmissionDate';

  const methods = useForm<FormValues>({
    resolver: zodResolver(importConfigSchema),
    reValidateMode: 'onBlur',
    defaultValues: {
      isAddressVisible: false,
    },
  });

  const { cardType } = methods.watch();

  const companyAddressList = companyAddressData?.data || [];
  const benefitPackages = benefitPackagesData?.data || [];

  const [fileToUpload, setFileToUpload] = useState<File | null>(null);

  const fileExceedsMaxSize =
    !!fileToUpload && fileToUpload.size > MAX_FILE_SIZE_IN_MB * 1024 * 1024;

  const [dragOver, setDragOver] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const dromoUploaderDisclosure = useDisclosure();

  const navigate = useNavigate();
  const { selectedCompany } = useUserCompaniesStore();
  const { user } = useAuthContext();

  const uploadingModalDisclosure = useDisclosure();
  const instructionsModalDisclosure = useDisclosure();

  function handleImport() {
    fileInputRef.current?.click();
  }

  function handleFileUpload(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files?.length) {
      const file = event.target.files[0];
      setFileToUpload(file);
    }
  }

  function handleDragOver(event: React.DragEvent<HTMLDivElement>) {
    setDragOver(true);
    event.preventDefault();
  }

  function handleDrop(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    setDragOver(false);
    if (event.dataTransfer.files?.length) {
      const file = event.dataTransfer.files[0];
      setFileToUpload(file);
    }
  }

  function handleDragLeave(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      setDragOver(false);
    }
  }

  const { api } = useFetchContext();

  const postEmployeesBulk = useMutation({
    mutationFn: ({ employees }: { employees: EmployeeDromoResponse }) =>
      api.post('/api/collaborators/bulk', {
        companyId: selectedCompany?.id,
        customerId: selectedCompany?.customerId,
        cardType: methods.getValues('cardType'),
        isAddressVisible: methods.getValues('isAddressVisible'),
        sendCommunication: sendCommsConfig?.sendCommunication,
        sendCommunicationDate: sendCommsConfig?.sendCommunicationDate
          ? formatISO(addHours(sendCommsConfig.sendCommunicationDate, 8))
          : undefined,
        collaborators: employees,
      }),
    onMutate: () => {
      uploadingModalDisclosure.onOpen();
    },
    onSuccess: () => {
      setEmployees(null);
    },
  });

  if (!user || !selectedCompany) {
    return 'Erro ao carregar informações do usuário ou empresa selecionada';
  }

  if (
    isPendingBenefitPackages ||
    isPendingCompanyAddresses ||
    isPendingCustomerConfig ||
    isPendingCardStock ||
    isFetchingEmployeesDocument
  ) {
    return <Spinner />;
  }

  return (
    <>
      <Box height="100%" paddingBottom={actionDrawerHeight}>
        <EmployeeImportStepper />
        <Box as="form" height="100%">
          <FormProvider {...methods}>
            <Flex gap={6} height={isNoNameCardsAllowed ? 'auto' : '100%'}>
              {isNoNameCardsAllowed && (
                <Stack
                  minWidth="358px"
                  maxWidth="358px"
                  spacing={6}
                  height="100%"
                >
                  <Card padding="32px" height="100%">
                    <Stack spacing={8}>
                      <H2>Escolher tipo de cartão</H2>
                      <EmployeeImportCardTypeForm />
                    </Stack>
                  </Card>
                  <NonNominalCardStock stock={nonNominalCardStock} />
                  <NonNominalCardsInfo />
                </Stack>
              )}

              <Stack width="100%" minHeight="100%" spacing={6}>
                {(!isNoNameCardsAllowed || cardType === 'NOMINAL') && (
                  <Card padding={8}>
                    <AddressVisibilityCheckbox
                      {...methods.register('isAddressVisible')}
                    />
                  </Card>
                )}

                <Card padding="32px" width="100%" height="100%">
                  <Text fontSize="20px" fontWeight="500" marginBottom="32px">
                    Importar planilha
                  </Text>
                  {isNoNameCardsAllowed && !cardType ? (
                    <Flex
                      direction="column"
                      height="100%"
                      alignItems="center"
                      justifyContent="center"
                      gap={6}
                      padding={6}
                    >
                      <Icon
                        as={FiCreditCard}
                        boxSize="50px"
                        color="primary.primary"
                      />
                      <Text fontSize="28px" textAlign="center">
                        Escolha um tipo de cartão para continuar
                      </Text>
                    </Flex>
                  ) : (
                    <Flex
                      border="1px dashed black"
                      borderRadius="8px"
                      background="#FEFEFC"
                      height="100%"
                      alignItems="center"
                      justifyContent="center"
                      flexDirection="column"
                      gap={4}
                      onDragOver={handleDragOver}
                      onDrop={handleDrop}
                      onDragLeave={handleDragLeave}
                    >
                      <Input
                        type="file"
                        style={{ display: 'none' }}
                        accept=".xlsx, .csv"
                        ref={fileInputRef}
                        onChange={handleFileUpload}
                      />
                      <Flex
                        flexDirection="column"
                        alignItems="center"
                        color="primary.primary"
                        width="380px"
                        gap={2}
                        padding={4}
                        cursor="pointer"
                        onClick={handleImport}
                      >
                        <UploadIcon
                          width="20px"
                          height="20px"
                          color={
                            dragOver
                              ? 'primary.primary'
                              : fileToUpload
                                ? 'gray.800'
                                : 'primary.primary'
                          }
                        />
                        {dragOver ? (
                          <Text
                            fontWeight={500}
                            textDecoration="underline"
                            textAlign="center"
                          >
                            Solte o arquivo aqui
                          </Text>
                        ) : (
                          <>
                            {fileToUpload ? (
                              <Text
                                fontWeight={500}
                                textDecoration="underline"
                                textAlign="center"
                                color="gray.800"
                              >
                                {fileToUpload.name}
                              </Text>
                            ) : (
                              <Text
                                fontWeight={500}
                                textDecoration="underline"
                                textAlign="center"
                              >
                                Clique aqui ou arraste o arquivo no formato XLSX
                                ou CSV UTF-8
                              </Text>
                            )}
                          </>
                        )}
                      </Flex>
                      {fileToUpload ? (
                        <>
                          <Text
                            fontSize="16px"
                            onClick={handleImport}
                            cursor="pointer"
                            padding={2}
                            maxWidth="258px"
                            textAlign="center"
                            lineHeight="1.3"
                          >
                            clique aqui ou arraste um novo arquivo para
                            substituir
                          </Text>
                          {fileExceedsMaxSize && (
                            <Text
                              fontWeight={600}
                              color="feedback.alert"
                              fontSize="14px"
                            >
                              Tamanho máximo: {MAX_FILE_SIZE_IN_MB}MB
                            </Text>
                          )}
                        </>
                      ) : (
                        <Text fontSize="14px">
                          Tamanho máximo: {MAX_FILE_SIZE_IN_MB}MB
                        </Text>
                      )}
                    </Flex>
                  )}
                </Card>
              </Stack>
            </Flex>
          </FormProvider>
        </Box>
      </Box>
      <DromoUploader
        licenseKey={DROMO_LICENSE_KEY || ''}
        fields={getDromoFields(
          companyAddressList,
          benefitPackages,
          cardType,
          sendCommsOnAdmissionDate
        )}
        settings={{
          importIdentifier: `employees-import-${selectedCompany?.id}-${fileToUpload?.name}`,
          developmentMode: SENTRY_ENVIRONMENT !== 'production',
          locale: 'pt',
          initialFile: fileToUpload,
          backendSyncMode: 'MAPPINGS_ONLY',
          autoMapHeaders: true,
          invalidDataBehavior: 'BLOCK_SUBMIT',
          styleOverrides: dromoStyleOverride,
        }}
        user={{
          id: user?.id,
          name: user.fullName,
          email: user.username,
          companyId: selectedCompany.id,
          companyName: selectedCompany.tradeName,
        }}
        columnHooks={employeeImportColumnHooks}
        rowHooks={getDromoRowHooks(existingCPFs)}
        onResults={(response: EmployeeDromoResponse) => {
          setEmployees(response);
          dromoUploaderDisclosure.onClose();
          postEmployeesBulk.mutate({ employees: response });
        }}
        open={dromoUploaderDisclosure.isOpen}
        onCancel={dromoUploaderDisclosure.onClose}
      />
      <Drawer
        placement="bottom"
        isOpen={true}
        onClose={() => {}}
        autoFocus={false}
        blockScrollOnMount={false}
        returnFocusOnClose={false}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        trapFocus={false}
        lockFocusAcrossFrames={false}
        variant="clickThrough"
      >
        <DrawerContent height={actionDrawerHeight} zIndex={1} paddingX={20}>
          <DrawerBody
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            gap={4}
          >
            <Button
              variant="outline"
              width="fit-content"
              onClick={() =>
                navigate('/colaboradores/importar/comunicacao', {
                  state: sendCommsConfig,
                })
              }
            >
              Voltar
            </Button>
            <CustomTooltip
              label={
                isNoNameCardsAllowed && !cardType
                  ? 'Você precisa escolher um tipo de cartão'
                  : fileToUpload
                    ? ''
                    : 'Você precisa anexar um arquivo'
              }
            >
              <Button
                isDisabled={
                  (isNoNameCardsAllowed && !cardType) ||
                  (!isNoNameCardsAllowed && !fileToUpload) ||
                  !fileToUpload ||
                  fileExceedsMaxSize
                }
                onClick={() => {
                  dromoUploaderDisclosure.onOpen();
                  window.open(
                    '/colaboradores/importar/instrucoes?modal=1',
                    '_blank'
                  );
                }}
              >
                {!isNoNameCardsAllowed || cardType === 'NOMINAL'
                  ? 'Importar planilha e pedir cartões'
                  : 'Importar planilha'}
              </Button>
            </CustomTooltip>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <EmployeeImportFeedbackModal
        isError={postEmployeesBulk.isError}
        isLoading={postEmployeesBulk.isPending}
        isSuccess={postEmployeesBulk.isSuccess}
        isOpen={uploadingModalDisclosure.isOpen}
        onClose={() => {
          setEmployees(null);
          uploadingModalDisclosure.onClose();
        }}
        retryEmployeesBulkMutation={() =>
          postEmployeesBulk.mutate({
            employees: employees ?? [],
          })
        }
      />
    </>
  );
};

export default ImportConfigPage;
