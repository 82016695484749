import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFetchContext } from '../../hooks/useFetchContext';
import { useUserCompaniesStore } from '../../stores/useUserCompaniesStore';
import { EmployeeBenefitCategory } from '../../types/employee';
import { useToast } from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { BenefitPackage } from '../../types/benefit';

export const useGetCompanyBenefitPackages = () => {
  const { selectedCompany } = useUserCompaniesStore();
  const { api } = useFetchContext();
  return useQuery({
    queryKey: ['benefit', { customerId: selectedCompany?.customerId }],
    queryFn: async () =>
      await api.get<BenefitPackage[]>(
        `/api/packages?customerId=${selectedCompany?.customerId}`
      ),
    enabled: !!selectedCompany?.customerId,
  });
};

type GetSelectedCompanyBenefitCategoriesProps = {
  onlyActive?: boolean;
};

export const useGetSelectedCompanyBenefitCategories = (
  props?: GetSelectedCompanyBenefitCategoriesProps
) => {
  const { api } = useFetchContext();
  const { selectedCompany } = useUserCompaniesStore();

  const onlyActive = props?.onlyActive;

  const isActiveQuery =
    onlyActive !== undefined ? `&isActive=${onlyActive}` : '';

  return useQuery({
    queryKey: [
      'benefit-categories',
      { customerId: selectedCompany?.customerId, onlyActive },
    ],
    queryFn: () =>
      api.get<EmployeeBenefitCategory[]>(
        `/api/categories-customer?customerId=${selectedCompany?.customerId}${isActiveQuery}`
      ),
    enabled: !!selectedCompany?.customerId,
    select: (response) => response.data,
    meta: {
      errorMessage:
        'Erro ao buscar categorias de benefícios. Tente novamente mais tarde.',
    },
  });
};

type CreateBenefitPayload = {
  customerId: string;
  name: string;
  package_categories?: {
    value: number;
    periodType: string;
  }[];
};

type UpdateBenefitPayload = CreateBenefitPayload & {
  id: string;
};

type CreateBenefitErrorResponse = {
  message: string;
};

export const useCreateBenefitPackage = () => {
  const { api } = useFetchContext();
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: async ({
      benefitPackage,
    }: {
      benefitPackage: CreateBenefitPayload;
    }) => api.post('/api/packages', benefitPackage),
    onSuccess: () => {
      toast({
        title: 'Pacote de benefícios criado com sucesso!',
        status: 'success',
      });
      queryClient.invalidateQueries({
        queryKey: ['benefit-categories'],
      });
      queryClient.invalidateQueries({
        queryKey: ['benefit'],
      });
      queryClient.invalidateQueries({ queryKey: ['employees'] });
    },
    onError: (error: AxiosError<CreateBenefitErrorResponse>) => {
      toast({
        title: 'Tivemos um problema ao criar o pacote de benefícios.',
        description:
          error.response?.data.message ?? 'Tente de novo daqui a pouco.',
        status: 'error',
      });
    },
  });
};

export const useUpdateBenefitPackage = () => {
  const { api } = useFetchContext();
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: async ({
      benefitPackage,
    }: {
      benefitPackage: UpdateBenefitPayload;
    }) => api.patch(`/api/packages/${benefitPackage.id}`, benefitPackage),
    onSuccess: () => {
      toast({
        title: 'Pacote de benefícios editado com sucesso!',
        status: 'success',
      });
      queryClient.invalidateQueries({
        queryKey: ['benefit-categories'],
      });
      queryClient.invalidateQueries({
        queryKey: ['benefit'],
      });
      queryClient.invalidateQueries({ queryKey: ['employees'] });
    },
    onError: (error: AxiosError<CreateBenefitErrorResponse>) => {
      toast({
        title: 'Tivemos um problema ao editar o pacote de benefícios.',
        description:
          error.response?.data.message ?? 'Tente de novo daqui a pouco.',
        status: 'error',
      });
    },
  });
};

export const useDeleteBenefitPackage = () => {
  const { api } = useFetchContext();
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    mutationFn: ({ benefitPackageId }: { benefitPackageId: string }) =>
      api.delete(`/api/packages/${benefitPackageId}`),
    onSuccess: () => {
      toast({
        title: 'Pacote de benefícios excluído com sucesso!',
        status: 'success',
      });
      queryClient.invalidateQueries({
        queryKey: ['benefit-categories'],
      });
      queryClient.invalidateQueries({
        queryKey: ['benefit'],
      });
      queryClient.invalidateQueries({
        queryKey: ['employees'],
      });
    },
    onError: () => {
      toast({
        title: 'Tivemos um problema ao excluir o pacote de benefícios.',
        description: 'Tente de novo daqui a pouco.',
        status: 'error',
      });
    },
  });
};
