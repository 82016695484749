import { createIcon } from '@chakra-ui/react';

export const ActiveIcon = createIcon({
  displayName: 'ActiveIcon',
  viewBox: '0 0 17 16',
  path: (
    <>
      <g clipPath="url(#clip0_11671_16419)" color="currentColor" fill="none">
        <path
          d="M12.3 5.195a.667.667 0 0 0-.943 0L7.606 8.947 5.814 7.18a.668.668 0 1 0-.957.933l1.83 1.8a1.25 1.25 0 0 0 .896.4h.022a1.25 1.25 0 0 0 .89-.369L12.3 6.138a.667.667 0 0 0 0-.943Z"
          fill="currentColor"
        />
        <circle
          cx="8.66699"
          cy="8"
          r="7.4"
          stroke="currentColor"
          strokeWidth="1.2"
        />
      </g>
      <defs>
        <clipPath id="clip0_11671_16419">
          <rect
            width="16"
            height="16"
            fill="currentColor"
            transform="translate(0.666992)"
          />
        </clipPath>
      </defs>
    </>
  ),
});
