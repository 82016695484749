import { createIcon } from '@chakra-ui/react';

export const ReceiptIcon = createIcon({
  displayName: 'ReceiptIcon',
  viewBox: '0 0 46 60',
  defaultProps: {
    boxSize: '60px',
    fill: 'currentColor',
  },
  path: (
    <>
      <path
        d="M40 0H20C16.686 0.00396964 13.5089 1.3222 11.1655 3.66555C8.8222 6.00889 7.50397 9.18601 7.5 12.5V57.5C7.50035 57.9547 7.62472 58.4007 7.8597 58.79C8.09469 59.1793 8.4314 59.4971 8.83359 59.7093C9.23578 59.9214 9.68822 60.0199 10.1422 59.994C10.5962 59.9682 11.0345 59.819 11.41 59.5625L16.675 55.965L21.94 59.5625C22.356 59.8473 22.8483 59.9998 23.3525 59.9998C23.8567 59.9998 24.349 59.8473 24.765 59.5625L30.015 55.965L35.265 59.5625C35.6812 59.8479 36.1741 60.0006 36.6788 60.0006C37.1834 60.0006 37.6763 59.8479 38.0925 59.5625L43.3425 55.9675L48.5925 59.56C48.9678 59.8157 49.4056 59.9643 49.859 59.9899C50.3124 60.0155 50.7642 59.9171 51.1659 59.7054C51.5676 59.4936 51.904 59.1764 52.1391 58.7879C52.3741 58.3993 52.4989 57.9541 52.5 57.5V12.5C52.496 9.18601 51.1778 6.00889 48.8345 3.66555C46.4911 1.3222 43.314 0.00396964 40 0V0ZM47.5 52.75L44.75 50.87C44.3335 50.5841 43.8402 50.431 43.335 50.431C42.8298 50.431 42.3365 50.5841 41.92 50.87L36.67 54.4675L31.42 50.87C31.0038 50.5846 30.5109 50.4319 30.0062 50.4319C29.5016 50.4319 29.0087 50.5846 28.5925 50.87L23.3425 54.4675L18.0925 50.87C17.6768 50.5857 17.1849 50.4336 16.6813 50.4336C16.1776 50.4336 15.6857 50.5857 15.27 50.87L12.5 52.75V12.5C12.5 10.5109 13.2902 8.60322 14.6967 7.1967C16.1032 5.79018 18.0109 5 20 5H40C41.9891 5 43.8968 5.79018 45.3033 7.1967C46.7098 8.60322 47.5 10.5109 47.5 12.5V52.75Z"
        fill="currentColor"
      />
      <path
        d="M40 20H20C18.6193 20 17.5 21.1193 17.5 22.5C17.5 23.8807 18.6193 25 20 25H40C41.3807 25 42.5 23.8807 42.5 22.5C42.5 21.1193 41.3807 20 40 20Z"
        fill="currentColor"
      />
      <path
        d="M35 30H20C18.6193 30 17.5 31.1193 17.5 32.5C17.5 33.8807 18.6193 35 20 35H35C36.3807 35 37.5 33.8807 37.5 32.5C37.5 31.1193 36.3807 30 35 30Z"
        fill="currentColor"
      />
    </>
  ),
});
