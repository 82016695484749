import {
  Box,
  BoxProps,
  Flex,
  Icon,
  IconProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import React from 'react';
import { BenefitCategories } from '../../types/benefit';
import { benefitsPresentation } from '../../utils/formatters/benefitsPresentation';

type BenefitDisplayProps = {
  benefit: BenefitCategories;
  children?: React.ReactNode;
  boxProps?: BoxProps;
  iconProps?: IconProps;
  textProps?: TextProps;
};

const BenefitDisplay = ({
  benefit,
  children,
  boxProps,
  iconProps,
  textProps,
}: BenefitDisplayProps) => {
  return (
    <Box {...boxProps}>
      <Flex align="flex-start" gap={1}>
        <Icon
          mt={0.5}
          as={benefitsPresentation[benefit?.category?.name]?.icon}
          display="inline"
          color={benefitsPresentation[benefit?.category?.name]?.color}
          {...iconProps}
        />

        <Text
          key={benefit.id}
          fontWeight={600}
          color={benefitsPresentation[benefit?.category?.name]?.color}
          wordBreak={'break-word'}
          maxW="max-content"
          lineHeight={1.2}
          {...textProps}
        >
          {benefitsPresentation[benefit?.category?.name]?.name}
        </Text>
      </Flex>
      {children}
    </Box>
  );
};

export default BenefitDisplay;
