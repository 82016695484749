import { VStack, Icon, Text } from '@chakra-ui/react';
import { ElementType } from 'react';

type CardContentProps = {
  icon: ElementType;
  title: string;
  description: string;
};

const CardTypeContent = ({ icon, title, description }: CardContentProps) => {
  return (
    <VStack spacing={6} width="100%">
      <Icon as={icon} boxSize={10} strokeWidth={'1px'} />
      <Text fontWeight={600} textAlign="center">
        {title}
      </Text>
      <Text fontSize="14px" textAlign="center">
        {description}
      </Text>
    </VStack>
  );
};

export default CardTypeContent;
