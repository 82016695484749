import {
  Box,
  Button,
  Card,
  Drawer,
  DrawerBody,
  DrawerContent,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  Stepper,
  StepSeparator,
  StepStatus,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FiCheckCircle, FiCreditCard } from 'react-icons/fi';
import { z } from 'zod';
import {
  useGetCompanyNonNominalCardStock,
  useRequestNewCard,
} from '../../api/card';
import { useGetCustomerConfigs } from '../../api/configs';
import { useGetEmployeeById } from '../../api/employees';
import ContactModalButton from '../../components/ContactModalButton';
import NonNominalCardsInfo from '../../components/NonNominalCardsInfo';
import { H2 } from '../../components/Typography';
import { CardTypeEnum, NewCardReasonEnum } from '../../types/card';
import { CustomerConfig } from '../../types/config';
import { newCardSchema } from '../../types/schemas/employeeRegistrationSchema';
import CardTypeForm from '../EmployeeRegistrationPage/components/CardTypeForm';
import NoNameCardSteps from '../EmployeeRegistrationPage/components/NoNameCardSteps';
import NonNominalCardStock from '../EmployeeRegistrationPage/components/NonNominalCardStock';
import SendCardForm from '../EmployeeRegistrationPage/components/SendCardForm';
import { useLocation, useNavigate } from 'react-router-dom';
import ContactForms from '../../components/ContactForms';
import { Employee } from '../../types/employee';
import { UserHeartIcon } from '../../assets/customIcons';
import { maskCpf } from '../../utils/formatters/maskCpf';
import { useCreateAddress } from '../../api/address';
import { Address, EntityTypeEnum } from '../../types/address';
import Spinner from '../../components/Spinner';
import EmployeeBasicInfoCard from '../../components/EmployeeBasicInfoCard';
import AlertCard from '../../components/AlertCard';

type FormValues = z.infer<typeof newCardSchema>;
type ReasonType = {
  reason: string;
  description?: NewCardReasonEnum;
};

const NewCardPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const employee = location.state?.employee as Employee;
  const reason = location.state?.reason as ReasonType;
  const changeCardSuccessModal = useDisclosure();

  const { data: customerConfigArray = [], isLoading } = useGetCustomerConfigs();
  const addAddressMutation = useCreateAddress();
  const requestNewCardMutation = useRequestNewCard();
  const customerConfig = customerConfigArray[0] as CustomerConfig | undefined;
  const isNoNameCardsAllowed = !!customerConfig?.isNoNameCardsAllowed;

  const { data: nonNominalCardStock = 0 } =
    useGetCompanyNonNominalCardStock(isNoNameCardsAllowed);

  const { data: employeeData } = useGetEmployeeById(employee.id);
  const methods = useForm<FormValues>({
    resolver: zodResolver(newCardSchema),
    reValidateMode: 'onBlur',
    values: {
      collaboratorData: {
        address: {
          address: employeeData?.data?.address?.address || '',
          city: employeeData?.data?.address?.city || '',
          complement: employeeData?.data?.address?.complement || '',
          district: employeeData?.data?.address?.district || '',
          number: employeeData?.data?.address?.number || '',
          state: employeeData?.data?.address?.state || '',
          zipCode: employeeData?.data?.address?.zipCode || '',
        },
      },
    },
  });

  const cardType = methods.watch('cardType');
  const cardTypeNotSelected = isNoNameCardsAllowed && cardType === undefined;

  const shouldShowNonNominalCardColumn = isNoNameCardsAllowed;

  function onSubmit(data: FormValues) {
    const colaboratorAddresId =
      data?.collaboratorData?.address?.id || employeeData?.data?.address?.id;

    const deliveryAddressId =
      data.deliveryType === 'COMPANY'
        ? data.companyAddressId
        : colaboratorAddresId;

    const dataToSend = {
      deliveryAddressId: deliveryAddressId || '',
      deliveryType: data.deliveryType,
      reason: reason.reason as NewCardReasonEnum,
      description: reason.description,
      cardType: data.cardType || 'NOMINAL',
      cardId: employee.cardId || '',
    };

    requestNewCardMutation.mutate(
      {
        collaboratorId: employee.id,
        data: dataToSend,
      },
      {
        onSuccess: () => {
          changeCardSuccessModal.onOpen();
        },
      }
    );
  }

  async function onCreateCollaboratorAddress(address: Partial<Address>) {
    if (employeeData) {
      const response = await addAddressMutation.mutateAsync({
        address: {
          ...address,
          entityId: employeeData.data.personId || '',
          entityType: EntityTypeEnum.PERSON,
          country: 'Brasil',
        },
      });
      return response.data.id;
    }
  }

  useEffect(() => {
    methods.setValue('isNoNameCardsAllowed', isNoNameCardsAllowed);
  }, [isNoNameCardsAllowed, methods]);

  if (isLoading) {
    return (
      <Flex
        height="100vh"
        width="100vw"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner />
      </Flex>
    );
  }

  return (
    <Box paddingBottom="104px">
      <Flex justifyContent="space-between">
        <Stepper
          index={1}
          orientation="horizontal"
          gap="0"
          width="430px"
          marginBottom={6}
        >
          <Step>
            <StepIndicator bg="gray.100" fontWeight={600} marginRight={1}>
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>
            <Box>
              <Text>Justificar solicitação</Text>
              <Text>Obrigatório</Text>
            </Box>
            <Box width="32px">
              <StepSeparator />
            </Box>
          </Step>
          <Step>
            <StepIndicator
              bg="gray.100"
              border="2px solid"
              borderColor="gray.300"
              fontWeight={600}
              marginRight={1}
            >
              <StepStatus
                complete={<StepIcon />}
                incomplete={<StepNumber />}
                active={<StepNumber />}
              />
            </StepIndicator>
            <Box>
              <Text fontWeight={600}>Escolher cartão</Text>
              <Text>Obrigatório</Text>
            </Box>
          </Step>
        </Stepper>

        <EmployeeBasicInfoCard cpf={employee.cpf} fullName={employee.name} />
      </Flex>

      <FormProvider {...methods}>
        <Flex gap={5}>
          <Flex gap={5} width="100%" flexWrap={{ base: 'nowrap', xs: 'wrap' }}>
            {shouldShowNonNominalCardColumn && (
              <Flex direction="column" maxWidth="360px" gap={6}>
                <Card
                  padding={8}
                  borderRadius="lg"
                  flexGrow={1}
                  height="600px"
                  maxHeight="640px"
                >
                  <Text fontWeight={500} fontSize="20px" marginBottom={8}>
                    Escolher tipo de cartão
                  </Text>
                  <CardTypeForm />
                </Card>
                <Box>
                  <NonNominalCardStock stock={nonNominalCardStock} />
                </Box>

                {shouldShowNonNominalCardColumn && (
                  <Box>
                    <NonNominalCardsInfo />
                  </Box>
                )}
              </Flex>
            )}
            <Card padding={8} borderRadius="lg" flexGrow={1} maxWidth="100%">
              <Flex
                justifyContent="space-between"
                alignItems="flex-start"
                marginBottom={8}
              >
                {cardTypeNotSelected ? (
                  <Box />
                ) : cardType === CardTypeEnum.NOT_NOMINAL ? (
                  <Flex direction="column" gap={2} justifyContent="flex-start">
                    <H2>Entrega do cartão</H2>
                    Confira o passo a passo antes de concluir a solicitação
                  </Flex>
                ) : (
                  <Flex direction="column" gap={2} justifyContent="flex-start">
                    <H2>Selecionar local de entrega do cartão</H2>
                  </Flex>
                )}
              </Flex>

              {(cardType === CardTypeEnum.NOMINAL || !isNoNameCardsAllowed) && (
                <SendCardForm
                  onCreateCollaboratorAddress={onCreateCollaboratorAddress}
                />
              )}

              {cardType === CardTypeEnum.NOT_NOMINAL && (
                <Box>
                  <NoNameCardSteps />
                  <Text>
                    Se você precisar, você pode{' '}
                    <ContactModalButton variant="link">
                      falar com o nosso atendimento
                    </ContactModalButton>{' '}
                    para solicitar mais cartões sem nome.
                  </Text>
                </Box>
              )}

              {isNoNameCardsAllowed && cardType === undefined && (
                <Flex
                  direction="column"
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                  gap={6}
                  padding={6}
                >
                  <Icon
                    as={FiCreditCard}
                    boxSize="50px"
                    color="primary.primary"
                  />
                  <Text fontSize="28px" textAlign="center">
                    Escolha um tipo de cartão para continuar
                  </Text>
                </Flex>
              )}
            </Card>
          </Flex>
        </Flex>
      </FormProvider>
      <Drawer
        placement="bottom"
        isOpen={true}
        onClose={() => {}}
        autoFocus={false}
        blockScrollOnMount={false}
        returnFocusOnClose={false}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        trapFocus={false}
        lockFocusAcrossFrames={false}
        variant="clickThrough"
      >
        <DrawerContent height={'104px'} zIndex={1} paddingX={2}>
          <DrawerBody
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            gap={4}
          >
            <Flex gap={4}>
              {!requestNewCardMutation.isPending ? (
                <Button
                  variant="outline"
                  width="145px"
                  onClick={() => navigate('/colaboradores')}
                  isDisabled={requestNewCardMutation.isPending}
                >
                  Cancelar
                </Button>
              ) : (
                <AlertCard
                  padding={4}
                  gap={4}
                  width="fit-content"
                  iconColor="primary.primary"
                >
                  Processando a solicitação, aguarde alguns segundos
                </AlertCard>
              )}

              <Button
                onClick={() => {
                  methods.handleSubmit(onSubmit)();
                }}
                isLoading={requestNewCardMutation.isPending}
                isDisabled={isNoNameCardsAllowed && cardTypeNotSelected}
              >
                Confirmar troca de cartão
              </Button>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Modal
        isCentered
        isOpen={changeCardSuccessModal.isOpen}
        onClose={() => {}}
        size="lg"
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent padding={8}>
          <ModalHeader fontWeight={700} padding={0}>
            <Flex alignItems="center" gap={2}>
              <Icon
                as={FiCheckCircle}
                color="feedback.success"
                fontSize="24px"
              />

              <Text>Troca de cartão solicitada com sucesso!</Text>
            </Flex>
          </ModalHeader>

          <ModalBody padding={0} marginY={10}>
            {isNoNameCardsAllowed &&
              (!nonNominalCardStock || nonNominalCardStock === 0) && (
                <Box marginBottom={6}>
                  <Text marginBottom={4}>
                    Você selecionou que entregará um cartão sem nome para o
                    colaborador, mas parece que{' '}
                    <Text as="span" fontWeight={600}>
                      você não possui mais nenhum cartão sem nome em estoque.
                    </Text>
                  </Text>

                  <Text marginBottom={4}>
                    Não se preocupe!{' '}
                    <Text as="span" fontWeight={600}>
                      Entre em contato
                    </Text>{' '}
                    com o nosso atendimento em um dos canais abaixo e solicite
                    mais cartões sem nome.
                  </Text>
                  <ContactForms />
                </Box>
              )}

            <Text color="feedback.alert">
              <Text as="span" fontWeight={600}>
                O cartão atual do colaborador foi cancelado
              </Text>
              , ele já pode consultar as informações atualizadas no Aplicativo
              Raiô
            </Text>
          </ModalBody>
          <ModalFooter padding={0}>
            <Button width="100%" onClick={() => navigate('/colaboradores')}>
              Ir para o menu Colaboradores
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default NewCardPage;
