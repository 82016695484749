import {
  Box,
  Button,
  Card,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerContentProps,
  Flex,
  Icon,
  Input,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { UploadIcon } from '../../../../assets/customIcons';
import { FiClock } from 'react-icons/fi';
import Spinner from '../../../../components/Spinner';
import { useUserCompaniesStore } from '../../../../stores/useUserCompaniesStore';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { useNavigate } from 'react-router-dom';
import DromoUploader from 'dromo-uploader-react';
import { dromoStyleOverride } from '../../../../styles/dromoStyleOverride';
import { getDromoRowHooks } from '../../utils/getDromoRowHooks';
import CustomTooltip from '../../../../components/CustomTooltip';
import { getDromoFields } from '../../utils/getDromoFields';
import { CustomerConfig } from '../../../../types/config';
import { useConvertToRechargePayload } from '../../../../api/recharge';
import { EmployeeBenefitCategory } from '../../../../types/employee';

const MAX_FILE_SIZE_IN_MB = 12;

type RechargeSpreadsheetUploaderProps = {
  existingCPFs: string[];
  benefitCategories: EmployeeBenefitCategory[];
};

const UploadingSpreadsheet: React.FC = () => {
  return (
    <Flex
      border="1px dashed black"
      borderRadius="8px"
      background="#FEFEFC"
      height="100%"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap={4}
    >
      <Flex alignItems="center" gap={2}>
        <Icon as={FiClock} color="primary.primary" fontSize="24px" />
        <Text color={'gray.800'} fontWeight={'700'}>
          Importando planilha, aguarde
        </Text>
      </Flex>
      <Spinner color="primary.primary" />
    </Flex>
  );
};

const RechargeSpreadsheetUploader = ({
  existingCPFs,
  benefitCategories,
}: RechargeSpreadsheetUploaderProps) => {
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);
  const [dragOver, setDragOver] = useState(false);

  const navigate = useNavigate();
  const { selectedCompany } = useUserCompaniesStore();
  const { user } = useAuthContext();

  const dromoUploaderDisclosure = useDisclosure();

  const fileExceedsMaxSize =
    !!fileToUpload && fileToUpload.size > MAX_FILE_SIZE_IN_MB * 1024 * 1024;

  const fileInputRef = useRef<HTMLInputElement>(null);

  function handleImport() {
    fileInputRef.current?.click();
  }

  function handleFileUpload(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files?.length) {
      const file = event.target.files[0];
      setFileToUpload(file);
    }
  }

  function handleDragOver(event: React.DragEvent<HTMLDivElement>) {
    setDragOver(true);
    event.preventDefault();
  }

  function handleDrop(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    setDragOver(false);
    if (event.dataTransfer.files?.length) {
      const file = event.dataTransfer.files[0];
      setFileToUpload(file);
    }
  }

  function handleDragLeave(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    if (event.target === event.currentTarget) {
      setDragOver(false);
    }
  }

  const convertToRechargePayload = useConvertToRechargePayload();

  if (!user || !selectedCompany) {
    return <Spinner />;
  }

  return (
    <>
      <Flex gap={6} minHeight="459px" width="100%">
        <Stack width="100%" spacing={6}>
          <Card
            padding="32px"
            height="100%"
            display="flex"
            flexDirection="column"
          >
            <Text fontSize="20px" fontWeight="500" marginBottom="32px">
              Importar planilha
            </Text>

            {convertToRechargePayload.isPending ? (
              <UploadingSpreadsheet />
            ) : (
              <Flex
                border="1px dashed black"
                borderRadius="8px"
                background="#FEFEFC"
                height="100%"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                gap={4}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onDragLeave={handleDragLeave}
              >
                <Input
                  type="file"
                  style={{ display: 'none' }}
                  accept=".xlsx, .csv"
                  ref={fileInputRef}
                  onChange={handleFileUpload}
                />
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  color="primary.primary"
                  width="380px"
                  gap={2}
                  padding={4}
                  cursor="pointer"
                  onClick={handleImport}
                >
                  <UploadIcon
                    width="20px"
                    height="20px"
                    color={
                      dragOver
                        ? 'primary.primary'
                        : fileToUpload
                          ? 'gray.800'
                          : 'primary.primary'
                    }
                  />
                  {dragOver ? (
                    <Text
                      fontWeight={500}
                      textDecoration="underline"
                      textAlign="center"
                    >
                      Solte o arquivo aqui
                    </Text>
                  ) : (
                    <>
                      {fileToUpload ? (
                        <Text
                          fontWeight={500}
                          textDecoration="underline"
                          textAlign="center"
                          color="gray.800"
                        >
                          {fileToUpload.name}
                        </Text>
                      ) : (
                        <Text
                          fontWeight={500}
                          textDecoration="underline"
                          textAlign="center"
                        >
                          Clique aqui ou arraste o arquivo no formato XLSX ou
                          CSV UTF-8
                        </Text>
                      )}
                    </>
                  )}
                </Flex>
                {fileToUpload ? (
                  <>
                    <Text
                      fontSize="16px"
                      onClick={handleImport}
                      cursor="pointer"
                      padding={2}
                      maxWidth="258px"
                      textAlign="center"
                      lineHeight="1.3"
                    >
                      clique aqui ou arraste um novo arquivo para substituir
                    </Text>
                    {fileExceedsMaxSize && (
                      <Text
                        fontWeight={600}
                        color="feedback.alert"
                        fontSize="14px"
                      >
                        Tamanho máximo: {MAX_FILE_SIZE_IN_MB}MB
                      </Text>
                    )}
                  </>
                ) : (
                  <Text fontSize="14px">
                    Tamanho máximo: {MAX_FILE_SIZE_IN_MB}MB
                  </Text>
                )}
              </Flex>
            )}
          </Card>
        </Stack>
      </Flex>

      <Drawer
        placement="bottom"
        isOpen={true}
        onClose={() => {}}
        autoFocus={false}
        blockScrollOnMount={false}
        returnFocusOnClose={false}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        trapFocus={false}
        lockFocusAcrossFrames={false}
        variant="clickThrough"
      >
        <DrawerContent height={'120px'} zIndex={1}>
          <DrawerBody
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            gap={4}
          >
            <Button
              isDisabled={convertToRechargePayload.isPending}
              variant="outline"
              width="fit-content"
              onClick={() => navigate('/recargas')}
            >
              Voltar
            </Button>
            <CustomTooltip
              label={
                fileToUpload
                  ? ''
                  : 'Por favor, selecione um arquivo de planilha para importar'
              }
            >
              <Button
                isDisabled={
                  !fileToUpload ||
                  fileExceedsMaxSize ||
                  convertToRechargePayload.isPending
                }
                onClick={dromoUploaderDisclosure.onOpen}
              >
                Importar planilha
              </Button>
            </CustomTooltip>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <DromoUploader
        licenseKey={DROMO_LICENSE_KEY || ''}
        fields={getDromoFields(benefitCategories)}
        settings={{
          importIdentifier: `recharge-import-${selectedCompany?.id}-${fileToUpload?.name}`,
          developmentMode: SENTRY_ENVIRONMENT !== 'production',
          locale: 'pt',
          initialFile: fileToUpload,
          backendSyncMode: 'MAPPINGS_ONLY',
          autoMapHeaders: true,
          invalidDataBehavior: 'BLOCK_SUBMIT',
          styleOverrides: dromoStyleOverride,
        }}
        user={{
          id: user?.id,
          name: user.fullName,
          email: user.username,
          companyId: selectedCompany.id,
          companyName: selectedCompany.tradeName,
        }}
        columnHooks={[
          {
            fieldName: 'document',
            callback: (cells) => {
              return cells.map((cell) => ({
                ...cell,
                value: cell.value.replace(/\D/g, ''),
              }));
            },
          },
        ]}
        rowHooks={getDromoRowHooks(existingCPFs)}
        onResults={(response) => {
          convertToRechargePayload.mutate({ rechargeData: response });
          dromoUploaderDisclosure.onClose();
        }}
        open={dromoUploaderDisclosure.isOpen}
        onCancel={dromoUploaderDisclosure.onClose}
      />
    </>
  );
};

export default RechargeSpreadsheetUploader;
