import { Box, useFormControl } from '@chakra-ui/react';
import { ForwardedRef, forwardRef } from 'react';
import ReactSelect, {
  CSSObjectWithLabel,
  GroupBase,
  Props as ReactSelectProps,
  SelectInstance,
} from 'react-select';
import CustomTooltip from '../CustomTooltip';

type Variant = 'outline' | 'ghost';

function getBorderColor(
  state: { isDisabled: boolean; isFocused: boolean },
  variant: Variant,
  ariaInvalid?: boolean
): string {
  if (variant === 'ghost') return '1px solid transparent';
  if (ariaInvalid) return '1px solid var(--chakra-colors-red-500)';
  if (state.isDisabled) return '1px solid transparent';
  if (state.isFocused) return '1px solid var(--chakra-colors-orange-500)';
  return '1px solid var(--chakra-colors-gray-300)';
}

const Select = forwardRef(
  <
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>,
  >(
    props: ReactSelectProps<Option, IsMulti, Group> & {
      width?: CSSObjectWithLabel['width'];
      height?: CSSObjectWithLabel['height'];
      variant?: Variant;
      tooltipLabel?: string;
      disabledTooltip?: boolean;
      maxWidth?: string;
      minWidth?: string;
    },
    ref: ForwardedRef<SelectInstance<Option, IsMulti, Group>>
  ) => {
    const { 'aria-invalid': ariaInvalid } = useFormControl(props);
    const { width = '100%', height = '56px' } = props;

    const variant = props.variant || 'outline';

    return (
      <CustomTooltip
        label={props.tooltipLabel}
        isDisabled={props.tooltipLabel ? props.disabledTooltip : true}
      >
        <Box>
          <ReactSelect
            ref={ref}
            noOptionsMessage={() => 'Nenhuma opção encontrada'}
            placeholder="Selecione"
            menuPlacement="auto"
            components={{ IndicatorSeparator: null }}
            styles={{
              menu: (provided) => ({
                ...provided,
                zIndex: '11',
              }),
              control: (provided, state) => ({
                ...provided,
                background: state.isDisabled
                  ? '#f0f0f0'
                  : variant === 'ghost'
                    ? 'transparent'
                    : 'var(--chakra-colors-gray-base_white)',
                minHeight: height,
                width: width,
                maxWidth: props.maxWidth,
                minWidth: props.minWidth,
                borderRadius: '8px',
                cursor: 'pointer',
                boxShadow: ariaInvalid ? '0 0 0 1px #e53e3e' : 'unset',
                border: getBorderColor(state, variant, ariaInvalid),
                outline: state.isFocused
                  ? '3px solid var(--chakra-colors-orange-500)'
                  : 'none',
                outlineOffset: '-2px',
              }),
              placeholder: (provided) => ({
                ...provided,
                color: 'var(--chakra-colors-gray-900)',
              }),
              option: (provided, state) => ({
                ...provided,
                fontWeight: state.isSelected ? 'bold' : 'normal',
                backgroundColor: state.isSelected
                  ? 'var(--chakra-colors-orange-500)'
                  : state.isFocused
                    ? 'var(--chakra-colors-orange-50)'
                    : 'unset',
                '& > * > *': {
                  color: state.isSelected
                    ? 'var(--chakra-colors-gray-50)'
                    : undefined,
                },
                '&:active': {
                  backgroundColor: 'unset',
                },
              }),
              multiValue: (provided) => ({
                ...provided,
                borderRadius: '8px',
                padding: '2px 6px',
              }),
              multiValueLabel: (provided) => ({
                ...provided,
              }),
              multiValueRemove: (provided) => ({
                ...provided,
                position: 'relative',
                left: '6px',
                marginTop: '-2px',
                marginBottom: '-2px',
                borderRadius: '0px 8px 8px 0px',
                padding: '2px 10px 2px 8px',
              }),
            }}
            {...props}
          />
        </Box>
      </CustomTooltip>
    );
  }
);

export default Select;
