import { z } from 'zod';
import { employeeNameRegex } from '../../utils/validations/regex';
import { useAuthContext } from '../../hooks/useAuthContext';
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { useUserCompaniesStore } from '../../stores/useUserCompaniesStore';
import { usePatchUser } from '../../api/users';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorMessage } from '@hookform/error-message';

const NAME_REQUIRED_MESSAGE = 'Você precisa preencher seu nome de usuário.';

type EditProflieFormsProps = {
  onClose: () => void;
};

const schema = z.object({
  fullName: z
    .string({
      required_error: NAME_REQUIRED_MESSAGE,
    })
    .min(1, NAME_REQUIRED_MESSAGE)
    .refine(
      (value) => employeeNameRegex.test(value),
      'O nome do usuário só pode conter letras e precisa de pelo menos um sobrenome.'
    ),
  username: z
    .string({ required_error: 'Campo obrigatório' })
    .email({ message: 'E-mail inválido' }),
});

type FormValues = z.infer<typeof schema>;

const EditProfileForms = ({ onClose }: EditProflieFormsProps) => {
  const { authState, setAuthInfo, isOwner } = useAuthContext();

  const { user } = authState;
  const usePatchUserMutation = usePatchUser({
    userId: user?.id || '',
    successMessage: 'Dados atualizados com sucesso!',
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      username: user?.username,
      fullName: user?.fullName,
    },
  });

  if (!user) {
    return null;
  }

  function handleSave(values: FormValues) {
    usePatchUserMutation.mutate(
      {
        user: {
          username: values?.username || '',
          fullName: values?.fullName || '',
        },
      },
      {
        onSuccess: () => {
          if (!authState.user) {
            return;
          }

          setAuthInfo({
            ...authState,
            user: {
              ...authState.user,
              username: values?.username || '',
              fullName: values?.fullName.trim() || '',
            },
          });
          onClose();
        },
      }
    );
  }

  return (
    <>
      <form onSubmit={handleSubmit(handleSave)}>
        <Flex gap={4} flexDir={'column'}>
          <FormControl isInvalid={!!errors.fullName}>
            <FormLabel>Nome</FormLabel>
            <Input {...register('fullName')} />
            <FormErrorMessage>
              <ErrorMessage name="fullName" errors={errors} />
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.username}>
            <FormLabel>E-mail</FormLabel>
            <Input
              type="email"
              {...register('username')}
              isDisabled={isOwner}
            />
            <FormErrorMessage>
              <ErrorMessage name="username" errors={errors} />
            </FormErrorMessage>
          </FormControl>
        </Flex>
        <Flex alignItems={'center'} gap="2" marginTop="10">
          <Button variant={'outline'} onClick={onClose}>
            Cancelar
          </Button>
          <Button type="submit" isLoading={usePatchUserMutation.isPending}>
            Salvar alterações
          </Button>
        </Flex>
      </form>
    </>
  );
};

export default EditProfileForms;
