import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link as ChakraLink,
  Text,
  VStack,
  VisuallyHidden,
  useToast,
  Heading,
} from '@chakra-ui/react';
import { Link, Navigate, useLocation } from 'react-router-dom';
import Title from '../../components/Title';
import AccessLayout from '../../layouts/AccessLayout';
import { ErrorMessage } from '@hookform/error-message';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useEffect, useState } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useFetchContext } from '../../hooks/useFetchContext';
import { useMutation } from '@tanstack/react-query';
import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import ContactModalButton from '../../components/ContactModalButton';
import { AuthState } from '../../types/auth';
import { HourglassIcon } from '../../assets/customIcons/HourglassIcon';

const FIELD_REQUIRED_MESSAGE = 'Você precisa preencher este campo.';
const TIMER_TO_RESEND_EMAIL: number = 10;

const schema = z.object({
  email: z
    .string({ required_error: FIELD_REQUIRED_MESSAGE })
    .email('E-mail inválido'),
});

type FormValues = z.infer<typeof schema>;

const RequestPasswordChange = () => {
  const [showTimer, setShowTimer] = useState(false);
  const [timer, setTimer] = useState(TIMER_TO_RESEND_EMAIL);

  const location = useLocation();

  const emailFromLoginPage = location.state?.emailFromLoginPage as
    | string
    | undefined;

  const { isAuthenticated } = useAuthContext();

  const { formState, register, handleSubmit } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: {
      email: emailFromLoginPage,
    },
  });

  const { api } = useFetchContext();
  const toast = useToast();

  const requestPasswordChange = useMutation({
    mutationFn: ({ email }: { email: string }) =>
      api.post<AuthState>(`/api/auth/${email}/forgot-password`),
    onSuccess: () => {
      setShowTimer(true);
      toast({
        title: 'Requisição feita com sucesso!',
        description:
          'Acabamos de enviar um email com as instruções para redefinir sua senha.',
        status: 'success',
      });
    },
    onError: () => {
      toast({
        title: 'Ocorreu um erro na solicitação!',
        description: 'Por favor, tente novamente.',
        status: 'error',
      });
    },
  });

  useEffect(() => {
    if (!showTimer) {
      return;
    }
    const intervalId = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      } else {
        setShowTimer(false);
        setTimer(TIMER_TO_RESEND_EMAIL);
        clearInterval(intervalId);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timer, showTimer]);

  const isAuthed = isAuthenticated();
  if (isAuthed) {
    return <Navigate to="/" />;
  }

  const { errors } = formState;

  const formattedTime = format(new Date(0, 0, 0, 0, 0, timer), 'mm:ss');

  return (
    <AccessLayout variant="passwordReset">
      <Box
        paddingX={{
          base: '20px',
          md: '80px',
        }}
      >
        <Card
          backgroundColor="gray.50"
          width={{
            base: '100%',
            md: '566px',
          }}
          boxShadow="none"
          position="relative"
          minWidth="327px"
        >
          <HourglassIcon
            position="absolute"
            top="-40px"
            right="-40px"
            display={{
              base: 'none',
              md: 'block',
            }}
          />
          <CardHeader>
            <Heading fontWeight={600} fontSize="24px">
              Alterar senha
            </Heading>
            <Text paddingTop="8" fontSize="md">
              Vamos enviar um e-mail para você com um link para alteração de
              senha.
            </Text>
          </CardHeader>
          <form
            onSubmit={handleSubmit((data) =>
              requestPasswordChange.mutate(data)
            )}
          >
            <CardBody paddingTop={0}>
              <VStack spacing={8}>
                <ErrorMessage
                  errors={errors}
                  name={'email' || 'password'}
                  message="Parece que seu e-mail ou senha estão incorretos."
                  render={({ message }) => (
                    <Text width="100%" textAlign="left" color="feedback.alert">
                      {message}
                    </Text>
                  )}
                />
                <FormControl isInvalid={!!errors.email}>
                  <VisuallyHidden>
                    <FormLabel>Email</FormLabel>
                  </VisuallyHidden>
                  <FormLabel fontSize="14px">Qual o seu e-mail?</FormLabel>
                  <Input
                    required
                    type="email"
                    autoComplete="email"
                    placeholder="Digite o seu e-mail"
                    {...register('email')}
                  />
                  <FormErrorMessage>
                    <ErrorMessage name="email" errors={errors} />
                  </FormErrorMessage>
                </FormControl>
                <Text
                  as="span"
                  color="primary.primary"
                  width="100%"
                  textAlign="right"
                  marginTop={-4}
                  fontSize="14px"
                >
                  <ChakraLink
                    as={Link}
                    to="/login"
                    textDecor="underline"
                    fontWeight={500}
                  >
                    Lembrei minha senha
                  </ChakraLink>
                </Text>
                <Button
                  data-testid="submit-button"
                  type="submit"
                  width="100%"
                  isDisabled={showTimer}
                  isLoading={requestPasswordChange.isPending}
                >
                  {requestPasswordChange.isSuccess
                    ? 'Reenviar email'
                    : 'Enviar email'}
                </Button>

                {showTimer && (
                  <Text fontSize="16px" fontWeight={400}>
                    Não recebeu o email? Você pode solicitar outro em{' '}
                    {formattedTime}
                  </Text>
                )}
              </VStack>
            </CardBody>
          </form>
        </Card>

        <Text textAlign="center" marginTop={4} fontSize="14px">
          Ainda não tem cadastro? <ContactModalButton fontSize="14px" />
        </Text>
      </Box>
    </AccessLayout>
  );
};

export default RequestPasswordChange;
