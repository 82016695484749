import { Box, Center, Icon, Stack, Text, VStack } from '@chakra-ui/react';
import { FiList } from 'react-icons/fi';

const FieldInstructionsEmptyState = () => {
  return (
    <Center height="75vh">
      <VStack>
        <Icon as={FiList} boxSize="56px" color="primary.primary" />
        <Text textAlign="center" fontSize="28px" lineHeight="1.3" width="590px">
          Clique em uma coluna ao lado para ver as instruções de preenchimento
        </Text>
      </VStack>
    </Center>
  );
};

export default FieldInstructionsEmptyState;
