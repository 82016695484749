import {
  Box,
  Button,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFetchContext } from '../../hooks/useFetchContext';
import { Address } from '../../types/address';

type DeleteAddressModalProps = {
  isOpen: boolean;
  onClose: () => void;
  triggerDeleteAddress: (id: string) => void;
  address: Address;
};

const DeleteAddressModal = (props: DeleteAddressModalProps) => {
  const { address, isOpen, onClose, triggerDeleteAddress } = props;

  const { name, id } = address;
  const queryClient = useQueryClient();
  const toast = useToast();
  const { api } = useFetchContext();
  const deleteAddress = useMutation({
    mutationFn: ({ id }: { id: string }) => {
      return api.delete(`/api/addresses/${id}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['company-addresses'],
      });

      toast({
        title: 'Endereço excluído com sucesso!',
        status: 'success',
      });
      onClose();
    },
    onError: () => {
      toast({
        title: 'Tivemos um problema para excluir o endereço no momento',
        description: 'PTente de novo daqui a pouco',
        status: 'error',
      });
    },
  });

  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onClose} closeOnEsc size="lg">
        <ModalOverlay />
        <ModalContent>
          <Box
            width="100%"
            height="100%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            padding="16px 32px"
          >
            <Box>
              <Text
                fontSize="20px"
                fontWeight="700"
                lineHeight="130%"
                marginBottom={10}
                width="90%"
              >
                Deseja realmente excluir o endereço {name}?
              </Text>

              <Text>
                Se você tiver solicitado algum cartão para este endereço, a
                entrega ainda será feita nele{' '}
              </Text>
            </Box>
            <ModalCloseButton />

            <Box
              marginTop="30px"
              display="flex"
              flexDirection="row"
              justifyContent="space-around"
              alignItems="center"
            >
              <Button
                width="150px"
                height="56px"
                variant={'outline'}
                border="1px solid orange"
                color="orange"
                onClick={() => {
                  onClose();
                }}
              >
                Cancelar
              </Button>
              <Button
                isLoading={deleteAddress?.isPending}
                width="250px"
                height="56px"
                marginLeft={10}
                onClick={() => {
                  deleteAddress.mutate({
                    id: id,
                  });
                  triggerDeleteAddress(id);
                }}
              >
                Excluir
              </Button>
            </Box>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeleteAddressModal;
