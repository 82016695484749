import { Box, Text } from '@chakra-ui/react';

import { BulbIcon } from '../../../../assets/customIcons';
import { Semibold } from '../../../../components/Typography';

const RechargeHelpInfo = () => {
  return (
    <Box
      background="light.orange"
      maxHeight={'75px'}
      display="flex"
      alignItems="center"
      padding={{ base: '12px 16px', md: '12px 16px', lg: '10px ' }}
      borderRadius="lg"
      fontSize="20px"
      lineHeight="130%"
    >
      <BulbIcon mr={3} color="primary.primary" />
      <Text>
        Para fazer uma <Semibold>recarga</Semibold>, comece selecionando os
        colaboradores
      </Text>
    </Box>
  );
};

export default RechargeHelpInfo;
