import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  Flex,
  FormControl,
  Radio,
  RadioGroup,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import HelpMessage from '../../components/HelpMessage';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
  usePatchCustomerConfigs,
  usePostCustomerConfigs,
} from '../../api/configs';
import { CustomerConfig } from '../../types/config';
import { RechargeVisibilityEnum } from '../../types/recharge';
import DiscardChangesModal from './DiscardChangesModal';
import ExitWithoutSaveModal from './ExitWithoutSaveModal';
import { useEffect } from 'react';
import { useUserCompaniesStore } from '../../stores/useUserCompaniesStore';

const RechargeConfigsSchema = z.object({
  configs: z.object({
    rechargeVisibilityConfig: z.nativeEnum(RechargeVisibilityEnum).optional(),
  }),
});

type FormValues = z.infer<typeof RechargeConfigsSchema>;

const RechargeConfigs = ({
  customerConfig,
  tabsIndex,
}: {
  customerConfig?: CustomerConfig;
  tabsIndex: number;
}) => {
  const patchCustomerConfigsMutation = usePatchCustomerConfigs();
  const postCustomerConfigsMutation = usePostCustomerConfigs();
  const { selectedCompany } = useUserCompaniesStore();
  const discardChangesModal = useDisclosure();
  const exitWithoutSavingModal = useDisclosure();
  const defaultValues = {
    configs: {
      rechargeVisibilityConfig: customerConfig?.rechargeVisibilityConfig,
    },
  };

  const onSubmit = (data: FormValues) => {
    if (customerConfig?.id) {
      patchCustomerConfigsMutation.mutate({
        data: {
          rechargeVisibilityConfig: data.configs.rechargeVisibilityConfig,
        },
        id: customerConfig.id || '',
      });
      reset({
        configs: {
          rechargeVisibilityConfig: data.configs.rechargeVisibilityConfig,
        },
      });
    } else {
      postCustomerConfigsMutation.mutate({
        data: {
          rechargeVisibilityConfig: data.configs.rechargeVisibilityConfig,
          customerId: selectedCompany?.customerId,
        },
      });
      reset({
        configs: {
          rechargeVisibilityConfig: data.configs.rechargeVisibilityConfig,
        },
      });
    }
  };

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    getValues,
    reset,
  } = useForm<FormValues>({
    resolver: zodResolver(RechargeConfigsSchema),
    defaultValues: defaultValues,
  });
  useEffect(() => {
    if (isDirty && tabsIndex !== 2 && exitWithoutSavingModal.isOpen === false) {
      exitWithoutSavingModal.onOpen();
    }

    // removed exitWithoutSavingModal because it causes the modal to open twice.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabsIndex, isDirty]);

  return (
    <>
      <form>
        <FormControl name="configs.rechargeVisibilityConfig" as="fieldset">
          <Text
            fontSize={'20px'}
            fontWeight={'500'}
            lineHeight={'130%'}
            marginBottom={8}
          >
            Informações da próxima recarga
          </Text>
          <Flex gap={1} marginBottom={4}>
            <Text>
              Quando devemos disponibilizar aos colaboradores da sua empresa as
              informações da próxima recarga no Aplicativo Raiô?
            </Text>
            <HelpMessage label="O colaborador irá visualizar uma mensagem na página inicial com as informações da próxima recarga" />
          </Flex>
          <Controller
            control={control}
            name="configs.rechargeVisibilityConfig"
            render={({ field: { onChange, value } }) => (
              <RadioGroup onChange={onChange} value={value}>
                <Stack>
                  <Flex gap={1}>
                    <Radio value={RechargeVisibilityEnum.CREATED}>
                      Desde o momento em que a recarga foi criada
                    </Radio>
                    <HelpMessage label="Nesta opção, é exibida a data de crédito prevista, mesmo enquanto o Boleto/Pix não foi pago" />
                  </Flex>

                  <Flex gap={1}>
                    <Radio value={RechargeVisibilityEnum.PAID}>
                      Só quando o Boleto/Pix for pago
                    </Radio>
                    <HelpMessage label="Nesta opção, é exibida a data de crédito e o valor de cada benefício, a partir da confirmação do pagamento do Boleto/Pix" />
                  </Flex>

                  <Flex gap={1}>
                    <Radio value={RechargeVisibilityEnum.NEVER}>
                      Não mostrar essa informação
                    </Radio>
                    <HelpMessage label="Nesta opção, não é exibida nenhuma informação sobre a próxima recarga" />
                  </Flex>
                </Stack>
              </RadioGroup>
            )}
          />
        </FormControl>

        <Drawer
          isOpen={isDirty || patchCustomerConfigsMutation.isPending}
          placement="bottom"
          onClose={() => {}}
          variant="clickThrough"
          autoFocus={false}
          blockScrollOnMount={false}
          returnFocusOnClose={false}
          closeOnEsc={false}
          closeOnOverlayClick={false}
          trapFocus={false}
          lockFocusAcrossFrames={false}
        >
          <DrawerContent padding="24px 32px">
            <DrawerBody
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              gap={4}
              padding={0}
            >
              <Button
                variant="outline"
                width="fit-content"
                onClick={() => {
                  discardChangesModal.onOpen();
                }}
              >
                Cancelar
              </Button>
              <Button
                width="fit-content"
                onClick={() => {
                  const values = getValues();
                  handleSubmit(() => onSubmit(values))();
                }}
                isLoading={patchCustomerConfigsMutation.isPending}
              >
                Salvar alterações
              </Button>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        {discardChangesModal.isOpen && (
          <DiscardChangesModal
            isOpen={true}
            onClose={discardChangesModal.onClose}
            onDiscardChanges={() => {
              reset(defaultValues);
              discardChangesModal.onClose();
            }}
          />
        )}

        {exitWithoutSavingModal.isOpen && (
          <ExitWithoutSaveModal
            isOpen={exitWithoutSavingModal.isOpen}
            onClose={() => {
              const values = getValues();
              handleSubmit(() => onSubmit(values))();
              exitWithoutSavingModal.onClose();
            }}
            onDiscardChanges={() => {
              exitWithoutSavingModal.onClose();
              reset(defaultValues);
            }}
          />
        )}
      </form>
    </>
  );
};

export default RechargeConfigs;
