import { Flex, Input, Text } from '@chakra-ui/react';
import { NumberFormatBase } from 'react-number-format';
import { formatCurrencyInCents } from '../../../../utils/formatters/formatCurrency';

const ReviewCurrencyInput = ({ value }: { value: number }) => {
  function formatValue() {
    if (value) {
      return formatCurrencyInCents(value).replace('R$', '').trim();
    }
    return '-       ';
  }

  return (
    <Flex position="relative">
      <Text position="absolute" top="11px" left="17px" zIndex="1">
        R$
      </Text>
      <NumberFormatBase
        border="none"
        textAlign="right"
        isReadOnly
        maxH="40px"
        maxWidth="140px"
        valueIsNumericString={false}
        backgroundColor="gray.100"
        format={formatValue}
        customInput={Input}
        _focusWithin={{
          zIndex: 'unset',
        }}
      />
    </Flex>
  );
};

export default ReviewCurrencyInput;
