import { Icon, IconProps, forwardRef } from '@chakra-ui/react';

export const EyeCloseIcon = forwardRef<IconProps, 'svg'>((props, ref) => {
  return (
    <Icon ref={ref} viewBox="0 0 16 16" {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M12.603 23.99a3.117 3.117 0 0 0 1.65-.634c.114-.088 2.414-2.43 2.625-2.675a1.73 1.73 0 0 0 .27-.474c.069-.196.08-.586.022-.8a1.501 1.501 0 0 0-.483-.768c-.288-.253-.585-.376-1.007-.422a1.91 1.91 0 0 0-1.285.328c-.065.048-.464.436-.887.86-.749.756-.769.774-.913.838a.856.856 0 0 1-.901-.111c-.047-.04-.4-.389-.788-.775a24.27 24.27 0 0 0-.808-.784c-.542-.42-1.368-.484-1.985-.152-.175.093-.42.3-.53.445a1.73 1.73 0 0 0-.262.516c-.057.195-.06.546-.008.733.043.158.169.403.266.528.087.11 2.164 2.253 2.36 2.437a3.42 3.42 0 0 0 1.58.826c.353.08.76.112 1.084.084Z" />
          <path
            fillRule="evenodd"
            d="M3.917 12.383a13.889 13.889 0 0 1-1.006-1.43 4.062 4.062 0 0 1 0-4.271c1.283-2.09 4.203-5.598 9.327-5.598 2.508 0 4.487.84 6.006 1.942L21.625.818a1 1 0 1 1 1.094 1.674l-2.913 1.902c.768.8 1.348 1.617 1.76 2.288a4.062 4.062 0 0 1 0 4.272c-1.284 2.09-4.203 5.598-9.328 5.598-3.05 0-5.32-1.244-6.928-2.69l-2.867 1.872a1 1 0 1 1-1.093-1.675l2.567-1.676Z"
            clipRule="evenodd"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M24 24H0V0h24z" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
});
