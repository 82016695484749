import { Box, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import {
  FiAlertCircle,
  FiCheckCircle,
  FiEdit2,
  FiMail,
  FiMapPin,
  FiType,
  FiUser,
} from 'react-icons/fi';
import { Semibold } from '../../../components/Typography';
import { BuildingIcon, HomeIcon } from '../../../assets/customIcons';

export type Required = 'required' | 'optional' | 'maybe';

export const FIELD_KEYS = [
  'fullName',
  'cpf',
  'cellphone',
  'email',
  'deliveryAddressType',
  'deliveryAddressName',
  'identificationNumber',
  'admissionDate',
  'employeeAddress',
  'benefitPackage',
  'socialName',
] as const;

export type FieldKeys = (typeof FIELD_KEYS)[number];

type FillingTips = {
  [key in FieldKeys]: {
    fieldTitle: string;
    required: Required;
    example: string;
    tips: {
      icon: React.ElementType;
      content: React.ReactNode;
    }[];
    faq: {
      question: string;
      answers: React.ReactNode[];
    }[];
  };
};

export const fillingTips: FillingTips = {
  fullName: {
    fieldTitle: 'Nome completo',
    required: 'required',
    example: 'Ana Santos',
    tips: [
      {
        icon: FiUser,
        content: (
          <Text>
            Informe o nome com pelo menos <Semibold>um sobrenome</Semibold>
          </Text>
        ),
      },
      {
        icon: FiType,
        content: (
          <Text>
            Use <Semibold>somente letras</Semibold>
          </Text>
        ),
      },
    ],
    faq: [
      {
        question: 'Posso usar o nome social do colaborador nesta coluna?',
        answers: [
          'Não, a coluna nome completo deve ter o nome registrado no documento do colaborador. Se o colaborador tiver um nome social, informe no campo Nome social.',
        ],
      },
    ],
  },
  cpf: {
    fieldTitle: 'CPF',
    required: 'required',
    example: '123.456.789-00',
    tips: [
      {
        icon: FiAlertCircle,
        content: (
          <Text>
            <Semibold>Cuidado com CPFs que começam com zero.</Semibold> Se a
            planilha formatar como "Número", os zeros iniciais serão removidos.
            Use o formato "Texto" para evitar isso
          </Text>
        ),
      },
      {
        icon: FiEdit2,
        content: (
          <Text>
            Você pode preencher <Semibold>com pontos e hífen</Semibold> (por
            exemplo, ”123.123.123-12”) ou <Semibold>sem</Semibold> (por exemplo,
            “12312312312”)
          </Text>
        ),
      },
    ],
    faq: [],
  },
  cellphone: {
    fieldTitle: 'Celular com DDD',
    required: 'maybe',
    example: '11999999999',
    tips: [
      {
        icon: FiAlertCircle,
        content: (
          <Text>
            <Semibold>Informe um celular ou um e-mail.</Semibold> Se os seus
            colaboradores não têm e-mail, informe um celular. Você também pode
            informar os dois
          </Text>
        ),
      },
      {
        icon: FiMapPin,
        content: <Text>Informe o celular com o DDD (código de área)</Text>,
      },
      {
        icon: FiEdit2,
        content: (
          <Text>
            Você pode preencher <Semibold>com caracteres especiais</Semibold>{' '}
            (por exemplo, "(11) 99999-9999") <Semibold>ou sem</Semibold> (por
            exemplo, "11999999999")
          </Text>
        ),
      },
    ],
    faq: [
      {
        question: 'Por que é necessário cadastrar um celular ou e-mail?',
        answers: [
          'Durante o primeiro acesso ao Aplicativo Raiô, o colaborador receberá um código por SMS ou por e-mail para validar o seu acesso.',
          'Se ambos os contatos forem cadastrados, o código será enviado primeiro por SMS. Se o colaborador não receber, ele pode pedir o reenvio por e-mail.',
        ],
      },
    ],
  },
  email: {
    fieldTitle: 'E-mail',
    required: 'maybe',
    example: 'ana.santos@empresa.com.br',
    tips: [
      {
        icon: FiAlertCircle,
        content: (
          <Text>
            <Semibold>Informe um celular ou um e-mail.</Semibold> Se os seus
            colaboradores não têm e-mail, informe um celular. Você também pode
            informar os dois
          </Text>
        ),
      },
      {
        icon: FiMail,
        content: (
          <Text>
            Informe o <Semibold>e-mail corporativo</Semibold> dos colaboradores.
            Se não tiverem, informe o e-mail pessoal
          </Text>
        ),
      },
    ],
    faq: [
      {
        question:
          'Por que o cadastro de um meio de contato (celular ou e-mail) é necessário?',
        answers: [
          'Durante o primeiro acesso ao Aplicativo Raiô, o colaborador receberá um código por SMS ou por e-mail para validar o seu acesso.',
          'Se ambos os meios de contato forem cadastrados, primeiro vamos tentar enviar o código por SMS. Se o colaborador não receber, ele poderá solicitar o reenvio por e-mail.',
        ],
      },
    ],
  },
  deliveryAddressType: {
    fieldTitle: 'Local de entrega do cartão',
    required: 'maybe',
    example: 'Empresa ou colaborador',
    tips: [
      {
        icon: BuildingIcon,
        content: (
          <Text>
            Se a entrega do cartão for em um endereço da empresa, preencha com{' '}
            <Semibold>"Empresa"</Semibold>
          </Text>
        ),
      },
      {
        icon: HomeIcon,
        content: (
          <Text>
            Se a entrega do cartão for no endereço do colaborador, preencha com{' '}
            <Semibold>"Colaborador"</Semibold>
          </Text>
        ),
      },
    ],
    faq: [
      {
        question:
          'Posso entregar alguns cartões na empresa e outros direto aos colaboradores?',
        answers: [
          'Sim, é só você definir o local de entrega correspondente a cada colaborador na planilha',
        ],
      },
      {
        question:
          'Minha empresa tem vários endereços. Como eu indico em qual será a entrega?',
        answers: [
          'Use a coluna "Nome do endereço de entrega do cartão" para definir o endereço de entrega. Se não preenchida, os cartões serão entregues no endereço fiscal da empresa cadastrado no Portal',
          'Preencha também a coluna "Local de entrega do cartão" com "Empresa"',
        ],
      },
      {
        question:
          'Preciso preencher esta coluna se vou entregar cartões sem nome?',
        answers: [
          'Não, preencha esta coluna apenas para entregar cartões com nome. Se os cartões sem nome já foram encomendados, deixe "Local de entrega do cartão" em branco',
        ],
      },
    ],
  },
  deliveryAddressName: {
    fieldTitle: 'Nome do endereço de entrega do cartão',
    required: 'maybe',
    example: 'Filial São Paulo',
    tips: [
      {
        icon: FiAlertCircle,
        content: (
          <Text>
            Esta coluna só deve ser preenchida quando{' '}
            <Semibold>o local de entrega é na empresa</Semibold> e a empresa tem{' '}
            <Semibold>mais de um endereço cadastrado na Raiô</Semibold>
          </Text>
        ),
      },
      {
        icon: BuildingIcon,
        content: (
          <Text>
            <Semibold>Informe</Semibold> o nome do endereço de entrega{' '}
            <Semibold>exatamente como você cadastrou no Portal</Semibold>. Você
            pode ver a lista de endereços cadastrados abaixo e copiar os nomes
          </Text>
        ),
      },
    ],
    faq: [
      {
        question: 'Qual é um exemplo de uso desta coluna?',
        answers: [
          'Uma empresa que possui filiais em várias cidades poderia usar esta coluna para definir em qual filial o cartão de cada colaborador deve ser entregue',
        ],
      },
      {
        question:
          'Posso escolher endereços diferentes para colaboradores diferentes?',
        answers: [
          'Sim, é só você preencher o nome do endereço de entrega do cartão correspondente a cada colaborador na planilha',
        ],
      },
    ],
  },
  identificationNumber: {
    fieldTitle: 'Matrícula do colaborador',
    required: 'optional',
    example: '01BR',
    tips: [
      {
        icon: FiAlertCircle,
        content: (
          <Text>
            Cuidado com matrículas que começam com zero. Se a planilha formatar
            como "Número", os zeros iniciais serão removidos. Use o formato
            "Texto" para evitar isso
          </Text>
        ),
      },
      {
        icon: FiUser,
        content: (
          <Text>
            A matrícula é um{' '}
            <Semibold>código único que identifica o colaborador</Semibold>{' '}
            internamente na empresa
          </Text>
        ),
      },
    ],
    faq: [],
  },
  admissionDate: {
    fieldTitle: 'Data de admissão',
    required: 'optional',
    example: '01/01/2024',
    tips: [
      {
        icon: FiEdit2,
        content: (
          <Text>
            Informe a data de admissão no{' '}
            <Semibold>formato DD/MM/AAAA</Semibold> (por exemplo, "22/01/2024")
          </Text>
        ),
      },
      {
        icon: FiAlertCircle,
        content: (
          <Text>
            Esta coluna precisa ser preenchida se você deseja que a mensagem de
            boas-vindas da Raiô, que tem o link para baixar o aplicativo, seja
            enviada na data de admissão
          </Text>
        ),
      },
    ],
    faq: [
      {
        question:
          'Quais são as opções de data para envio da mensagem de boas-vindas?',
        answers: [
          'Você pode escolher enviar assim que a importação for finalizada, em uma data específica ou na data de admissão de cada colaborador (caso esta coluna seja preenchida)',
        ],
      },
      {
        question: 'Como é enviada a mensagem de boas-vindas?',
        answers: [
          'Se celular e e-mail forem cadastrados, a mensagem é enviada por e-mail. Se apenas um estiver cadastrado, a mensagem é enviada pelo canal disponível (SMS ou e-mail)',
          'Se o colaborador não receber a mensagem, você pode solicitar o reenvio aqui no Portal depois',
        ],
      },
    ],
  },
  employeeAddress: {
    fieldTitle: 'Endereço do colaborador',
    required: 'maybe',
    example: 'CEP, Rua, Número, Complemento, Bairro, Cidade e Estado',
    tips: [
      {
        icon: FiEdit2,
        content: (
          <Text>
            Os campos de endereço do colaborador são{' '}
            <Semibold>
              obrigatórios apenas se a entrega for para o colaborador
            </Semibold>
            . Mesmo com entrega na empresa, você pode cadastrar o endereço para
            futuras entregas
          </Text>
        ),
      },
      {
        icon: FiAlertCircle,
        content: (
          <Text>
            Cuidado com CEPs que começam com zero. Se a planilha formatar como
            "Número", os zeros iniciais serão removidos. Use o formato "Texto"
            para evitar isso
          </Text>
        ),
      },
      {
        icon: FiType,
        content: (
          <Text>
            Você pode preencher o CEP com pontos e hífen (por exemplo,
            ”12.123-123”) ou sem (por exemplo, “12312312312”)
          </Text>
        ),
      },
      {
        icon: FiCheckCircle,
        content: (
          <Text>
            Se o endereço do colaborador não tiver número ou não tiver
            complemento, você não precisa preencher estas colunas
          </Text>
        ),
      },
    ],
    faq: [
      {
        question: 'Preciso preencher todos os campos do endereço?',
        answers: [
          'Se você cadastrar o endereço do colaborador, você deve preencher todos os campos: CEP, nome da rua, número (se tiver), complemento (se tiver), bairro, cidade e estado',
        ],
      },
      {
        question: 'Existe um limite de caracteres para os campos de endereço?',
        answers: [
          <Box>
            <Text>
              Sim, alguns campos possuem um limite específico que deve ser
              seguido:
            </Text>

            <UnorderedList marginTop={4}>
              <ListItem>Nome da rua: 40 caracteres</ListItem>
              <ListItem>Número: 9 números</ListItem>
              <ListItem>Complemento: 20 caracteres</ListItem>
              <ListItem>Bairro: 30 caracteres</ListItem>
              <ListItem>Cidade: 30 caracteres</ListItem>
            </UnorderedList>
          </Box>,
        ],
      },
    ],
  },
  benefitPackage: {
    fieldTitle: 'Nome do pacote de benefícios',
    required: 'optional',
    example: 'Padrão',
    tips: [
      {
        icon: FiEdit2,
        content: (
          <Text>
            <Semibold>Informe</Semibold> o nome do pacote que você deseja
            atribuir ao colaborador{' '}
            <Semibold>exatamente como você cadastrou no Portal</Semibold>. Você
            pode ver a lista de pacotes cadastrados abaixo e copiar os nomes
          </Text>
        ),
      },
    ],
    faq: [
      {
        question:
          'Posso escolher pacotes diferentes para colaboradores diferentes?',
        answers: [
          'Sim, é só você preencher o nome do pacote de benefícios correspondente a cada colaborador na planilha',
        ],
      },
    ],
  },
  socialName: {
    fieldTitle: 'Nome social do colaborador',
    required: 'optional',
    example: 'Ana Santos',
    tips: [
      {
        icon: FiUser,
        content: <Text>Informe o nome social com pelo menos um sobrenome</Text>,
      },
      {
        icon: FiType,
        content: (
          <Text>
            Use <Semibold>somente letras</Semibold>
          </Text>
        ),
      },
    ],
    faq: [
      {
        question: 'O que é o nome social do colaborador?',
        answers: [
          'Nome social é o nome que a pessoa escolhe usar, diferente do nome no documento oficial, para melhor representar sua identidade',
        ],
      },
      {
        question: 'Como o nome social é usado se ele for preenchido?',
        answers: [
          'É usado nas comunicações com o colaborador e na definição do nome impresso nos cartões com nome',
        ],
      },
    ],
  },
};
