import { createIcon } from '@chakra-ui/react';

export const ScanBarCode = createIcon({
  displayName: 'ScanBarCode',
  viewBox: '0 0 16 16',
  defaultProps: {
    boxSize: '16px',
  },
  path: (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 49" fill="none">
        <path
          d="M14 12.5V24.5M14 30.5V36.5M28 12.5V24.5M28 30.5V36.5M34 12.5V24.5M34 30.5V36.5M12 6.5H6V12.5M4 24.5H44M36 6.5H42V12.5M12 42.5H6V36.5M36 42.5H42V36.5M20 24.5V12.5H22V24.5H20ZM20 36.5V30.5H22V36.5H20Z"
          stroke="currentColor"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  ),
});
