import { z } from 'zod';

const NAME_REQUIRED_MESSAGE = 'Você precisa preencher o nome do pacote.';

const packageCategoriesSchema = z
  .array(
    z.object({
      id: z.string().optional(),
      name: z.string(),
      value: z.number(),
      periodType: z.string(),
    })
  )
  .optional();

export const benefitPackageCreationSchema = z
  .object({
    name: z
      .string({
        required_error: NAME_REQUIRED_MESSAGE,
      })
      .min(1, NAME_REQUIRED_MESSAGE),
    restrictCategories: packageCategoriesSchema,
    freeUsageCategories: packageCategoriesSchema,
  })
  .superRefine((values, ctx) => {
    const restrictCategories = values.restrictCategories ?? [];
    const freeUsageCategories = values.freeUsageCategories ?? [];

    const allCategories = restrictCategories.concat(freeUsageCategories);

    if (allCategories.every((category) => category.value === 0)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Você precisa preencher o valor de pelo menos um benefício.',
        path: ['package_categories'],
      });
    }
  });
