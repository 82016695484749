import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Icon,
  Link,
  Text,
  useToast,
} from '@chakra-ui/react';
import Title from '../../components/Title';
import FirstAccessLayout from '../../layouts/FirstAccessLayout';
import Stepper from '../../components/Stepper';
import { FiDownload } from 'react-icons/fi';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import InfoText from '../../components/InfoText';
import { BulbIcon } from '../../assets/customIcons';
import { useUserCompaniesStore } from '../../stores/useUserCompaniesStore';
import { formatCNPJ } from '../../utils/formatters/formatCNPJ';
import { useMutation } from '@tanstack/react-query';
import { useFetchContext } from '../../hooks/useFetchContext';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';
import { ConsentMutationValues } from './types';
import FullScreenBgFirstAccessLayout from '../../layouts/FullScreenBackgroundFirstAccessLayout';
import { HourglassIcon } from '../../assets/customIcons/HourglassIcon';

const schema = z.object({
  consent: z
    .boolean()
    .refine(
      (val) => val === true,
      'Para continuar, você precisa confirmar que a empresa está de acordo com os Termos.'
    ),
});

type FormValues = z.infer<typeof schema>;

const PRODUCT_NAME = 'Produto Raiô Benefícios';

const TERMS_URL =
  'https://raio-portal.s3.sa-east-1.amazonaws.com/termos-de-uso-raio-beneficios.pdf';

const CardTermsPage = () => {
  const { register, formState, handleSubmit } = useForm<FormValues>({
    resolver: zodResolver(schema),
  });

  const toast = useToast();
  const navigate = useNavigate();

  const { errors } = formState;

  const { user } = useAuthContext();

  const { selectedCompany, setSelectedCompany } = useUserCompaniesStore();

  const { api } = useFetchContext();

  const updateCompanyConsent = useMutation({
    mutationFn: (values: ConsentMutationValues) =>
      api.patch('/api/customers/setup/step-three', values),
    onSuccess: () => {
      if (selectedCompany) {
        setSelectedCompany({
          ...selectedCompany,
          registrationStep: 4,
        });

        navigate('/informacoes-adicionais');
      }

      toast({
        title: 'Aceite de termos de uso registrado com sucesso!',
        status: 'success',
      });
    },
    onError: () => {
      toast({
        title: 'Erro ao registrar o aceite de termos de uso',
        description: 'Tente novamente mais tarde',
        status: 'error',
      });
    },
  });

  function onSubmit({ consent }: FormValues) {
    if (consent === true) {
      updateCompanyConsent.mutate({
        userId: user?.id,
        registrationNumber: selectedCompany?.registrationNumber,
        customerId: selectedCompany?.customerId,
      });
    }
  }

  return (
    <FullScreenBgFirstAccessLayout>
      <Box
        position="relative"
        maxHeight="960px"
        marginTop={10}
        px={{ base: 5, sm: 10, md: 20 }}
        py={{ base: 10, md: 20 }}
      >
        <HourglassIcon
          position="absolute"
          top="53px"
          right="50px"
          width={'55.8px'}
          height={'54.51px'}
          display={{
            base: 'none',
            md: 'block',
          }}
        />

        <Card bg="#fff" boxShadow="none" maxWidth="1212px">
          <CardHeader>
            <Flex direction="column" gap={4}>
              <Stepper numberOfSteps={4} activeStep={3} />
              <Heading fontSize="24px">
                Termos de uso do {PRODUCT_NAME} para {selectedCompany?.name}
              </Heading>
            </Flex>
          </CardHeader>

          <form onSubmit={handleSubmit(onSubmit)}>
            <CardBody paddingTop={0}>
              <Flex direction="column" gap={6}>
                <Box height="40vh" backgroundColor="gray.100" borderRadius="lg">
                  <iframe src={`${TERMS_URL}`} width="100%" height="100%" />
                </Box>

                <InfoText
                  as="div"
                  display="flex"
                  flexDirection={{ base: 'column', md: 'row' }}
                  justifyContent={{ base: 'flex-start', md: 'space-between' }}
                  padding="12px 24px"
                  gap={2}
                >
                  <Flex alignItems="center" gap={2}>
                    <BulbIcon boxSize="16px" color={'primary.primary'} />
                    <Text lineHeight={1}>
                      Você pode acessar o portal novamente depois e continuar de
                      onde parou.
                    </Text>
                  </Flex>

                  <Button
                    as={Link}
                    variant="link"
                    href={TERMS_URL}
                    leftIcon={<FiDownload width="20px" height="20px" />}
                    fontWeight={500}
                    target="_blank"
                    download={`Termos de uso do ${PRODUCT_NAME}.pdf`}
                    lineHeight={1}
                    minWidth="max-content"
                    marginLeft={{ base: '-8px', md: 'unset' }}
                  >
                    Fazer download
                  </Button>
                </InfoText>

                <FormControl isInvalid={!!errors.consent}>
                  <Checkbox size="lg" {...register('consent')}>
                    <Text lineHeight={1.2} fontSize="16px" marginLeft={2}>
                      Ao confirmar, você declara que a empresa{' '}
                      <Text as="span" fontWeight={700}>
                        {selectedCompany?.name}
                      </Text>
                      , inscrita no CNPJ{' '}
                      {formatCNPJ(selectedCompany?.registrationNumber)},{' '}
                      <Text as="span" fontWeight={700}>
                        está de acordo
                      </Text>{' '}
                      com os Termos de Uso do {PRODUCT_NAME}.
                    </Text>
                  </Checkbox>
                  <FormErrorMessage marginLeft="35px">
                    <ErrorMessage errors={errors} name="consent" />
                  </FormErrorMessage>
                </FormControl>
              </Flex>
            </CardBody>

            <CardFooter paddingTop={0}>
              <Button
                type="submit"
                width="290px"
                marginTop={4}
                marginLeft="auto"
                isLoading={updateCompanyConsent.isPending}
              >
                Confirmar
              </Button>
            </CardFooter>
          </form>
        </Card>
      </Box>
    </FullScreenBgFirstAccessLayout>
  );
};

export default CardTermsPage;
