import { Card, CardProps, Text } from '@chakra-ui/react';
import { UserHeartIcon } from '../../../../../assets/customIcons';

const RegistrationProcessingEmptyState = (props: CardProps) => {
  return (
    <Card
      width="100%"
      height="100%"
      direction="column"
      alignItems="center"
      justifyContent="center"
      gap={6}
      backgroundColor="gray.50"
      paddingX={8}
      paddingY="160px"
      {...props}
    >
      <UserHeartIcon boxSize="56px" color="primary.primary" />
      <Text fontSize="40px" fontWeight={700}>
        Já Raiô!
      </Text>
      <Text fontSize="32px">
        O cadastro de todos os colaboradores foi processado
      </Text>
    </Card>
  );
};

export default RegistrationProcessingEmptyState;
