import {
  Box,
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import rechargeImage from '../../../../assets/images/recharge.png';
import { useNavigate } from 'react-router-dom';

type NewRechargeModalProps = {
  children: React.ReactNode;
};

const NewRechargeModal = ({ children }: NewRechargeModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  return (
    <>
      <Box onClick={onOpen}>{children}</Box>

      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size="md"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontWeight={700} fontSize="18px">
            Fazer nova recarga
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontWeight="400" fontSize="16px">
              Por enquanto, você pode fazer uma nova recarga na página de{' '}
              <Text as="span" fontWeight={600}>
                Colaboradores.
              </Text>
            </Text>

            <Text fontWeight="400" fontSize="16px" mt={4}>
              Lá você seleciona os colaboradores que deseja adicionar à recarga
              e clica em{' '}
              <Text as="span" fontWeight={600}>
                Recarregar Benefícios.
              </Text>
            </Text>

            <Flex w="100%" justifyContent="center" my="40px">
              <Image
                alignSelf="flex-end"
                src={rechargeImage}
                objectFit="contain"
              />
            </Flex>
          </ModalBody>

          <ModalFooter
            display="flex"
            w="100%"
            alignItems="center"
            gap={12}
            justifyContent="center"
          >
            <Button mr={3} onClick={() => navigate('/colaboradores')}>
              Ir para a página de Colaboradores
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default NewRechargeModal;
