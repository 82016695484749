import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useFetchContext } from '../../hooks/useFetchContext';
import { useUserCompaniesStore } from '../../stores/useUserCompaniesStore';
import { useToast } from '@chakra-ui/react';
import { CustomerConfig } from '../../types/config';

export const useGetCustomerConfigs = () => {
  const { api } = useFetchContext();
  const { selectedCompany } = useUserCompaniesStore();

  return useQuery({
    queryKey: ['customer-configs', selectedCompany?.customerId],
    queryFn: () =>
      api.get<CustomerConfig[]>(
        `/api/customer-configs?customerId=${selectedCompany?.customerId}`
      ),
    select: (response) => response.data,
    staleTime: 0,
    gcTime: 0,
    enabled: !!selectedCompany?.customerId,
    meta: {
      errorMessage:
        'Não foi possível carregar a lista de empresas. Tente novamente mais tarde.',
    },
  });
};

export const usePatchCustomerConfigs = () => {
  const { api } = useFetchContext();
  const toast = useToast();
  const queryClient = useQueryClient();
  const { selectedCompany } = useUserCompaniesStore();

  return useMutation({
    mutationFn: ({ data, id }: { data: Partial<CustomerConfig>; id: string }) =>
      api.patch(`/api/customer-configs/${id}`, data),
    onSuccess: () => {
      toast({
        title: 'Alterações salvas com sucesso!',
        status: 'success',
      });
      queryClient.invalidateQueries({
        queryKey: ['customer-configs', selectedCompany?.customerId],
      });
      queryClient.invalidateQueries({
        queryKey: ['benefit'],
      });
      queryClient.invalidateQueries({
        queryKey: ['benefit-categories'],
      });
      queryClient.invalidateQueries({
        queryKey: ['employees'],
      });
      queryClient.invalidateQueries({
        queryKey: [
          'benefit-categories',
          { customerId: selectedCompany?.customerId },
        ],
      });
    },
    onError: () => {
      toast({
        title: 'Tivemos um problema salvar as alterações no momento.',
        description: 'Tente de novo daqui a pouco.',
        status: 'error',
      });
    },
  });
};

export const usePostCustomerConfigs = () => {
  const { api } = useFetchContext();
  const toast = useToast();
  const queryClient = useQueryClient();
  const { selectedCompany } = useUserCompaniesStore();

  return useMutation({
    mutationFn: ({ data }: { data: Partial<CustomerConfig> }) =>
      api.post(`/api/customer-configs`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['customer-configs', selectedCompany?.customerId],
      });
      queryClient.invalidateQueries({
        queryKey: ['benefit'],
      });
      queryClient.invalidateQueries({
        queryKey: ['benefit-categories'],
      });
      queryClient.invalidateQueries({
        queryKey: ['employees'],
      });
      toast({
        title: 'Alterações salvas com sucesso!',
        status: 'success',
      });
    },
    onError: () => {
      toast({
        title: 'Tivemos um problema salvar as alterações no momento.',
        description: 'Tente de novo daqui a pouco.',
        status: 'error',
      });
    },
  });
};
