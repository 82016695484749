import {
  Button,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FiArchive } from 'react-icons/fi';
import ContactForms from '../ContactForms';
import { Semibold } from '../Typography';

const title = 'Saiba mais sobre cartões sem nome';

const NonNominalCardsInfo = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        variant="link"
        textDecor="underline"
        fontWeight={600}
        display="flex"
        minHeight="fit-content"
        gap={2}
        marginTop="16px"
        onClick={onOpen}
      >
        <Icon as={FiArchive} />
        {title}
      </Button>

      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size="3xl">
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton height="26px" />
            <ModalBody p={4}>
              <Text fontSize="18px" fontWeight={700}>
                {title}
              </Text>
              <Text marginTop={4}>
                Os <Semibold>Cartões Sem Nome</Semibold> são uma{' '}
                <Semibold>solução</Semibold> para{' '}
                <Semibold>evitar prazos de produção e de entrega</Semibold> dos
                cartões a cada nova contratação!
              </Text>

              <VStack align="flex-start" spacing={4} marginTop={10}>
                <Text fontSize="18px" fontWeight={700}>
                  Como funciona?
                </Text>

                <Text>
                  Você pode{' '}
                  <Semibold>
                    encomendar um lote com vários Cartões Sem Nome
                  </Semibold>{' '}
                  antes mesmo de cadastrar os colaboradores que irão recebê-los,
                  agilizando os primeiros passos e garantindo o acesso imediato
                  aos benefícios
                </Text>

                <Text>
                  A partir da encomenda do lote dos Cartões Sem Nome,{' '}
                  <Semibold>
                    estes serão entregues direto para a sua empresa,
                  </Semibold>{' '}
                  onde devem ser <Semibold>guardados em segurança.</Semibold>{' '}
                  Quando um <Semibold>novo colaborador é contratado,</Semibold>{' '}
                  basta <Semibold>você entregar</Semibold> um destes cartões.
                </Text>

                <Text>
                  O colaborador poderá vincular este cartão à sua conta Raiô
                  através do aplicativo e começar a usar os seus benefícios
                  imediatamente!
                </Text>
              </VStack>

              <Text fontSize="18px" fontWeight={700} marginTop={10}>
                Como encomendar?
              </Text>

              <Text marginTop={4} marginBottom={6}>
                Entre em contato com o nosso atendimento.{' '}
                <Semibold>
                  Os pedidos e quantidades estão sujeitos a análise prévia da
                  Raiô.
                </Semibold>
              </Text>

              <ContactForms />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default NonNominalCardsInfo;
