import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  Flex,
  HStack,
} from '@chakra-ui/react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import PaymentMethod, { StoragedPaymentMethod } from '../PaymentMethod';
import CreditDate from '../CreditDate';
import SendToFinance from '../SendToFinance';
import ActionDrawerInfoCard from '../ActionDrawerInfoCard';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useUserCompaniesStore } from '../../../../stores/useUserCompaniesStore';
import { getLocalStorageItem } from '../../../../utils/localstorage';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { formatCurrencyInCents } from '../../../../utils/formatters/formatCurrency';
import {
  useCreateRecharge,
  useGetEarliestBilletCreditDate,
  useGetRechargeDates,
} from '../../../../api/recharge';
import { Employee } from '../../../../types/employee';
import RechargeVisibilityConfig from '../RechargeVisibilityConfig';
import {
  RechargePaymentMethod,
  RechargeVisibilityEnum,
} from '../../../../types/recharge';
import RechargeObservation from '../RechargeObservation';
import { checkoutSchema } from '../../../../types/schemas/rechargeSchema';
import Spinner from '../../../../components/Spinner';
import useDisableBilletButton from '../../../../hooks/useDisableBilletButton';

export type FormValues = z.infer<typeof checkoutSchema>;

type CheckoutOrderInfosProps = {
  rechargeVisibilityConfig: RechargeVisibilityEnum | undefined;
  isSpreadsheetImport: boolean | undefined;
  companyAccountBalance: number;
};

const CheckoutOrderInfos = (data: CheckoutOrderInfosProps) => {
  const { isSpreadsheetImport, companyAccountBalance } = data;
  const { selectedCompany } = useUserCompaniesStore();
  const paymentMethods = getLocalStorageItem('paymentMethods');

  const [selectedPaymentMethod] = useState(() => {
    return getStoragePaymentMethod();
  });

  function getStoragePaymentMethod() {
    if (!paymentMethods) return '';
    const parsedPaymentMethods: StoragedPaymentMethod[] =
      JSON.parse(paymentMethods);
    const selectedCompanyId = selectedCompany?.id || '';
    const findedMethod = parsedPaymentMethods.find(
      (m) => selectedCompanyId === m.id
    );

    if (findedMethod) {
      return findedMethod.method;
    }
  }

  const location = useLocation();

  const employees = location.state?.employees as Employee[] | undefined;

  const numOfDays = location.state?.rechargeCompetencyInDays as
    | number
    | undefined;

  const grandTotal = location.state?.grandTotal as number | undefined;

  const methods = useForm<FormValues>({
    resolver: zodResolver(checkoutSchema),
    defaultValues: {
      paymentMethod: selectedPaymentMethod,
      rechargeVisibilityConfig:
        data?.rechargeVisibilityConfig || RechargeVisibilityEnum.PAID,
    },
  });

  const navigate = useNavigate();

  const defaultRechargeVisibility = data?.rechargeVisibilityConfig;

  const createRecharge = useCreateRecharge();

  function setRechargeVisibility(value: RechargeVisibilityEnum) {
    methods.setValue('rechargeVisibilityConfig', value, {
      shouldDirty: true,
    });
  }

  function setRechargeObservation(value: string) {
    methods.setValue('observation', value, {
      shouldDirty: true,
    });
  }

  const rechargeValues = methods.getValues();
  const {
    paymentMethod,
    scheduledDate,
    rechargeVisibilityConfig: rechargeVisibility,
    observation: rechargeObservation,
  } = rechargeValues;

  function resetRechargeObservation() {
    methods.setValue('observation', '', { shouldDirty: true });
  }

  const {
    data: earliestBilletCreditDate,
    isLoading: isLoadingEarliestBilletCreditDate,
  } = useGetEarliestBilletCreditDate();

  const getRechargeDates = useGetRechargeDates({
    paymentMethod,
    scheduledDate,
  });

  const { data: rechargeDates } = getRechargeDates;

  const { shouldDisableBilletButton } = useDisableBilletButton(
    scheduledDate,
    earliestBilletCreditDate?.creditDate
  );

  useEffect(() => {
    if (shouldDisableBilletButton && paymentMethod === 'boleto') {
      methods.setValue('paymentMethod', '', { shouldDirty: true });
    }
  }, [shouldDisableBilletButton, methods, paymentMethod]);

  if (!employees || employees?.length === 0) {
    return <Navigate to="/recargas" />;
  }

  if (isLoadingEarliestBilletCreditDate) {
    return <Spinner />;
  }

  return (
    <Box marginBottom="400px">
      <FormProvider {...methods}>
        <form>
          <Flex flexWrap={'wrap'} gap={8}>
            <Box flexBasis={'496px'} flexGrow={1}>
              <CreditDate getRechargeDates={getRechargeDates} />
            </Box>
            <Box flexGrow={1} flexBasis={'496px'}>
              <PaymentMethod
                grandTotal={grandTotal}
                accountBalance={companyAccountBalance}
                resetRechargeObservation={resetRechargeObservation}
                setRechargeVisibility={setRechargeVisibility}
                defaultRechargeVisibility={defaultRechargeVisibility || 'PAID'}
                shouldDisableBilletButton={shouldDisableBilletButton}
                earliestBilletCreditDate={earliestBilletCreditDate?.creditDate}
                getRechargeDates={getRechargeDates}
              />
            </Box>
            <Flex
              flexDirection="column"
              gap={8}
              flexGrow={1}
              flexBasis={'496px'}
            >
              <RechargeObservation
                rechargeObservation={rechargeObservation || ''}
                setRechargeObservation={setRechargeObservation}
                employees={employees || []}
                rechargeValues={rechargeValues}
              />

              <RechargeVisibilityConfig
                paymentMethod={paymentMethod}
                rechargeVisibility={rechargeVisibility}
                setRechargeVisibility={setRechargeVisibility}
              />
              <SendToFinance />
            </Flex>
          </Flex>

          <Drawer
            placement="bottom"
            isOpen={true}
            autoFocus={false}
            blockScrollOnMount={false}
            returnFocusOnClose={false}
            closeOnEsc={false}
            closeOnOverlayClick={false}
            trapFocus={false}
            lockFocusAcrossFrames={false}
            variant="clickThrough"
            onClose={() => {}}
          >
            <DrawerContent zIndex={1} paddingX={20} height="144px">
              <DrawerBody>
                <Flex
                  alignItems="center"
                  direction="row-reverse"
                  gap={4}
                  height="100%"
                  justifyContent="space-between"
                >
                  <Flex
                    alignItems="center"
                    gap={4}
                    height="100%"
                    justifyContent="space-between"
                  >
                    <Button
                      isDisabled={createRecharge.isPending}
                      variant="outline"
                      onClick={() =>
                        navigate('/recargas/nova-recarga', {
                          state: {
                            selectedEmployees: employees,
                            rechargeCompetencyInDays: numOfDays,
                            isSpreadsheetImport,
                          },
                        })
                      }
                    >
                      Voltar e editar
                    </Button>
                    <Button
                      isDisabled={!rechargeDates}
                      isLoading={createRecharge.isPending}
                      onClick={methods.handleSubmit((data) => {
                        const observation =
                          rechargeObservation &&
                          rechargeObservation.replace(/\r?\\n/g, '\n');

                        const payload = {
                          paymentMethod: data.paymentMethod,
                          creditAvailability: data.creditAvailability,
                          scheduledDate: rechargeDates?.creditDate,
                          dueDate: rechargeDates?.dueDate,
                          employees: employees?.map((employee) => ({
                            id: employee.id,
                            categories: employee.categories,
                          })),
                          rechargeVisibilityConfig: rechargeVisibility,
                          observation: observation,
                        };
                        createRecharge.mutate(payload, {
                          onSuccess: (response) => {
                            navigate(
                              '/recargas/nova-recarga/informacoes-da-recarga',
                              {
                                state: {
                                  ...response.data,
                                  employees,
                                  observation,
                                },
                              }
                            );
                          },
                        });
                      })}
                    >
                      Fazer recarga
                    </Button>
                  </Flex>

                  <HStack spacing={4}>
                    <ActionDrawerInfoCard
                      label="Colaboradores na recarga"
                      value={employees?.length}
                    />
                    <ActionDrawerInfoCard
                      label="Valor total"
                      value={formatCurrencyInCents(grandTotal ?? 0)}
                    />
                  </HStack>
                </Flex>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </form>
      </FormProvider>
    </Box>
  );
};

export default CheckoutOrderInfos;
