import { Icon, Text } from '@chakra-ui/react';
import { FiCreditCard, FiUser } from 'react-icons/fi';
import { HiOutlineCurrencyDollar } from 'react-icons/hi2';

export const disableSingleEmployeeHelperTexts = [
  {
    key: 1,
    icon: HiOutlineCurrencyDollar,
    disableText: (
      <>
        Este colaborador não vai mais aparecer no menu Colaboradores e{' '}
        <Text as="span" fontWeight={600}>
          não vai ser incluído
        </Text>{' '}
        nas{' '}
        <Text as="span" fontWeight={600}>
          {' '}
          próximas recargas
        </Text>
      </>
    ),
  },
  {
    key: 2,
    icon: FiCreditCard,
    disableText: (
      <>
        O cartão dele{' '}
        <Text as="span" fontWeight={600}>
          {' '}
          não será bloqueado
        </Text>{' '}
        para que ele
        <Text as="span" fontWeight={600}>
          {' '}
          continue usando os benefícios
        </Text>{' '}
        recebidos
      </>
    ),
  },
  {
    key: 3,
    icon: FiUser,
    disableText: (
      <>
        Para
        <Text as="span" fontWeight={600}>
          {' '}
          readmitir o colaborador
        </Text>
        , basta iniciar um novo cadastro individual e{' '}
        <Text as="span" fontWeight={600}>
          {' '}
          digitar apenas o CPF dele
        </Text>
      </>
    ),
  },
];

export const disableMultipleEmployeesHelperTexts = [
  {
    key: 1,
    icon: HiOutlineCurrencyDollar,
    disableText: (
      <>
        Estes colaboradores não vão mais aparecer no menu Colaboradores e{' '}
        <Text as="span" fontWeight={600}>
          não vão ser incluídos
        </Text>{' '}
        nas{' '}
        <Text as="span" fontWeight={600}>
          próximas recargas
        </Text>
      </>
    ),
  },
  {
    key: 2,
    icon: FiCreditCard,
    disableText: (
      <>
        Os cartões deles{' '}
        <Text as="span" fontWeight={600}>
          {' '}
          não serão bloqueados
        </Text>{' '}
        para que eles
        <Text as="span" fontWeight={600}>
          {' '}
          continuem usando os benefícios
        </Text>{' '}
        recebidos
      </>
    ),
  },
  {
    key: 3,
    icon: FiUser,
    disableText: (
      <>
        Para
        <Text as="span" fontWeight={600}>
          {' '}
          readmitir um colaborador
        </Text>
        , basta iniciar um novo cadastro individual e{' '}
        <Text as="span" fontWeight={600}>
          {' '}
          digitar apenas o CPF dele
        </Text>
      </>
    ),
  },
];
