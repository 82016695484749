import { createIcon } from '@chakra-ui/react';

export const CoinIcon = createIcon({
  displayName: 'CoinIcon',
  viewBox: '0 0 60 60',
  defaultProps: {
    boxSize: '60px',
    fill: 'none',
  },
  path: (
    <path
      d="M30 18.8889C25.3972 18.8889 21.6667 21.375 21.6667 24.4444C21.6667 27.5139 25.3972 30 30 30C34.6028 30 38.3333 32.4861 38.3333 35.5556C38.3333 38.625 34.6028 41.1111 30 41.1111M30 18.8889V41.1111M30 18.8889C33.0833 18.8889 35.7778 20.0056 37.2194 21.6667L30 18.8889ZM30 18.8889V16.1111M30 41.1111V43.8889M30 41.1111C26.9167 41.1111 24.2222 39.9944 22.7806 38.3333L30 41.1111ZM55 30C55 33.283 54.3534 36.5339 53.097 39.5671C51.8406 42.6002 49.9991 45.3562 47.6777 47.6777C45.3562 49.9991 42.6002 51.8406 39.5671 53.097C36.5339 54.3534 33.283 55 30 55C26.717 55 23.4661 54.3534 20.4329 53.097C17.3998 51.8406 14.6438 49.9991 12.3223 47.6777C10.0009 45.3562 8.15938 42.6002 6.90301 39.5671C5.64664 36.5339 5 33.283 5 30C5 23.3696 7.63392 17.0107 12.3223 12.3223C17.0107 7.63392 23.3696 5 30 5C36.6304 5 42.9893 7.63392 47.6777 12.3223C52.3661 17.0107 55 23.3696 55 30Z"
      stroke="currentColor"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
});
