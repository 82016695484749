import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  Text,
  Icon,
  Alert,
  AlertIcon,
  Box,
  VStack,
} from '@chakra-ui/react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { PostEmployeeReturn } from '../../../../types/employee';
import { FiAlertCircle, FiCheckCircle } from 'react-icons/fi';
import { Semibold } from '../../../../components/Typography';
import ContactForms from '../../../../components/ContactForms';
import { CardTypeEnum } from '../../../../types/card';

type ModalProps = {
  onClose: () => void;
  isOpen: boolean;
  rechargeBenefit?: () => void;
  postEmployeeReturn?: PostEmployeeReturn;
  handleFormReset: () => void;
  isNoNameCardsAllowed: boolean;
  nonNominalCardStock: number;
  cardType?: string;
};

const NonNominalCardsStockInfo = () => {
  return (
    <VStack align="flex-start" spacing={4}>
      <Text>
        Você escolheu entregar um cartão sem nome para o colaborador, mas no
        momento <Semibold>sua empresa não possui cartões sem nome</Semibold>{' '}
        disponíveis em estoque ou em produção.
      </Text>
      <Text>
        Não se preocupe! <Semibold>Entre em contato</Semibold> com o nosso
        atendimento em um dos canais abaixo e solicite mais cartões sem nome.
      </Text>
      <ContactForms />
    </VStack>
  );
};

const SuccessCollaboratorRegistrationModal = (modalProps: ModalProps) => {
  const { isNoNameCardsAllowed, nonNominalCardStock, cardType } = modalProps;
  const navigate = useNavigate();

  const handleCloseModal = useCallback(() => {
    modalProps.handleFormReset();
    modalProps.onClose();
  }, [modalProps]);

  const closeAndNavigateToEmployeesList = () => {
    modalProps.handleFormReset();
    modalProps.onClose();
    navigate('/colaboradores');
  };

  return (
    <Modal
      isOpen={modalProps.isOpen}
      onClose={handleCloseModal}
      isCentered
      size="lg"
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader marginBottom={2} fontSize="18px">
          <Flex alignItems="center" gap={2}>
            <Icon as={FiCheckCircle} color="feedback.success" fontSize="24px" />
            <Text>Colaborador cadastrado com sucesso!</Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton
          top="30px"
          onClick={closeAndNavigateToEmployeesList}
        />
        <ModalBody>
          <Alert
            status="warning"
            marginBottom={6}
            borderRadius="lg"
            backgroundColor="light.orange"
          >
            <AlertIcon as={FiAlertCircle} color="primary.primary" />
            Assim que o processamento do cadastro terminar, você poderá fazer
            uma recarga.
          </Alert>

          {isNoNameCardsAllowed &&
            cardType === CardTypeEnum.NOT_NOMINAL &&
            nonNominalCardStock === 0 && (
              <Box marginBottom={8}>
                <NonNominalCardsStockInfo />
              </Box>
            )}

          <Flex flexDir={'column'} gap={3}>
            <Button onClick={handleCloseModal} width="full">
              Cadastrar outro colaborador
            </Button>
            <Button
              variant="outline"
              onClick={closeAndNavigateToEmployeesList}
              width="full"
            >
              Ir para a página de colaboradores
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SuccessCollaboratorRegistrationModal;
