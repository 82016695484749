import { Button, Flex, Icon, Link, useClipboard } from '@chakra-ui/react';
import { FiCheckCircle, FiCopy } from 'react-icons/fi';

const CopyButton = ({ copyText }: { copyText: string }) => {
  const { hasCopied, onCopy } = useClipboard(copyText || '');

  return (
    <>
      {hasCopied ? (
        <Flex alignItems="center">
          <Button
            variant="link"
            padding={0}
            fontWeight={600}
            color="feedback.success"
            ml={2}
            leftIcon={<Icon as={FiCheckCircle} boxSize="22px" />}
            onClick={onCopy}
            _hover={{
              color: 'feedback.success',
            }}
          >
            Copiado!
          </Button>
        </Flex>
      ) : (
        <Flex alignItems="center">
          <Button
            variant="link"
            padding={0}
            fontWeight={600}
            ml={2}
            leftIcon={<Icon as={FiCopy} boxSize="22px" />}
            onClick={onCopy}
          >
            Copiar
          </Button>
        </Flex>
      )}
    </>
  );
};

export default CopyButton;
