import { parseISO, isValid, format } from 'date-fns';
import { tz } from '@date-fns/tz';
import { isoDateRegex } from '../validations/regex';

export function getWeekdayFromISO(isoDate: string): string {
  if (!isoDateRegex.test(isoDate)) {
    return '';
  }

  const parsedDate = parseISO(isoDate);

  if (!isValid(parsedDate)) {
    return '';
  }

  return format(parsedDate, 'EEEE', {
    in: tz('America/Sao_Paulo'),
  });
}
