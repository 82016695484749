import { Required } from '../../utils/fillingTips';
import { Flex, Text, TextProps } from '@chakra-ui/react';

type EmployeeImportMandatoryBadgeProps = {
  required: Required;
};

const BadgeLabel = (props: TextProps) => {
  return (
    <Text
      as="span"
      display="flex"
      alignItems="center"
      paddingX={3}
      paddingY={1}
      borderRadius="full"
      fontSize="sm"
      fontWeight={400}
      {...props}
    ></Text>
  );
};

const EmployeeImportMandatoryBadge = ({
  required,
}: EmployeeImportMandatoryBadgeProps) => {
  return (
    <Flex fontSize="2xl" gap={8} height="26px">
      {required === 'required' && (
        <BadgeLabel background="light.red" color="feedback.alert">
          Obrigatório
        </BadgeLabel>
      )}
      {required === 'maybe' && (
        <BadgeLabel background="light.orange" color="primary.primary">
          Pode ser obrigatório
        </BadgeLabel>
      )}
      {required === 'optional' && (
        <BadgeLabel background="light.blue" color="status.paused">
          Opcional
        </BadgeLabel>
      )}
    </Flex>
  );
};

export default EmployeeImportMandatoryBadge;
