export const getCardStatusText = (status: string) => {
  const statusMappings: { [key: string]: string } = {
    INACTIVE: 'Solicitado',
    EMITTED: 'Solicitado',
    PENDING: 'Rastrear cartão',
    MISPLACED: 'Extraviado',
    ACTIVE: 'Ativo',
    BLOCKED: 'Bloqueado',
    CANCELED: 'Cancelado',
    NOT_FOUND: 'Aguardando ativação',
  };

  return statusMappings[status] || 'Desconhecido';
};
