import {
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react';
import { FiEdit, FiMoreVertical, FiTrash2 } from 'react-icons/fi';
import { memo } from 'react';
import { MailPlusIcon } from '../../../../assets/customIcons/MailPlusIcon';
import AlertDialog from '../../../../components/AlertDialog';
import { useDeleteUser, useSendCommsToUsers } from '../../../../api/users';
import AddAndEditUser from '../AddAndEditUser';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import CustomTooltip from '../../../../components/CustomTooltip';
import { User } from '../../../../types/user';
import { useUserCompaniesStore } from '../../../../stores/useUserCompaniesStore';

type UserActionMenuProps = {
  user: User;
};

const UserActionMenu = memo(({ user }: UserActionMenuProps) => {
  const { fullName, id: userId } = user;

  const { selectedCompany } = useUserCompaniesStore();

  const customerUser = user.customerUsers.find(
    (customerUser) => customerUser.customerId === selectedCompany?.customerId
  );

  const isOwner = !!customerUser?.owner;

  const editUserDrawerControlProps = useDisclosure();
  const deleteUserModalControl = useDisclosure();
  const sendCommsConfirmationAlert = useDisclosure();
  const userDeleteMutation = useDeleteUser({
    userId: userId,
  });

  const sendCommsToUsers = useSendCommsToUsers();

  const { user: loggedInUser, isAdmin } = useAuthContext();

  const loggedInUserId = loggedInUser?.id;

  const isOwnerOrOwnUser = isOwner || loggedInUserId === userId;

  return (
    <Flex justifyContent="center">
      <Menu>
        <CustomTooltip
          label="Não há ações disponíveis"
          isDisabled={!isOwnerOrOwnUser}
        >
          <MenuButton
            as={IconButton}
            isDisabled={isOwnerOrOwnUser}
            aria-label="Menu de ações"
            icon={<Icon as={FiMoreVertical} boxSize="24px" />}
            variant="ghost"
            boxSize="30px"
          >
            Ações
          </MenuButton>
        </CustomTooltip>
        <MenuList>
          {isAdmin && (
            <>
              <MenuItem
                icon={<Icon as={FiEdit} boxSize="16px" />}
                onClick={editUserDrawerControlProps.onOpen}
              >
                Editar usuário
              </MenuItem>

              <MenuItem
                icon={<Icon as={FiTrash2} boxSize="16px" />}
                onClick={deleteUserModalControl.onOpen}
              >
                Deletar usuário
              </MenuItem>
            </>
          )}

          <MenuItem
            icon={<MailPlusIcon boxSize="16px" />}
            onClick={sendCommsConfirmationAlert.onOpen}
          >
            Reenviar comunicação
          </MenuItem>

          {deleteUserModalControl.isOpen && (
            <AlertDialog
              isOpen={deleteUserModalControl.isOpen}
              onClose={deleteUserModalControl.onClose}
              cancelButtonAction={deleteUserModalControl.onClose}
              title={`Excluir usuário`}
              confirmButtonLabel="Excluir"
              confirmButtonAction={() => {
                userDeleteMutation.mutate();
                deleteUserModalControl.onClose();
              }}
            >
              Deseja realmente excluir {fullName}?
            </AlertDialog>
          )}

          {sendCommsConfirmationAlert.isOpen && (
            <AlertDialog
              isOpen={sendCommsConfirmationAlert.isOpen}
              onClose={sendCommsConfirmationAlert.onClose}
              cancelButtonAction={sendCommsConfirmationAlert.onClose}
              title={`Enviar comunicação para ${fullName}`}
              isConfirmButtonLoading={sendCommsToUsers.isPending}
              confirmButtonLabel="Enviar"
              confirmButtonAction={() =>
                sendCommsToUsers.mutate(
                  { userIds: [userId] },
                  {
                    onSuccess: () => {
                      sendCommsConfirmationAlert.onClose();
                    },
                  }
                )
              }
            >
              Enviaremos novamente ao usuário o e-mail com link de primeiro
              acesso do Portal Raiô
            </AlertDialog>
          )}
          {editUserDrawerControlProps.isOpen && (
            <AddAndEditUser
              isEdit
              userId={userId}
              isOpen={editUserDrawerControlProps.isOpen}
              onClose={editUserDrawerControlProps.onClose}
            />
          )}
        </MenuList>
      </Menu>
    </Flex>
  );
});

export default UserActionMenu;
