import { Icon, IconProps, forwardRef } from '@chakra-ui/react';

export const EyeOpenIcon = forwardRef<IconProps, 'svg'>((props, ref) => {
  return (
    <Icon ref={ref} viewBox="0 0 16 16" {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <g fill="currentColor" clipPath="url(#a)">
          <path d="M21.26 13.588c-1.284-2.09-4.204-5.598-9.328-5.598-5.125 0-8.044 3.508-9.328 5.598a4.062 4.062 0 0 0 0 4.272c1.284 2.09 4.203 5.598 9.328 5.598 5.124 0 8.044-3.507 9.327-5.598a4.062 4.062 0 0 0 0-4.272Zm-1.412 3.406c-1.102 1.792-3.597 4.809-7.916 4.809-4.32 0-6.815-3.017-7.917-4.81a2.414 2.414 0 0 1 0-2.538c1.102-1.793 3.598-4.81 7.917-4.81s6.814 3.014 7.917 4.81a2.415 2.415 0 0 1 0 2.539ZM11.57.01a3.117 3.117 0 0 0-1.65.634c-.114.088-2.414 2.43-2.625 2.675-.095.108-.22.328-.27.474-.07.196-.08.586-.022.8.077.3.241.56.483.768.288.253.584.376 1.007.422a1.91 1.91 0 0 0 1.284-.328c.065-.048.465-.436.888-.86.749-.756.769-.774.913-.839a.856.856 0 0 1 .901.112c.047.04.4.389.788.775.386.385.749.739.808.784.542.42 1.368.484 1.985.152.175-.093.42-.3.53-.445a1.73 1.73 0 0 0 .262-.516 1.68 1.68 0 0 0 .008-.733 1.837 1.837 0 0 0-.266-.528c-.088-.11-2.164-2.254-2.361-2.437a3.415 3.415 0 0 0-1.58-.826A3.75 3.75 0 0 0 11.57.01Z" />
          <path d="M11.93 11.586a4.138 4.138 0 1 0 .001 8.276 4.138 4.138 0 0 0 0-8.276Zm0 6.62a2.483 2.483 0 1 1 0-4.965 2.483 2.483 0 0 1 0 4.966Z" />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M2 0h19.862v24H2z" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
});
