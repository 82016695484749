import { createColumnHelper } from '@tanstack/react-table';
import { Checkbox, Flex, Link, Text } from '@chakra-ui/react';
import { maskCpfForPrivacy } from '../../../../utils/formatters/maskCpfForPrivacy';
import {
  Employee,
  TranslateEmployeeStatusEnum,
} from '../../../../types/employee';
import { CardStatusEnum } from '../../../../types/employee';
import EmployeeStatusBadge from '../../../../components/EmployeeStatusBadge';
import { getCardStatusText } from '../../utils/getCardStatusText';
import { ReactElement } from 'react';
import { BlockedIcon } from '../../../../assets/customIcons/BlockedIcon';
import { EmittedIcon } from '../../../../assets/customIcons/EmittedIcon';
import { CanceledIcon } from '../../../../assets/customIcons/CanceledIcon';
import { ActiveIcon } from '../../../../assets/customIcons/ActiveIcon';
import PendingActivationCardModal from '../PendingActivationCardModal';
import { TruckIcon } from '../../../../assets/customIcons/TruckIcon';
import TrackingCardModal from '../TrackingCardModal';
import MisplacedCardModal from '../MisplacedCardModal';

const columnHelper = createColumnHelper<Employee>();

const setCardStatus = (
  status: keyof typeof CardStatusEnum,
  employee: Employee
) => {
  const statusMappings: { [key: string]: ReactElement } = {
    INACTIVE: (
      <>
        <Flex flexDir={'row'} alignItems={'center'} gap={2}>
          <EmittedIcon />
          <Text fontSize={'16px'} fontWeight={'400'}>
            {getCardStatusText(status)}
          </Text>
        </Flex>
      </>
    ),
    EMITTED: (
      <>
        <Flex flexDir={'row'} alignItems={'center'} gap={2}>
          <EmittedIcon />
          <Text fontSize={'16px'} fontWeight={'400'}>
            {getCardStatusText(status)}
          </Text>
        </Flex>
      </>
    ),
    ACTIVE: (
      <>
        <Flex flexDir={'row'} alignItems={'center'} gap={2}>
          <ActiveIcon />

          <Text fontSize={'16px'} fontWeight={'400'}>
            {getCardStatusText(status)}
          </Text>
        </Flex>
      </>
    ),
    BLOCKED: (
      <>
        <Flex flexDir={'row'} alignItems={'center'} gap={2}>
          <BlockedIcon />

          <Text fontSize={'16px'} fontWeight={'400'}>
            {getCardStatusText(status)}
          </Text>
        </Flex>
      </>
    ),
    CANCELED: (
      <>
        <Flex flexDir={'row'} alignItems={'center'} gap={2}>
          <CanceledIcon />

          <Text fontSize={'16px'} fontWeight={'400'}>
            {getCardStatusText(status)}
          </Text>
        </Flex>
      </>
    ),
    [CardStatusEnum.NOT_FOUND]: (
      <PendingActivationCardModal title="Aguardando ativação" />
    ),
    PENDING: (
      <>
        <Flex flexDir={'row'} alignItems={'center'} gap={2}>
          <TrackingCardModal
            employeeName={employee.name}
            cardId={employee.cardId}
          />
        </Flex>
      </>
    ),
    MISPLACED: (
      <MisplacedCardModal cardId={employee.cardId} title="Extraviado" />
    ),
  };

  return statusMappings[status] || <></>;
};

export const employeesTableColumns = [
  columnHelper.display({
    id: 'select',
    enableColumnFilter: false,
    header: ({ table }) => (
      <Checkbox
        size="lg"
        isChecked={table.getIsAllRowsSelected()}
        isIndeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
        title="Selecionar todos os colaboradores"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        size="lg"
        isChecked={row.getIsSelected()}
        isIndeterminate={row.getIsSomeSelected()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
  }),
  columnHelper.accessor('name', {
    cell: (info) => (
      <Text cursor="pointer" onClick={info.row.getToggleSelectedHandler()}>
        {info.getValue()}
      </Text>
    ),
    header: 'Nome',
    enableColumnFilter: true,
    sortingFn: (a, b) => {
      return a.original.name.localeCompare(b.original.name);
    },
    meta: { minWidth: '130px' },
  }),
  columnHelper.accessor('cpf', {
    cell: (info) => {
      const cpf = info.getValue();
      return (
        <Text cursor="pointer" onClick={info.row.getToggleSelectedHandler()}>
          {maskCpfForPrivacy(cpf)}
        </Text>
      );
    },
    header: 'CPF',
    enableColumnFilter: true,
    meta: { minWidth: '120px' },
  }),
  columnHelper.accessor('status', {
    cell: (info) => {
      const status =
        info.getValue() as unknown as keyof typeof TranslateEmployeeStatusEnum;
      return <EmployeeStatusBadge status={status} />;
    },
    sortingFn: (a, b) => {
      const statusA =
        TranslateEmployeeStatusEnum[
          a.original
            .status as unknown as keyof typeof TranslateEmployeeStatusEnum
        ];
      const statusB =
        TranslateEmployeeStatusEnum[
          b.original
            .status as unknown as keyof typeof TranslateEmployeeStatusEnum
        ];
      return statusA.localeCompare(statusB);
    },
    enableColumnFilter: false,
    header: 'Status do colaborador',
    meta: { maxWidth: '100px' },
  }),
  columnHelper.accessor('cardStatus', {
    cell: (info) => {
      const employee = info.row.original;
      const status = info.getValue();
      return <>{setCardStatus(status, employee)}</>;
    },
    sortingFn: (a, b) => {
      const statusA = getCardStatusText(a.original.cardStatus);
      const statusB = getCardStatusText(b.original.cardStatus);
      return statusA.localeCompare(statusB);
    },
    header: 'Status do cartão mais atual',
    meta: { maxWidth: '155px' },
    enableColumnFilter: false,
  }),

  columnHelper.accessor('packageName', {
    cell: (info) =>
      info.getValue() || (
        <Link
          textDecoration="underline"
          href={`/colaboradores/editar-colaborador/${info.row.original.id}#beneficios`}
        >
          Escolher pacote
        </Link>
      ),
    enableColumnFilter: true,
    header: 'Pacote de benefícios',
    meta: { minWidth: '150px' },
  }),
];
