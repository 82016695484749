import {
  Box,
  Button,
  CheckboxGroup,
  Flex,
  Stack,
  Text,
} from '@chakra-ui/react';
import CheckboxCard from '../../../../components/CheckboxCard';
import { PixIcon } from '../../../../assets/customIcons/PixIcon';
import { ScanBarCode } from '../../../../assets/customIcons/ScanBarCode';
import { H2, Semibold } from '../../../../components/Typography';
import { IoCalendarOutline } from 'react-icons/io5';
import { useUserCompaniesStore } from '../../../../stores/useUserCompaniesStore';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '../../../../utils/localstorage';
import ConnectForm from '../../../../components/ConnectForm';
import { CheckoutOrderFormValues } from '../../types';
import { Controller } from 'react-hook-form';
import { capitalizeFirstLetter } from '../../../../utils/formatters/capitalizeFirstLetter';
import PaymentMethodCard from '../PaymentMethodCards';
import { WalletIcon } from '../../../../assets/customIcons/WalletIcon';
import { formatCurrencyInCents } from '../../../../utils/formatters/formatCurrency';
import CheckboxText from '../../../../components/CheckboxText';
import { getWeekdayFromISO } from '../../../../utils/formatters/getWeekdayFromISO';
import { getDayMonthYearFromISO } from '../../../../utils/formatters/getDayMonthYearFromISO';
import { RechargeDates } from '../../../../types/recharge';
import InfoDisplayWithIcon from '../../../../components/InfoDisplayWithIcon';
import { FiCalendar } from 'react-icons/fi';
import { formatISO, startOfDay } from 'date-fns';
import { UseQueryResult } from '@tanstack/react-query';

export type StoragedPaymentMethod = {
  id: string;
  method: string;
};

type PaymentMethodProps = {
  grandTotal?: number;
  accountBalance?: number | undefined;
  resetRechargeObservation(): void;
  setRechargeVisibility(value: string): void;
  setChangeDueDate?(value: boolean): void;
  defaultRechargeVisibility: string;
  alert?: React.ReactNode;
  isEdit?: boolean;
  showChangeDueDate?: boolean;
  changeDueDate?: boolean;
  shouldDisableBilletButton?: boolean;
  earliestBilletCreditDate?: string;
  earliestBilletDueDate?: string;
  getRechargeDates: UseQueryResult<RechargeDates, Error>;
  oldRechargeDueDate?: string;
};

const PaymentMethod = ({
  grandTotal,
  accountBalance,
  resetRechargeObservation,
  setRechargeVisibility,
  defaultRechargeVisibility,
  alert,
  isEdit,
  showChangeDueDate,
  setChangeDueDate,
  changeDueDate,
  shouldDisableBilletButton = false,
  earliestBilletCreditDate = '',
  getRechargeDates,
  oldRechargeDueDate = '',
  earliestBilletDueDate = '',
}: PaymentMethodProps) => {
  const { selectedCompany } = useUserCompaniesStore();

  const { data: rechargeDates } = getRechargeDates;

  const calculatedDueDate = rechargeDates?.dueDate || '';

  const paymentMethods = getLocalStorageItem('paymentMethods');

  function handleChangeStorageMethod(method: string) {
    if (method === 'account_balance') {
      return;
    }

    if (!paymentMethods) {
      setLocalStorageItem(
        'paymentMethods',
        JSON.stringify([{ id: selectedCompany?.id, method }])
      );
      return;
    }

    const parsedPaymentMethods: StoragedPaymentMethod[] =
      JSON.parse(paymentMethods);
    const selectedCompanyId = selectedCompany?.id || '';
    const foundMethod = parsedPaymentMethods.find(
      (m) => m.id === selectedCompanyId
    );

    let updatedPaymentMethods = parsedPaymentMethods;
    if (foundMethod) {
      updatedPaymentMethods = parsedPaymentMethods.map((m) =>
        m.id === selectedCompanyId ? { ...m, method } : m
      );
    } else {
      updatedPaymentMethods = [
        ...parsedPaymentMethods,
        { id: selectedCompanyId, method },
      ];
    }

    setLocalStorageItem(
      'paymentMethods',
      JSON.stringify(updatedPaymentMethods)
    );
  }

  const companyCreditsGreaterThanZero =
    accountBalance !== undefined && accountBalance > 0;

  const companyCreditsLessThanGrandTotal =
    accountBalance !== undefined &&
    grandTotal !== undefined &&
    accountBalance < grandTotal;

  return (
    <ConnectForm<CheckoutOrderFormValues>>
      {({ watch, control, formState: { errors }, setValue }) => {
        const paymentMethodType = watch('paymentMethod');
        const scheduledDate = watch('scheduledDate');
        const creditAvailability = watch('creditAvailability');

        const editedRechargeDueDate =
          calculatedDueDate < oldRechargeDueDate
            ? calculatedDueDate
            : oldRechargeDueDate;

        const minimumRechargeDueDate =
          earliestBilletDueDate < editedRechargeDueDate
            ? editedRechargeDueDate
            : earliestBilletDueDate;

        if (
          (!scheduledDate && creditAvailability === 'scheduled') ||
          !creditAvailability
        ) {
          return (
            <Box
              padding={8}
              bg={'white'}
              minHeight={'400px'}
              borderRadius={'8px'}
              height="100%"
            >
              <Flex
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                height="100%"
                gap={6}
              >
                <Box color="orange.500" mr={2}>
                  <IoCalendarOutline size="40px" />
                </Box>
                <Text fontSize="24px" textAlign="center">
                  Escolha a data de crédito para continuar
                </Text>
              </Flex>
            </Box>
          );
        }
        return (
          <Box
            padding={8}
            bg={'white'}
            minHeight={'400px'}
            borderRadius={'8px'}
            height="100%"
          >
            <H2>{isEdit ? 'Alterar' : 'Escolher'} forma de pagamento</H2>

            {alert && <Box>{alert}</Box>}

            {showChangeDueDate && setChangeDueDate && (
              <CheckboxText
                text={`Selecione esta opção se você deseja gerar um novo código ${paymentMethodType === 'pix' ? 'Pix' : 'Boleto'}`}
                marginTop={8}
                isChecked={changeDueDate}
                onChange={(e) => {
                  setChangeDueDate(e.currentTarget.checked);
                }}
              />
            )}

            <Flex gap={6} flexWrap={'wrap'} justifyContent={'left'} my={8}>
              <Controller
                control={control}
                name="paymentMethod"
                render={({ field: { onChange } }) => (
                  <CheckboxGroup>
                    <CheckboxCard
                      justifyContent={'center'}
                      minWidth={'204px'}
                      height={'160px'}
                      isChecked={paymentMethodType === 'pix' ? true : false}
                      onChange={(e) => {
                        if (setChangeDueDate && showChangeDueDate) {
                          setChangeDueDate(showChangeDueDate);
                        }

                        setRechargeVisibility(defaultRechargeVisibility);
                        onChange(e.target.value);
                        handleChangeStorageMethod(e.target.value);
                      }}
                      value="pix"
                    >
                      <PaymentMethodCard
                        color={
                          paymentMethodType === 'pix' ? 'inerit' : 'gray.700'
                        }
                        icon={
                          <PixIcon
                            boxSize={'48px'}
                            color={
                              paymentMethodType === 'pix'
                                ? 'primary.primary'
                                : 'gray.700'
                            }
                          />
                        }
                        paymentMethodName="Pix"
                        type="pix"
                      />
                    </CheckboxCard>
                    <CheckboxCard
                      isDisabled={shouldDisableBilletButton}
                      justifyContent={'center'}
                      minWidth={'204px'}
                      height={'160px'}
                      isChecked={paymentMethodType === 'boleto' ? true : false}
                      onChange={(e) => {
                        if (setChangeDueDate && showChangeDueDate) {
                          setChangeDueDate(showChangeDueDate);
                        }
                        setRechargeVisibility(defaultRechargeVisibility);
                        onChange(e.target.value);
                        handleChangeStorageMethod(e.target.value);
                      }}
                      value="boleto"
                      _disabled={{
                        opacity: 0.5,
                      }}
                    >
                      <PaymentMethodCard
                        color={
                          paymentMethodType === 'boleto' ? 'inerit' : 'gray.700'
                        }
                        icon={
                          <ScanBarCode
                            boxSize={'48px'}
                            color={
                              paymentMethodType === 'boleto'
                                ? 'primary.primary'
                                : 'gray.700'
                            }
                          />
                        }
                        paymentMethodName="Boleto"
                        type="boleto"
                      />
                    </CheckboxCard>

                    {companyCreditsGreaterThanZero && (
                      <CheckboxCard
                        isDisabled={companyCreditsLessThanGrandTotal}
                        justifyContent={'left'}
                        alignItems={'center'}
                        minWidth={'432px'}
                        height={'56px'}
                        padding={'0 16px'}
                        marginTop={0}
                        display="flex"
                        isChecked={paymentMethodType === 'account_balance'}
                        onChange={(e) => {
                          if (setChangeDueDate && showChangeDueDate) {
                            setChangeDueDate(showChangeDueDate);
                          }
                          onChange(e.target.value);
                          setRechargeVisibility('CREATED');
                          handleChangeStorageMethod(e.target.value);
                          if (e.target.value === 'account_balance') {
                            resetRechargeObservation();
                          }
                        }}
                        value="account_balance"
                      >
                        <PaymentMethodCard
                          flexDir={'row'}
                          marginTop="0"
                          color={
                            paymentMethodType === 'account_balance'
                              ? 'account_balance'
                              : 'gray.700'
                          }
                          icon={
                            <WalletIcon
                              width="21px"
                              height="19px"
                              color={
                                paymentMethodType === 'account_balance'
                                  ? 'primary.primary'
                                  : 'gray.700'
                              }
                            />
                          }
                          paymentMethodName={
                            <>
                              <Text
                                as="span"
                                fontSize={'18px'}
                                fontWeight={600}
                              >
                                {' '}
                                Créditos Raiô:{' '}
                                <Text as="span" fontWeight={600}>
                                  {formatCurrencyInCents(accountBalance ?? 0)}
                                </Text>
                              </Text>
                            </>
                          }
                          type="account_balance"
                        />
                      </CheckboxCard>
                    )}

                    {companyCreditsLessThanGrandTotal &&
                      companyCreditsGreaterThanZero && (
                        <Box width={'432px'}>
                          <Text
                            color="primary.primary"
                            fontSize={'16px'}
                            fontWeight={400}
                          >
                            <Text as="span" fontWeight={600}>
                              Para pagar com Créditos Raiô
                            </Text>
                            , o valor da recarga deve ser igual ou inferior ao
                            saldo disponível
                          </Text>
                        </Box>
                      )}
                  </CheckboxGroup>
                )}
              />

              {paymentMethodType && paymentMethodType !== 'account_balance' && (
                <Box width="100%">
                  {isEdit && oldRechargeDueDate && (
                    <InfoDisplayWithIcon
                      marginTop={6}
                      label={`Data de vencimento do
                    ${capitalizeFirstLetter(paymentMethodType)}`}
                      icon={FiCalendar}
                      isLoading={getRechargeDates.isLoading}
                    >
                      {rechargeDates && (
                        <Text>
                          {capitalizeFirstLetter(
                            getWeekdayFromISO(
                              changeDueDate
                                ? calculatedDueDate
                                : minimumRechargeDueDate
                            )
                          )}
                          {', '}
                          <Semibold>
                            {getDayMonthYearFromISO(
                              changeDueDate
                                ? calculatedDueDate
                                : minimumRechargeDueDate
                            )}
                          </Semibold>
                        </Text>
                      )}
                    </InfoDisplayWithIcon>
                  )}

                  {!isEdit && (
                    <InfoDisplayWithIcon
                      marginTop={6}
                      label={`Data de vencimento do
                    ${capitalizeFirstLetter(paymentMethodType)}`}
                      icon={FiCalendar}
                      isLoading={getRechargeDates.isLoading}
                    >
                      {rechargeDates && (
                        <Text>
                          {capitalizeFirstLetter(
                            getWeekdayFromISO(calculatedDueDate)
                          )}
                          {', '}
                          <Semibold>
                            {getDayMonthYearFromISO(calculatedDueDate)}
                          </Semibold>
                        </Text>
                      )}
                    </InfoDisplayWithIcon>
                  )}
                </Box>
              )}
            </Flex>

            {errors.paymentMethod && (
              <Text color="feedback.alert" fontSize="16px" marginY={8}>
                {errors.paymentMethod?.message}
              </Text>
            )}

            {creditAvailability === 'scheduled' &&
              shouldDisableBilletButton &&
              earliestBilletCreditDate && (
                <Stack spacing={4} color="primary.primary">
                  <Text>
                    <Semibold>Para pagar via Boleto</Semibold>, a data de
                    crédito precisar ser na{' '}
                    {getWeekdayFromISO(earliestBilletCreditDate)},{' '}
                    {getDayMonthYearFromISO(earliestBilletCreditDate)}, ou
                    depois
                  </Text>
                  <Button
                    variant="link"
                    padding={0}
                    color="inherit"
                    fontWeight={600}
                    onClick={() => {
                      setValue(
                        'scheduledDate',
                        formatISO(startOfDay(earliestBilletCreditDate))
                      );
                      setValue('paymentMethod', 'boleto');
                    }}
                  >
                    Atualizar automaticamente a data de crédito
                  </Button>
                </Stack>
              )}
          </Box>
        );
      }}
    </ConnectForm>
  );
};

export default PaymentMethod;
