import { capitalizeFirstLetterOfEachWord } from '../../../utils/formatters/capitalizeFirstLetterOfEachWord';
import { sanitizeAndCapitalizeString } from '../../../utils/formatters/sanitizeAndCapitalizeString';
import { isAllUpperCase } from '../../../utils/validations/isAllUpperCase';

export function sanitizeString(value: string) {
  let sanitizedAndCapitalizeString = sanitizeAndCapitalizeString(value);
  if (isAllUpperCase(value)) {
    sanitizedAndCapitalizeString = capitalizeFirstLetterOfEachWord(value);
  }
  return sanitizedAndCapitalizeString;
}
